import { useQuery } from "@tanstack/react-query";

import { getOrderOptions } from "../../../generated/client/octalog-service/@tanstack/react-query.gen";

export const useGetOrder = (orderNumber: string | null) => {
  return useQuery({
    ...getOrderOptions({
      path: {
        orderNumber: orderNumber ?? "",
      },
    }),
    enabled: !!orderNumber,
  });
};
