import type { MantineColorsTuple } from "@mantine/core";
import { useMantineTheme } from "@mantine/core";
import { useCommonColors } from "octalog-base";
import React from "react";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import type { PolarViewBox } from "recharts/types/util/types";

import type { Translatable } from "../../common/Dictionary";

export interface HolzPieChartProps<T> {
  data: T[];
  dataNameKey: keyof T;
  dataValueKey: string;
  dataNameTranslator?: (name: string) => string;
  headline: string;
  subline: string;
  color: MantineColorsTuple;
}

export function HolzPieChart<T>({
  data,
  dataNameKey,
  dataValueKey,
  dataNameTranslator,
  headline,
  subline,
  color,
}: HolzPieChartProps<T>): React.JSX.Element {
  const theme = useMantineTheme();
  const commonColors = useCommonColors();

  function getColor<T>(item: T, dataNameKey: keyof T, index = 0): string {
    return item[dataNameKey] !== undefined && item[dataNameKey] !== null
      ? (color[9 - (index % 6)] ?? theme.primaryColor)
      : theme.primaryColor;
  }

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          dataKey={dataValueKey}
          innerRadius="40%"
          label
          labelLine={false}
          outerRadius="50%"
          paddingAngle={7}
        >
          <Label
            content={
              <HolzPieChartLabel
                headline={headline}
                subline={subline}
                textColor={commonColors.text}
                viewBox={{ cx: Number(Pie.defaultProps.cx), cy: Number(Pie.defaultProps.cy) }}
              />
            }
            style={{ fill: commonColors.text }}
          />
          {data.map((item, index) => (
            <Cell fill={getColor<T>(item, dataNameKey, index)} key={item[dataNameKey] as Translatable} stroke="none" />
          ))}
        </Pie>
        <Legend
          align="center"
          layout="horizontal"
          payload={data.map((item, index) => ({
            id: item[dataNameKey] as string,
            type: "square",
            value: dataNameTranslator ? dataNameTranslator(item[dataNameKey] as Translatable) : item[dataNameKey],
            color: getColor<T>(item, dataNameKey, index),
          }))}
          verticalAlign="bottom"
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

interface HolzPieChartLabelProps {
  viewBox: PolarViewBox;
  headline: string;
  subline: string;
  textColor: string;
}

function HolzPieChartLabel({ viewBox, headline, subline, textColor }: HolzPieChartLabelProps) {
  const { cx, cy } = viewBox;
  return (
    <text dy={-6} fill={textColor} textAnchor="middle" x={cx} y={cy}>
      <tspan>{headline}</tspan>
      <tspan dy="1.2em" x={cx}>
        {subline}
      </tspan>
    </text>
  );
}
