import type { BoxProps } from "@mantine/core";
import { Divider, Container, Stack, Button, Alert, Center, Text } from "@mantine/core";
import { RenderJson } from "octalog-base";

import { nameOf } from "../../../common/Utils";
import { FloatingLabelInput } from "../../../components/input/FloatingLabelInput/FloatingLabelInput";
import { FloatingLabelPriceInput } from "../../../components/input/FloatingLabelInput/FloatingLabelPriceInput";
import type { ArticleDto } from "../../../generated/client/octalog-service";

import { ArticleTable } from "./ArticleTable";
import { useArticleForm } from "./hooks/useArticleForm";
import { useCreateArticleMutation } from "./mutation/useCreateArticleMutation";

interface ArticlesSettingsProps extends BoxProps {}

export function ArticlesSettings({ ...rest }: ArticlesSettingsProps) {
  const form = useArticleForm();

  const createArticle = useCreateArticleMutation({
    onSuccess: async () => {
      form.reset();
    },
  });

  const handleSubmit = form.onSubmit((values) => {
    createArticle.mutate({
      body: values,
    });
  });

  return (
    <Container {...rest}>
      <ArticleTable />
      <Divider />
      <form onSubmit={handleSubmit}>
        <Stack gap="md" my="md">
          <FloatingLabelInput
            {...form.getInputProps(nameOf<ArticleDto>().name)}
            autoComplete="off"
            data-1p-ignore
            data-lpignore
            label="Artikelname"
            required
          />
          <FloatingLabelInput
            {...form.getInputProps(nameOf<ArticleDto>().description)}
            autoComplete="off"
            data-1p-ignore
            data-lpignore
            label="Artikelbeschreibung"
          />
          <FloatingLabelPriceInput {...form.getInputProps(nameOf<ArticleDto>().price)} label="Preis" />
          <FloatingLabelInput {...form.getInputProps(nameOf<ArticleDto>().sku)} label="Artikelnummer (SKU)" required />
          <Center>
            <Button disabled={!form.isValid()} loading={createArticle.isPending} type="submit">
              {createArticle.isPending ? "Wird erstellt..." : "Artikel hinzufügen"}
            </Button>
          </Center>
        </Stack>
      </form>

      {createArticle.isError ? (
        <Alert color="red" mt="md">
          <Text>
            Fehler beim Erstellen des Artikels:{" "}
            {createArticle.error instanceof Error ? createArticle.error.message : "Unknown error"}
          </Text>
        </Alert>
      ) : null}
      <RenderJson data={form} title="Form Values" />
      <RenderJson data={form.getTransformedValues()} title="Transformed Values" />
    </Container>
  );
}
