import { range } from "lodash-es";
import { useEffect, useState } from "react";

const MAX_RANGE = 100;

export function useNumberInputRange(containerIdsOnChangeHandler: (containerIds: string[]) => void) {
  const logPrefix = "useNumberInputRange -";

  const [numberInputFrom, setNumberInputFrom] = useState<number | undefined>();
  const [numberInputTo, setNumberInputTo] = useState<number | undefined>();

  let errorInputFrom: string | null = null;
  if (numberInputTo && !numberInputFrom) {
    errorInputFrom = "Bitte geben Sie eine Startnummer ein";
  }

  let errorInputTo: string | null = null;
  if (numberInputFrom && !numberInputTo) {
    errorInputTo = "Bitte geben Sie eine Endnummer ein";
  } else if (numberInputFrom && numberInputTo && numberInputFrom > numberInputTo) {
    errorInputTo = "Die Endnummer muss größer als die Startnummer sein";
  } else if (numberInputFrom && numberInputTo && numberInputTo - numberInputFrom > MAX_RANGE) {
    errorInputTo = `Es können maximal ${MAX_RANGE} Behälter ausgewählt werden`;
  }

  useEffect(() => {
    const fromToIsSet = numberInputFrom !== undefined && numberInputTo !== undefined;
    console.group(`${logPrefix} range handler`);
    console.log("Set:", fromToIsSet);

    if (errorInputFrom || errorInputTo) {
      console.log("Error:", errorInputFrom, errorInputTo);
      return;
    }

    if (fromToIsSet) {
      const idRange = range(numberInputFrom, numberInputTo + 1).map((it) => it.toString());
      console.log("From:", numberInputFrom);
      console.log("To:", numberInputTo);
      console.log("Range:", idRange);
      containerIdsOnChangeHandler(idRange);
    }
    console.groupEnd();
  }, [numberInputFrom, numberInputTo, containerIdsOnChangeHandler, errorInputFrom, errorInputTo]);

  return {
    numberInputFrom,
    numberInputTo,
    setNumberInputFrom,
    setNumberInputTo,
    errorInputFrom,
    errorInputTo,
  };
}
