import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCommonColors } from "octalog-base";

import { QUERY_KEY_CONTACTS } from "../../common/Constants";
import { tryToExtractDescription } from "../../common/error/ErrorMessageUtil";
import type { UpdateContactByIdData } from "../../generated/client/octalog-service";
import { ContactControllerService } from "../../generated/client/octalog-service";

export const useUpdateContactMutation = () => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (updateContactData: UpdateContactByIdData) => {
      return ContactControllerService.updateContactById(updateContactData);
    },
    onSuccess: () => {
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTACTS,
      });
      notifications.show({
        title: "Erfolg",
        message: `Kontakt wurde erfolgreich aktualisiert!`,
        color: commonColors.greenPrimary,
      });
    },
    onError: (error) => {
      notifications.show({
        title: "Fehler",
        message: `Kontakt konnte nicht aktualisiert werden: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};
