import { Button, TextInput } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useCallback, useState } from "react";

import { useCreateAreaMutation } from "../../../hooks/areas/useAreaHooks";

export function CreateNewAreaRow() {
  const [newAreaName, setNewAreaName] = useState("");
  const createAreaMutation = useCreateAreaMutation();

  const handleAdd = useCallback(() => {
    createAreaMutation.mutate({ body: { name: newAreaName } });
    setNewAreaName("");
  }, [createAreaMutation, newAreaName]);

  return (
    <>
      <TextInput
        data-testid="area-name-input"
        onChange={(event) => setNewAreaName(event.currentTarget.value)}
        placeholder="Lagerplatzname"
        value={newAreaName}
      />
      <Button
        data-testid="create-area-button"
        disabled={createAreaMutation.isPending || !newAreaName}
        fullWidth={true}
        loading={createAreaMutation.isPending}
        onClick={handleAdd}
        variant="light"
      >
        <IconPlus size={16} />
      </Button>
    </>
  );
}
