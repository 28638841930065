import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import type { UseMutationOptions, MutationOptions } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useCommonColors } from "octalog-base";

import { QUERY_KEY_ALL_ORDERS } from "../common/Constants";
import { tryToExtractDescription } from "../common/error/ErrorMessageUtil";

interface MutationConfig<TData, TError extends Error, TVariables, TContext>
  extends MutationOptions<TData, TError, TVariables, TContext> {
  successMessage: string | ((response: TData) => string);
  errorMessage: string | ((error: TError) => string);
}

export const useBaseMutation = <TData, TError extends Error, TVariables>({
  successMessage,
  errorMessage,
  ...rest
}: MutationConfig<TData, TError, TVariables, unknown>): UseMutationOptions<TData, TError, TVariables> => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();

  return {
    ...rest,
    onSuccess: async (data: TData, variables: TVariables, context: unknown) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY_ALL_ORDERS });
      const message = typeof successMessage === "function" ? successMessage(data) : successMessage;
      notifications.show({
        title: "Erfolg",
        message: message,
        color: commonColors.greenPrimary,
        icon: <IconCheck size={16} />,
      });
      rest.onSuccess?.(data, variables, context);
    },
    onError: (error: TError, variables: TVariables, context: unknown) => {
      const message = typeof errorMessage === "function" ? errorMessage(error) : errorMessage;
      notifications.show({
        title: "Fehler",
        message: `${message}: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
        icon: <IconX size={16} />,
      });
      rest.onError?.(error, variables, context);
    },
  };
};
