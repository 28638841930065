import { Anchor, Button, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCommonColors } from "octalog-base";
import { useState } from "react";
import { useNavigate } from "react-router";

import { REGEX_EMAIL } from "../../common/Constants";
import { tryToExtractDescription } from "../../common/error/ErrorMessageUtil";
import { FloatingLabelInput } from "../../components/input/FloatingLabelInput/FloatingLabelInput";
import { PasswordStrength } from "../../components/input/PasswordStrength/PasswordStrength";
import { useSignUpMutation } from "../../service/iam/IamHooks";

import { AuthContainer } from "./components/AuthContainer";
import { AuthLoadingState } from "./components/AuthLoadingState";
import { AuthSuccessState } from "./components/AuthSuccessState";
import classes from "./signIn.module.css";

export function SignUp() {
  const commonColors = useCommonColors();
  const navigate = useNavigate();
  const signUp = useSignUpMutation();
  const [isValidPassword, setIsValidPassword] = useState(false);

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      username: "",
      password: "",
      firstName: "",
      lastName: "",
      companyName: "",
    },
    validate: {
      username: (value) => (REGEX_EMAIL.test(value) ? null : "Email ist nicht gültig"),
      firstName: (value) => (value.length < 2 ? "Name muss mindestens 2 Buchstaben haben" : null),
      lastName: (value) => (value.length < 2 ? "Name muss mindestens 2 Buchstaben haben" : null),
      companyName: (value) => (value.length < 2 ? "Name muss mindestens 2 Buchstaben haben" : null),
      password: () => (isValidPassword ? null : "error"),
    },
  });

  function renderErrorBox() {
    if (signUp.error?.message !== "Unauthorized") {
      return (
        <Text c={commonColors.redPrimary} className={signUp.isError ? classes.error : undefined} mt={20} size="sm">
          {tryToExtractDescription(signUp.error)}
        </Text>
      );
    }

    return null;
  }

  return (
    <AuthContainer
      subtitle={
        <>
          Haben Sie bereits einen Account?{" "}
          <Anchor component="button" onClick={() => navigate("/sign-in")} size="sm">
            Hier einloggen
          </Anchor>
        </>
      }
      title="Herzlich willkommen!"
    >
      {signUp.isPending ? (
        <AuthLoadingState title="Registrierung wird durchgeführt" />
      ) : signUp.isSuccess ? (
        <AuthSuccessState
          buttonText="Anmelden"
          dataTestIdButton="signIn"
          dataTestIdTitle="signUpSuccessful"
          onButtonClick={() => navigate("/sign-in")}
          title="Registrierung erfolgreich"
        >
          <Text mt={10}>Eine Bestätigungsmail wurde an Ihre Email-Adresse gesendet.</Text>
          <Text mt={10}>Bitte bestätigen Sie Ihre Registrierung.</Text>
        </AuthSuccessState>
      ) : (
        <form
          onSubmit={form.onSubmit((values) => {
            signUp.mutate({ body: values });
          })}
        >
          <FloatingLabelInput
            autoComplete="given-name"
            data-testid="firstName"
            id="firstName"
            label="Vorname"
            required
            {...form.getInputProps("firstName")}
          />
          <FloatingLabelInput
            autoComplete="family-name"
            data-testid="lastName"
            id="lastName"
            label="Nachname"
            required
            {...form.getInputProps("lastName")}
          />
          <FloatingLabelInput
            autoComplete="organization"
            data-testid="companyName"
            id="companyName"
            label="Unternehmen"
            required
            {...form.getInputProps("companyName")}
          />
          <FloatingLabelInput
            autoComplete="username"
            data-testid="username"
            id="username"
            label="Email"
            required
            {...form.getInputProps("username")}
          />
          <PasswordStrength
            aria-label="Neues Passwort"
            autoComplete="new-password"
            data-testid="password"
            id="new-password"
            isValidCallback={setIsValidPassword}
            label="Passwort"
            maxLength={100}
            minLength={8}
            required
            {...form.getInputProps("password")}
          />
          <Button data-testid="submit" disabled={signUp.isPending || !form.isValid()} fullWidth mt="xl" type="submit">
            Registrieren
          </Button>
          {renderErrorBox()}
        </form>
      )}
    </AuthContainer>
  );
}
