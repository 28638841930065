import { Button, Grid, Modal, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import { useState } from "react";

import type { AreaResponseDto } from "../../../generated/client/octalog-service";
import { useUpdateAreaMutation } from "../../../hooks/areas/useAreaHooks";

export function EditAreaModal({ areaDto }: { areaDto: AreaResponseDto }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [area, setArea] = useState({ areaId: areaDto.areaId, areaName: areaDto.name });
  const updateAreaMutation = useUpdateAreaMutation();

  const handleSave = () => {
    updateAreaMutation.mutate({ path: { areaId: area.areaId }, body: { name: area.areaName } });
    close();
  };

  return (
    <>
      <Modal onClose={close} opened={opened} title="Lagerplatz bearbeiten">
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              data-autofocus
              label="Name"
              onChange={(event) => setArea({ ...area, areaName: event.currentTarget.value })}
              placeholder={areaDto.name}
              value={area.areaName}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Button onClick={close} variant="default">
              Abbrechen
            </Button>
          </Grid.Col>
          <Grid.Col span={6} ta="right">
            <Button onClick={handleSave}>Speichern</Button>
          </Grid.Col>
        </Grid>
      </Modal>
      <Button fullWidth={true} onClick={open} variant="default">
        <IconEdit size={16} />
      </Button>
    </>
  );
}
