import { Paper, Space, Stack } from "@mantine/core";
import { useDebouncedValue, useMediaQuery } from "@mantine/hooks";
import type { DataTableSortStatus } from "mantine-datatable";
import { DataTable } from "mantine-datatable";
import { useCommonColors, RootContainer } from "octalog-base";
import { useEffect, useState } from "react";

import type { GetContainerResponseDto } from "../../generated/client/octalog-service";
import { useAreaNameTranslator, useGetAreasQuery } from "../../hooks/areas/useAreaHooks";
import { useGetContainerTypesQuery } from "../../service/containers/ContainerTypeHooks";

import { AssignContainerToAreaModal } from "./components/AssignContainerToAreaModal";
import { ContainerCount } from "./components/ContainerCount";
import { EmptyModal } from "./components/EmptyModal";
import { CONTAINER_NAME_ACCESSOR } from "./ContainerAccessor";
import { StockHeader } from "./StockHeader";
import { getStockTableColumns, sortIcons } from "./StockTableColumns";
import { useFilteredAndSortedContainers } from "./useContainerFilter";

export function Stock() {
  const commonColors = useCommonColors();
  const containerTypesQuery = useGetContainerTypesQuery();
  const getAreaName = useAreaNameTranslator();

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<GetContainerResponseDto>>({
    columnAccessor: CONTAINER_NAME_ACCESSOR,
    direction: "asc",
  });

  const [containers, setContainers] = useState([] as GetContainerResponseDto[]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  // Filter states
  const [selectedContainerNames, setSelectedContainerNames] = useState("");
  const [debouncedSelectedContainerNames] = useDebouncedValue(selectedContainerNames, 200);
  const [selectedContainerTypes, setSelectedContainerTypes] = useState<string[]>([]);
  const [selectedWoodTypes, setSelectedWoodTypes] = useState<string[]>([]);
  const [selectedCuttingLengths, setSelectedCuttingLengths] = useState<string[]>([]);
  const [selectedHumidityLevels, setSelectedHumidityLevels] = useState<string[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);

  const { filteredAndSortedContainers, isPending, isError, error } = useFilteredAndSortedContainers();

  useEffect(() => {
    const containers = filteredAndSortedContainers(
      debouncedSelectedContainerNames,
      selectedContainerTypes,
      selectedWoodTypes,
      selectedCuttingLengths,
      selectedHumidityLevels,
      selectedAreas,
      sortStatus,
    );
    setContainers(containers);
  }, [
    debouncedSelectedContainerNames,
    filteredAndSortedContainers,
    selectedAreas,
    selectedContainerTypes,
    selectedCuttingLengths,
    selectedHumidityLevels,
    selectedWoodTypes,
    sortStatus,
  ]);

  const [selectedRecords, setSelectedRecords] = useState<GetContainerResponseDto[]>([]);
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
  const [isEmptyModalOpen, setIsEmptyModalOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width: 600px)");

  if (isError) {
    throw error;
  }

  const columns = getStockTableColumns(commonColors, containerTypesQuery, useGetAreasQuery(), getAreaName, {
    selectedContainerNames,
    setSelectedContainerNames,
    selectedContainerTypes,
    setSelectedContainerTypes,
    selectedWoodTypes,
    setSelectedWoodTypes,
    selectedCuttingLengths,
    setSelectedCuttingLengths,
    selectedHumidityLevels,
    setSelectedHumidityLevels,
    selectedAreas,
    setSelectedAreas,
  });

  function renderContent() {
    return (
      <>
        <Stack gap="md">
          <StockHeader
            isMobile={isMobile || false}
            openAssignAreaModal={() => setIsAreaModalOpen(true)}
            openEmptyModal={() => setIsEmptyModalOpen(true)}
            selectedRecords={selectedRecords.length}
            totalRecords={containers.length}
          />
        </Stack>
        <Space h="md" />
        <DataTable
          columns={columns}
          defaultColumnProps={{
            textAlign: "center",
            noWrap: true,
          }}
          fetching={isPending}
          highlightOnHover
          idAccessor={CONTAINER_NAME_ACCESSOR}
          loaderBackgroundBlur={2}
          loaderSize="lg"
          loaderType="dots"
          mih={{ base: "50vh", md: "70vh" }}
          onPageChange={setPage}
          onRecordsPerPageChange={setPageSize}
          onSelectedRecordsChange={setSelectedRecords}
          onSortStatusChange={setSortStatus}
          page={page}
          records={containers.slice((page - 1) * pageSize, page * pageSize)}
          recordsPerPage={pageSize}
          recordsPerPageOptions={[10, 20, 30, 50]}
          selectedRecords={selectedRecords}
          sortIcons={sortIcons}
          sortStatus={sortStatus}
          totalRecords={containers.length}
        />
        <div>
          <ContainerCount selectedCount={selectedRecords.length} totalCount={containers.length} />
        </div>
      </>
    );
  }

  // noinspection RequiredAttributes
  return (
    <RootContainer fluid maw="100%" w="100%">
      {isMobile ? (
        renderContent()
      ) : (
        <Paper p="md" withBorder>
          {renderContent()}
        </Paper>
      )}
      <AssignContainerToAreaModal
        onClose={() => setIsAreaModalOpen(false)}
        opened={isAreaModalOpen}
        selectedContainerIds={selectedRecords.map((container) => container.containerId)}
      />
      <EmptyModal
        containerIds={selectedRecords.map((container) => container.containerId)}
        onClose={() => setIsEmptyModalOpen(false)}
        opened={isEmptyModalOpen}
        pending={isPending}
      />
    </RootContainer>
  );
}
