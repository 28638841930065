import { Grid, TextInput } from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";

import { nameOf } from "../../../common/Utils";
import { AreaSelection } from "../../../components/input/container/area-selection/AreaSelection";
import { ContainerTypeSelection } from "../../../components/input/container/container-type-selection/ContainerTypeSelection";
import type {
  UpdateContainerRequestDto,
  UpdateContainerTypeRequestDto,
} from "../../../generated/client/octalog-service";

import type { UpdateContainerFormData } from "./useEditContainerForm";

interface EditContainerModalDetailsProps {
  form: UseFormReturnType<UpdateContainerFormData, (values: UpdateContainerFormData) => UpdateContainerFormData>;
}

export function EditContainerModalContainerDetails({ form }: EditContainerModalDetailsProps) {
  return (
    <>
      <Grid.Col span={12}>
        <TextInput
          key={`${nameOf<UpdateContainerFormData>().container}.${nameOf<UpdateContainerRequestDto>().containerName}`}
          label="ID"
          {...form.getInputProps(
            `${nameOf<UpdateContainerFormData>().container}.${nameOf<UpdateContainerRequestDto>().containerName}`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <AreaSelection
          key={`${nameOf<UpdateContainerFormData>().container}.${nameOf<UpdateContainerRequestDto>().areaId}`}
          {...form.getInputProps(
            `${nameOf<UpdateContainerFormData>().container}.${nameOf<UpdateContainerRequestDto>().areaId}`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <ContainerTypeSelection
          key={`${nameOf<UpdateContainerFormData>().container}.${nameOf<UpdateContainerRequestDto>().containerType}.${nameOf<UpdateContainerTypeRequestDto>().containerTypeId}`}
          {...form.getInputProps(
            `${nameOf<UpdateContainerFormData>().container}.${nameOf<UpdateContainerRequestDto>().containerType}.${nameOf<UpdateContainerTypeRequestDto>().containerTypeId}`,
          )}
        />
      </Grid.Col>
    </>
  );
}
