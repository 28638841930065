import type { DataTableSortStatus } from "mantine-datatable";

export function sortData<T>(data: T[], sortStatus: DataTableSortStatus<T>): T[] {
  return [...data].sort((a, b) => {
    const aValue = a[sortStatus.columnAccessor as keyof T];
    const bValue = b[sortStatus.columnAccessor as keyof T];

    if ((aValue === undefined || aValue === null) && (bValue === undefined || bValue === null)) return 0;
    if (aValue === undefined || aValue === null) return 1;
    if (bValue === undefined || bValue === null) return -1;

    if (sortStatus.direction === "asc") {
      return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    }
    return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
  });
}
