import type { GroupProps } from "@mantine/core";
import { Badge, Group } from "@mantine/core";
import { useCommonColors } from "octalog-base";

export interface ContactListLegendProps extends GroupProps {}

export function ContactListLegend(props: ContactListLegendProps) {
  const commonColors = useCommonColors();

  return (
    <Group justify="center" {...props}>
      <Badge color={commonColors.primaryColor} variant="outline">
        Kundennummer
      </Badge>
      <Badge color={commonColors.redPrimary} variant="outline">
        Lieferantennummer
      </Badge>
      <Badge color={commonColors.bluePrimary} variant="outline">
        Kd.-Nr. bei Lieferant
      </Badge>
    </Group>
  );
}
