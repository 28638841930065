import type { ContainerProps, MantineColorsTuple, PaperProps } from "@mantine/core";
import { Box, Container, Paper, rem, Space, Text, ThemeIcon, useMantineTheme } from "@mantine/core";
import { useResizeObserver } from "@mantine/hooks";
import type { IconType } from "octalog-base";
import { usePrimaryShade } from "octalog-base";
import React, { useEffect, createElement, memo } from "react";

import classes from "./IconPaper.module.css";

export interface IconPaperProps extends ContainerProps {
  title?: string;
  description?: string;
  icon: IconType;
  children?: React.ReactNode;
  paperProps?: PaperProps;
  iconColor?: MantineColorsTuple;
  iconLabel?: string;
  topValueOnChangeHandler?: (topValue: number) => void;
  onContentHeightChangeHandler?: (height: number) => void;
}

function IconComponent({
  icon,
  iconColor,
  iconLabel,
}: {
  icon: IconType;
  iconColor?: MantineColorsTuple;
  iconLabel?: string;
}) {
  const theme = useMantineTheme();
  const shade = usePrimaryShade();

  return (
    <Box className={classes.iconWrapper}>
      <ThemeIcon
        aria-label={iconLabel}
        color={iconColor ? iconColor[shade] : theme.primaryColor[shade]}
        radius={60}
        size={60}
        variant="light"
      >
        {createElement(icon, { style: { width: rem(32), height: rem(32) }, stroke: 1.5 })}
      </ThemeIcon>
    </Box>
  );
}

const getTopValue = (title?: string, description?: string) => {
  return (title && description && 94) || (title && 74) || (description && 57) || 34;
};

const IconPaper: React.FC<IconPaperProps> = memo(
  ({
    title,
    description,
    icon,
    children,
    paperProps,
    iconColor,
    iconLabel,
    topValueOnChangeHandler,
    onContentHeightChangeHandler: onContentHeightChangeHandler,
    ...rest
  }) => {
    const topValue = getTopValue(title, description);

    useEffect(() => {
      if (topValueOnChangeHandler) {
        topValueOnChangeHandler(topValue);
      }
    }, [topValue, topValueOnChangeHandler]);

    const [ref, rect] = useResizeObserver();

    useEffect(() => {
      if (onContentHeightChangeHandler) {
        onContentHeightChangeHandler(rect.height);
      }
    }, [rect.height, onContentHeightChangeHandler]);

    return (
      <Container fluid miw="100%" p={0} {...rest}>
        <Box mt={-topValue} pos="relative" top={topValue}>
          <IconComponent icon={icon} iconColor={iconColor} iconLabel={iconLabel} />
          {title ? (
            <Text fw={500} fz="lg" mt={10}>
              {title}
            </Text>
          ) : null}
          {description ? (
            <Text c="dimmed" fz="xs" mt={3}>
              {description}
            </Text>
          ) : null}
        </Box>
        <Paper className={classes.paper} withBorder {...paperProps} ref={ref}>
          <Space h={topValue} />
          {children}
        </Paper>
      </Container>
    );
  },
);

IconPaper.displayName = "IconPaper";

export { IconPaper };
