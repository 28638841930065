import { useQuery } from "@tanstack/react-query";

import type { LocalDateInternal } from "../../../common/calendar/LocalDateInternal";
import { getOrdersOptions } from "../../../generated/client/octalog-service/@tanstack/react-query.gen";

export const useGetOrders = ({
  scheduledDeliveryDateFrom,
  scheduledDeliveryDateTo,
}: {
  scheduledDeliveryDateFrom?: LocalDateInternal;
  scheduledDeliveryDateTo?: LocalDateInternal;
} = {}) => {
  return useQuery({
    ...getOrdersOptions({
      query: {
        scheduledDeliveryDateFrom: scheduledDeliveryDateFrom?.toString(),
        scheduledDeliveryDateTo: scheduledDeliveryDateTo?.toString(),
      },
    }),
  });
};
