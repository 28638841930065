import { Button, NumberInput, Select, TextInput } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";

import { UNIT_OF_MEASUREMENT_OPTIONS_SHORT } from "../../../common/Constants";
import { UnitOfMeasurement } from "../../../generated/client/octalog-service";
import { useCreateContainerTypeMutation } from "../../../service/containers/ContainerTypeHooks";

export function CreateNewContainerTypeRow() {
  const createContainerTypeMutation = useCreateContainerTypeMutation();

  const defaultContainerType = useMemo(
    () => ({
      name: "",
      volume: 1,
      unitOfMeasurement: UnitOfMeasurement.LOOSE_CUBIC_METER,
    }),
    [],
  );

  const [newContainerType, setNewContainerType] = useState(defaultContainerType);

  const handleAdd = useCallback(() => {
    createContainerTypeMutation.mutate({ body: newContainerType });
    setNewContainerType(defaultContainerType);
  }, [createContainerTypeMutation, defaultContainerType, newContainerType]);

  const handleChange = useCallback(
    (field: string, value: string | number) => {
      setNewContainerType({ ...newContainerType, [field]: value });
    },
    [newContainerType],
  );

  return (
    <>
      <TextInput
        data-testid="container-type-name-input"
        onChange={(event) => handleChange("name", event.currentTarget.value)}
        placeholder="Name"
        ta="center"
        value={newContainerType.name}
      />
      <NumberInput
        data-testid="container-type-volume-input"
        decimalScale={2}
        decimalSeparator=","
        fixedDecimalScale
        min={0}
        onChange={(event) => handleChange("volume", Number(event))}
        placeholder="Volumen"
        value={newContainerType.volume}
      />
      <Select
        data={UNIT_OF_MEASUREMENT_OPTIONS_SHORT}
        data-testid="container-type-unit-of-measurement-input"
        onChange={(value) => handleChange("unitOfMeasurement", value as UnitOfMeasurement)}
        placeholder="Maßeinheit"
        value={newContainerType.unitOfMeasurement}
      />
      <Button
        data-testid="create-container-type-button"
        disabled={
          !newContainerType.name ||
          !newContainerType.unitOfMeasurement ||
          !newContainerType.volume ||
          createContainerTypeMutation.isPending
        }
        fullWidth={true}
        loading={createContainerTypeMutation.isPending}
        onClick={handleAdd}
        variant="light"
      >
        <IconPlus size={16} />
      </Button>
    </>
  );
}
