import { useMutation, useQueryClient } from "@tanstack/react-query";

import { LocalDateInternal } from "../../../common/calendar/LocalDateInternal";
import {
  scheduleDeliveryMutation,
  getOrderQueryKey,
  getDeliveryScheduleQueryKey,
} from "../../../generated/client/octalog-service/@tanstack/react-query.gen";
import { useBaseMutation } from "../../../hooks/useBaseMutation";

export const useScheduleDeliveryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    useBaseMutation({
      ...scheduleDeliveryMutation(),
      successMessage: "Der Liefertermin wurde erfolgreich aktualisiert.",
      errorMessage: "Der Liefertermin konnte nicht aktualisiert werden",
      onSuccess: (_, request) => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({
          queryKey: getOrderQueryKey({
            path: {
              orderNumber: request.body.orderNumber,
            },
          }),
        });

        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({
          queryKey: getDeliveryScheduleQueryKey({
            query: {
              startDate: LocalDateInternal.weekStart(request.body.deliveryDate).toString(),
              endDate: LocalDateInternal.weekEnd(request.body.deliveryDate).toString(),
            },
          }),
        });
      },
    }),
  );
};
