import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { Grid } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { RootContainer } from "octalog-base";
import { useEffect } from "react";
import invariant from "tiny-invariant";

import type { LocalDateInternal } from "../../../../common/calendar/LocalDateInternal";
import type { LocalTimeInternal } from "../../../../common/calendar/LocalTimeInternal";
import { instanceOf, nameOf } from "../../../../common/Utils";
import {
  HEADER_HEIGHT_BASE,
  FOOTER_HEIGHT_BASE,
  HEADER_HEIGHT_LG,
  FOOTER_HEIGHT_LG,
} from "../../../../layout/constants";

import { Calendar } from "./components/Calendar/Calendar";
import { OrderDetailPanel } from "./components/OrderDetailPanel/OrderDetailPanel";
import { OrderList } from "./components/OrderList/OrderList";
import { DeliverySchedulingProvider } from "./context";
import type { DropSourceData, DropDestinationData } from "./hooks/useDeliveryBoxDrop";
import { useDeliveryBoxDrop, DestinationId } from "./hooks/useDeliveryBoxDrop";

export function DeliverySchedulingView() {
  const handleDeliveryDrop = useDeliveryBoxDrop();

  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        const destination = location.current.dropTargets[0];
        if (!destination) {
          // if dropped outside any drop targets
          return;
        }

        const destinationId = destination.data.destinationId;
        invariant(
          destinationId === DestinationId.calendar || destinationId === DestinationId.orderList,
          `Invalid destination ID: ${destinationId}`,
        );

        const orderNumber = source.data.orderNumber;
        invariant(typeof orderNumber === "string", `Invalid order ID: ${orderNumber}`);

        const duration = source.data.duration;
        invariant(typeof duration === "string", `Invalid duration: ${duration}`);

        const date = destination.data.date;
        invariant(
          instanceOf<LocalDateInternal>(date, nameOf<LocalDateInternal>().__localDateInternal) || date === undefined,
          `Invalid date: ${date}`,
        );

        const time = destination.data.time;
        invariant(
          instanceOf<LocalTimeInternal>(time, nameOf<LocalTimeInternal>().__localTimeInternal) || time === undefined,
          `Invalid time: ${time}`,
        );

        const dropSourceData: DropSourceData = {
          orderNumber: orderNumber,
          duration: duration,
        };

        const dropDestinationData: DropDestinationData = {
          destinationId: destinationId,
          date: date,
          time: time,
        };

        handleDeliveryDrop(dropSourceData, dropDestinationData);
      },
    });
  }, [handleDeliveryDrop]);

  const { height } = useViewportSize();

  const calculatedHeight = {
    base: height - (HEADER_HEIGHT_BASE + FOOTER_HEIGHT_BASE - 36),
    lg: height - (HEADER_HEIGHT_LG + FOOTER_HEIGHT_LG + 50),
  };

  return (
    <RootContainer fluid maw="100%" w="100%">
      <DeliverySchedulingProvider>
        <Grid styles={{ inner: { height: "100%" } }}>
          <Grid.Col py={0} span={{ base: 12, md: 8 }}>
            <Calendar maxHeight={calculatedHeight} />
          </Grid.Col>
          <Grid.Col py={0} span={{ base: 12, md: 4 }}>
            <OrderList />
          </Grid.Col>
        </Grid>
        <OrderDetailPanel />
      </DeliverySchedulingProvider>
    </RootContainer>
  );
}
