import { Grid, Text } from "@mantine/core";

interface LabelValueProps {
  label: string;
  value: string | number | undefined;
}

export function LabelValue({ label, value }: LabelValueProps) {
  return (
    <Grid align="flex-start" gutter={{ base: 0, md: "md" }}>
      <Grid.Col span={{ base: 12, xs: 6 }}>
        <Text fw={700} size="md">
          {label}:
        </Text>
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 6 }}>
        <Text c="dimmed" size="md">
          {value}
        </Text>
      </Grid.Col>
    </Grid>
  );
}
