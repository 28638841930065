import { Grid } from "@mantine/core";
import { IconBox } from "@tabler/icons-react";
import { get } from "lodash-es";

import { PieChartPaper } from "../../../components/paper/PieChartPaper";
import type {
  ContainerCountByArea,
  ContainerCountByCuttingLength,
  ContainerCountByHumidityLevel,
  ContainerCountByWoodType,
  ContainerStatisticsDto,
} from "../../../generated/client/octalog-service";
import { AbstractIconPaperStatistics } from "../AbstractIconPaperStatistics";

import { useByContainerPieChartConfig } from "./useByContainerPieChartConfig";

const calcSumOfContainers = (
  data: (
    | ContainerCountByArea
    | ContainerCountByWoodType
    | ContainerCountByHumidityLevel
    | ContainerCountByCuttingLength
  )[],
) => data?.reduce((acc, cur) => acc + cur.count, 0)?.toString() || "n/a";

export function PieChartStatisticsByContainer() {
  const chartConfig = useByContainerPieChartConfig();

  return (
    <AbstractIconPaperStatistics icon={IconBox} title="Container Statistiken">
      {(data: ContainerStatisticsDto | undefined) => {
        return (
          <Grid align="stretch" justify="space-between" pb={8}>
            {chartConfig.map((config) => (
              <Grid.Col key={config.title} span={{ base: 12, md: 6 }}>
                <PieChartPaper
                  holzPieChartProps={{
                    headline: calcSumOfContainers(get(data, config.dataKey)),
                    subline: config.subline,
                    data: get(data, config.dataKey) || [],
                    dataNameKey: config.dataNameKey,
                    dataNameTranslator: config.dataNameTranslator,
                    dataValueKey: config.dataValueKey,
                    color: config.color,
                  }}
                  icon={config.icon}
                  iconColor={config.color}
                  px={8}
                  title={config.title}
                />
              </Grid.Col>
            ))}
          </Grid>
        );
      }}
    </AbstractIconPaperStatistics>
  );
}
