import { Title, Stack, Center, Text } from "@mantine/core";
import { IconWood, IconShoppingCart } from "@tabler/icons-react";

import { formatCurrency } from "../../../../../common/UnitFormatter";
import { FormList } from "../../../../../components/output/FormList/FormList";
import { useOrderItems, OrderItemType } from "../contexts/FormContexts";

import { OrderItem } from "./OrderItem";

export function OrderItemsSection() {
  const {
    form,
    state: { totalPrice, itemCount },
    onAddItem,
    onRemoveItem,
  } = useOrderItems();

  const renderOrderItems = () => (
    <>
      {form.values.itemsWood.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key --- Needs refactoring
        <OrderItem index={index} key={index} onRemoveItem={onRemoveItem} type={OrderItemType.WOOD} />
      ))}
      {form.values.itemsArticle.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key --- Needs refactoring
        <OrderItem index={index} key={index} onRemoveItem={onRemoveItem} type={OrderItemType.ARTICLE} />
      ))}
    </>
  );

  const onAddWoodItem = () => onAddItem(OrderItemType.WOOD);

  const onAddArticleItem = () => onAddItem(OrderItemType.ARTICLE);

  return (
    <Stack ta="left">
      <Center>
        <Title c="dimmed" mt="xs" order={4}>
          Artikel
        </Title>
      </Center>
      <FormList
        label="Artikel"
        menuItems={[
          { key: OrderItemType.WOOD, label: "Holz", onClick: onAddWoodItem, Icon: IconWood },
          {
            key: OrderItemType.ARTICLE,
            label: "Anderer Artikel",
            onClick: onAddArticleItem,
            Icon: IconShoppingCart,
          },
        ]}
        shadow="none"
        withBorder={false}
      >
        {renderOrderItems()}
      </FormList>
      {itemCount > 0 ? (
        <Text size="sm" ta="right">
          Gesamtpreis: {formatCurrency(totalPrice)}
        </Text>
      ) : null}
    </Stack>
  );
}
