import { Text } from "@mantine/core";

import { CopyText } from "../../../../../../../../components/output/copy-text/CopyText";

export interface AddressDetailProps {
  label: string;
  address: string;
}

export function AddressDetail({ label, address }: AddressDetailProps) {
  return (
    <>
      <CopyText fw={700} mr={5} valueToCopy={address}>
        {label}
      </CopyText>
      <Text c="dimmed" style={{ whiteSpace: "pre-line" }}>
        {address}
      </Text>
    </>
  );
}
