import { Button } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";

interface RemoveButtonProps {
  onClick: () => void;
}

export function RemoveButton({ onClick }: RemoveButtonProps) {
  const commonColors = useCommonColors();
  return (
    <Button color={commonColors.redPrimary} onClick={onClick} variant="transparent">
      <IconX color={commonColors.redPrimary} size={16} />
    </Button>
  );
}
