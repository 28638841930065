import { IconFileInvoice } from "@tabler/icons-react";
import { RootContainer } from "octalog-base";
import { useEffect } from "react";

import { IconPaper } from "../../../../components/paper/IconPaper";
import { useGetContactsQuery } from "../../../../service/contacts/useGetContactsQuery";
import { generateAddressTexts } from "../../../contacts/list/contactUtil";
import { useOrderForm } from "../../components/OrderForm/hooks/useOrderForm";
import { OrderForm } from "../../components/OrderForm/OrderForm";

export function CreateOrderView() {
  const { form, handleSubmit, isPending } = useOrderForm();

  const contactsQuery = useGetContactsQuery();

  const selectedContact = contactsQuery.data?.find((contact) => contact.contactId === Number(form.values.contactId));

  // required to avoid infinite loop
  const formSetValues = form.setValues;
  useEffect(() => {
    const addressTexts = generateAddressTexts(selectedContact);

    const invoiceAddressText = addressTexts?.get("INVOICE");
    const deliveryAddressText = addressTexts?.get("DELIVERY");

    formSetValues({
      invoiceAddress: invoiceAddressText ?? deliveryAddressText ?? "",
      deliveryAddress: deliveryAddressText ?? "",
    });
  }, [formSetValues, selectedContact]);

  return (
    <RootContainer fluid>
      <IconPaper icon={IconFileInvoice} paperProps={{ pb: 16, px: 16 }} title="Neue Bestellung">
        <OrderForm form={form} isPending={isPending} onSubmit={handleSubmit} />
      </IconPaper>
    </RootContainer>
  );
}
