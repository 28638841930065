import type { BoxProps } from "@mantine/core";
import { Box } from "@mantine/core";
import clsx from "clsx";
import { memo } from "react";

import type { LocalDateInternal } from "../../../../../../../../../common/calendar/LocalDateInternal";
import type { ScheduledOrder } from "../../../../../types/ScheduledOrder";
import { TOTAL_COLUMN_COUNT, TOTAL_ROW_COUNT, ROWS_PER_HOUR, ROWS_PER_DAY } from "../../../common/constants";
import { getTimeOfRowIndex } from "../../utils/indexUtils";

import classes from "./ContentCell.module.css";
import { TimeSlot } from "./TimeSlot/TimeSlot";

interface ContentCellProps extends BoxProps {
  columnIndex: number;
  rowIndex: number;
  orders: ScheduledOrder[];
  weekStart: LocalDateInternal;
}

export const ContentCell = memo(function ContentCell({
  columnIndex,
  rowIndex,
  orders,
  weekStart,
  ...rest
}: ContentCellProps) {
  if (columnIndex < 0 || columnIndex >= TOTAL_COLUMN_COUNT) {
    console.error("ContentCell should not be rendered for an invalid day index", columnIndex);
    throw new Error("ContentCell should not be rendered for an invalid day index");
  } else if (rowIndex < 0 || rowIndex >= TOTAL_ROW_COUNT) {
    console.error("ContentCell should not be rendered for an invalid time index", rowIndex);
    throw new Error("ContentCell should not be rendered for an invalid time index");
  } else {
    // To get the day of the week, we need to subtract 1 from the column index
    // Monday is column 1 in the grid, but day 0 in dayjs
    const dayOfWeek = columnIndex;
    const date = weekStart.addDays(dayOfWeek);
    const time = getTimeOfRowIndex(rowIndex);

    const isFirstOfHour = rowIndex % ROWS_PER_HOUR === 0;
    const isLastOfDay = rowIndex === ROWS_PER_DAY - 1;

    return orders.length === 0 ? (
      <Box
        className={clsx(classes.contentCell, {
          [classes.weekend as string]: dayOfWeek >= 5,
          [classes.firstOfHour as string]: isFirstOfHour,
          [classes.lastOfDay as string]: isLastOfDay,
        })}
        {...rest}
      >
        <TimeSlot date={date} time={time} />
      </Box>
    ) : (
      <Box className={clsx(classes.contentCell, { [classes.weekend as string]: dayOfWeek >= 5 })} {...rest}>
        {orders.map((order) => (
          <TimeSlot date={date} key={order.orderNumber} order={order} time={time} />
        ))}
      </Box>
    );
  }
});
