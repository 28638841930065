import type { TransformedValues, UseFormReturnType } from "@mantine/form";
import type { GetInputPropsReturnType, LooseKeys, GetInputPropsOptions } from "octalog-base";

import { LocalDateInternal } from "../../../../../common/calendar/LocalDateInternal";
import { LocalTimeInternal } from "../../../../../common/calendar/LocalTimeInternal";
import { trimRecursively } from "../../../../../common/Utils";
import type {
  OrderDto,
  OrderUpdateDto,
  OrderCreateDto,
  OrderItemArticleUpdateDto,
  OrderItemWoodUpdateDto,
  OrderItemArticleCreateDto,
  OrderItemWoodCreateDto,
} from "../../../../../generated/client/octalog-service";
import { useCreateOrderMutation } from "../../../mutation/useCreateOrderMutation";
import { useUpdateOrderMutation } from "../../../mutation/useUpdateOrderMutation";
import {
  isOrderItemWoodDto,
  isOrderItemArticleDto,
  isOrderItemWood,
  isOrderItemArticle,
} from "../../../utils/orderTypeUtil";
import type { OrderFormData, OrderItemWoodData, OrderItemArticleData } from "../contexts/FormContexts";
import { OrderItemType, useOrderForm as useBaseOrderForm } from "../contexts/FormContexts";

import { useOrderValidation } from "./useOrderValidation";

const initialValues = {
  totalPrice: 0,
  contactId: -1,
  deliveryAddress: "",
  deliveryTargetDateStart: undefined,
  deliveryTargetDateEnd: undefined,
  deliveryTargetTimeStart: undefined,
  deliveryTargetTimeEnd: undefined,
  invoiceAddress: "",
  orderDate: LocalDateInternal.today(),
  itemsWood: [],
  itemsArticle: [],
} satisfies OrderFormData;

const enhanceGetInputProps = (payload: {
  inputProps: GetInputPropsReturnType;
  field: LooseKeys<OrderFormData>;
  options: GetInputPropsOptions;
  form: UseFormReturnType<OrderFormData, typeof transformOrderData>;
}) => {
  if (payload.options.fieldType === "ClearableTimeInput") {
    // noinspection JSUnusedGlobalSymbols
    return {
      onClear: () => {
        payload.form.setFieldValue(payload.field, undefined);
      },
    };
  }

  return {};
};

export const transformInitialOrderData = (data: OrderDto): OrderFormData => {
  const woodItems: OrderItemWoodData[] = [];
  const articleItems: OrderItemArticleData[] = [];

  for (const [index, item] of data.items.entries()) {
    if (isOrderItemWoodDto(item)) {
      woodItems.push({
        ...item,
        type: OrderItemType.WOOD,
        index: index,
      } as OrderItemWoodData);
    } else if (isOrderItemArticleDto(item)) {
      articleItems.push({
        ...item,
        type: OrderItemType.ARTICLE,
        index: index,
      } as OrderItemArticleData);
    } else {
      throw new Error("Unknown order item type");
    }
  }

  return {
    ...data,
    deliveryTargetDateStart: LocalDateInternal.fromString(data.deliveryTargetDateStart),
    deliveryTargetDateEnd: LocalDateInternal.fromStringOrUndefined(data.deliveryTargetDateEnd),
    deliveryTargetTimeStart: data.deliveryTargetTimeStart
      ? LocalTimeInternal.fromString(data.deliveryTargetTimeStart)
      : undefined,
    deliveryTargetTimeEnd: data.deliveryTargetTimeEnd
      ? LocalTimeInternal.fromString(data.deliveryTargetTimeEnd)
      : undefined,
    orderDate: LocalDateInternal.fromString(data.orderDate),
    itemsWood: woodItems,
    itemsArticle: articleItems,
  };
};

export type OrderForm = UseFormReturnType<OrderFormData, typeof transformOrderData>;

export const transformOrderData = (values: OrderFormData): OrderUpdateDto | OrderCreateDto => {
  const trimmedValues = trimRecursively<OrderFormData>(values);
  if (!trimmedValues) {
    throw new Error("Order data may not be null");
  }

  const baseTransform = {
    ...trimmedValues,
    deliveryTargetDateStart: trimmedValues.deliveryTargetDateStart?.toString(),
    deliveryTargetDateEnd: trimmedValues.deliveryTargetDateEnd?.toString(),
    deliveryTargetTimeStart: trimmedValues.deliveryTargetTimeStart?.toString(),
    deliveryTargetTimeEnd: trimmedValues.deliveryTargetTimeEnd?.toString(),
    orderDate: trimmedValues.orderDate.toString(),
    items: [...trimmedValues.itemsWood, ...trimmedValues.itemsArticle],
  };

  // If it's an update (has orderNumber), return OrderDto
  if ("orderNumber" in values && values.orderNumber) {
    return {
      ...baseTransform,
      orderNumber: values.orderNumber,
      status: values.status,
      items: baseTransform.items.map((item) => {
        if (isOrderItemWood(item)) {
          return {
            ...item,
            type: "OrderItemWoodUpdateDto",
          } as OrderItemWoodUpdateDto;
        } else if (isOrderItemArticle(item)) {
          return {
            ...item,
            type: "OrderItemArticleUpdateDto",
          } as OrderItemArticleUpdateDto;
        } else {
          throw new Error("Unknown order item type");
        }
      }),
    } as OrderUpdateDto;
  }

  // If it's a creation (no orderNumber), return OrderCreateDto
  return {
    ...baseTransform,
    items: baseTransform.items.map((item) => {
      if (isOrderItemWood(item)) {
        return {
          ...item,
          type: "OrderItemWoodCreateDto",
        } as OrderItemWoodCreateDto;
      } else if (isOrderItemArticle(item)) {
        return {
          ...item,
          type: "OrderItemArticleCreateDto",
        } as OrderItemArticleCreateDto;
      } else {
        throw new Error("Unknown order item type");
      }
    }),
  } as OrderCreateDto;
};

export const useOrderForm = (initialOrderData?: OrderDto, resetOnSubmit = true) => {
  const validateForm = useOrderValidation();
  const form = useBaseOrderForm({
    validateInputOnBlur: true,
    validateInputOnChange: true,
    initialValues: initialOrderData ? transformInitialOrderData(initialOrderData) : initialValues,
    validate: validateForm,
    enhanceGetInputProps,
    transformValues: transformOrderData,
  });

  const createMutation = useCreateOrderMutation();
  const updateMutation = useUpdateOrderMutation();
  const isPending = createMutation.isPending || updateMutation.isPending;

  const handleSubmit = async (values: TransformedValues<typeof form>) => {
    try {
      await (initialOrderData
        ? updateMutation.mutateAsync({
            path: { orderNumber: initialOrderData.orderNumber },
            body: values as OrderUpdateDto,
          })
        : createMutation.mutateAsync({
            body: values as OrderCreateDto,
          }));

      if (resetOnSubmit) {
        form.reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    form,
    handleSubmit,
    isPending,
  };
};
