import type {
  OrderItemDto,
  OrderItemWoodDto,
  OrderItemArticleDto,
  OrderItemWoodCreateDto,
  OrderItemCreateDto,
} from "../../../generated/client/octalog-service";
import type {
  BaseOrderItemData,
  OrderItemArticleData,
  OrderItemWoodData,
} from "../components/OrderForm/contexts/FormContexts";
import { OrderItemType, OrderItemDtoType } from "../components/OrderForm/contexts/FormContexts";

export function isOrderItemWood(orderItem: BaseOrderItemData): orderItem is OrderItemWoodData {
  return orderItem.type === OrderItemType.WOOD;
}

export function isOrderItemArticle(orderItem: BaseOrderItemData): orderItem is OrderItemArticleData {
  return orderItem.type === OrderItemType.ARTICLE;
}

export function isOrderItemWoodDto(orderItem: OrderItemDto): orderItem is OrderItemWoodDto {
  return orderItem.type === OrderItemDtoType.WOOD;
}

export function isOrderItemArticleDto(orderItem: OrderItemDto): orderItem is OrderItemArticleDto {
  return orderItem.type === OrderItemDtoType.ARTICLE;
}

export function isOrderItemWoodCreateDto(orderItem: OrderItemCreateDto): orderItem is OrderItemWoodCreateDto {
  return orderItem.type === OrderItemDtoType.WOOD_CREATE;
}

export function isOrderItemArticleCreateDto(orderItem: OrderItemCreateDto): orderItem is OrderItemArticleDto {
  return orderItem.type === OrderItemDtoType.ARTICLE_CREATE;
}
