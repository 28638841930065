import { Box, Text, Stack, Group } from "@mantine/core";
import { useMediaQuery, useResizeObserver } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";

import { formatCurrency } from "../../../common/UnitFormatter";
import { QueryLoaderBoundary } from "../../../components/loader/QueryLoaderBoundary";
import { LabelValue } from "../../../components/output/LabelValue/LabelValue";
import type { ArticleDto } from "../../../generated/client/octalog-service";
import { useGetArticles } from "../../../query/useGetArticles";

export function ArticleTable() {
  const articlesQuery = useGetArticles();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [ref, rect] = useResizeObserver();

  console.log(rect);

  const getColumns = () => {
    const baseColumns = [
      {
        accessor: "name",
        title: "Name",
        render: (record: ArticleDto) => (
          <Text maw={rect.width / 3} ta="left" truncate="end" w="100%">
            {record.name}
          </Text>
        ),
      },
      {
        accessor: "sku",
        title: "Artikelnummer",
        render: (record: ArticleDto) => (
          <Group gap="xs" justify="space-between" wrap="nowrap">
            <Text maw={rect.width / 3} truncate="end">
              {record.name}
            </Text>
            {isMobile ? <IconChevronDown color="gray" size={16} /> : null}
          </Group>
        ),
      },
    ];

    const expandedColumns = [
      { accessor: "description", title: "Beschreibung" },
      {
        accessor: "price",
        title: "Preis",
        render: (record: ArticleDto) => (record.price ? formatCurrency(record.price) : ""),
      },
    ];

    return isMobile ? baseColumns : [...baseColumns, ...expandedColumns];
  };

  return (
    <Box ref={ref}>
      <QueryLoaderBoundary queryResult={articlesQuery} size="md">
        {(articlesData) => (
          <DataTable
            columns={getColumns()}
            highlightOnHover={isMobile}
            idAccessor="sku"
            my="md"
            records={articlesData.data}
            rowExpansion={
              isMobile
                ? {
                    content: ({ record }) => (
                      <Box p="xs">
                        <Stack gap="xs" justify="flex-start" ta="left">
                          <LabelValue label="Name" value={record.name} />
                          <LabelValue label="Artikelnummer" value={record.sku} />
                          <LabelValue label="Beschreibung" value={record.description || "-"} />
                          <LabelValue label="Preis" value={record.price ? formatCurrency(record.price) : "-"} />
                        </Stack>
                      </Box>
                    ),
                  }
                : undefined
            }
            style={{ root: { backgroundColor: "red" } }}
            withTableBorder
          />
        )}
      </QueryLoaderBoundary>
    </Box>
  );
}
