import type { FocusEvent } from "react";
import { useState } from "react";

import type { FloatingLabelBaseProps } from "./types";

export type UseFloatingLabelProps<T extends HTMLElement> = FloatingLabelBaseProps<T> & {
  onBlur?: (event: FocusEvent<T>) => void;
  onFocus?: (event: FocusEvent<T>) => void;
};

export interface UseFloatingLabelResult<T extends HTMLElement> {
  floating: boolean | undefined;
  handleBlur: (event: FocusEvent<T>) => void;
  handleFocus: (event: FocusEvent<T>) => void;
}

export function useFloatingLabel<T extends HTMLElement>({
  value,
  onBlur,
  onFocus,
}: UseFloatingLabelProps<T>): UseFloatingLabelResult<T> {
  const [focused, setFocused] = useState<boolean>(false);

  const normalizedValue = value?.toString().trim() ?? "";
  const floating = normalizedValue.length > 0 || focused || undefined;

  const handleBlur = (event: FocusEvent<T>): void => {
    setFocused(false);
    onBlur?.(event);
  };

  const handleFocus = (event: FocusEvent<T>): void => {
    setFocused(true);
    onFocus?.(event);
  };

  return {
    floating,
    handleBlur,
    handleFocus,
  };
}
