import { ActionIcon, Button, Divider, Grid, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import { isEmpty } from "lodash-es";
import { RenderJson, useCommonColors } from "octalog-base";

import type { GetContainerResponseDto } from "../../../generated/client/octalog-service";
import { useUpdateContainerMutation } from "../../../service/containers/ContainerHooks";

import { EditContainerModalContainerDetails } from "./EditContainerModalContainerDetails";
import { EditContainerModalContent } from "./EditContainerModalContent";
import type { UpdateContainerFormData } from "./useEditContainerForm";
import { useEditContainerForm } from "./useEditContainerForm";

export function EditContainerModal({ containerDto }: { containerDto: GetContainerResponseDto }) {
  const commonColors = useCommonColors();
  const [opened, { open, close }] = useDisclosure(false);
  const updateContainerMutation = useUpdateContainerMutation();
  const form = useEditContainerForm(containerDto);

  function handleSubmit(values: UpdateContainerFormData) {
    updateContainerMutation.mutate({
      body: values.container,
      path: {
        containerId: values.containerId,
      },
    });
  }

  return (
    <>
      <Modal onClose={close} opened={opened}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col span={12}>
              <Title order={4}>Behälter-Details</Title>
            </Grid.Col>
            <EditContainerModalContainerDetails form={form} />
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            <Grid.Col span={12}>
              <Title order={4}>Behälter-Inhalt</Title>
            </Grid.Col>
            <EditContainerModalContent containerDto={containerDto} form={form} />
            <Grid.Col span={6}>
              <Button onClick={close} variant="default">
                Abbrechen
              </Button>
            </Grid.Col>
            <Grid.Col span={6} ta="right">
              <Button
                disabled={updateContainerMutation.isPending || !isEmpty(form.errors)}
                loading={updateContainerMutation.isPending}
                type="submit"
              >
                Speichern
              </Button>
            </Grid.Col>
          </Grid>
        </form>
        <RenderJson data={form.errors} />
      </Modal>
      <ActionIcon color={commonColors.primaryColor} onClick={open} size="sm" variant="subtle">
        <IconEdit size={16} />
      </ActionIcon>
    </>
  );
}
