import { useMemo } from "react";

import type { ScheduledOrder } from "../../../../types/ScheduledOrder";
import { TOTAL_COLUMN_COUNT, TOTAL_ROW_COUNT } from "../../common/constants";
import { getIndicesOfDateAndTime } from "../utils/indexUtils";

export function useOrdersMatrix(orders: ScheduledOrder[]) {
  return useMemo(() => {
    // Initialize a 2D array: [days][timeSlots]
    const matrix: ScheduledOrder[][][] = Array.from({ length: TOTAL_COLUMN_COUNT }, () =>
      Array.from({ length: TOTAL_ROW_COUNT }, () => [] as ScheduledOrder[]),
    );

    // Group orders into their respective slots
    for (const order of orders) {
      const deliveryDate = order.deliverySchedule.deliveryDate;
      const deliveryTime = order.deliverySchedule.deliveryTime;

      const { columnIndex, rowIndex } = getIndicesOfDateAndTime(deliveryDate, deliveryTime);
      matrix[columnIndex]![rowIndex]!.push(order);
    }

    return matrix;
  }, [orders]);
}
