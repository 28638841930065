import { createFormContext } from "@mantine/form";
import type { CountryCode } from "libphonenumber-js";

import type {
  ContactCreateDto,
  CreatePhoneNumberDto,
  IndividualContactCreateDto,
} from "../../../../generated/client/octalog-service";
import type { SalutationType } from "../contact/individual/salutation/SalutationType";
import type { ContactIdentifierType } from "../identifier/ContactIdentifierType";

import type { transformValues } from "./contactFormTransformer";

export type FormPhoneNumber = CreatePhoneNumberDto & {
  phoneNumber: string;
  countryCode: CountryCode;
};

export type ContactData = ContactCreateDto & {
  phoneNumbers: FormPhoneNumber[];
  selectedContactIdentifier: ContactIdentifierType;
  contactId?: number;
  individualContact?: IndividualContactData;
};

export type IndividualContactData = IndividualContactCreateDto & {
  salutationType: SalutationType;
};

export const [CreateContactFormProvider, useCreateContactContext, useCreateContactForm] = createFormContext<
  ContactData,
  typeof transformValues
>();
