import { Button, Grid, Modal, NumberInput, Select, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import { useState } from "react";

import { UNIT_OF_MEASUREMENT_OPTIONS_SHORT } from "../../../common/Constants";
import { translateUnitOfMeasurement } from "../../../common/Dictionary";
import type { ContainerTypeResponseDto, UnitOfMeasurement } from "../../../generated/client/octalog-service";
import { useUpdateContainerTypeMutation } from "../../../service/containers/ContainerTypeHooks";

export function EditContainerTypeModal({ containerTypeDto }: { containerTypeDto: ContainerTypeResponseDto }) {
  const [isModalOpen, { open, close }] = useDisclosure(false);
  const [containerType, setContainerType] = useState({
    name: containerTypeDto.name,
    unitOfMeasurement: containerTypeDto.unitOfMeasurement,
    volume: containerTypeDto.volume,
  });
  const updateContainerTypeMutation = useUpdateContainerTypeMutation();

  const handleSave = () => {
    updateContainerTypeMutation.mutate({
      body: {
        ...containerTypeDto,
        ...containerType,
      },
    });
    close();
  };

  return (
    <>
      <Button fullWidth={true} onClick={open} variant="default">
        <IconEdit size={16} />
      </Button>
      <Modal onClose={close} opened={isModalOpen} title="Edit Container Type">
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              data-autofocus
              label="Name"
              onChange={(event) => setContainerType({ ...containerType, name: event.currentTarget.value })}
              placeholder={containerTypeDto.name}
              value={containerType.name}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              decimalScale={2}
              decimalSeparator=","
              fixedDecimalScale
              label="Volumen"
              min={0}
              onChange={(event) => {
                setContainerType({ ...containerType, volume: Number(event) });
              }}
              value={containerType.volume.toString()}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              data={UNIT_OF_MEASUREMENT_OPTIONS_SHORT}
              label="Maßeinheit"
              onChange={(value) => {
                if (value !== null) {
                  setContainerType({ ...containerType, unitOfMeasurement: value as UnitOfMeasurement });
                }
              }}
              placeholder={translateUnitOfMeasurement(containerTypeDto.unitOfMeasurement as UnitOfMeasurement)}
              value={containerType.unitOfMeasurement}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Button onClick={close} variant="default">
              Abbrechen
            </Button>
          </Grid.Col>
          <Grid.Col span={6} ta="right">
            <Button onClick={handleSave}>Speichern</Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
}
