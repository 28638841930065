import { Text, Grid } from "@mantine/core";

import type { OrderDto } from "../../../../../../../../generated/client/octalog-service";

import { AddressDetail } from "./AddressDetail";

export interface AddressDetailsProps {
  order: OrderDto;
}

export function AddressDetails({ order }: AddressDetailsProps) {
  return (
    <Grid>
      <Grid.Col span={12}>
        <Text fw={700}>Adresse</Text>
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 6 }} ta="left">
        <AddressDetail address={order.invoiceAddress} label="Rechnungsadresse" />
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 6 }} ta="left">
        <AddressDetail address={order.deliveryAddress || "Gleich mit Rechnungsadresse"} label="Lieferadresse" />
      </Grid.Col>
    </Grid>
  );
}
