import { Text } from "@mantine/core";

import type {
  AreaResponseDto,
  ContainerTypeResponseDto,
  CuttingLengthDto,
  HumidityLevelDto,
  WoodTypeDto,
  WoodType,
  CuttingLength,
  HumidityLevel,
  ContactDetailEmailType,
  ContactDetailPhoneNumberType,
} from "../../generated/client/octalog-service";
import type { Translatable } from "../Dictionary";
import { translate } from "../Dictionary";

export type TypeofTranslatable =
  | typeof WoodType
  | typeof CuttingLength
  | typeof HumidityLevel
  | typeof ContactDetailEmailType
  | typeof ContactDetailPhoneNumberType;

export function translatableToComboboxData(translatable: TypeofTranslatable) {
  return Object.values(translatable).map((it) => ({
    label: translate(it as Translatable),
    value: it,
  }));
}

export function areasToComboBoxData(areas?: AreaResponseDto[]) {
  return areas?.map((area) => {
    return {
      value: area.areaId.toString(),
      label: area.name,
    };
  });
}

export function containerTypesToComboBoxData(containerTypes?: ContainerTypeResponseDto[]) {
  return containerTypes?.map((containerType) => {
    return {
      value: containerType.containerTypeId.toString(),
      label: containerType.name,
    };
  });
}

export function woodTypesToComboBoxData(woodTypes?: WoodTypeDto[]) {
  return (
    woodTypes?.map((woodType) => {
      return {
        value: woodType.woodTypeId.toString(),
        label: <Text size="sm">{translate(woodType.name as Translatable)}</Text>,
      };
    }) ?? []
  );
}

export function cuttingLengthsToComboBoxData(cuttingLengths?: CuttingLengthDto[]) {
  return (
    cuttingLengths?.map((cuttingLength) => {
      return {
        value: cuttingLength.cuttingLengthId.toString(),
        label: <Text size="sm">{translate(cuttingLength.name as Translatable)}</Text>,
      };
    }) ?? []
  );
}

export function humidityLevelToComboBoxData(humidityLevels?: HumidityLevelDto[]) {
  return (
    humidityLevels?.map((humidityLevel) => {
      return {
        value: humidityLevel.humidityLevelId.toString(),
        label: <Text size="sm">{translate(humidityLevel.name as Translatable)}</Text>,
      };
    }) ?? []
  );
}
