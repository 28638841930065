import { Button, Group, Paper, Stack, Text, Title, Select } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { RenderJson } from "octalog-base";

import { Duration } from "../../../../../../common/calendar/Duration";
import { LocalDateInternal } from "../../../../../../common/calendar/LocalDateInternal";
import { LocalTimeInternal } from "../../../../../../common/calendar/LocalTimeInternal";
import { formatDate } from "../../../../../../common/UnitFormatter";
import { nameOf } from "../../../../../../common/Utils";
import type {
  OrderDto,
  OrderStatus,
  OrderDeliveryScheduleDto,
  LocalTime,
} from "../../../../../../generated/client/octalog-service";
import { OrderStatusBadge } from "../../../../components/OrderStatusBadge/OrderStatusBadge";
import { useScheduleDeliveryMutation } from "../../../../mutation/useScheduleDeliveryMutation";

import classes from "./OrderDetailPanel.module.css";

interface OrderDetailPanelContentProps {
  order: OrderDto;
}

interface OrderDeliveryScheduleFormData extends Omit<OrderDeliveryScheduleDto, "deliveryDate" | "deliveryTime"> {
  deliveryDate: Date | null;
  deliveryTime: LocalTime | "";
}

export function OrderDetailPanelContent({ order }: OrderDetailPanelContentProps) {
  const deliveryScheduleMutation = useScheduleDeliveryMutation();

  const transformOrderDeliveryScheduleFormData = (values: OrderDeliveryScheduleFormData): OrderDeliveryScheduleDto => {
    return {
      ...values,
      deliveryDate: LocalDateInternal.fromDate(values.deliveryDate!).toString(),
      deliveryTime: LocalTimeInternal.fromString(values.deliveryTime).toString(),
    };
  };

  const form = useForm<OrderDeliveryScheduleFormData, typeof transformOrderDeliveryScheduleFormData>({
    initialValues: {
      orderNumber: order.orderNumber,
      deliveryDate: order.deliverySchedule?.deliveryDate
        ? LocalDateInternal.fromString(order.deliverySchedule.deliveryDate).toDate()
        : null,
      deliveryTime: order.deliverySchedule?.deliveryTime ?? "",
      duration: order.deliverySchedule?.duration ?? "PT1H",
    },

    validate: {
      orderNumber: (value: string | undefined) => (value ? null : "Bitte wählen Sie eine Bestellung aus"),
      deliveryDate: (value: Date | null) => (value ? null : "Bitte geben Sie ein Datum ein"),
      deliveryTime: (value: LocalTime | null) => (value ? null : "Bitte geben Sie eine Uhrzeit ein"),
      duration: (value: string | undefined) => (value ? null : "Bitte geben Sie eine Dauer ein"),
    },

    transformValues: transformOrderDeliveryScheduleFormData,
  });

  const durationData = [
    { value: "PT15M", label: "15 Minuten" },
    { value: "PT30M", label: "30 Minuten" },
    { value: "PT45M", label: "45 Minuten" },
    { value: "PT1H", label: "1 Stunde" },
    { value: "PT1H15M", label: "1 Stunde 15 Minuten" },
    { value: "PT1H30M", label: "1 Stunde 30 Minuten" },
    { value: "PT1H45M", label: "1 Stunde 45 Minuten" },
    { value: "PT2H", label: "2 Stunden" },
    { value: "PT2H15M", label: "2 Stunden 15 Minuten" },
    { value: "PT2H30M", label: "2 Stunden 30 Minuten" },
    { value: "PT2H45M", label: "2 Stunden 45 Minuten" },
    { value: "PT3H", label: "3 Stunden" },
    { value: "PT3H15M", label: "3 Stunden 15 Minuten" },
    { value: "PT3H30M", label: "3 Stunden 30 Minuten" },
    { value: "PT3H45M", label: "3 Stunden 45 Minuten" },
  ];

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        deliveryScheduleMutation.mutate({
          body: values,
        });
      })}
    >
      <Stack className={classes.container}>
        <Title order={4}>Bestellung #{order.orderNumber}</Title>

        <Paper className={classes.section} p="md" withBorder>
          <Stack gap="md">
            <div>
              <Text className={classes.label}>Kunde</Text>
              <Text className={classes.value} fw={500}>
                {order.invoiceAddress.split("\n")[0]}
              </Text>
              <Text className={classes.value} size="sm">
                {order.invoiceAddress.split("\n").slice(1).join(", ")}
              </Text>
            </div>
            <div>
              <Text className={classes.label}>Status</Text>
              <OrderStatusBadge size="lg" status={order.status as OrderStatus} />
            </div>
            <div>
              <Text className={classes.label}>Lieferadresse</Text>
              <Text className={classes.value}>{order.deliveryAddress || order.invoiceAddress}</Text>
            </div>
            <div>
              <Text className={classes.label}>Gewünschter Lieferzeitraum</Text>
              <Text className={classes.value} fw={500}>
                {formatDate(order.deliveryTargetDateStart)}
                {order.deliveryTargetDateEnd ? ` - ${formatDate(order.deliveryTargetDateEnd)}` : ""}
              </Text>
              <Text className={classes.value} size="sm">
                {order.deliveryTargetTimeStart ? ` ${order.deliveryTargetTimeStart}` : ""}
                {order.deliveryTargetTimeEnd ? ` - ${order.deliveryTargetTimeEnd}` : ""}
              </Text>
            </div>
            {order.deliverySchedule ? (
              <div>
                <Text className={classes.label}>Geplante Lieferung</Text>
                <Text className={classes.value} fw={500}>
                  {formatDate(order.deliverySchedule.deliveryDate)} {order.deliverySchedule.deliveryTime}
                </Text>
                <Text className={classes.value} size="sm">
                  Dauer: {Duration.parse(order.deliverySchedule.duration).humanize()}
                </Text>
              </div>
            ) : null}
          </Stack>
        </Paper>

        <Paper className={classes.section} p="md" withBorder>
          <Text className={classes.label}>Geplante Lieferung</Text>
          <Stack>
            <DatePicker {...form.getInputProps(`${nameOf<OrderDeliveryScheduleFormData>().deliveryDate}`)} />
            <Text size="sm">Start</Text>
            <TimeInput {...form.getInputProps(`${nameOf<OrderDeliveryScheduleFormData>().deliveryTime}`)} />
            <Text>Dauer</Text>
            <Select
              data={durationData}
              {...form.getInputProps(`${nameOf<OrderDeliveryScheduleFormData>().duration}`)}
            />
          </Stack>
        </Paper>

        <Group className={classes.buttonGroup} justify="center">
          <Button
            color="gray"
            onClick={() => {
              form.reset();
            }}
            variant="light"
          >
            Zurücksetzen
          </Button>
          <Button type="submit">Speichern</Button>
        </Group>

        <RenderJson data={order} h={700} w={800} />
      </Stack>
    </form>
  );
}
