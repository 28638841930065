import { Container, Stack } from "@mantine/core";
import { Footer, OctalogLogo } from "octalog-base";
import { Outlet } from "react-router";

export function LayoutExternal() {
  return (
    <Stack h="100vh" justify="space-between">
      <Stack justify="center" pt="10vh">
        <Container h="15vh">
          <OctalogLogo size="100%" />
        </Container>
        <Outlet />
      </Stack>
      <Footer />
    </Stack>
  );
}
