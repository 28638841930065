import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCommonColors } from "octalog-base";

import { QUERY_KEY_CONTAINER_STATISTICS, QUERY_KEY_CONTAINERS } from "../../common/Constants";
import { tryToExtractDescription } from "../../common/error/ErrorMessageUtil";
import {
  createContainersMutation,
  deleteContentBatchWithoutCheckMutation,
  updateContainerMutation,
  getContainersQueryKey,
} from "../../generated/client/octalog-service/@tanstack/react-query.gen";

export const useCreateContainersMutation = () => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();
  return useMutation({
    ...createContainersMutation(),
    onSuccess: (response) => {
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINERS,
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: getContainersQueryKey({
          query: { containerNames: response.containers.map((container) => container.containerName) },
        }),
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINER_STATISTICS,
      });
      notifications.show({
        title: "Erfolg",
        message: `Behälter ${response.containers.map((container) => container.containerName).join(", ")} wurde/n erfolgreich erstellt!`,
        color: commonColors.greenPrimary,
      });
    },
    onError: (error: Error, variables) => {
      notifications.show({
        title: "Fehler",
        message: `Behälter ${variables.body.containers.map((container) => container.containerName).join(", ")} konnte/n nicht erstellt werden: ${error.message}`,
        color: commonColors.redPrimary,
      });
    },
  });
};

export interface ClearContainersProps {
  containerIds: number[];
  emptyingDate?: Date;
  newAreaId?: number;
}

export const useClearContainersMutation = () => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();
  return useMutation({
    ...deleteContentBatchWithoutCheckMutation(),
    onSuccess: (response) => {
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINERS,
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: [
          getContainersQueryKey({
            query: { containerNames: response.containers.map((container) => container.containerName) },
          }),
        ],
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINER_STATISTICS,
      });
      notifications.show({
        title: "Erfolg",
        message: `Behälter ${response.containers.map((container) => container.containerName).join(", ")} wurde/n erfolgreich entleert!`,
        color: commonColors.greenPrimary,
      });
    },
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Behälter konnte/n nicht entleert werden: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};

export const useUpdateContainerMutation = () => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();
  return useMutation({
    ...updateContainerMutation(),
    onSuccess: (response) => {
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINERS,
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: [
          getContainersQueryKey({
            query: { containerNames: [response.containerName] },
          }),
        ],
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINER_STATISTICS,
      });
      notifications.show({
        title: "Erfolg",
        message: `Behälter ${response.containerName} wurde erfolgreich aktualisiert!`,
        color: commonColors.greenPrimary,
      });
    },
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Behälter konnte nicht aktualisiert werden: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};
