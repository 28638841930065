import { Button, Container, Grid, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router";

import classes from "./GeneralError.module.css";

export function GeneralError() {
  const navigate = useNavigate();
  return (
    <Container className={classes.root} fluid>
      <Grid align="center" justify="center">
        <Grid.Col span={12}>
          <div className={classes.label}>💥 Oh nein...</div>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title className={classes.title}>...es ist ein unerwarteter Fehler aufgetreten 😵</Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text c="dimmed" className={classes.description} size="lg">
            Wir entschuldigen uns für die Unannehmlichkeiten.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text c="dimmed" className={classes.description} size="lg">
            Falls das Problem weiterhin besteht, kontaktieren Sie bitte den Support:
          </Text>
          <Text c="dimmed" className={classes.description} size="lg">
            <a href="mailto:info@octalog.de">info@octalog.de</a>
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Button onClick={() => navigate("/")} size="md" variant="subtle">
            Zurück zur Startseite
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
