import { useEffect, useState } from "react";

import { useErrorMessageGenerator } from "../../../common/hooks/use-error-message-generator";
import { useFilterContainerStates } from "../../../service/containers/useFilterContainerStates";
import type { ContainerWithState } from "../../../service/containers/useGetContainerStates";
import { EMPTY, FILLED, NON_EXISTENT } from "../../../service/containers/useGetContainerStates";

export function useSelectionError(mode: "fill" | "empty", containerStates: ContainerWithState[]) {
  const [error, setError] = useState<string>("");
  const determineContainerStates = useFilterContainerStates();
  const errorMessageGenerator = useErrorMessageGenerator();

  useEffect(() => {
    if (mode === "fill") {
      setError(
        errorMessageGenerator(
          determineContainerStates(containerStates, [NON_EXISTENT]).map(
            (containerState) => containerState.containerName,
          ),
          "Behälter {names} existiert nicht. Bitte vorher erstellen!",
          "Behälter {names} existieren nicht. Bitte vorher erstellen!",
          "{names}",
        ) ||
          errorMessageGenerator(
            determineContainerStates(containerStates, [FILLED]).map((containerState) => containerState.containerName),
            "Behälter {names} ist bereits befüllt. Bitte vorher entleeren!",
            "Behälter {names} sind bereits befüllt. Bitte vorher entleeren!",
            "{names}",
          ),
      );
    } else if (mode === "empty") {
      setError(
        errorMessageGenerator(
          determineContainerStates(containerStates, [NON_EXISTENT]).map(
            (containerState) => containerState.containerName,
          ),
          "Behälter {names} existiert nicht!",
          "Behälter {names} existieren nicht!",
          "{names}",
        ) ||
          errorMessageGenerator(
            determineContainerStates(containerStates, [EMPTY]).map((containerState) => containerState.containerName),
            "Behälter {names} ist nicht befüllt!",
            "Behälter {names} sind nicht befüllt!",
            "{names}",
          ),
      );
    }
  }, [containerStates, determineContainerStates, errorMessageGenerator, mode]);

  return error;
}
