import type { ContainerProps } from "@mantine/core";
import { Button, Container, Group, NumberInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { RenderJson } from "octalog-base";

import { nameOf } from "../../../../common/Utils";
import type { PriceSettingsDto } from "../../../../generated/client/octalog-service";
import { usePriceSettingsMutation } from "../../../../service/prices/usePriceSettingsMutation";

export function EnablePriceSettings(props: ContainerProps) {
  const priceSettingsMutation = usePriceSettingsMutation();

  const form = useForm<PriceSettingsDto>({
    mode: "uncontrolled",
    initialValues: {
      basePrice: undefined,
      priceModifiersWoodType: [],
      priceModifiersHumidityLevel: [],
      priceModifiersCuttingLength: [],
    },
    validate: {
      basePrice: (value) => {
        return value === undefined || value === null || value < 1 ? "Bitte geben Sie einen Basispreis ein" : null;
      },
    },
    validateInputOnBlur: true,
  });

  return (
    <Container {...props}>
      <form
        onSubmit={form.onSubmit((values) =>
          priceSettingsMutation.mutate({
            body: values,
          }),
        )}
      >
        <NumberInput
          allowNegative={false}
          decimalScale={2}
          decimalSeparator=","
          fixedDecimalScale
          hideControls
          inputContainer={(children) => (
            <Group align="center" justify="center">
              {children}
              <Button disabled={!form.isValid()} loading={priceSettingsMutation.isPending} type="submit">
                Preisverwaltung einschalten
              </Button>
            </Group>
          )}
          key={form.key(`${nameOf<PriceSettingsDto>().basePrice}`)}
          placeholder="Basispreis"
          {...form.getInputProps(`${nameOf<PriceSettingsDto>().basePrice}`)}
        />
        <RenderJson data={form.errors} title="Enable Price Settings" />
      </form>
    </Container>
  );
}
