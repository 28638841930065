import { EditTable } from "../../../components/table/edit-table/EditTable";
import type { EditTableHeader, EditTableRow } from "../../../components/table/edit-table/EditTableTypes";
import type { AreaListResponseDto, AreaResponseDto } from "../../../generated/client/octalog-service";

import { CreateNewAreaRow } from "./CreateNewAreaRow";
import { EditAreaModal } from "./EditAreaModal";

interface AreaTableProps {
  data: AreaListResponseDto;
}

export function AreaTable({ data }: AreaTableProps) {
  const headers: EditTableHeader[] = [
    { id: "name", label: "Name" },
    { id: "edit", label: "" },
  ];

  const rows: EditTableRow[] = data.areas.map((area: AreaResponseDto) => ({
    id: area.areaId.toString(),
    columns: [
      {
        id: "name",
        label: area.name,
      },
    ],
    editColumn: <EditAreaModal areaDto={area} />,
  }));

  return <EditTable createRow={<CreateNewAreaRow />} headers={headers} rows={rows} testId="areas-settings-table" />;
}
