import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router";

import { GeneralError } from "./GeneralError";

export function ErrorBoundary() {
  const error = useRouteError() as Error;
  console.error("ErrorBoundary caught an error:", error);
  useEffect(() => {
    captureException(error);
  }, [error]);

  return <GeneralError />;
}
