import { get } from "lodash-es";
import { useCallback } from "react";

import { translate } from "../../common/Dictionary";
import type { GetContainerResponseDto } from "../../generated/client/octalog-service";
import { useAreaNameTranslator } from "../../hooks/areas/useAreaHooks";
import { useGetContainers } from "../../service/containers/useGetContainers";
import { useGetContainerTypeName } from "../../service/containers/useGetContainerTypeName";

import {
  AREA_ID_ACCESSOR,
  CONTAINER_TYPE_ID_ACCESSOR,
  CUTTING_LENGTH_ACCESSOR,
  HUMIDITY_LEVEL_ACCESSOR,
  LEVEL_ACCESSOR,
  WOOD_TYPE_ACCESSOR,
} from "./ContainerAccessor";

function filter(value: string | undefined, selectedValues: string[]): boolean {
  if (selectedValues.length > 0) {
    return value ? selectedValues.includes(value) : false;
  } else {
    return true;
  }
}

export function useFilteredAndSortedContainers(): {
  filteredAndSortedContainers: (
    selectedContainerName: string,
    selectedContainerTypes: string[],
    selectedWoodTypes: string[],
    selectedCuttingLengths: string[],
    selectedHumidityLevels: string[],
    selectedAreas: string[],
    sortStatus: {
      columnAccessor: string;
      direction: "asc" | "desc";
    },
  ) => GetContainerResponseDto[];
  isPending: boolean;
  isError: boolean;
  error: Error | null;
} {
  const { data, isPending, isError, error } = useGetContainers();
  const getAreaName = useAreaNameTranslator();
  const getContainerTypeName = useGetContainerTypeName();

  return {
    filteredAndSortedContainers: useCallback(
      (
        selectedContainerName: string,
        selectedContainerTypes: string[],
        selectedWoodTypes: string[],
        selectedCuttingLengths: string[],
        selectedHumidityLevels: string[],
        selectedAreas: string[],
        sortStatus: { columnAccessor: string; direction: "asc" | "desc" },
      ): GetContainerResponseDto[] => {
        if (isPending) {
          return [];
        } else if (isError) {
          throw error;
        } else {
          const filteredContainers = data?.containers
            .filter((container) => {
              return selectedContainerName === "" || container.containerName.toString().includes(selectedContainerName);
            })
            .filter((container) => filter(container.containerType.containerTypeId?.toString(), selectedContainerTypes))
            .filter((container) => filter(container.content?.woodType, selectedWoodTypes))
            .filter((container) => filter(container.content?.cuttingLength, selectedCuttingLengths))
            .filter((container) => filter(container.content?.humidityLevel, selectedHumidityLevels))
            .filter((container) => filter(container.area?.areaId?.toString(), selectedAreas));

          const sortedContainers =
            filteredContainers?.sort((a: GetContainerResponseDto, b: GetContainerResponseDto) => {
              const valueA = get(a, sortStatus.columnAccessor);
              const valueB = get(b, sortStatus.columnAccessor);

              if (sortStatus.columnAccessor === LEVEL_ACCESSOR) {
                if (!valueA && !valueB) {
                  return 0;
                } else {
                  if (!valueA) {
                    return -1;
                  } else if (!valueB) {
                    return 1;
                  }
                }
              }

              if (!valueA && !valueB) {
                return 0;
              } else {
                if (!valueA) {
                  return 1;
                } else if (!valueB) {
                  return -1;
                }
              }

              const { translatedValA, translatedValB } = (() => {
                switch (sortStatus.columnAccessor) {
                  case CONTAINER_TYPE_ID_ACCESSOR: {
                    return {
                      translatedValA: getContainerTypeName(valueA),
                      translatedValB: getContainerTypeName(valueB),
                    };
                  }
                  case WOOD_TYPE_ACCESSOR:
                  case CUTTING_LENGTH_ACCESSOR:
                  case HUMIDITY_LEVEL_ACCESSOR: {
                    return {
                      translatedValA: translate(valueA),
                      translatedValB: translate(valueB),
                    };
                  }
                  case AREA_ID_ACCESSOR: {
                    return {
                      translatedValA: getAreaName(valueA),
                      translatedValB: getAreaName(valueB),
                    };
                  }
                  default: {
                    return {
                      translatedValA: valueA,
                      translatedValB: valueB,
                    };
                  }
                }
              })();

              return String(translatedValA).localeCompare(String(translatedValB), "de", {
                numeric: true,
                sensitivity: "base",
              });
            }) || [];

          return sortStatus.direction === "desc" ? sortedContainers.reverse() : sortedContainers;
        }
      },
      [data?.containers, error, getAreaName, getContainerTypeName, isError, isPending],
    ),
    isPending: isPending,
    isError: isError,
    error: error,
  };
}
