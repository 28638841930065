import { Stack } from "@mantine/core";
import { chunk } from "lodash-es";
import { useCallback, useMemo, useState } from "react";

import { QueryLoaderBoundary } from "../../../components/loader/QueryLoaderBoundary";
import { ContactEntry } from "../../../components/output/contact/ContactEntry";
import { PaginationWrapper } from "../../../components/pagination/PaginationWrapper";
import type { GetContactsResponse } from "../../../generated/client/octalog-service";
import { useFilteredContacts } from "../../../hooks/useFilteredContacts";
import { useGetContactsQuery } from "../../../service/contacts/useGetContactsQuery";

import { ContactListLegend } from "./ContactListLegend";
import { SearchField } from "./SearchField";

const CONTACTS_PER_PAGE = 25;
const SEARCH_INPUT_WIDTH = 300;

function ContactListContent({ data }: { data: GetContactsResponse }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage, setPage] = useState(1);

  const filteredContacts = useFilteredContacts(data, searchTerm);
  const chunks = useMemo(() => chunk(filteredContacts, CONTACTS_PER_PAGE), [filteredContacts]);

  const handleSearchChange = useCallback((term: string) => {
    setPage(1);
    setSearchTerm(term);
  }, []);
  const handlePageChange = useCallback((page: number) => setPage(page), []);

  const contacts = chunks[activePage - 1]?.map((contact) => (
    <ContactEntry contact={contact} key={contact.contactId} preview={true} />
  ));

  return (
    <Stack>
      <SearchField
        mt={15}
        searchTerm={searchTerm}
        setSearchTerm={handleSearchChange}
        textInputProps={{ w: SEARCH_INPUT_WIDTH }}
      />
      <PaginationWrapper mt={15} onChange={handlePageChange} total={chunks.length} value={activePage} />
      {contacts}
      <ContactListLegend my={10} />
      <PaginationWrapper mb={15} onChange={handlePageChange} total={chunks.length} value={activePage} />
    </Stack>
  );
}

export function ContactList() {
  const queryResult = useGetContactsQuery();

  return (
    <QueryLoaderBoundary mih={200} queryResult={queryResult}>
      {(data) => <ContactListContent data={data} />}
    </QueryLoaderBoundary>
  );
}
