import type { BoxProps } from "@mantine/core";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { nameOf } from "../../../common/Utils";
import type { ContactSyncDto } from "../../../generated/client/octalog-service";
import { useContactSyncMutation } from "../../../service/contacts/useContactSyncMutation";

interface ContactsSettingsProps extends BoxProps {}

function SyncContacts({ ...rest }: ContactsSettingsProps) {
  const contactSyncMutation = useContactSyncMutation();

  const form = useForm<ContactSyncDto>({
    initialValues: {
      accessToken: "",
    },
    validate: {
      accessToken: (value) => (value.length < 10 ? "Der Access Token muss mindestens 10 Zeichen lang sein." : null),
    },
  });

  return (
    <Box {...rest}>
      <form onSubmit={form.onSubmit((values) => contactSyncMutation.mutate({ body: values }))}>
        <TextInput
          data-1p-ignore
          label="Lexware Access Token"
          placeholder="Geben Sie Ihren Lexware Access Token ein"
          required
          type="password"
          {...form.getInputProps(nameOf<ContactSyncDto>().accessToken)}
        />
        <Button
          disabled={contactSyncMutation.isPending || form.values.accessToken.length === 0 || !form.isValid()}
          loading={contactSyncMutation.isPending}
          mt={20}
          type="submit"
        >
          Kontakte synchronisieren
        </Button>
      </form>
    </Box>
  );
}

export function ContactsSettings() {
  return <SyncContacts m={10} />;
}
