import type { ContainerTypeResponseDto, UnitOfMeasurement } from "../../generated/client/octalog-service";

export function getContainerTypeVolume(
  containerTypeResponse: ContainerTypeResponseDto[] | undefined,
  containerTypeId: number,
): number | undefined {
  const containerType = containerTypeResponse?.find(
    (containerType) => containerType.containerTypeId === containerTypeId,
  );
  return containerType?.volume;
}

export function getContainerTypeUnitOfMeasurement(
  containerTypeResponses: ContainerTypeResponseDto[] | undefined,
  containerTypeId: number,
): UnitOfMeasurement | undefined {
  const containerType = containerTypeResponses?.find(
    (containerType) => containerType.containerTypeId === containerTypeId,
  );
  return containerType?.unitOfMeasurement as UnitOfMeasurement;
}
