import { Center, SegmentedControl } from "@mantine/core";
import { IconUserDollar, IconUserStar } from "@tabler/icons-react";

import { nameOf } from "../../../../common/Utils";
import { ContactType } from "../../../../generated/client/octalog-service";
import type { ContactData } from "../common/contactFormContext";
import { useCreateContactContext } from "../common/contactFormContext";

export function ContactTypeSegmentControl() {
  const form = useCreateContactContext();
  return (
    <SegmentedControl
      data={[
        {
          value: ContactType.INDIVIDUAL,
          label: (
            <Center style={{ gap: 10 }}>
              <IconUserStar size={16} />
              <span>Person</span>
            </Center>
          ),
        },
        {
          value: ContactType.COMPANY,
          label: (
            <Center style={{ gap: 10 }}>
              <IconUserDollar size={16} />
              <span>Firma</span>
            </Center>
          ),
        },
      ]}
      {...form.getInputProps(nameOf<ContactData>().contactType)}
      fullWidth
    />
  );
}
