import { useMemo } from "react";

import { translateUntyped } from "../../common/Dictionary";

import { useGetHumidityLevels } from "./useGetHumidityLevels";

interface HumidityLevel {
  humidityLevelId: number;
  name: string;
}

export const useHumidityLevelIdTranslator = (): ((humidityLevelId: number) => string | number) => {
  const humidityLevels = useGetHumidityLevels();

  return useMemo(() => {
    return (humidityLevelId: number): string | number => {
      if (humidityLevels.isLoading) {
        return humidityLevelId;
      }

      if (humidityLevels.isError) {
        return `Error: ${humidityLevels.error.message}`;
      }

      const humidityLevel: HumidityLevel | undefined = humidityLevels.data?.humidityLevels.find(
        (level: HumidityLevel) => level.humidityLevelId === humidityLevelId,
      );

      return translateUntyped(humidityLevel?.name) ?? humidityLevelId;
    };
  }, [humidityLevels]);
};
