import { useQuery } from "@tanstack/react-query";

import type { GetContainerResponseDto } from "../../generated/client/octalog-service";
import { UnitOfMeasurement, ContainerControllerService } from "../../generated/client/octalog-service";
import { getContainersQueryKey } from "../../generated/client/octalog-service/@tanstack/react-query.gen";

export const EMPTY = "empty";
export const FILLED = "filled";
export const NON_EXISTENT = "non-existent";
export type ContainerStateType = typeof EMPTY | typeof FILLED | typeof NON_EXISTENT;

export interface ContainerWithState extends GetContainerResponseDto {
  state: ContainerStateType;
}

function getContainersWithState(containerNames: string[]): Promise<ContainerWithState[]> {
  return containerNames.length === 0
    ? Promise.resolve([])
    : new Promise<ContainerWithState[]>((resolve) => {
        ContainerControllerService.getContainers({
          query: {
            containerIds: undefined,
            containerNames: containerNames,
          },
        }).then((response) => {
          resolve(requestedContainerIdsToContainerStates(containerNames, response?.data?.containers));
        });
      });
}

function requestedContainerIdsToContainerStates(
  requestedContainerNames: string[],
  containers?: GetContainerResponseDto[],
): ContainerWithState[] {
  return requestedContainerNames.map((containerName) => {
    const container = containers?.find((it) => it.containerName === containerName);
    return container
      ? {
          area: container.area,
          containerId: container.containerId,
          containerName: container.containerName,
          containerType: container.containerType,
          content: container.content,
          state: container.content ? FILLED : EMPTY,
        }
      : {
          containerId: -1,
          containerName: containerName,
          containerType: {
            containerTypeId: -1,
            name: "",
            unitOfMeasurement: UnitOfMeasurement.LOOSE_CUBIC_METER, // just a dummy value... needs improvement
            volume: -1,
          },
          state: NON_EXISTENT,
        };
  });
}

export const useGetContainerStates = (containerNames: string[]) => {
  return useQuery({
    queryKey: getContainersQueryKey({ query: { containerNames: containerNames } }),
    queryFn: () => getContainersWithState(containerNames),
  });
};
