import "./App.css";
import { MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { Notifications } from "@mantine/notifications";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { octalogTheme, DebugProvider, cssVariablesResolver } from "octalog-base";

import { configureWhyDidYouRender } from "./common/debug/wdyr";
import { configureDayJs } from "./config/dayjs";
import { CLIENT } from "./config/fetch";
import { disableLoggingInProduction } from "./config/logging";
import { Router } from "./Router";

const initialize = () => {
  console.log("Initializing Octalog UI…");
  configureWhyDidYouRender();
  configureDayJs();
  disableLoggingInProduction();
  console.log("FetchClient BaseURL:", CLIENT.getConfig().baseUrl);
};

initialize();

export function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider cssVariablesResolver={cssVariablesResolver} defaultColorScheme="auto" theme={octalogTheme}>
        <DatesProvider settings={{ locale: "de", timezone: "Europe/Berlin" }}>
          <Notifications />
          <DebugProvider>
            <Router />
            <ReactQueryDevtools initialIsOpen={false} />
          </DebugProvider>
        </DatesProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
}
