export const instanceOf = <T extends object>(value: unknown, fieldName: string): value is T => {
  if (typeof value === "object" && value !== null) {
    return fieldName in value;
  }
  return false;
};

export function nameOf<TObj extends object>(): { [K in keyof Required<TObj>]: K } {
  return new Proxy({} as { [K in keyof Required<TObj>]: K }, {
    get: (_, prop) => prop as keyof Required<TObj>,
    set: () => {
      throw new Error("Set operation is not supported");
    },
  });
}

interface PreserveDuringTrim {
  __preserveDuringTrim?: true; // marker property

  [key: string]: unknown;
}

type Trimmable = string | object | TrimmableObject[] | null | undefined;
type TrimmableObject = Record<string, Trimmable>;

export function trimRecursively<T extends Trimmable>(value: T): T | undefined {
  if (typeof value === "string") {
    const trimmedValue = value.trim();
    return trimmedValue.length > 0 ? (trimmedValue as T) : undefined;
  }

  if (Array.isArray(value)) {
    return value.map((element) => trimRecursively(element)) as T;
  }

  if (typeof value === "object" && value !== null) {
    // Check if the object should be preserved
    if (value instanceof Date || (value as PreserveDuringTrim).__preserveDuringTrim) {
      return value;
    }

    return Object.fromEntries(Object.entries(value).map(([key, val]) => [key, trimRecursively(val)])) as T;
  }

  return value;
}

// https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export function exhaustiveCheck(value: never): never {
  throw new Error(`Unhandled enum value: ${JSON.stringify(value)}`);
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars --- forced to declare this interface
  interface Array<T> {
    isEmpty(): boolean;
  }
}

Array.prototype.isEmpty = function () {
  return this.length === 0;
};
