import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCommonColors } from "octalog-base";

import { QUERY_KEY_CONTAINER_STATISTICS, QUERY_KEY_CONTAINERS } from "../../common/Constants";
import { tryToExtractDescription } from "../../common/error/ErrorMessageUtil";
import {
  changeContentLevelBatchMutation,
  addContentToContainerBatchMutation,
  getContainersQueryKey,
} from "../../generated/client/octalog-service/@tanstack/react-query.gen";

export const useAddContentToContainerMutation = () => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();
  return useMutation({
    ...addContentToContainerBatchMutation(),
    onSuccess: (response) => {
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_CONTAINERS });
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: [
          getContainersQueryKey({
            query: { containerNames: response.containers.map((container) => container.containerName) },
          }),
        ],
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_CONTAINER_STATISTICS });
      notifications.show({
        title: "Erfolg",
        message: `Inhalt wurde erfolgreich zu den Behältern ${response.containers.map((container) => container.containerName).join(", ")} hinzugefügt!`,
        color: commonColors.greenPrimary,
      });
    },
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Inhalt konnte nicht zu den Behältern hinzugefügt werden: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};

export const useEmptyContentMutation = () => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();
  return useMutation({
    ...changeContentLevelBatchMutation(),
    onSuccess: (response, request) => {
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINERS,
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: getContainersQueryKey({
          query: { containerNames: response.containers.map((container) => container.containerName) },
        }),
      });

      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINER_STATISTICS,
      });
      notifications.show({
        title: "Erfolg",
        message: `Inhalt von Behälter ${response.containers.map((container) => container.containerName).join(", ")} um ${request.body.volume} verringert!`,
        color: commonColors.greenPrimary,
      });
    },
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Inhalt konnte nicht geleert werden: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};
