import { AppShell, useMantineColorScheme, Stack, Center } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCommonColors, Footer } from "octalog-base";
import { Outlet, useNavigate } from "react-router";

import { LoaderBoundary } from "../components/loader/LoaderBoundary";
import { useMe } from "../service/iam/IamHooks";

import { Header } from "./components/Header/Header";
import { Navbar } from "./components/Navbar/Navbar";
import { FOOTER_HEIGHT_BASE, FOOTER_HEIGHT_LG, HEADER_HEIGHT_BASE, HEADER_HEIGHT_LG } from "./constants";
import classes from "./LayoutInternal.module.css";

export function LayoutInternal() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const commonColors = useCommonColors();

  const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

  const me = useMe();
  const navigate = useNavigate();

  if (me.isPending) {
    return (
      <Stack h="100vh" justify="space-between" pt="45vh" w="100%">
        <LoaderBoundary isPending={me.isPending} loaderSize="lg" />
        <Footer />
      </Stack>
    );
  }

  return (
    <AppShell
      header={{ height: { base: HEADER_HEIGHT_BASE, lg: HEADER_HEIGHT_LG } }}
      layout="alt"
      navbar={{
        width: 300,
        breakpoint: "md",
        collapsed: {
          mobile: !mobileOpened,
          desktop: !desktopOpened,
        },
      }}
      transitionDuration={500}
      transitionTimingFunction="ease"
    >
      <AppShell.Header>
        <Header
          desktopOpened={desktopOpened}
          mobileOpened={mobileOpened}
          toggleDesktop={toggleDesktop}
          toggleMobile={toggleMobile}
        />
      </AppShell.Header>
      <AppShell.Navbar p="md" styles={{ navbar: { backgroundColor: commonColors.bodyLight } }}>
        <Navbar
          closeMobile={closeMobile}
          colorScheme={colorScheme}
          mobileOpened={mobileOpened}
          showDeveloperOptions={me.data?.roles?.includes("DEVELOPER") ?? false}
          signOut={() => navigate("/sign-out")}
          toggleColorScheme={toggleColorScheme}
          toggleMobile={toggleMobile}
        />
      </AppShell.Navbar>
      <AppShell.Main>
        <div className={classes.wrapper}>
          <Center>
            <Outlet />
          </Center>
          <div className={classes.spacer} />
          <Footer h={{ base: FOOTER_HEIGHT_BASE, lg: FOOTER_HEIGHT_LG }} />
        </div>
      </AppShell.Main>
    </AppShell>
  );
}
