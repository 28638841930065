import { client } from "../generated/client/octalog-service";

// Configure the client that's used by the generated services
client.setConfig({
  baseUrl: "/octalog-service",
});

client.interceptors.response.use((response) => {
  if (response.status === 401 && globalThis.location.pathname !== "/sign-in") {
    console.error("Unauthorized! Redirecting to sign-in page…");
    globalThis.location.href = "/sign-out";
  }
  return response;
});

// Export the configured client
export { client as CLIENT } from "../generated/client/octalog-service";
