import { Paper, Stack, Title, Text } from "@mantine/core";
import { RootContainer } from "octalog-base";
import type { ReactNode } from "react";

interface AuthContainerProps {
  title?: string;
  subtitle?: ReactNode;
  children: ReactNode;
  dataTestIdTitle?: string;
}

export function AuthContainer({ title, dataTestIdTitle, subtitle, children }: AuthContainerProps) {
  return (
    <RootContainer fluid maw="100%" w="100%">
      {title ? (
        <Title data-testid={dataTestIdTitle} ta="center">
          {title}
        </Title>
      ) : null}
      {subtitle ? (
        <Text c="dimmed" mt={5} size="sm" ta="center">
          {subtitle}
        </Text>
      ) : null}
      <Stack align="center">
        <Paper maw={400} mt={30} p={30} w="80vw" withBorder>
          {children}
        </Paper>
      </Stack>
    </RootContainer>
  );
}
