import { LocalDateInternal } from "../../../../../common/calendar/LocalDateInternal";
import { LocalTimeInternal } from "../../../../../common/calendar/LocalTimeInternal";
import type { OrderDeliveryScheduleDto, OrderDto } from "../../../../../generated/client/octalog-service";

export interface ScheduledOrder extends Omit<OrderDto, "deliverySchedule"> {
  __isScheduledOrder: true;
  deliverySchedule: Omit<OrderDeliveryScheduleDto, "deliveryDate" | "deliveryTime"> & {
    deliveryDate: LocalDateInternal;
    deliveryTime: LocalTimeInternal;
  };
}

export function toScheduledOrder(order: OrderDto): ScheduledOrder {
  if (!order.deliverySchedule) {
    throw new Error("Order must have a delivery schedule");
  }

  return {
    __isScheduledOrder: true,
    ...order,
    deliverySchedule: {
      ...order.deliverySchedule,
      deliveryDate: LocalDateInternal.fromString(order.deliverySchedule.deliveryDate),
      deliveryTime: LocalTimeInternal.fromString(order.deliverySchedule.deliveryTime),
    },
  };
}

export function toScheduledOrders(orders: OrderDto[]): ScheduledOrder[] {
  return orders.map((order) => toScheduledOrder(order));
}
