import { Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";

import type { ContactResponseDto } from "../../../generated/client/octalog-service";
import { transformInitialData, useContactForm } from "../../../views/contacts/new/common/useContactForm";
import { ContactForm } from "../../../views/contacts/new/ContactForm";

interface EditContactModalProps {
  opened: boolean;
  onClose: () => void;
  contact: ContactResponseDto;
}

export function EditContactModal({ opened, onClose, contact }: EditContactModalProps) {
  const { form, handleSubmit } = useContactForm(contact);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const formSetValues = form.setValues;
  useEffect(() => {
    formSetValues(transformInitialData(contact));
  }, [contact, formSetValues]);

  return (
    <Modal fullScreen={isMobile} onClose={onClose} opened={opened} size="auto">
      <ContactForm form={form} onSubmit={handleSubmit} />
    </Modal>
  );
}
