import { Grid, Text, Title, Center } from "@mantine/core";
import { DatePickerInput, DatesProvider } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import { DEFAULT_ICON_SIZE } from "octalog-base";

import { LocalDateInternal } from "../../../../../common/calendar/LocalDateInternal";
import { nameOf } from "../../../../../common/Utils";
import { ClearableTimeInput } from "../../../../../components/input/time/ClearableTimeInput";
import type { OrderCreateDto } from "../../../../../generated/client/octalog-service";
import type { OrderCreateFormData } from "../contexts/FormContexts";
import { useDeliveryForm } from "../contexts/FormContexts";

export function DeliveryDetailsSection() {
  const { form, setDateRange } = useDeliveryForm();

  return (
    <Grid ta="left">
      <Grid.Col span={12}>
        <Center>
          <Title c="dimmed" mt="xs" order={4}>
            Lieferzeitraum
          </Title>
        </Center>
      </Grid.Col>
      <Grid.Col span={12}>
        <Title order={6}>Datum</Title>
        <Text c="dimmed" size="xs">
          Genaues Datum oder Datumsbereich
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <DatesProvider settings={{ locale: "de", timezone: "UTC" }}>
          <DatePickerInput
            {...form.getInputProps(`${nameOf<OrderCreateDto>().deliveryTargetDateStart}`)}
            label="Datum / Von"
            leftSection={<IconCalendar size={DEFAULT_ICON_SIZE} />}
            onChange={(value) => value && setDateRange(LocalDateInternal.fromDate(value))}
            required
            styles={{ input: { textAlign: "center" } }}
            value={form.values.deliveryTargetDateStart?.toDate() ?? null}
            valueFormat="DD.MM.YYYY"
          />
        </DatesProvider>
      </Grid.Col>
      <Grid.Col span={6}>
        <DatesProvider settings={{ locale: "de", timezone: "UTC" }}>
          <DatePickerInput
            {...form.getInputProps(`${nameOf<OrderCreateFormData>().deliveryTargetDateEnd}`)}
            clearable
            defaultDate={form.values.deliveryTargetDateStart?.toDate()}
            disabled={!form.values.deliveryTargetDateStart}
            label="Bis"
            leftSection={<IconCalendar size={DEFAULT_ICON_SIZE} />}
            minDate={form.values.deliveryTargetDateStart?.toDate()}
            onChange={(value) =>
              setDateRange(form.values.deliveryTargetDateStart!, value ? LocalDateInternal.fromDate(value) : undefined)
            }
            styles={{ input: { textAlign: "center" } }}
            value={form.values.deliveryTargetDateEnd?.toDate() ?? null}
            valueFormat="DD.MM.YYYY"
          />
        </DatesProvider>
      </Grid.Col>
      <Grid.Col span={12}>
        <Title order={6}>Uhrzeit</Title>
        <Text c="dimmed" size="xs">
          Genaue Uhrzeit, Zeitraum oder keine Angabe (den ganzen Tag)
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <ClearableTimeInput
          {...form.getInputProps(`${nameOf<OrderCreateDto>().deliveryTargetTimeStart}`, {
            fieldType: "ClearableTimeInput",
          })}
          label="Uhrzeit / Von"
          styles={{ input: { textAlign: "center" } }}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <ClearableTimeInput
          {...form.getInputProps(`${nameOf<OrderCreateDto>().deliveryTargetTimeEnd}`, {
            fieldType: "ClearableTimeInput",
          })}
          disabled={!form.values.deliveryTargetTimeStart}
          label="Bis"
          minTime={form.values.deliveryTargetTimeStart?.toString()}
          styles={{ input: { textAlign: "center" } }}
        />
      </Grid.Col>
    </Grid>
  );
}
