import type { CenterProps, MantineSize } from "@mantine/core";
import { Center, Loader } from "@mantine/core";
import { useCommonColors } from "octalog-base";

export interface LoadingIndicatorProps extends CenterProps {
  loaderSize?: MantineSize;
}

export function LoadingIndicator({ loaderSize = "md", ...centerProps }: LoadingIndicatorProps) {
  const { primaryColor } = useCommonColors();
  return (
    <Center {...centerProps}>
      <Loader color={primaryColor} size={loaderSize} type="dots" />
    </Center>
  );
}
