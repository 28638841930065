import { useMutation } from "@tanstack/react-query";

import { updateOrderMutation } from "../../../generated/client/octalog-service/@tanstack/react-query.gen";
import { useBaseMutation } from "../../../hooks/useBaseMutation";

export const useUpdateOrderMutation = () => {
  return useMutation(
    useBaseMutation({
      ...updateOrderMutation(),
      successMessage: "Der Auftrag wurde erfolgreich aktualisiert.",
      errorMessage: "Der Auftrag konnte nicht aktualisiert werden",
    }),
  );
};
