import type React from "react";
import { useState, useEffect } from "react";

export function useIsRefReady(ref: React.RefObject<HTMLDivElement | null>) {
  const [isRefReady, setIsRefReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref.current) {
        setIsRefReady(true);
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [ref]);

  return isRefReady;
}
