import { Group, Text } from "@mantine/core";
import { IconChevronUp, IconSelector, IconChevronDown } from "@tabler/icons-react";
import type { DataTableColumn, DataTableSortStatus } from "mantine-datatable";
import { DataTable } from "mantine-datatable";
import { RenderJson } from "octalog-base";
import { useState } from "react";

import { sortData } from "../../../../../../common/datatable/DataTableUtils";
import { translateOrderStatus } from "../../../../../../common/Dictionary";
import { formatDate } from "../../../../../../common/UnitFormatter";
import { nameOf } from "../../../../../../common/Utils";
import { ErrorIndicator } from "../../../../../../components/loader/ErrorIndicator";
import type { OrderDto, OrderStatus } from "../../../../../../generated/client/octalog-service";
import { useGetOrders } from "../../../../query/useGetOrders";
import { OrderDetails } from "../OrderDetails/OrderDetails";

const columns: DataTableColumn<OrderDto>[] = [
  {
    accessor: "invoiceAddress",
    title: "Kunde",
    sortable: true,
    ellipsis: true,
    render: (order: OrderDto) => order.invoiceAddress.split("\n")[0],
  },
  {
    accessor: "deliveryTargetDateStart",
    title: "Von",
    sortable: true,
    ellipsis: true,
    render: (order: OrderDto) => formatDate(order.deliveryTargetDateStart),
  },
  {
    accessor: "status",
    title: "Status",
    sortable: true,
    ellipsis: true,
    render: (order: OrderDto) => (
      <Group gap="xs" justify="space-between" wrap="nowrap">
        <Text truncate="end">{translateOrderStatus(order.status as OrderStatus)}</Text>
        <IconChevronDown color="gray" size={16} />
      </Group>
    ),
  },
];

function useOrderSorting(orders: OrderDto[] | undefined) {
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<OrderDto>>({
    columnAccessor: `${nameOf<OrderDto>().orderNumber}`,
    direction: "asc",
  });

  const sortedOrders = orders ? sortData(orders, sortStatus) : [];

  return { sortStatus, setSortStatus, sortedOrders };
}

export function OrdersTable() {
  const { data: response, isLoading, isError, error } = useGetOrders();
  const orders = response?.data;
  const { sortStatus, setSortStatus, sortedOrders } = useOrderSorting(orders);

  if (isError) {
    return <ErrorIndicator error={error} />;
  }

  // noinspection RequiredAttributes
  return (
    <>
      <DataTable
        columns={columns}
        fetching={isLoading}
        highlightOnHover
        idAccessor={nameOf<OrderDto>().orderNumber}
        onSortStatusChange={setSortStatus}
        records={sortedOrders}
        rowExpansion={{
          allowMultiple: true,
          content: ({ record: order }) => <OrderDetails order={order} />,
        }}
        sortIcons={{
          sorted: <IconChevronUp size={14} />,
          unsorted: <IconSelector size={14} />,
        }}
        sortStatus={sortStatus}
      />
      <RenderJson data={response} title="Orders" />
    </>
  );
}
