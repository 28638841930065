import type { UnstyledButtonProps } from "@mantine/core";
import { Avatar, Group, Text, UnstyledButton } from "@mantine/core";
import { useCommonColors } from "octalog-base";

import { useMe } from "../../service/iam/IamHooks";

import classes from "./UserButton.module.css";

export function UserButton(props: UnstyledButtonProps) {
  const me = useMe();
  const commonColors = useCommonColors();

  return (
    <UnstyledButton {...props} className={classes.user}>
      <Group>
        <Avatar color={commonColors.whiteAndBlack} name={me.data?.firstName + " " + me.data?.lastName} radius="xl" />
        <div style={{ flex: 1 }}>
          <Text fw={500} size="sm">
            {me.data?.firstName} {me.data?.lastName}
          </Text>
          <Text c="dimmed" size="xs">
            {me.data?.email}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );
}
