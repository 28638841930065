import { Button, Collapse, Container, Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { RenderJson } from "octalog-base";

import { QueryLoaderBoundary } from "../../../../../components/loader/QueryLoaderBoundary";
import { useGetPrices } from "../../../../../service/prices/useGetPrices";

import { PriceTable } from "./PriceTable";

export function Prices() {
  const [opened, { toggle }] = useDisclosure(false);
  const prices = useGetPrices();

  return (
    <Container mt={10}>
      <Group justify="center" mb={5}>
        <Button onClick={toggle} variant="transparent">
          Details
        </Button>
      </Group>

      <Collapse in={opened} transitionDuration={0}>
        <QueryLoaderBoundary queryResult={prices}>{(data) => <PriceTable prices={data.prices} />}</QueryLoaderBoundary>
      </Collapse>
      <RenderJson data={prices} />
    </Container>
  );
}
