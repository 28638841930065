import type { BoxProps } from "@mantine/core";
import { Box, Pagination } from "@mantine/core";

interface PaginationWrapperProps extends BoxProps {
  onChange: (page: number) => void;
  total: number;
  value: number;
}

export function PaginationWrapper({ onChange, total, value, ...rest }: PaginationWrapperProps) {
  return (
    <Box style={{ display: "flex", justifyContent: "center" }} {...rest}>
      <Pagination onChange={onChange} size="sm" total={total} value={value} />
    </Box>
  );
}
