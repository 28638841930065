import { Group, Pill } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

import { EMPTY, FILLED, NON_EXISTENT } from "../../../service/containers/useGetContainerStates";

import { colorLookup } from "./colorLookup";
import type { Mode } from "./ContainerIdSelection";

interface ContainerStateLegendProps {
  mode: Mode;
}

export function ContainerStateLegend({ mode }: ContainerStateLegendProps) {
  return (
    <Group gap="xs" mt={10}>
      <IconInfoCircle size="16" />
      <Pill
        size="xs"
        styles={{
          root: { backgroundColor: (colorLookup[mode === "fill" ? EMPTY : FILLED] as Record<Mode, string>)[mode] },
        }}
      >
        {mode === "fill" ? "Leer" : "Befüllt"}
      </Pill>
      <Pill size="xs" styles={{ root: { backgroundColor: colorLookup[NON_EXISTENT] as string } }}>
        Existiert nicht
      </Pill>
      <Pill
        size="xs"
        styles={{
          root: { backgroundColor: (colorLookup[mode === "fill" ? FILLED : EMPTY] as Record<Mode, string>)[mode] },
        }}
      >
        {mode === "fill" ? "Befüllt" : "Leer"}
      </Pill>
    </Group>
  );
}
