import { Group, Menu, ActionIcon } from "@mantine/core";
import { IconDotsVertical, IconBuildingWarehouse, IconUpload } from "@tabler/icons-react";

import { ContainerCount } from "./components/ContainerCount";

interface StockHeaderProps {
  selectedRecords: number;
  totalRecords: number;
  openAssignAreaModal: () => void;
  openEmptyModal: () => void;
  isMobile: boolean;
}

export function StockHeader({
  selectedRecords,
  totalRecords,
  openAssignAreaModal,
  openEmptyModal,
  isMobile,
}: StockHeaderProps) {
  return (
    <Group gap="md" justify={isMobile ? "center" : "flex-end"} wrap="wrap">
      <ContainerCount selectedCount={selectedRecords} totalCount={totalRecords} />
      <Menu position="bottom-end" shadow="md" width={200}>
        <Menu.Target>
          <ActionIcon variant="outline">
            <IconDotsVertical size={16} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            disabled={selectedRecords <= 0}
            leftSection={<IconBuildingWarehouse size={14} />}
            onClick={() => {
              openAssignAreaModal();
            }}
          >
            Lagerplatz zuweisen
          </Menu.Item>
          <Menu.Item
            disabled={selectedRecords <= 0}
            leftSection={<IconUpload size={14} />}
            onClick={() => {
              openEmptyModal();
            }}
          >
            Behälter entleeren
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
