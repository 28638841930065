import { ActionIcon, Alert, Badge, Divider, Flex, Grid, Group, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import countries from "i18n-iso-countries";
import { useCommonColors, RenderJson } from "octalog-base";

import { translateAddressType } from "../../../common/Dictionary";
import type { AddressResponseDto, ContactResponseDto } from "../../../generated/client/octalog-service";
import { ContactType } from "../../../generated/client/octalog-service";
import { generateAddressText, getContactName } from "../../../views/contacts/list/contactUtil";
import { CopyText } from "../copy-text/CopyText";

import { EditContactModal } from "./EditContactModal";

const INNER_PADDING = 20;

interface ContactEntryBodyProps {
  contact: ContactResponseDto;
}

function ContactTypeBadge({ contact }: { contact: ContactResponseDto }) {
  const commonColors = useCommonColors();
  return (
    <Grid.Col span={12} ta="left">
      <Badge color={commonColors.text} miw={80} variant="outline">
        {contact.contactType === ContactType.INDIVIDUAL ? "Person" : "Firma"}
      </Badge>
    </Grid.Col>
  );
}

function RenderExternalSyncError({ contact }: { contact: ContactResponseDto }) {
  const commonColors = useCommonColors();
  return contact.externalReference?.externalSyncImportError ? (
    <Grid.Col span={12}>
      <Alert color={commonColors.orangePrimary} title="Warnung">
        <Text fw={500} size="sm" style={{ whiteSpace: "pre-line" }}>
          {contact.externalReference.externalSyncImportError}
        </Text>
      </Alert>
    </Grid.Col>
  ) : null;
}

function Identifiers({ contact }: { contact: ContactResponseDto }) {
  return (
    <>
      <Grid.Col span={12}>
        <Divider label="IDs" />
      </Grid.Col>
      <Grid.Col px={INNER_PADDING} span={12}>
        <Flex align="flex-start" direction="column" gap="xs" justify="flex-start">
          {contact.contactIdentifierCustomer ? (
            <Group>
              <Text fw={700}>Kundennummer:</Text>
              <CopyText c="dimmed" mr={5} size="sm">
                {contact.contactIdentifierCustomer.customerId}
              </CopyText>
            </Group>
          ) : null}
          {contact.contactIdentifierSupplier ? (
            <Group>
              <Text fw={700}>Lieferantennummer:</Text>
              <CopyText c="dimmed" mr={5} size="sm">
                {contact.contactIdentifierSupplier.supplierId}
              </CopyText>
            </Group>
          ) : null}
          {contact.contactIdentifierSupplier?.customerIdAtSupplier ? (
            <Group>
              <Text fw={700}>Kd.-Nr. bei Lieferanten:</Text>
              <CopyText c="dimmed" mr={5} size="sm">
                {contact.contactIdentifierSupplier.customerIdAtSupplier}
              </CopyText>
            </Group>
          ) : null}
        </Flex>
      </Grid.Col>
    </>
  );
}

function Note({ contact }: { contact: ContactResponseDto }) {
  return (
    <>
      <Grid.Col hidden={!contact.note} span={12}>
        <Divider label="Notiz" />
      </Grid.Col>
      <Grid.Col hidden={!contact.note} px={INNER_PADDING} span={12}>
        <Text c="dimmed">{contact.note}</Text>
      </Grid.Col>
    </>
  );
}

function Addresses({ contact }: { contact: ContactResponseDto }) {
  return (
    <>
      <Grid.Col hidden={contact.addresses.length === 0} span={12}>
        <Divider label="Adressen" />
      </Grid.Col>
      <Grid.Col px={INNER_PADDING} span={12}>
        <Grid>
          {contact.addresses.map((address) => (
            <Grid.Col key={address.addressId} span={{ base: 12, xs: 6, md: 4 }}>
              <Address address={address} contact={contact} />
            </Grid.Col>
          ))}
        </Grid>
      </Grid.Col>
    </>
  );
}

function Address({ contact, address }: { contact: ContactResponseDto; address: AddressResponseDto }) {
  return (
    <Stack align="flex-start" gap={0} my={5}>
      <CopyText fw={700} mr={5} valueToCopy={generateAddressText(contact, address.addressType)}>
        {translateAddressType(address.addressType)}
      </CopyText>
      <Text c="dimmed" size="sm">
        {getContactName(contact)}
      </Text>
      <Text c="dimmed" size="sm">
        {address.addition}
      </Text>
      <Text c="dimmed" size="sm">
        {address.street}
      </Text>
      <Text c="dimmed" size="sm">
        {address.postalCode} {address.city}
      </Text>
      <Text c="dimmed" size="sm">
        {countries.getName(address.country, "de")}
      </Text>
    </Stack>
  );
}

function PhoneNumbers({ contact }: { contact: ContactResponseDto }) {
  return (
    <>
      <Grid.Col hidden={contact.phoneNumbers.length === 0} span={12}>
        <Divider label="Telefonnummern" />
      </Grid.Col>
      <Grid.Col px={INNER_PADDING} span={12}>
        <Stack align="flex-start" gap={0}>
          {contact.phoneNumbers.map((phoneNumber) => (
            <CopyText c="dimmed" key={phoneNumber.phoneNumberId} m={5} size="sm">
              {phoneNumber.phoneNumber}
            </CopyText>
          ))}
        </Stack>
      </Grid.Col>
    </>
  );
}

function Emails({ contact }: { contact: ContactResponseDto }) {
  return (
    <>
      <Grid.Col hidden={contact.emails.length === 0} span={12}>
        <Divider label="E-Mail-Adressen" />
      </Grid.Col>
      <Grid.Col px={INNER_PADDING} span={12}>
        <Stack align="flex-start" gap={0}>
          {contact.emails.map((email) => (
            <CopyText c="dimmed" key={email.emailId} m={5} size="sm">
              {email.email}
            </CopyText>
          ))}
        </Stack>
      </Grid.Col>
    </>
  );
}

export function ContactEntryBody({ contact }: ContactEntryBodyProps) {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const commonColors = useCommonColors();
  return (
    <Grid>
      <RenderExternalSyncError contact={contact} /> {/* Add this line */}
      <ContactTypeBadge contact={contact} />
      <Identifiers contact={contact} />
      <Note contact={contact} />
      <Addresses contact={contact} />
      <PhoneNumbers contact={contact} />
      <Emails contact={contact} />
      <Grid.Col span={12}>
        <Flex justify="flex-end">
          <ActionIcon color={commonColors.primaryColor} onClick={openModal} size="sm" variant="subtle">
            <IconEdit />
          </ActionIcon>
        </Flex>
      </Grid.Col>
      <EditContactModal contact={contact} onClose={closeModal} opened={modalOpened} />
      <RenderJson data={contact} inGrid={true} />
    </Grid>
  );
}
