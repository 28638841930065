import { Grid, Text } from "@mantine/core";

import { nameOf } from "../../../../common/Utils";
import { FloatingLabelInput } from "../../../../components/input/FloatingLabelInput/FloatingLabelInput";
import { FloatingLabelNumericFormat } from "../../../../components/input/FloatingLabelInput/FloatingLabelNumericFormat";
import type { CreateContactIdentifierSupplierDto } from "../../../../generated/client/octalog-service";
import type { ContactData } from "../common/contactFormContext";
import { useCreateContactContext } from "../common/contactFormContext";

export function InputIdentifierSupplier() {
  const form = useCreateContactContext();
  const keySupplierId = `${nameOf<ContactData>().contactIdentifierSupplier}.${nameOf<CreateContactIdentifierSupplierDto>().supplierId}`;
  const keyCustomerIdAtSupplier = `${nameOf<ContactData>().contactIdentifierSupplier}.${nameOf<CreateContactIdentifierSupplierDto>().customerIdAtSupplier}`;

  return (
    <>
      <Grid.Col span={12}>
        <Text>Lieferanteninformationen</Text>
      </Grid.Col>
      <Grid.Col span={12}>
        <FloatingLabelNumericFormat
          allowNegative={false}
          label="Lieferanten-Nr. (wird automatisch erzeugt)"
          {...form.getInputProps(keySupplierId)}
          value={form.values.contactIdentifierSupplier?.supplierId ?? ""}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <FloatingLabelInput
          label="Ihre kd.-Nr. bei diesem Lieferanten"
          {...form.getInputProps(keyCustomerIdAtSupplier)}
          value={form.values.contactIdentifierSupplier?.customerIdAtSupplier ?? ""}
        />
      </Grid.Col>
    </>
  );
}
