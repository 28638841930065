import { Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";

import type { OrderDto } from "../../../../../../../generated/client/octalog-service";
import { useOrderForm, transformInitialOrderData } from "../../../../../components/OrderForm/hooks/useOrderForm";
import { OrderForm } from "../../../../../components/OrderForm/OrderForm";

interface OrderUpdateModalProps {
  opened: boolean;
  onClose: () => void;
  order: OrderDto;
}

export function OrderUpdateModal({ order, opened, onClose }: OrderUpdateModalProps) {
  const { form, isPending, handleSubmit } = useOrderForm(order, false);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const formSetValues = form.setValues;
  useEffect(() => {
    formSetValues(transformInitialOrderData(order));
  }, [order, formSetValues]);

  return (
    <Modal fullScreen={isMobile} onClose={onClose} opened={opened} size="auto">
      <OrderForm form={form} isPending={isPending} onSubmit={handleSubmit} showContactSelect={false} />
    </Modal>
  );
}
