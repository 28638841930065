import type { BoxProps, MantineSize } from "@mantine/core";
import { Box } from "@mantine/core";
import type React from "react";

import { ErrorIndicator } from "./ErrorIndicator";
import { LoadingIndicator } from "./LoadingIndicator";

export interface LoaderBoundaryProps extends BoxProps {
  error?: Error | null;
  isError?: boolean;
  isPending?: boolean;
  loaderSize?: MantineSize;
  children?: React.JSX.Element[] | React.JSX.Element;
  hidden?: boolean;
}

export function LoaderBoundary({
  isPending,
  isError,
  error,
  loaderSize = "md",
  children,
  hidden = false,
  ...boxProps
}: LoaderBoundaryProps) {
  if (isPending) {
    return <LoadingIndicator loaderSize={loaderSize} {...boxProps} />;
  }

  if (isError) {
    return <ErrorIndicator error={error} {...boxProps} />;
  }

  return hidden ? null : <Box {...boxProps}>{children}</Box>;
}
