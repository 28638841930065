import { Anchor, Button, Text, Stack } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router";

import { useRequiredParams } from "../../common/react-router/RequiredParams";

import { AuthContainer } from "./components/AuthContainer";
import { AuthSuccessState } from "./components/AuthSuccessState";

export function SignUpConfirmation() {
  const { result } = useRequiredParams<{ result: "succeeded" | "not-found" | "expired" }>();
  const navigate = useNavigate();

  return (
    <AuthContainer>
      {result === "succeeded" ? (
        <AuthSuccessState
          buttonText="Anmelden"
          onButtonClick={() => navigate("/sign-in")}
          title="Bestätigung erfolgreich"
        />
      ) : (
        <Stack align="center" gap="md">
          <h2>Bestätigung fehlgeschlagen</h2>
          <IconX size={40} />
          <Text mt={10}>Der Bestätigungslink ist ungültig.</Text>
          <Text>Vielleicht wurde der Account bereits bestätigt?</Text>
          <Text>Falls Sie weitere Hilfe benötigen, kontaktieren Sie uns bitte:</Text>
          <Anchor href="mailto:info@octalog.de">info@octalog.de</Anchor>
          <Button onClick={() => navigate("/sign-up")}>Registrierung erneut starten</Button>
        </Stack>
      )}
    </AuthContainer>
  );
}
