import type { PasswordInputProps } from "@mantine/core";
import { Group, Progress, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useEffect } from "react";

import { FloatingLabelPasswordInput } from "../FloatingLabelInput/FloatingLabelPasswordInput";

interface PasswordRequirementProps {
  meets: boolean;
  label: string;
}

function PasswordRequirement({ meets, label }: PasswordRequirementProps) {
  return (
    <Group align="flex-start" mt={7}>
      <Text c={meets ? "teal" : "red"} mt={7} size="sm" style={{ display: "flex", alignItems: "center" }}>
        {meets ? <IconCheck size={14} style={{ marginRight: 10 }} /> : <IconX size={14} style={{ marginRight: 10 }} />}
        {label}
      </Text>
    </Group>
  );
}

const regexRequirements = [
  { id: "digit", regex: /\d/, label: "Enthält Zahl" },
  { id: "lower", regex: /[a-z]/, label: "Enthält Kleinbuchstabe" },
  { id: "upper", regex: /[A-Z]/, label: "Enthält Großbuchstabe" },
  { id: "special", regex: /[!#$%&'()*+,.:;<=>?@^|-]/, label: "Enthält Sonderzeichen" },
];

function getStrength(password: string) {
  let multiplier = password.length > 7 ? 0 : 1;

  for (const requirement of regexRequirements) {
    if (!requirement.regex.test(password)) {
      multiplier += 1;
    }
  }

  return Math.max(100 - (100 / (regexRequirements.length + 1)) * multiplier, 0);
}

export interface PasswordStrengthProps extends PasswordInputProps {
  isValidCallback: (isValid: boolean) => void;
}

export function PasswordStrength({ isValidCallback, value, ...rest }: PasswordStrengthProps) {
  const checks = regexRequirements.map((requirement) => (
    <PasswordRequirement key={requirement.id} label={requirement.label} meets={requirement.regex.test(String(value))} />
  ));

  const strength = getStrength(String(value));
  const validPassword = strength >= 100;
  const color = validPassword ? "teal" : strength > 50 ? "yellow" : "red";

  useEffect(() => {
    isValidCallback(validPassword);
  }, [isValidCallback, validPassword]);

  return (
    <>
      <FloatingLabelPasswordInput {...rest} error={strength < 100 && strength > 0 ? "Passwort ist zu schwach" : ""} />
      {value ? <Progress color={color} mb="xs" size={5} value={strength} /> : null}
      {value ? <PasswordRequirement label="Mindestens 8 Zeichen" meets={String(value).length > 7} /> : null}
      {value ? checks : null}
    </>
  );
}
