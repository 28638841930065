import type { PaperProps } from "@mantine/core";

import { nameOf } from "../../../../common/Utils";
import { FormListItem } from "../../../../components/output/FormList/FormListItem";
import { FormListSimple } from "../../../../components/output/FormList/FormListSimple";
import { ContactDetailAddressType } from "../../../../generated/client/octalog-service";
import type { ContactData } from "../common/contactFormContext";
import { useCreateContactContext } from "../common/contactFormContext";
import { initialAddress } from "../common/useContactForm";

import { AddressDetailsInput } from "./AddressDetailsInput";

interface AddressInputProps extends PaperProps {}

export function AddressInput({ ...rest }: AddressInputProps) {
  const MAX_ADDRESSES = 2;
  const form = useCreateContactContext();

  function formListItems() {
    return form.values.addresses.map((_, index) => (
      <FormListItem
        // eslint-disable-next-line react/no-array-index-key --- OCTA-45 Refactor NewContactView
        key={`${nameOf<ContactData>().addresses}.${index}`}
        removeListItem={() => form.removeListItem(nameOf<ContactData>().addresses, index)}
      >
        <AddressDetailsInput index={index} />
      </FormListItem>
    ));
  }

  function insertListItem() {
    if (form.values.addresses.length < MAX_ADDRESSES) {
      const type =
        form.values.addresses.length === 0 ? ContactDetailAddressType.INVOICE : ContactDetailAddressType.DELIVERY;
      form.insertListItem(nameOf<ContactData>().addresses, { ...initialAddress, addressType: type });
    }
  }

  return (
    <FormListSimple insertListItem={insertListItem} label="Adresse" maxItems={MAX_ADDRESSES} {...rest}>
      {formListItems()}
    </FormListSimple>
  );
}
