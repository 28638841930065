import type { ButtonProps } from "@mantine/core";
import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";

interface AddButtonProps extends ButtonProps {
  onClick: () => void;
}

export function AddButton({ ...rest }: AddButtonProps) {
  const commonColors = useCommonColors();
  return (
    <Button color={commonColors.primaryColor} variant="transparent" {...rest}>
      <IconPlus color={commonColors.primaryColor} size={16} />
    </Button>
  );
}
