import { Modal, Select, Button, Loader, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconBuildingWarehouse } from "@tabler/icons-react";
import { useState } from "react";

import { areasToComboBoxData } from "../../../common/mantine/ComboboxUtils";
import { useGetAreasQuery, useAssignAreaMutation } from "../../../hooks/areas/useAreaHooks";

interface AssignContainerToAreaModalProps {
  opened: boolean;
  onClose: () => void;
  selectedContainerIds: number[];
}

export function AssignContainerToAreaModal({ opened, onClose, selectedContainerIds }: AssignContainerToAreaModalProps) {
  const areasQuery = useGetAreasQuery();
  const [selectedArea, setSelectedArea] = useState<number | null>(null);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const assignContainerMutation = useAssignAreaMutation();

  const handleAssignArea = (areaId: number, containerIds: number[]) => {
    assignContainerMutation.mutate({
      path: { areaId },
      body: { containerIds: containerIds },
    });
  };

  return (
    <Modal fullScreen={isMobile} onClose={onClose} opened={opened} size="auto" title="Lagerplatz zuweisen">
      <Stack gap="md">
        {areasQuery.isLoading ? (
          <Loader />
        ) : (
          <Select
            data={areasToComboBoxData(areasQuery.data?.areas)}
            onChange={(value) => setSelectedArea(Number(value))}
            placeholder="Lagerplatz auswählen"
            value={selectedArea?.toString()}
          />
        )}
        <Button
          disabled={!selectedArea || assignContainerMutation.isPending}
          fullWidth
          loaderProps={{ type: "dots" }}
          loading={assignContainerMutation.isPending}
          onClick={() => {
            if (selectedArea) {
              handleAssignArea(selectedArea, selectedContainerIds);
              onClose();
            }
          }}
          px={0}
          rightSection={<IconBuildingWarehouse size={14} />}
          variant="light"
        >
          {selectedContainerIds.length} Behälter zuweisen
        </Button>
      </Stack>
    </Modal>
  );
}
