import { Grid, Group, Text } from "@mantine/core";

import { UNIT_OF_MEASUREMENT_OPTIONS_SHORT } from "../../../../../common/Constants";
import {
  cuttingLengthsToComboBoxData,
  humidityLevelToComboBoxData,
  woodTypesToComboBoxData,
} from "../../../../../common/mantine/ComboboxUtils";
import type { NestedKeyOf } from "../../../../../common/Utils";
import { nameOf } from "../../../../../common/Utils";
import { DecimalScale2Input } from "../../../../../components/input/number/DecimalScale2Input";
import { SegmentedControlInput } from "../../../../../components/input/SegmentControll/SegmentedControlInput";
import type { OrderFormData, OrderItemWoodData } from "../contexts/FormContexts";
import { useOrderItems } from "../contexts/FormContexts";

import { CalculatedWoodPrice } from "./CalculatedWoodPrice";

interface OrderItemWoodProps {
  index: number;
}

export function OrderItemWood({ index }: OrderItemWoodProps) {
  const {
    form,
    contextData: {
      wood: { woodTypes, cuttingLengths, humidityLevels },
    },
  } = useOrderItems();
  const formKeyPrefix = `${nameOf<OrderFormData>().itemsWood}.${index}.` as NestedKeyOf<OrderFormData>;
  return (
    <Grid align="end">
      <Grid.Col span={12}>
        <SegmentedControlInput
          data={woodTypesToComboBoxData(woodTypes)}
          form={form}
          formKey={`${formKeyPrefix}${nameOf<OrderItemWoodData>().woodTypeId}` as NestedKeyOf<OrderFormData>}
          fullWidth
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <SegmentedControlInput
          data={cuttingLengthsToComboBoxData(cuttingLengths)}
          form={form}
          formKey={
            `${nameOf<OrderFormData>().itemsWood}.${index}.${nameOf<OrderItemWoodData>().cuttingLengthId}` as NestedKeyOf<OrderFormData>
          }
          fullWidth
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <SegmentedControlInput
          data={humidityLevelToComboBoxData(humidityLevels)}
          form={form}
          formKey={
            `${nameOf<OrderFormData>().itemsWood}.${index}.${nameOf<OrderItemWoodData>().humidityLevelId}` as NestedKeyOf<OrderFormData>
          }
          fullWidth
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <DecimalScale2Input
          {...form.getInputProps(
            `${nameOf<OrderFormData>().itemsWood}.${index}.${nameOf<OrderItemWoodData>().volume}` as NestedKeyOf<OrderFormData>,
          )}
          inputContainer={(children) => (
            <Group align="flex-start" grow wrap="nowrap">
              {children}
              <SegmentedControlInput
                data={UNIT_OF_MEASUREMENT_OPTIONS_SHORT}
                data-testid="container-type-unit-of-measurement-input"
                form={form}
                formKey={
                  `${nameOf<OrderFormData>().itemsWood}.${index}.${nameOf<OrderItemWoodData>().unitOfMeasurement}` as NestedKeyOf<OrderFormData>
                }
              />
            </Group>
          )}
          label="Volumen"
          pb={2}
          required
          rightSection={
            <Text mr={5} size="sm" variant="dimmed">
              m³
            </Text>
          }
          styles={{ input: { textAlign: "center" } }}
        />
      </Grid.Col>
      <Grid.Col span={7}>
        <CalculatedWoodPrice
          cuttingLengthId={form.getValues().itemsWood[index]?.cuttingLengthId}
          formKey={
            `${nameOf<OrderFormData>().itemsWood}.${index}.${nameOf<OrderItemWoodData>().price}` as NestedKeyOf<OrderFormData>
          }
          humidityLevelId={form.getValues().itemsWood[index]?.humidityLevelId}
          usePreset={form.getValues().itemsWood[index]?.orderItemId !== undefined}
          volume={form.getValues().itemsWood[index]?.volume}
          woodTypeId={form.getValues().itemsWood[index]?.woodTypeId}
        />
      </Grid.Col>
      <Grid.Col span={5}>
        <DecimalScale2Input
          {...form.getInputProps(
            `${nameOf<OrderFormData>().itemsWood}.${index}.${nameOf<OrderItemWoodData>().priceOverride}` as NestedKeyOf<OrderFormData>,
          )}
          description="Preis überschreiben"
          label="Angebot"
          rightSection={
            <Text mr={5} size="sm" variant="dimmed">
              €
            </Text>
          }
          styles={{ input: { textAlign: "center" } }}
        />
      </Grid.Col>
    </Grid>
  );
}
