import { Grid } from "@mantine/core";

import { nameOf } from "../../../../../common/Utils";
import { FloatingLabelInput } from "../../../../../components/input/FloatingLabelInput/FloatingLabelInput";
import type { CompanyContactCreateDto } from "../../../../../generated/client/octalog-service";
import type { ContactData } from "../../common/contactFormContext";
import { useCreateContactContext } from "../../common/contactFormContext";

export function InputCompanyContact() {
  const form = useCreateContactContext();
  return (
    <Grid.Col span={12}>
      <FloatingLabelInput
        data-1p-ignore
        label="Firmenname"
        {...form.getInputProps(
          `${nameOf<ContactData>().companyContact}.${nameOf<CompanyContactCreateDto>().companyName}`,
        )}
      />
    </Grid.Col>
  );
}
