import type { NumberInputProps } from "@mantine/core";
import { NumberInput } from "@mantine/core";
import { IconCurrencyEuro } from "@tabler/icons-react";

export function PriceModifierInput({ ...numberInputProps }: NumberInputProps) {
  return (
    <NumberInput
      decimalScale={2}
      decimalSeparator=","
      fixedDecimalScale
      hideControls
      placeholder="0.00"
      rightSection={<IconCurrencyEuro size={18} />}
      {...numberInputProps}
    />
  );
}
