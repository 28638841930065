import type { PaperProps } from "@mantine/core";

import { nameOf } from "../../../../common/Utils";
import { FormListItem } from "../../../../components/output/FormList/FormListItem";
import { FormListSimple } from "../../../../components/output/FormList/FormListSimple";
import { ContactDetailPhoneNumberType } from "../../../../generated/client/octalog-service";
import type { ContactData, FormPhoneNumber } from "../common/contactFormContext";
import { useCreateContactContext } from "../common/contactFormContext";

import { PhoneNumberDetailsInput } from "./PhoneNumberDetailsInput";

interface PhoneNumberInputProps extends PaperProps {}

export function PhoneNumberInput({ ...rest }: PhoneNumberInputProps) {
  const form = useCreateContactContext();

  function formListItems() {
    return form.values.phoneNumbers.map((_: FormPhoneNumber, index) => (
      <FormListItem
        // eslint-disable-next-line react/no-array-index-key --- OCTA-45 Refactor NewContactView
        key={`${nameOf<ContactData>().phoneNumbers}.${index}`}
        removeListItem={() => form.removeListItem(nameOf<ContactData>().phoneNumbers, index)}
      >
        <PhoneNumberDetailsInput
          countryCodeInputProps={form.getInputProps(
            `${nameOf<ContactData>().phoneNumbers}.${index}.${nameOf<FormPhoneNumber>().countryCode}`,
          )}
          phoneNumberInputProps={form.getInputProps(
            `${nameOf<ContactData>().phoneNumbers}.${index}.${nameOf<FormPhoneNumber>().phoneNumber}`,
          )}
          phoneNumberTypeInputProps={form.getInputProps(
            `${nameOf<ContactData>().phoneNumbers}.${index}.${nameOf<FormPhoneNumber>().phoneNumberType}`,
          )}
        />
      </FormListItem>
    ));
  }

  function insertListItem() {
    form.insertListItem(nameOf<ContactData>().phoneNumbers, {
      phoneNumberType: ContactDetailPhoneNumberType.PRIVATE,
      phoneNumber: "",
      countryCode: "DE",
    } satisfies FormPhoneNumber);
  }

  return (
    <FormListSimple insertListItem={insertListItem} label="Telefonnummer" {...rest}>
      {formListItems()}
    </FormListSimple>
  );
}
