import { Button, Container, Paper, SimpleGrid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconUpload } from "@tabler/icons-react";
import { RenderJson } from "octalog-base";
import { useEffect } from "react";

import { nameOf } from "../../../../common/Utils";
import { useSelectionContext } from "../../../../components/input/ContainerIdSelection/SelectionFormContext";
import type { ClearContainersProps } from "../../../../service/containers/ContainerHooks";
import { useClearContainersMutation } from "../../../../service/containers/ContainerHooks";
import { useFilterContainerStates } from "../../../../service/containers/useFilterContainerStates";
import { FILLED } from "../../../../service/containers/useGetContainerStates";
import classes from "../Actions.module.css";

export function ClearContainersAction() {
  const fillContainerForm = useSelectionContext();
  const clearContainersMutation = useClearContainersMutation();
  const filterContainerStates = useFilterContainerStates();
  const clearContainersForm = useForm<ClearContainersProps>({
    initialValues: {
      containerIds: [],
    },
  });
  const setFieldValueClearContainers = clearContainersForm.setFieldValue;

  useEffect(() => {
    setFieldValueClearContainers(
      nameOf<ClearContainersProps>().containerIds,
      filterContainerStates(fillContainerForm.values.containersWithState, [FILLED]).map(
        (containerState) => containerState.containerId,
      ),
    );
  }, [filterContainerStates, fillContainerForm.values.containersWithState, setFieldValueClearContainers]);

  return (
    <Paper mt={20} ta="left" withBorder>
      <form
        onSubmit={clearContainersForm.onSubmit((values) => {
          clearContainersMutation.mutate({
            body: {
              containerIds: values.containerIds,
            },
          });
        })}
      >
        <SimpleGrid cols={{ base: 1, sm: 2 }} p={10} verticalSpacing={0}>
          <Container className={classes.placeholder} />
          <Button
            classNames={{ section: classes.button__section }}
            disabled={clearContainersForm.values.containerIds.length === 0 || fillContainerForm.values.isPending}
            leftSection={
              clearContainersForm.values.containerIds.length === 0
                ? "0"
                : clearContainersForm.values.containerIds.length
            }
            loaderProps={{ type: "dots" }}
            loading={clearContainersMutation.isPending}
            rightSection={<IconUpload size={14} />}
            type="submit"
            variant="light"
          >
            Entleeren
          </Button>
        </SimpleGrid>
        <RenderJson data={clearContainersForm.values} />
      </form>
    </Paper>
  );
}
