/* eslint-disable react/jsx-max-depth --- Needs refactoring */

import { Button, Paper, SimpleGrid, Stack } from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";
import { RenderJson } from "octalog-base";

import type { OrderCreateDto, OrderUpdateDto } from "../../../../generated/client/octalog-service";

import type { OrderFormData } from "./contexts/FormContexts";
import { CustomerDataSection } from "./CustomerDataSection/CustomerDataSection";
import { DeliveryDetailsSection } from "./DeliveryDetailsSection/DeliveryDetailsSection";
import { OrderItemsSection } from "./OrderItemsSection/OrderItemsSection";
import { CustomerFormProvider, DeliveryFormProvider, OrderItemsProvider } from "./providers/OrderFormProviders";

interface OrderFormProps {
  showContactSelect?: boolean;
  form: UseFormReturnType<OrderFormData, (values: OrderFormData) => OrderUpdateDto | OrderCreateDto>;
  onSubmit: (values: OrderCreateDto | OrderUpdateDto) => void;
  isPending: boolean;
}

function SubmitButton({
  isValid,
  hasItems,
  isSubmitting,
  isUpdate,
}: {
  isValid: boolean;
  hasItems: boolean;
  isSubmitting: boolean;
  isUpdate: boolean;
}) {
  return (
    <Button disabled={!isValid || !hasItems} fullWidth={false} loading={isSubmitting} type="submit">
      {isUpdate ? "Auftrag Aktualisieren" : "Auftrag Erstellen"}
    </Button>
  );
}

export function OrderForm({ showContactSelect = true, form, onSubmit, isPending }: OrderFormProps) {
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack align="center">
        <SimpleGrid cols={{ base: 1, lg: 2 }}>
          <Stack>
            <Paper mt="md" p="xs" w="100%" withBorder>
              <CustomerFormProvider form={form} showContactSelect={showContactSelect}>
                <CustomerDataSection />
              </CustomerFormProvider>
            </Paper>
            <Paper mt="md" p="xs" w="100%" withBorder>
              <DeliveryFormProvider form={form}>
                <DeliveryDetailsSection />
              </DeliveryFormProvider>
            </Paper>
          </Stack>
          <Paper mt="md" p="xs" w="100%" withBorder>
            <OrderItemsProvider form={form}>
              <OrderItemsSection />
            </OrderItemsProvider>
          </Paper>
        </SimpleGrid>
        <SubmitButton
          hasItems={form.values.itemsWood.length > 0 || form.values.itemsArticle.length > 0}
          isSubmitting={isPending}
          isUpdate={!!form.values.orderNumber}
          isValid={form.isValid()}
        />
      </Stack>
      <RenderJson data={form} title="Form Values" />
      <RenderJson data={form.getTransformedValues()} title="Transformed Values" />
    </form>
  );
}
