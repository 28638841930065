import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEY_ALL_ORDERS } from "../../../common/Constants";
import { createOrderMutation } from "../../../generated/client/octalog-service/@tanstack/react-query.gen";
import { useBaseMutation } from "../../../hooks/useBaseMutation";

export const useCreateOrderMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    useBaseMutation({
      ...createOrderMutation(),
      successMessage: "Der Auftrag wurde erfolgreich erstellt.",
      errorMessage: "Der Auftrag konnte nicht erstellt werden",
      onSuccess: () => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_ALL_ORDERS,
        });
      },
    }),
  );
};
