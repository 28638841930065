import { Grid } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { RenderJson, RootContainer } from "octalog-base";
import { useState } from "react";

import { Selection, SELECTION_DEBOUNCE_TIME_MS } from "../../components/input/ContainerIdSelection/Selection";
import {
  SelectionFormProvider,
  useSelectionForm,
} from "../../components/input/ContainerIdSelection/SelectionFormContext";
import { ContainerDetails } from "../../components/output/container-details/ContainerDetails";

import { Actions } from "./components/Actions";
import { Fill } from "./components/Fill";

export function FillView() {
  const fillContainerForm = useSelectionForm({
    initialValues: {
      containersWithState: [],
      isPending: false,
      error: "",
    },
  });

  const [selectedContainerNames, setSelectedContainerNames] = useDebouncedState<string[]>(
    [],
    SELECTION_DEBOUNCE_TIME_MS,
  );

  const [maxHeight, setMaxHeight] = useState(0);

  function heightOnChangeHandler(maxHeight: number) {
    setMaxHeight(maxHeight);
  }

  return (
    <RootContainer fluid>
      <SelectionFormProvider form={fillContainerForm}>
        <Grid>
          <Grid.Col order={{ base: 1, sm: 1 }} span={{ base: 12, sm: 6 }}>
            <Selection
              heightOnChangeHandler={heightOnChangeHandler}
              mode="fill"
              selectedContainerNames={selectedContainerNames}
              setSelectedContainerNames={setSelectedContainerNames}
            />
          </Grid.Col>
          <Grid.Col order={{ base: 2, sm: 2 }} span={{ base: 12, sm: 6 }}>
            <ContainerDetails
              containers={fillContainerForm.values.containersWithState}
              isPending={fillContainerForm.values.isPending}
              maxHeight={maxHeight}
            />
          </Grid.Col>
          <Grid.Col order={{ base: 3, sm: 4 }} span={{ base: 12, sm: 6 }}>
            <Actions />
          </Grid.Col>
          <Grid.Col order={{ base: 4, sm: 3 }} span={{ base: 12, sm: 6 }}>
            <Fill
              clearContainerIdsHandler={() => setSelectedContainerNames([])}
              containerIds={fillContainerForm.values.containersWithState.map((container) => container.containerId)}
              error={fillContainerForm.values.error}
              pending={fillContainerForm.values.isPending}
            />
          </Grid.Col>
        </Grid>
        <RenderJson data={fillContainerForm.values} />
      </SelectionFormProvider>
    </RootContainer>
  );
}
