import type { NumberInputProps } from "@mantine/core";
import { NumberInput } from "@mantine/core";
import React from "react";

export interface DecimalScale2InputProps
  extends Omit<NumberInputProps, "decimalScale" | "decimalSeparator" | "fixedDecimalScale" | "step"> {}

/**
 * Input component for decimal numbers with fixed two decimal places
 * Uses the German number format (comma as decimal separator)
 */
export function DecimalScale2Input({ value, ...props }: DecimalScale2InputProps): React.JSX.Element {
  return (
    <NumberInput
      {...props}
      decimalScale={2}
      decimalSeparator=","
      fixedDecimalScale
      hideControls
      min={0}
      step={0.01}
      value={value ?? ""}
    />
  );
}
