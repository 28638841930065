import { Badge, Grid, Group, Stack, Text } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";

import type { ContactResponseDto } from "../../../generated/client/octalog-service";

import { ContactName } from "./ContactName";

interface ContactEntryHeadProps {
  contact: ContactResponseDto;
  preview?: boolean;
}

function renderAddress(contact: ContactResponseDto) {
  return contact.addresses[0] ? (
    <Stack align="flex-start" gap={0}>
      <Text c="dimmed" size="sm">
        {contact.addresses[0].street}
      </Text>
      <Text c="dimmed" size="sm">
        {contact.addresses[0].postalCode} {contact.addresses[0].city}
      </Text>
    </Stack>
  ) : null;
}

export function ContactEntryHead({ contact, preview }: ContactEntryHeadProps) {
  const commonColors = useCommonColors();

  function renderContactIdentifier(contact: ContactResponseDto) {
    if (contact.contactIdentifierCustomer) {
      return (
        <Badge color={commonColors.primaryColor} miw={80} variant="outline">
          {contact.contactIdentifierCustomer.customerId}
        </Badge>
      );
    } else if (contact.contactIdentifierSupplier) {
      return (
        <Badge color={commonColors.redPrimary} miw={80} variant="outline">
          {contact.contactIdentifierSupplier.supplierId}
        </Badge>
      );
    }
  }

  const hasExternalSyncError = contact.externalReference?.externalSyncImportError;

  return (
    <Grid gutter={0} justify="space-between" mt={10} pr={10}>
      <Grid.Col span={12}>
        <Group align="flex-start" justify="space-between" preventGrowOverflow={false} wrap="nowrap">
          <ContactName contact={contact} fw={700} size="md" />
          {hasExternalSyncError ? (
            <IconAlertTriangle
              color={commonColors.orangePrimary}
              size={20}
              style={{ marginLeft: 5 }}
              title="Warung: Externe Synchronisation nicht vollständig abgeschlossen!"
            />
          ) : null}
          {renderContactIdentifier(contact)}
        </Group>
      </Grid.Col>
      {preview ? <Grid.Col span={12}>{renderAddress(contact)}</Grid.Col> : null}
    </Grid>
  );
}
