import { ActionIcon, Flex, MultiSelect, TextInput } from "@mantine/core";
import { IconChevronUp, IconEdit, IconSearch, IconSelector, IconX } from "@tabler/icons-react";
import type { UseQueryResult } from "@tanstack/react-query";
import type { CommonColors } from "octalog-base";
import type { Dispatch, SetStateAction } from "react";

import { translate, translateUnitOfMeasurementPossibleUndefined } from "../../common/Dictionary";
import {
  areasToComboBoxData,
  containerTypesToComboBoxData,
  translatableToComboboxData,
} from "../../common/mantine/ComboboxUtils";
import { formatDate } from "../../common/UnitFormatter";
import { HumidityLevelBadge } from "../../components/badge/HumidityLevel";
import type {
  ContainerTypeListResponseDto,
  AreaListResponseDto,
  GetContainerResponseDto,
} from "../../generated/client/octalog-service";
import { WoodType, CuttingLength, HumidityLevel } from "../../generated/client/octalog-service";

import {
  AREA_ID_ACCESSOR,
  CONTAINER_NAME_ACCESSOR,
  CONTAINER_TYPE_ID_ACCESSOR,
  CUTTING_LENGTH_ACCESSOR,
  FILLING_DATE_ACCESSOR,
  HUMIDITY_LEVEL_ACCESSOR,
  LEVEL_ACCESSOR,
  WOOD_TYPE_ACCESSOR,
} from "./ContainerAccessor";
import { EditContainerModal } from "./edit/EditContainerModal";
import { getContainerTypeVolume, getContainerTypeUnitOfMeasurement } from "./stockUtils";

export const getStockTableColumns = (
  commonColors: CommonColors,
  containerTypesQuery: UseQueryResult<ContainerTypeListResponseDto, Error>,
  areasQuery: UseQueryResult<AreaListResponseDto, Error>,
  getAreaName: (areaId?: string | number) => string | number | undefined,
  {
    selectedContainerNames,
    setSelectedContainerNames,
    selectedContainerTypes,
    setSelectedContainerTypes,
    selectedWoodTypes,
    setSelectedWoodTypes,
    selectedCuttingLengths,
    setSelectedCuttingLengths,
    selectedHumidityLevels,
    setSelectedHumidityLevels,
    selectedAreas,
    setSelectedAreas,
  }: {
    selectedContainerNames: string;
    setSelectedContainerNames: Dispatch<SetStateAction<string>>;
    selectedContainerTypes: string[];
    setSelectedContainerTypes: Dispatch<SetStateAction<string[]>>;
    selectedWoodTypes: string[];
    setSelectedWoodTypes: Dispatch<SetStateAction<string[]>>;
    selectedCuttingLengths: string[];
    setSelectedCuttingLengths: Dispatch<SetStateAction<string[]>>;
    selectedHumidityLevels: string[];
    setSelectedHumidityLevels: Dispatch<SetStateAction<string[]>>;
    selectedAreas: string[];
    setSelectedAreas: Dispatch<SetStateAction<string[]>>;
  },
) => [
  {
    accessor: CONTAINER_NAME_ACCESSOR,
    title: "Name",
    sortable: true,
    filter: (
      <TextInput
        leftSection={<IconSearch size={16} />}
        onChange={(e) => setSelectedContainerNames(e.currentTarget.value)}
        rightSection={
          <ActionIcon c="dimmed" onClick={() => setSelectedContainerNames("")} size="sm" variant="transparent">
            <IconX size={14} />
          </ActionIcon>
        }
        value={selectedContainerNames}
      />
    ),
    filtering: selectedContainerNames !== "",
  },
  {
    accessor: CONTAINER_TYPE_ID_ACCESSOR,
    title: "Typ",
    render: (container: GetContainerResponseDto) => container.containerType.name,
    sortable: true,
    filter: (
      <MultiSelect
        checkIconPosition="right"
        clearable
        data={containerTypesToComboBoxData(containerTypesQuery.data?.containerTypes)}
        leftSection={<IconSearch size={16} />}
        onChange={setSelectedContainerTypes}
        styles={{ input: { width: 250 } }}
        value={selectedContainerTypes}
      />
    ),
    filtering: selectedContainerTypes.length > 0,
  },
  {
    accessor: WOOD_TYPE_ACCESSOR,
    title: "Holzart",
    render: (container: GetContainerResponseDto) =>
      container?.content?.woodType && translate(container.content.woodType as WoodType),
    sortable: true,
    filter: (
      <MultiSelect
        checkIconPosition="right"
        clearable
        data={translatableToComboboxData(WoodType)}
        leftSection={<IconSearch size={16} />}
        onChange={setSelectedWoodTypes}
        styles={{ input: { width: 250 } }}
        value={selectedWoodTypes}
      />
    ),
    filtering: selectedWoodTypes.length > 0,
  },
  {
    accessor: CUTTING_LENGTH_ACCESSOR,
    title: "Schnittlänge",
    render: (container: GetContainerResponseDto) =>
      container?.content?.cuttingLength && translate(container.content.cuttingLength as CuttingLength),
    sortable: true,
    filter: (
      <MultiSelect
        checkIconPosition="right"
        clearable
        data={translatableToComboboxData(CuttingLength)}
        leftSection={<IconSearch size={16} />}
        onChange={setSelectedCuttingLengths}
        styles={{ input: { width: 250 } }}
        value={selectedCuttingLengths}
      />
    ),
    filtering: selectedCuttingLengths.length > 0,
  },
  {
    accessor: HUMIDITY_LEVEL_ACCESSOR,
    title: "Feuchtigkeit",
    render: (container: GetContainerResponseDto) => HumidityLevelBadge(commonColors, container?.content?.humidityLevel),
    sortable: true,
    filter: (
      <MultiSelect
        checkIconPosition="right"
        clearable
        data={translatableToComboboxData(HumidityLevel)}
        leftSection={<IconSearch size={16} />}
        onChange={setSelectedHumidityLevels}
        styles={{ input: { width: 250 } }}
        value={selectedHumidityLevels}
      />
    ),
    filtering: selectedHumidityLevels.length > 0,
  },
  {
    accessor: LEVEL_ACCESSOR,
    title: "Füllstand",
    render: (container: GetContainerResponseDto) => {
      const volume = getContainerTypeVolume(
        containerTypesQuery.data?.containerTypes,
        container.containerType.containerTypeId,
      );
      const unit = getContainerTypeUnitOfMeasurement(
        containerTypesQuery.data?.containerTypes,
        container.containerType.containerTypeId,
      );
      return `${container?.content?.level || "0"}/${volume} ${translateUnitOfMeasurementPossibleUndefined(unit)}`;
    },
    sortable: true,
  },
  {
    accessor: FILLING_DATE_ACCESSOR,
    title: "Fülldatum",
    render: (container: GetContainerResponseDto) =>
      container?.content?.fillingDate && formatDate(container.content.fillingDate),
    sortable: true,
  },
  {
    accessor: AREA_ID_ACCESSOR,
    title: "Lagerplatz",
    render: (container: GetContainerResponseDto) => getAreaName(container?.area?.areaId),
    sortable: true,
    filter: (
      <MultiSelect
        checkIconPosition="right"
        clearable
        data={areasToComboBoxData(areasQuery.data?.areas)}
        leftSection={<IconSearch size={16} />}
        onChange={setSelectedAreas}
        styles={{ input: { width: 250 } }}
        value={selectedAreas}
      />
    ),
    filtering: selectedAreas.length > 0,
  },
  {
    accessor: "actions",
    title: (
      <Flex align="center" justify="center">
        <IconEdit size={16} />
      </Flex>
    ),
    width: "0%",
    textAlign: "right" as const,
    render: (container: GetContainerResponseDto) => <EditContainerModal containerDto={container} />,
  },
];

export const sortIcons = {
  sorted: <IconChevronUp size={14} />,
  unsorted: <IconSelector size={14} />,
};
