import type { ComboboxStringItem } from "@mantine/core";
import { memo } from "react";

import type { ContactResponseDto } from "../../generated/client/octalog-service";
import { ContactName } from "../output/contact/ContactName";

interface ContactOptionItemProps {
  option: ComboboxStringItem;
  contacts: ContactResponseDto[];
}

export const ContactOptionItem = memo(function ContactOptionItem({ option, contacts }: ContactOptionItemProps) {
  const contact = contacts.find((c) => c.contactId.toString() === option.value);

  if (!contact) return null;

  return <ContactName contact={contact} size="xs" withSalutation={false} />;
});
