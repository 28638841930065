import type { TextareaProps } from "@mantine/core";
import { Textarea } from "@mantine/core";
import { useRef, useEffect } from "react";

import classes from "./DisabledTextInput.module.css";

interface DisabledTextareaProps extends Omit<TextareaProps, "onChange"> {
  onChange?: (value: string | number | readonly string[]) => void;
}

export function DisabledTextarea({ disabled = true, onChange, value, ...rest }: DisabledTextareaProps) {
  const previousExternalValue = useRef(value);

  useEffect(() => {
    // Only update if externalValue has actually changed
    if (value !== previousExternalValue.current) {
      previousExternalValue.current = value;
      if (onChange) {
        onChange(value || "");
      }
    }
  }, [value, onChange]);

  return <Textarea autosize classNames={{ input: classes.input }} disabled={disabled} value={value} {...rest} />;
}
