import { Button, Grid, NumberInput, SegmentedControl, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { IconDownload } from "@tabler/icons-react";
import { RenderJson } from "octalog-base";
import { useEffect, useCallback } from "react";

import { LocalDateInternal } from "../../../common/calendar/LocalDateInternal";
import { translatableToComboboxData } from "../../../common/mantine/ComboboxUtils";
import { nameOf } from "../../../common/Utils";
import { IconPaper } from "../../../components/paper/IconPaper";
import type { AddContentRequestDto, ContainerAddContentBatchDto } from "../../../generated/client/octalog-service";
import { WoodType, CuttingLength, HumidityLevel } from "../../../generated/client/octalog-service";
import { useAddContentToContainerMutation } from "../../../service/containers/ContentHooks";

interface FillProps {
  containerIds: number[];
  clearContainerIdsHandler: () => void;
  error: string;
  pending: boolean;
}

interface AddContentRequestData extends Omit<AddContentRequestDto, "fillingDate"> {
  fillingDate: LocalDateInternal;
}

interface ContainerAddContentBatchData extends Omit<ContainerAddContentBatchDto, "content"> {
  content: AddContentRequestData;
}

export function Fill({ containerIds, clearContainerIdsHandler, error, pending }: FillProps) {
  const addContentToContainerMutation = useAddContentToContainerMutation();

  const containerAddContentBatchTransformer = useCallback(
    (values: ContainerAddContentBatchData): ContainerAddContentBatchDto => {
      return {
        ...values,
        content: {
          ...values.content,
          fillingDate: values.content.fillingDate.toString(),
        },
      };
    },
    [],
  );

  const addContainerBatchForm = useForm<ContainerAddContentBatchData, typeof containerAddContentBatchTransformer>({
    initialValues: {
      containerIds: containerIds,
      content: {
        woodType: WoodType.BEECH,
        cuttingLength: CuttingLength.CM25,
        humidityLevel: HumidityLevel.DRIED,
        fillingDate: LocalDateInternal.today(),
        level: undefined,
      },
    },
    transformValues: containerAddContentBatchTransformer,
  });

  const setFieldValueAddContainerBatch = addContainerBatchForm.setFieldValue;
  useEffect(() => {
    setFieldValueAddContainerBatch(nameOf<ContainerAddContentBatchDto>().containerIds, containerIds);
  }, [containerIds, setFieldValueAddContainerBatch]);

  return (
    <IconPaper description="der ausgewählten Behälter durchführen" icon={IconDownload} p={0} title="Befüllen">
      <form
        onSubmit={addContainerBatchForm.onSubmit((values) => {
          addContentToContainerMutation.mutate(
            { body: values },
            {
              onSuccess: () => {
                clearContainerIdsHandler();
              },
            },
          );
        })}
      >
        <Grid m={16}>
          <Grid.Col span={12}>
            <Text fw={500} mb={10} size="sm">
              Holzart
            </Text>
            <SegmentedControl
              data={translatableToComboboxData(WoodType)}
              fullWidth
              key={`${addContainerBatchForm.key(nameOf<ContainerAddContentBatchDto>().content)}.${nameOf<AddContentRequestDto>().woodType}`}
              {...addContainerBatchForm.getInputProps(
                `${nameOf<ContainerAddContentBatchDto>().content}.${nameOf<AddContentRequestDto>().woodType}`,
              )}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text fw={500} mb={10} size="sm">
              Trocknungsgrad
            </Text>
            <SegmentedControl
              data={translatableToComboboxData(HumidityLevel)}
              fullWidth
              key={`${addContainerBatchForm.key(nameOf<ContainerAddContentBatchDto>().content)}.${nameOf<AddContentRequestDto>().humidityLevel}`}
              {...addContainerBatchForm.getInputProps(
                `${nameOf<ContainerAddContentBatchDto>().content}.${nameOf<AddContentRequestDto>().humidityLevel}`,
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Text fw={500} mb={10} size="sm">
              Schnittlänge
            </Text>
            <SegmentedControl
              data={translatableToComboboxData(CuttingLength)}
              fullWidth
              key={`${addContainerBatchForm.key(nameOf<ContainerAddContentBatchDto>().content)}.${nameOf<AddContentRequestDto>().cuttingLength}`}
              {...addContainerBatchForm.getInputProps(
                `${nameOf<ContainerAddContentBatchDto>().content}.${nameOf<AddContentRequestDto>().cuttingLength}`,
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Text fw={500} mb={10} size="sm">
              Volumen
            </Text>
            <NumberInput
              decimalScale={2}
              decimalSeparator=","
              fixedDecimalScale
              key={`${addContainerBatchForm.key(nameOf<ContainerAddContentBatchDto>().content)}.${nameOf<AddContentRequestDto>().level}`}
              min={0}
              placeholder="Max"
              {...addContainerBatchForm.getInputProps(
                `${nameOf<ContainerAddContentBatchDto>().content}.${nameOf<AddContentRequestDto>().level}`,
              )}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text fw={500} mb={10} size="sm">
              Fülldatum
            </Text>
            <DatePickerInput
              locale="de"
              onChange={(value) => {
                addContainerBatchForm.setFieldValue(
                  `${nameOf<ContainerAddContentBatchDto>().content}.${nameOf<AddContentRequestDto>().fillingDate}`,
                  value ? LocalDateInternal.fromDate(value) : LocalDateInternal.today(),
                );
              }}
              value={addContainerBatchForm.values.content.fillingDate.toDate()}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Button
              disabled={pending || error.length > 0 || containerIds.length <= 0}
              loaderProps={{ type: "dots" }}
              loading={addContentToContainerMutation.isPending}
              mt={20}
              type="submit"
              variant="filled"
            >
              Container befüllen
            </Button>
          </Grid.Col>
        </Grid>
        <RenderJson data={addContainerBatchForm.values} />
      </form>
    </IconPaper>
  );
}
