import { Stack, Title, Button } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";
import type { ReactNode } from "react";

interface AuthSuccessStateProps {
  title: string;
  dataTestIdTitle?: string;
  children?: ReactNode;
  buttonText?: string;
  dataTestIdButton?: string;
  onButtonClick?: () => void;
}

export function AuthSuccessState({
  title,
  dataTestIdTitle,
  children,
  buttonText,
  dataTestIdButton,
  onButtonClick,
}: AuthSuccessStateProps) {
  const commonColors = useCommonColors();

  return (
    <Stack align="center" gap="md">
      <Title data-testid={dataTestIdTitle} order={2}>
        {title}
      </Title>
      <IconCheck aria-label="Erfolgreich" color={commonColors.greenPrimary} size={40} />
      {children}
      {buttonText && onButtonClick ? (
        <Button data-testid={dataTestIdButton} onClick={onButtonClick}>
          {buttonText}
        </Button>
      ) : null}
    </Stack>
  );
}
