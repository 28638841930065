import type { GridProps } from "@mantine/core";
import { Grid, Select } from "@mantine/core";
import type { AddressAutofillRetrieveResponse } from "@mapbox/search-js-core";
import { AddressAutofill } from "@mapbox/search-js-react";
import countries from "i18n-iso-countries";
// eslint-disable-next-line import/extensions -- this is a json file
import countriesDe from "i18n-iso-countries/langs/de.json";

import { nameOf } from "../../../../common/Utils";
import { ErrorSegmentControl } from "../../../../components/input/ErrorSegmentControl/ErrorSegmentControl";
import { FloatingLabelInput } from "../../../../components/input/FloatingLabelInput/FloatingLabelInput";
import { MAPBOX_TOKEN } from "../../../../config/environment";
import type { CreateAddressDto } from "../../../../generated/client/octalog-service";
import { ContactDetailAddressType } from "../../../../generated/client/octalog-service";
import type { ContactData } from "../common/contactFormContext";
import { useCreateContactContext } from "../common/contactFormContext";

countries.registerLocale(countriesDe);

interface InputAddressDetailsProps extends GridProps {
  index: number;
}

export function AddressDetailsInput({ index, ...rest }: InputAddressDetailsProps) {
  const form = useCreateContactContext();

  const handleAutofillRetrieve = (response: AddressAutofillRetrieveResponse) => {
    form.setFieldValue(
      `${nameOf<ContactData>().addresses}.${index}.${nameOf<CreateAddressDto>().country}`,
      response.features[0].properties.country_code.toUpperCase(),
    );
  };

  const inputFields = [
    { placeholder: "Adresszusatz", property: nameOf<CreateAddressDto>().addition, autoComplete: "address-line2" },
    { placeholder: "Postleitzahl", property: nameOf<CreateAddressDto>().postalCode, autoComplete: "postal-code" },
    { placeholder: "Stadt", property: nameOf<CreateAddressDto>().city, autoComplete: "address-level2" },
  ];

  return (
    <Grid {...rest}>
      <Grid.Col span={12}>
        <ErrorSegmentControl
          data={[
            { label: "Rechnungsadresse", value: ContactDetailAddressType.INVOICE },
            { label: "Lieferadresse", value: ContactDetailAddressType.DELIVERY },
          ]}
          fullWidth
          {...form.getInputProps(
            `${nameOf<ContactData>().addresses}.${index}.${nameOf<CreateAddressDto>().addressType}`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <AddressAutofill
          accessToken={MAPBOX_TOKEN}
          onRetrieve={handleAutofillRetrieve}
          options={{
            language: "de",
            proximity: "ip",
          }}
          popoverOptions={{
            offset: 7,
          }}
          theme={{
            variables: {
              unit: "var(--mantine-font-size-sm)",
              fontWeightBold: "500",
              fontWeightSemibold: "400",
              colorText: "var(--mantine-color-text)",
              colorBackground: "var(--mantine-color-default)",
              colorBackgroundHover: "var(--mantine-color-default-hover)",
              border: "1px solid var(--mantine-color-default-border)",
              borderRadius: "var(--mantine-radius-default)",
              boxShadow: "none",
            },
          }}
        >
          <FloatingLabelInput
            autoComplete="address-line1"
            data-1p-ignore
            label="Straße u. Hausnummer"
            {...form.getInputProps(`${nameOf<ContactData>().addresses}.${index}.${nameOf<CreateAddressDto>().street}`)}
          />
        </AddressAutofill>
      </Grid.Col>
      {inputFields.map((field) => (
        <Grid.Col key={index + "-" + field.property} span={{ base: 12, md: 6 }}>
          <FloatingLabelInput
            autoComplete={field.autoComplete}
            data-1p-ignore
            label={field.placeholder}
            {...form.getInputProps(`${nameOf<ContactData>().addresses}.${index}.${field.property}`)}
          />
        </Grid.Col>
      ))}
      <Grid.Col span={12}>
        <Select
          allowDeselect={false}
          data={Object.values(countries.getNames("de", { select: "official" })).map((country) => ({
            label: country,
            value: countries.getAlpha2Code(country, "de") ?? "DE",
          }))}
          data-1p-ignore
          searchable
          {...form.getInputProps(`${nameOf<ContactData>().addresses}.${index}.${nameOf<CreateAddressDto>().country}`)}
        />
      </Grid.Col>
    </Grid>
  );
}
