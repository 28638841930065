import { Button, Grid } from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";
import { RenderJson } from "octalog-base";

import { nameOf } from "../../../common/Utils";

import { AddressInput } from "./address/AddressInput";
import type { ContactData } from "./common/contactFormContext";
import { CreateContactFormProvider } from "./common/contactFormContext";
import { ContactTypeSegmentControl } from "./contact/ContactTypeSegmentControl";
import { InputContact } from "./contact/InputContact";
import { EmailInput } from "./email/EmailInput";
import { ContactIdentifierSegmentControl } from "./identifier/ContactIdentifierSegmentControl";
import { InputIdentifier } from "./identifier/InputIdentifier";
import { InputNote } from "./note/InputNote";
import { PhoneNumberInput } from "./phone-number/PhoneNumberInput";

interface ContactFormProps {
  form: UseFormReturnType<ContactData>;
  onSubmit: (values: ContactData) => void;
}

export function ContactForm({ form, onSubmit }: ContactFormProps) {
  return (
    <CreateContactFormProvider form={form}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Grid m={16} ta="center">
          <Grid.Col span={12}>
            <ContactTypeSegmentControl />
          </Grid.Col>
          <Grid.Col span={12}>
            <ContactIdentifierSegmentControl
              fullWidth
              {...form.getInputProps(`${nameOf<ContactData>().selectedContactIdentifier}`)}
            />
          </Grid.Col>
          <InputContact />
          <InputNote />
          <Grid.Col span={12}>
            <InputIdentifier selectedContactIdentifier={form.values.selectedContactIdentifier} />
          </Grid.Col>
          <Grid.Col span={12}>
            <AddressInput />
          </Grid.Col>
          <Grid.Col span={12}>
            <PhoneNumberInput />
          </Grid.Col>
          <Grid.Col span={12}>
            <EmailInput />
          </Grid.Col>
          <Grid.Col span={12}>
            <Button type="submit">{form.values.contactId ? "Kontakt Aktualisieren" : "Kontakt Erstellen"}</Button>
          </Grid.Col>
          <RenderJson data={form.errors} inGrid={true} title="Errors" />
          <RenderJson data={form.values} inGrid={true} title="Raw JSON" />
          <RenderJson data={form.getTransformedValues()} inGrid={true} title="Transformed JSON" />
        </Grid>
      </form>
    </CreateContactFormProvider>
  );
}
