import type { ContactDetailAddressType, ContactResponseDto } from "../../../generated/client/octalog-service";
import { ContactType } from "../../../generated/client/octalog-service";

function getFirstName(contact: ContactResponseDto): string {
  return contact.individualContact?.firstName ?? "";
}

function getLastName(contact: ContactResponseDto): string {
  return contact.individualContact?.lastName ?? "";
}

function getSalutation(contact: ContactResponseDto): string {
  return contact.individualContact?.salutation ?? "";
}

export function getContactName(contact: ContactResponseDto, withSalutation = true): string {
  switch (contact.contactType) {
    case ContactType.INDIVIDUAL: {
      return `${withSalutation ? getSalutation(contact) : ""} ${getFirstName(contact)} ${getLastName(contact)}`.trim();
    }
    case ContactType.COMPANY: {
      return `${contact.companyContact?.companyName}`;
    }
    default: {
      throw new Error(`Unknown contact type ${contact.contactType}`);
    }
  }
}

export function generateAddressText(
  contact: ContactResponseDto,
  addressType: `${ContactDetailAddressType}`,
): string | undefined {
  const address = contact.addresses.find((address) => address.addressType === addressType);
  if (!address) {
    return undefined;
  }
  return (
    `${getContactName(contact)}\n` +
    `${address.addition ? address.addition + "\n" : ""}` +
    `${address.street}\n` +
    `${address.postalCode} ${address.city}\n` +
    `${address.country}`
  );
}

export function generateAddressTexts(
  contact?: ContactResponseDto,
): Map<`${ContactDetailAddressType}`, string> | undefined {
  if (!contact) {
    return undefined;
  }
  const result = new Map<`${ContactDetailAddressType}`, string>();
  for (const address of contact.addresses) {
    const addressText = generateAddressText(contact, address.addressType);
    if (addressText) {
      result.set(address.addressType, addressText);
    }
  }
  return result;
}
