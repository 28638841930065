import { Flex, Grid, Switch, Text } from "@mantine/core";
import { IconBuildingWarehouse } from "@tabler/icons-react";
import { useCommonColors, RenderJson } from "octalog-base";
import { useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import type { ContainerStatisticsDto } from "../../generated/client/octalog-service";
import { useAreaNameTranslator } from "../../hooks/areas/useAreaHooks";

import { AbstractIconPaperStatistics } from "./AbstractIconPaperStatistics";

export function AreaStatistics() {
  const commonColors = useCommonColors();
  const areaNameTranslator = useAreaNameTranslator();

  enum statisticsType {
    volume = "volume",
    count = "count",
  }

  const [selectedStatisticsType, setSelectedStatisticsType] = useState<statisticsType>(statisticsType.count);
  const dataValueKey = selectedStatisticsType;

  function renderContent(data: ContainerStatisticsDto | undefined) {
    const selectedData =
      selectedStatisticsType === statisticsType.count
        ? data?.areaStatistics.containerCountByArea
        : data?.areaStatistics.volumeByArea;

    return (
      <Grid align="stretch" justify="flex-end">
        <Grid.Col span={{ base: 12 }}>
          <Flex align="center" justify="flex-end">
            <Text mr={10}>Container</Text>
            <Switch
              color={commonColors.colors.gray[8]}
              mr={10}
              onChange={(event) =>
                setSelectedStatisticsType(event.currentTarget.checked ? statisticsType.volume : statisticsType.count)
              }
            />
            <Text mr={20}>Volumen</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col pb={20} pl={0} span={{ base: 12 }}>
          <ResponsiveContainer height={500} width="100%">
            <BarChart
              data={selectedData}
              margin={{
                top: 5,
                right: 10,
                left: 0,
                bottom: 40,
              }}
            >
              <Bar dataKey={dataValueKey} label={{ position: "top" }}>
                {selectedData?.map((data) => <Cell fill={commonColors.primaryColor} key={data.areaId} />)}
              </Bar>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                angle={-35}
                dataKey="areaId"
                textAnchor="end"
                tickFormatter={(value) => {
                  return areaNameTranslator ? areaNameTranslator(value) : value;
                }}
              />
              <YAxis />
              <Tooltip
                contentStyle={{ backgroundColor: commonColors.body }}
                cursor={{ fill: commonColors.grayPrimary, opacity: 0.2 }}
                formatter={(value) => {
                  return [
                    selectedStatisticsType === statisticsType.volume ? value + " m³" : value,
                    selectedStatisticsType === statisticsType.volume ? "Volumen" : "Container",
                  ];
                }}
                itemStyle={{ color: commonColors.primaryColor }}
                labelFormatter={(value) => {
                  return areaNameTranslator ? areaNameTranslator(value) : value;
                }}
              />
            </BarChart>
          </ResponsiveContainer>
          <RenderJson data={selectedData} />
        </Grid.Col>
        <RenderJson data={data?.areaStatistics} inGrid={true} />
      </Grid>
    );
  }

  return (
    <AbstractIconPaperStatistics color={commonColors.colors.red} icon={IconBuildingWarehouse} title="Lagerplätze">
      {(data: ContainerStatisticsDto | undefined) => {
        return renderContent(data);
      }}
    </AbstractIconPaperStatistics>
  );
}
