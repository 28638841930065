import type { NavLinkProps } from "@mantine/core";
import { NavLink, ThemeIcon } from "@mantine/core";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router";

import type { Link } from "./links";
import classes from "./NavigationLink.module.css";
import { useActiveLink } from "./useActiveLinkIndex";

export interface NavigationLinkProps extends NavLinkProps {
  link: Link;
  closeNavigationInMobileModeCallback: () => void;
}

function NavigationLink({ link, closeNavigationInMobileModeCallback, ...rest }: NavigationLinkProps) {
  const navigate = useNavigate();

  const isActive = useActiveLink(link);

  const handleClick = useCallback(() => {
    navigate(link.to);
    closeNavigationInMobileModeCallback();
  }, [navigate, link.to, closeNavigationInMobileModeCallback]);

  const commonProps = {
    active: isActive,
    "data-testid": `main-link-${link.id}`,
    label: link.label,
    leftSection: (
      <ThemeIcon color={link.color} variant="light">
        {link.icon}
      </ThemeIcon>
    ),
    onClick: link.subLinks ? undefined : handleClick,
    ...rest,
  };

  if (link.subLinks) {
    return (
      <NavLink className={classes.linkWithSublinks} key={link.to} {...commonProps}>
        {link.subLinks.map((subLink: Link) => (
          <NavigationLink
            className={classes.subLink}
            closeNavigationInMobileModeCallback={closeNavigationInMobileModeCallback}
            data-testid={`sub-link-${subLink.id}`}
            key={subLink.to}
            link={subLink}
          />
        ))}
      </NavLink>
    );
  }

  return <NavLink className={classes.link} key={link.to} {...commonProps} />;
}

export const MemorizedNavigationLink = memo(NavigationLink);
