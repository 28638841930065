import type { CSSVariablesResolver } from "@mantine/core";

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    // Common variables for both light and dark modes
  },
  light: {
    "--octalog-color-body": theme.white,
    "--octalog-color-body-light": theme.colors.gray[0],
  },
  dark: {
    "--octalog-color-body": theme.colors.dark[7],
    "--octalog-color-body-light": theme.colors.dark[6],
  },
});
