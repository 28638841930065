import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";

import type { LocalDateInternal } from "../../../../../common/calendar/LocalDateInternal";
import type { LocalTimeInternal } from "../../../../../common/calendar/LocalTimeInternal";
import { nameOf } from "../../../../../common/Utils";
import { useGetCuttingLengths } from "../../../../../service/settings/useGetCuttingLengths";
import { useGetHumidityLevels } from "../../../../../service/settings/useGetHumidityLevels";
import { useGetWoodTypes } from "../../../../../service/settings/useGetWoodTypes";
import type { OrderFormData, OrderItemsContextProps } from "../contexts/FormContexts";
import { CustomerFormContext, DeliveryFormContext, OrderItemType, OrderItemsContext } from "../contexts/FormContexts";
import type { OrderForm } from "../hooks/useOrderForm";

interface ProviderProps {
  children: ReactNode;
  form: OrderForm;
}

export function CustomerFormProvider({
  children,
  form,
  showContactSelect,
}: ProviderProps & {
  showContactSelect: boolean;
}) {
  const [state, setState] = useState({
    isAddressValid: false,
    hasSelectedContact: false,
  });

  const validateAddresses = useCallback(() => {
    const hasInvoiceAddress = Boolean(form.values.invoiceAddress?.trim());
    const needsDeliveryAddress = form.values.deliveryAddressDifferent;
    const hasDeliveryAddress = Boolean(form.values.deliveryAddress?.trim());

    return hasInvoiceAddress && (!needsDeliveryAddress || hasDeliveryAddress);
  }, [form.values]);

  useEffect(() => {
    setState({
      isAddressValid: validateAddresses(),
      hasSelectedContact: form.values.contactId !== undefined,
    });
  }, [form.values.contactId, validateAddresses]);

  const setDeliveryAddress = useCallback(
    (address?: string) => {
      form.setFieldValue(`${nameOf<OrderFormData>().deliveryAddress}`, address);
    },
    [form],
  );

  const clearDeliveryAddress = useCallback(() => {
    form.setFieldValue(`${nameOf<OrderFormData>().deliveryAddress}`, undefined);
  }, [form]);

  const value = {
    form,
    showContactSelect,
    state,
    setDeliveryAddress,
    clearDeliveryAddress,
    validateAddresses,
  };

  return <CustomerFormContext.Provider value={value}>{children}</CustomerFormContext.Provider>;
}

export function DeliveryFormProvider({ children, form }: ProviderProps) {
  const [state, setState] = useState({
    isDateValid: false,
    isTimeValid: false,
    hasValidRange: false,
  });

  const setDateRange = useCallback(
    (start: LocalDateInternal, end?: LocalDateInternal) => {
      form.setFieldValue(`${nameOf<OrderFormData>().deliveryTargetDateStart}`, start);
      form.setFieldValue(`${nameOf<OrderFormData>().deliveryTargetDateEnd}`, end);
    },
    [form],
  );

  const setTimeRange = useCallback(
    (start: LocalTimeInternal, end?: LocalTimeInternal) => {
      form.setFieldValue(`${nameOf<OrderFormData>().deliveryTargetTimeStart}`, start);
      form.setFieldValue(`${nameOf<OrderFormData>().deliveryTargetTimeEnd}`, end);
    },
    [form],
  );

  const validateDeliveryRange = useCallback(() => {
    const hasStartDate = Boolean(form.values.deliveryTargetDateStart);
    const hasValidEndDate =
      !form.values.deliveryTargetDateEnd ||
      (form.values.deliveryTargetDateStart &&
        form.values.deliveryTargetDateEnd >= form.values.deliveryTargetDateStart) ||
      false;
    const hasValidTimeRange =
      !form.values.deliveryTargetTimeStart ||
      !form.values.deliveryTargetTimeEnd ||
      form.values.deliveryTargetTimeEnd > form.values.deliveryTargetTimeStart;

    return hasStartDate && hasValidEndDate && hasValidTimeRange;
  }, [form.values]);

  useEffect(() => {
    setState({
      isDateValid: Boolean(form.values.deliveryTargetDateStart),
      isTimeValid: validateDeliveryRange(),
      hasValidRange: validateDeliveryRange(),
    });
  }, [
    form.values.deliveryTargetDateStart,
    form.values.deliveryTargetDateEnd,
    form.values.deliveryTargetTimeStart,
    form.values.deliveryTargetTimeEnd,
    validateDeliveryRange,
  ]);

  const value = {
    form,
    state,
    setDateRange,
    setTimeRange,
    validateDeliveryRange,
  };

  return <DeliveryFormContext.Provider value={value}>{children}</DeliveryFormContext.Provider>;
}

export function OrderItemsProvider({ children, form }: ProviderProps) {
  const [state, setState] = useState({
    totalPrice: 0,
    itemCount: 0,
    isValid: false,
  });

  const calculateTotalPrice = useCallback(() => {
    const woodTotal = form.values.itemsWood.reduce((sum, item) => sum + (item.price || 0), 0);
    const articleTotal = form.values.itemsArticle.reduce(
      (sum, item) => sum + (item.price || 0) * (item.quantity || 0),
      0,
    );
    return woodTotal + articleTotal;
  }, [form.values.itemsWood, form.values.itemsArticle]);

  const validateItems = useCallback(() => {
    const hasValidWoodItems = form.values.itemsWood.every(
      (item) => item.woodTypeId && item.cuttingLengthId && item.humidityLevelId && item.volume && item.price,
    );
    const hasValidArticleItems = form.values.itemsArticle.every((item) => item.sku && item.quantity && item.price);
    return hasValidWoodItems && hasValidArticleItems;
  }, [form.values.itemsWood, form.values.itemsArticle]);

  useEffect(() => {
    setState({
      totalPrice: calculateTotalPrice(),
      itemCount: form.values.itemsWood.length + form.values.itemsArticle.length,
      isValid: validateItems(),
    });
  }, [form.values.itemsWood, form.values.itemsArticle, calculateTotalPrice, validateItems]);

  const { data: cuttingLengthsData } = useGetCuttingLengths();
  const { data: humidityLevelsData } = useGetHumidityLevels();
  const { data: woodTypesData } = useGetWoodTypes();

  const handleAddWoodItem = () => {
    form.insertListItem(`${nameOf<OrderFormData>().itemsWood}`, {
      index: form.values.itemsWood.length,
      type: OrderItemType.WOOD,
      cuttingLengthId: cuttingLengthsData?.cuttingLengths?.[0]?.cuttingLengthId,
      humidityLevelId: humidityLevelsData?.humidityLevels?.[0]?.humidityLevelId,
      woodTypeId: woodTypesData?.woodTypes?.[0]?.woodTypeId,
      unitOfMeasurement: "SOLID_CUBIC_METER",
    });
  };

  const handleAddArticleItem = () => {
    form.insertListItem(`${nameOf<OrderFormData>().itemsArticle}`, {
      index: form.values.itemsArticle.length,
      type: OrderItemType.ARTICLE,
      quantity: 1,
    });
  };

  const handleAddItem = (type: OrderItemType) => {
    if (type === OrderItemType.WOOD) {
      handleAddWoodItem();
    } else {
      handleAddArticleItem();
    }
  };

  const handleRemoveItem = (index: number, type: OrderItemType) => {
    const listName =
      type === OrderItemType.WOOD ? nameOf<OrderFormData>().itemsWood : nameOf<OrderFormData>().itemsArticle;
    form.removeListItem(listName, index);
  };

  const value = {
    form,
    state,
    onAddItem: handleAddItem,
    onRemoveItem: handleRemoveItem,
    calculateTotalPrice,
    validateItems,
    contextData: {
      wood: {
        woodTypes: woodTypesData?.woodTypes,
        cuttingLengths: cuttingLengthsData?.cuttingLengths,
        humidityLevels: humidityLevelsData?.humidityLevels,
      },
    },
  } satisfies OrderItemsContextProps;

  return <OrderItemsContext.Provider value={value}>{children}</OrderItemsContext.Provider>;
}
