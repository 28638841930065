import { Group, Box, Text } from "@mantine/core";

import type { LocalDateInternal } from "../../../../../../../common/calendar/LocalDateInternal";
import { WEEKDAYS } from "../common/constants";

import classes from "./CalendarHeader.module.css";

interface CalendarHeaderProps {
  weekStart: LocalDateInternal;
  isMobile: boolean;
}

export function CalendarHeader({ weekStart, isMobile }: CalendarHeaderProps) {
  return (
    <Group className={classes.headerContainer} gap={0} grow justify="space-evenly" pr="md">
      <Box className={classes.headerEmpty} h="100%" />
      {WEEKDAYS.map((day, index) => {
        const date = weekStart.addDays(index);
        return (
          <Box key={day}>
            <Text size={isMobile ? "sm" : "md"} truncate>
              {day}
            </Text>
            <Text c="dimmed" size={isMobile ? "xs" : "sm"} truncate>
              {date.humanize()}
            </Text>
          </Box>
        );
      })}
    </Group>
  );
}
