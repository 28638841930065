import { Button, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router";

import { REGEX_EMAIL } from "../../common/Constants";
import { FloatingLabelInput } from "../../components/input/FloatingLabelInput/FloatingLabelInput";
import { useStartPasswordResetMutation } from "../../service/iam/IamHooks";

import { AuthContainer } from "./components/AuthContainer";
import { AuthLoadingState } from "./components/AuthLoadingState";
import { AuthSuccessState } from "./components/AuthSuccessState";

export function StartPasswordReset() {
  const navigate = useNavigate();
  const startPasswordReset = useStartPasswordResetMutation();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      username: "",
    },
    validate: {
      username: (value) => (REGEX_EMAIL.test(value) ? null : "Nicht gültige Email"),
    },
  });

  return (
    <AuthContainer title="Passwort zurücksetzen">
      {startPasswordReset.isPending ? (
        <AuthLoadingState title="Zurücksetzen wird durchgeführt" />
      ) : startPasswordReset.isSuccess ? (
        <AuthSuccessState
          buttonText="Anmelden"
          dataTestIdTitle="startPasswordResetSuccessful"
          onButtonClick={() => navigate("/sign-in")}
          title="Zurücksetzen erfolgreich"
        >
          <Text mt={10}>Eine Bestätigungsmail wurde an die Email-Adresse gesendet, falls diese existiert.</Text>
          <Text mt={10}>Bitte bestätigen Sie den Link in der Email.</Text>
        </AuthSuccessState>
      ) : (
        <form
          aria-label="Passwort zurücksetzen Formular"
          onSubmit={form.onSubmit((values) => {
            startPasswordReset.mutate({ body: values });
          })}
        >
          <FloatingLabelInput
            aria-label="Email Adresse für Passwort zurücksetzen"
            autoComplete="username email"
            data-testid="username"
            id="reset-email-field"
            label="Email"
            required
            type="email"
            {...form.getInputProps("username")}
          />
          <Button
            aria-label="Passwort zurücksetzen anfordern"
            data-testid="submit"
            disabled={startPasswordReset.isPending || !form.isValid()}
            fullWidth
            mt="xl"
            type="submit"
          >
            Passwort zurücksetzen
          </Button>
        </form>
      )}
    </AuthContainer>
  );
}
