import type { MantineColorsTuple, MantineTheme } from "@mantine/core";
import { Box, Grid, LoadingOverlay, useMantineTheme } from "@mantine/core";
import type { IconType } from "octalog-base";
import { useCommonColors } from "octalog-base";
import type React from "react";

import { IconPaper } from "../../components/paper/IconPaper";
import type { ContainerStatisticsDto } from "../../generated/client/octalog-service";
import { useGetContainerStatistics } from "../../service/statistics/StatisticsService";

export function AbstractIconPaperStatistics({
  children,
  title,
  icon,
  color,
}: {
  children: (data: ContainerStatisticsDto | undefined, theme: MantineTheme) => React.JSX.Element;
  title: string;
  icon: IconType;
  color?: MantineColorsTuple;
}) {
  const containerStatistics = useGetContainerStatistics();
  const theme = useMantineTheme();
  const commonColors = useCommonColors();

  return (
    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
      <IconPaper icon={icon} iconColor={color} p={0} paperProps={{ p: 8 }} title={title} variant="light">
        <Box pos="relative">
          <LoadingOverlay
            overlayProps={{ radius: "sm", blur: 3, color: commonColors.body, backgroundOpacity: 0.1 }}
            visible={containerStatistics.isPending}
            zIndex={1000}
          />
          {children(containerStatistics.data, theme)}
        </Box>
      </IconPaper>
    </Grid.Col>
  );
}
