import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/charts/styles.css";
import "@mantine/notifications/styles.css";
import "mantine-datatable/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";

import { App } from "./App";
import { initSentry } from "./config/sentry";

const initialize = () => {
  console.log("Initializing Octalog Instrumentation…");
  initSentry();
};

initialize();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
