import { ContactType } from "../../../../generated/client/octalog-service";
import { useCreateContactContext } from "../common/contactFormContext";

import { InputCompanyContact } from "./company/InputCompanyContact";
import { InputIndividualContact } from "./individual/InputIndividualContact";

export function InputContact() {
  const form = useCreateContactContext();

  return (
    <>
      {(() => {
        switch (form.values.contactType) {
          case ContactType.INDIVIDUAL: {
            return <InputIndividualContact />;
          }
          case ContactType.COMPANY: {
            return <InputCompanyContact />;
          }
          default: {
            throw new Error(`Unknown contact type: ${form.values.contactType}`);
          }
        }
      })()}
    </>
  );
}
