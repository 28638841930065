import type { StackProps } from "@mantine/core";
import { Divider, SimpleGrid, Text, ScrollArea, Stack } from "@mantine/core";
import React from "react";

import { LoaderBoundary } from "../../loader/LoaderBoundary";

import { StandardTableDetails } from "./StandardTableDetails";

export interface StandardTableRow {
  id: string;
  columns: React.ReactNode[];
}

interface StandardTableProps extends StackProps {
  error?: Error | null;
  headers: {
    id: string;
    title: string;
  }[];
  isError?: boolean;
  isPending?: boolean;
  rows: StandardTableRow[];
}

export function StandardTable({
  error = null,
  headers,
  isError = false,
  isPending = false,
  rows,
  ...rest
}: StandardTableProps) {
  function createRows() {
    return rows.map((row) => (
      <React.Fragment key={row.id}>
        <StandardTableDetails data={row.columns} />
      </React.Fragment>
    ));
  }

  return (
    <LoaderBoundary error={error} isError={isError} isPending={isPending} loaderSize="md">
      <Stack {...rest}>
        <SimpleGrid cols={headers.length} mt={10} mx={10} spacing={10}>
          {headers.map((header) => (
            <div key={header.id} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Text truncate="end">{header.title}</Text>
            </div>
          ))}
        </SimpleGrid>
        <Divider my={7} />
        <ScrollArea.Autosize type="auto">
          <SimpleGrid cols={headers.length} mb={10} mx={10} spacing={10}>
            {createRows()}
          </SimpleGrid>
        </ScrollArea.Autosize>
      </Stack>
    </LoaderBoundary>
  );
}
