import { Drawer } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { QueryLoaderBoundary } from "../../../../../../components/loader/QueryLoaderBoundary";
import type { ApiResponseOrderDto } from "../../../../../../generated/client/octalog-service";
import { useGetOrder } from "../../../../query/useGetOrder";
import { useDeliverySchedulingContext } from "../../context";

import { OrderDetailPanelContent } from "./OrderDetailPanelContent";

export function OrderDetailPanel() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const { selectedOrderNumber, setSelectedOrderNumber, isDetailPanelOpen, detailPanelHandlers } =
    useDeliverySchedulingContext();

  const orderQuery = useGetOrder(selectedOrderNumber);

  return (
    <Drawer
      onClose={() => {
        detailPanelHandlers.close();
        setSelectedOrderNumber(null);
      }}
      opened={isDetailPanelOpen}
      position="right"
      size={isMobile ? "100%" : "md"}
      title="Bestelldetails"
    >
      <QueryLoaderBoundary queryResult={orderQuery}>
        {(apiResponse: ApiResponseOrderDto) => <OrderDetailPanelContent order={apiResponse.data} />}
      </QueryLoaderBoundary>
    </Drawer>
  );
}
