import { Box, Grid, Stack } from "@mantine/core";

import { translateUnitOfMeasurement } from "../../../../../../../common/Dictionary";
import { formatCubicMeter, formatCurrency } from "../../../../../../../common/UnitFormatter";
import { LabelValue } from "../../../../../../../components/output/LabelValue/LabelValue";
import type { OrderItemDto, UnitOfMeasurement } from "../../../../../../../generated/client/octalog-service";
import { useCuttingLengthIdTranslator } from "../../../../../../../service/settings/useCuttingLengthIdTranslator";
import { useHumidityLevelIdTranslator } from "../../../../../../../service/settings/useHumidityLevelIdTranslator";
import { useWoodTypeIdTranslator } from "../../../../../../../service/settings/useWoodTypeIdTranslator";
import { isOrderItemWoodDto, isOrderItemArticleDto } from "../../../../../utils/orderTypeUtil";

export interface ProductDetailsProps {
  item: OrderItemDto;
}

export function ProductDetails({ item }: ProductDetailsProps) {
  const translateWoodTypeId = useWoodTypeIdTranslator();
  const translateHumidityLevelId = useHumidityLevelIdTranslator();
  const translateCuttingLengthId = useCuttingLengthIdTranslator();

  let details;
  if (isOrderItemWoodDto(item)) {
    details = [
      { label: "Holzart", value: item.woodTypeId ? translateWoodTypeId(item.woodTypeId) : undefined },
      {
        label: "Feuchtigkeit",
        value: item.humidityLevelId ? translateHumidityLevelId(item.humidityLevelId) : undefined,
      },
      {
        label: "Schnittlänge",
        value: item.cuttingLengthId ? translateCuttingLengthId(item.cuttingLengthId) : undefined,
      },
      {
        label: "Maßeinheit",
        value: item.unitOfMeasurement
          ? translateUnitOfMeasurement(item.unitOfMeasurement as UnitOfMeasurement)
          : undefined,
      },
      { label: "Volumen", value: item.volume ? formatCubicMeter(item.volume) : undefined },
      { label: "Preis", value: item.price ? formatCurrency(item.price) : undefined },
      {
        label: "Angebot",
        value: item.priceOverride ? formatCurrency(item.priceOverride) : undefined,
      },
    ];
  } else if (isOrderItemArticleDto(item)) {
    details = [
      { label: "Artikelnummer", value: item.sku },
      { label: "Artikelname", value: item.name },
      { label: "Beschreibung", value: item.description },
      { label: "Menge", value: item.quantity },
      { label: "Preis", value: item.price ? formatCurrency(item.price) : undefined },
      {
        label: "Angebot",
        value: item.priceOverride ? formatCurrency(item.priceOverride) : undefined,
      },
    ];
  } else {
    throw new Error(`Unknown order item type: ${item.type}`);
  }

  return (
    <Box>
      <Grid align="flex-end">
        <Grid.Col span={6} ta="left">
          <Stack>
            {details
              .slice(0, Math.ceil(details.length / 2))
              .map(
                (detail) =>
                  detail.value !== undefined && (
                    <LabelValue key={detail.label} label={detail.label} value={detail.value} />
                  ),
              )}
          </Stack>
        </Grid.Col>
        <Grid.Col span={6} ta="left">
          <Stack>
            {details
              .slice(Math.ceil(details.length / 2))
              .map(
                (detail) =>
                  detail.value !== undefined && (
                    <LabelValue key={detail.label} label={detail.label} value={detail.value} />
                  ),
              )}
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
