import { Grid, ScrollArea } from "@mantine/core";
import { range } from "lodash-es";
import { memo, useEffect, useRef } from "react";
import invariant from "tiny-invariant";

import type { LocalDateInternal } from "../../../../../../../common/calendar/LocalDateInternal";
import { useIsRefReady } from "../../../../../../../hooks/useIsRefReady";
import type { ScheduledOrder } from "../../../types/ScheduledOrder";
import { TOTAL_COLUMN_COUNT, ROWS_PER_DAY, HOURS_PER_DAY, WEEKDAYS, ROW_HEIGHT_PX } from "../common/constants";

import classes from "./CalendarGrid.module.css";
import { ContentCell } from "./Cell/ContentCell/ContentCell";
import { TimeCell } from "./Cell/TimeCell/TimeCell";
import { useOrdersMatrix } from "./hooks/useOrdersMatrix";
import { registerDropTargetAndAutoScroll } from "./utils/dropTargetUtil";

interface CalendarGridProps {
  weekStart: LocalDateInternal;
  orders: ScheduledOrder[];
  isMobile: boolean;
}

export const CalendarGrid = memo(function CalendarGrid({ weekStart, orders, isMobile }: CalendarGridProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const isRefReady = useIsRefReady(scrollRef);

  const ordersMatrix = useOrdersMatrix(orders);

  /**
   * Register drop target for the calendar grid
   */
  useEffect(() => {
    if (isRefReady && scrollRef.current) {
      const element = scrollRef.current;
      invariant(element, "scrollRef should not be null");
      registerDropTargetAndAutoScroll({ ref: scrollRef });
    }
  }, [isRefReady]);

  // The first and last hour have only 1/2 height, so we need to add 1 to get the full height
  const timeCellRowCount = HOURS_PER_DAY + 1;

  return (
    <ScrollArea.Autosize
      className={classes.scrollArea}
      classNames={{ viewport: classes.scrollAreaViewPort, scrollbar: classes.scrollAreaScrollbar }}
      pb="md"
      pr={isMobile ? 0 : "md"}
      ref={scrollRef}
      scrollbars="y"
      type="auto"
    >
      <Grid columns={TOTAL_COLUMN_COUNT * 2} grow>
        <Grid.Col p={0} span={1}>
          {range(timeCellRowCount).map((rowIndex) => {
            return <TimeCell isMobile={isMobile} key={rowIndex} rowIndex={rowIndex} />;
          })}
        </Grid.Col>
        <Grid.Col span={13}>
          <Grid columns={TOTAL_COLUMN_COUNT} grow>
            {[
              range(WEEKDAYS.length).map((columnIndex) => {
                return (
                  <Grid.Col key={columnIndex} p={0} span={1}>
                    {range(ROWS_PER_DAY).map((rowIndex) => {
                      return (
                        <ContentCell
                          columnIndex={columnIndex}
                          h={ROW_HEIGHT_PX}
                          key={`${rowIndex}-${columnIndex}`}
                          orders={ordersMatrix[columnIndex]?.[rowIndex] ?? []}
                          rowIndex={rowIndex}
                          weekStart={weekStart}
                        />
                      );
                    })}
                  </Grid.Col>
                );
              }),
            ]}
          </Grid>
        </Grid.Col>
      </Grid>
    </ScrollArea.Autosize>
  );
});
