import { useDisclosure } from "@mantine/hooks";
import React, { useState } from "react";

import { DeliverySchedulingContext } from "./DeliverySchedulingContext";

export function DeliverySchedulingProvider({ children }: { children: React.ReactNode }) {
  const [selectedOrderNumber, setSelectedOrderNumber] = useState<string | null>(null);
  const [isDetailPanelOpen, detailPanelHandlers] = useDisclosure(false);
  return (
    <DeliverySchedulingContext.Provider
      value={{
        selectedOrderNumber,
        setSelectedOrderNumber,
        isDetailPanelOpen,
        detailPanelHandlers,
      }}
    >
      {children}
    </DeliverySchedulingContext.Provider>
  );
}
