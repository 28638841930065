import { Container } from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";

import { IconPaper } from "../../../components/paper/IconPaper";

import { AssignContainersAction } from "./actions/AssignContainersAction";
import { ClearContainersAction } from "./actions/ClearContainersAction";
import { CreateContainersAction } from "./actions/CreateContainersAction";

export function Actions() {
  return (
    <IconPaper description="mit den Behältern durchführen" icon={IconSettings} p={0} title="Aktionen">
      <Container mb={20}>
        <CreateContainersAction />
        <ClearContainersAction />
        <AssignContainersAction />
      </Container>
    </IconPaper>
  );
}
