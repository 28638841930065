import { Select, Stack } from "@mantine/core";
import { RenderJson } from "octalog-base";
import { useState, useMemo } from "react";

import { QueryLoaderBoundary } from "../../../../../components/loader/QueryLoaderBoundary";
import type { ApiResponseListArticleDto, ArticleDto } from "../../../../../generated/client/octalog-service";
import { useGetArticles } from "../../../../../query/useGetArticles";
import { useOrderItems } from "../contexts/FormContexts";
import type { OrderForm } from "../hooks/useOrderForm";

import { OrderItemArticleDetails } from "./OrderItemArticleDetails";

interface OrderItemArticleProps {
  index: number;
}

export function OrderItemArticle({ index }: OrderItemArticleProps) {
  const { form } = useOrderItems();
  const articlesQuery = useGetArticles();

  return (
    <QueryLoaderBoundary queryResult={articlesQuery} size="md">
      {(data) => <OrderItemArticleDetailsWrapper data={data} form={form} index={index} />}
    </QueryLoaderBoundary>
  );
}

function OrderItemArticleDetailsWrapper({
  form,
  index,
  data,
}: {
  form: OrderForm;
  index: number;
  data: ApiResponseListArticleDto;
}) {
  const [selectedArticle, setSelectedArticle] = useState<ArticleDto | undefined>(
    data.data.find((article) => article.sku === form.values.itemsArticle[index]?.sku),
  );

  const options = useMemo(() => data.data.map((article) => ({ value: article.sku, label: article.name })), [data.data]);

  return (
    <Stack mt={-20}>
      <Select
        allowDeselect={false}
        data={options}
        label="Auswahl"
        onChange={(value) => {
          const selected = data.data.find((article) => article.sku === value);
          setSelectedArticle(selected);
        }}
        value={selectedArticle?.sku ?? null}
      />
      <OrderItemArticleDetails article={selectedArticle} form={form} index={index} />
      <RenderJson data={selectedArticle} />
    </Stack>
  );
}
