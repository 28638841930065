import type { BoxProps } from "@mantine/core";
import { Autocomplete, CloseButton } from "@mantine/core";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { DEFAULT_ICON_SIZE } from "octalog-base";
import { memo } from "react";

import type { ContactResponseDto } from "../../generated/client/octalog-service";
import { LoaderBoundary } from "../loader/LoaderBoundary";

import { ContactOptionItem } from "./ContactOptionItem";
import { useContactSelect } from "./useContactSelect";

const TRANSITION_DURATION = 200;

interface ContactSelectProps extends BoxProps {
  onSelect: (contact: ContactResponseDto | null) => void;
  resetOnUndefined?: string;
}

export const ContactSelect = memo(function ContactSelect({ onSelect, resetOnUndefined, ...rest }: ContactSelectProps) {
  const {
    value,
    contacts,
    handleChange,
    clearValue,
    contactOptions,
    filteredContactOptions,
    isLoading,
    isError,
    error,
  } = useContactSelect(onSelect, resetOnUndefined);

  return (
    <LoaderBoundary error={error} isError={isError} isPending={isLoading} {...rest}>
      <Autocomplete
        comboboxProps={{
          transitionProps: { transition: "pop", duration: TRANSITION_DURATION },
          shadow: "md",
        }}
        data={contactOptions}
        disabled={isLoading}
        filter={() => filteredContactOptions}
        label="Kontaktsuche"
        leftSection={<IconSearch size={DEFAULT_ICON_SIZE} />}
        maxDropdownHeight={400}
        onChange={handleChange}
        placeholder="Kontaktsuche"
        renderOption={({ option }) => <ContactOptionItem contacts={contacts} option={option} />}
        rightSection={
          value ? (
            <CloseButton
              aria-label="Entfernen"
              onClick={clearValue}
              onMouseDown={(event) => event.preventDefault()}
              size="sm"
            />
          ) : (
            <IconChevronDown size={DEFAULT_ICON_SIZE} />
          )
        }
        value={value}
      />
    </LoaderBoundary>
  );
});
