import { Button, Divider, Flex, NumberInput, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCurrencyEuro } from "@tabler/icons-react";
import { RenderJson } from "octalog-base";

import type { PriceSettingsDto } from "../../../../../generated/client/octalog-service";
import { usePriceSettingsMutation } from "../../../../../service/prices/usePriceSettingsMutation";

import { PriceModifierSection } from "./PriceModifierSection";
import { PriceModifierType } from "./PriceModifierType";

interface EditPriceSettingsProps {
  priceSettings: PriceSettingsDto;
}

export function PriceModifiers({ priceSettings }: EditPriceSettingsProps) {
  const priceSettingsMutation = usePriceSettingsMutation();

  const form = useForm<PriceSettingsDto>({
    initialValues: {
      basePrice: priceSettings.basePrice,
      priceModifiersWoodType: priceSettings.priceModifiersWoodType,
      priceModifiersHumidityLevel: priceSettings.priceModifiersHumidityLevel,
      priceModifiersCuttingLength: priceSettings.priceModifiersCuttingLength,
    },
    validate: {
      basePrice: (value) => {
        if (value === undefined || value === null || value.toString().trim().length === 0) {
          return "Bitte Basispreis eingeben";
        } else if (value < 1) {
          return "Basispreis muss positiv sein";
        } else {
          return null;
        }
      },
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  });

  return (
    <form onSubmit={form.onSubmit((values) => priceSettingsMutation.mutate({ body: values }))}>
      <Stack>
        <Text fw={700}>Basispreis</Text>
        <Flex direction="row" gap="md" justify="center" mb={10} wrap="wrap">
          <NumberInput
            decimalScale={2}
            decimalSeparator=","
            fixedDecimalScale
            hideControls
            key={form.key("basePrice")}
            placeholder="0.00"
            rightSection={<IconCurrencyEuro size={18} />}
            style={{ width: "10rem" }}
            {...form.getInputProps("basePrice")}
          />
        </Flex>
        <Divider />
        <PriceModifierSection
          form={form}
          modifiers={priceSettings.priceModifiersWoodType}
          title="Holzart"
          type={PriceModifierType.WoodType}
        />
        <PriceModifierSection
          form={form}
          modifiers={priceSettings.priceModifiersHumidityLevel}
          title="Feuchtigkeitsgrad"
          type={PriceModifierType.HumidityLevel}
        />
        <PriceModifierSection
          form={form}
          modifiers={priceSettings.priceModifiersCuttingLength}
          title="Schnittlänge"
          type={PriceModifierType.CuttingLength}
        />
        <Flex direction="row" gap="sm" justify="center" my={10} wrap="wrap">
          <Button disabled={!form.isValid()} mt={10} type="submit">
            Speichern
          </Button>
        </Flex>
        <RenderJson data={form} title="Edit Price Settings" />
      </Stack>
    </form>
  );
}
