import { IconInfoCircle } from "@tabler/icons-react";
import React, { useState } from "react";

import type { Translatable } from "../../../common/Dictionary";
import { translatePossibleUndefined } from "../../../common/Dictionary";
import { useAreaNameTranslator } from "../../../hooks/areas/useAreaHooks";
import type { ContainerWithState } from "../../../service/containers/useGetContainerStates";
import { NON_EXISTENT } from "../../../service/containers/useGetContainerStates";
import { IconPaper } from "../../paper/IconPaper";
import type { StandardTableRow } from "../../table/standard-table/StandardTable";
import { StandardTable } from "../../table/standard-table/StandardTable";

interface ContainerDetailsProps {
  containers: ContainerWithState[];
  isPending: boolean;
  maxHeight?: number;
  maxHeightOnChangeHandler?: (maxHeight: number) => void;
}

export function ContainerDetails({
  containers,
  isPending,
  maxHeight,
  maxHeightOnChangeHandler,
}: ContainerDetailsProps) {
  const getAreaName = useAreaNameTranslator();

  const rows: StandardTableRow[] =
    containers
      .filter((containerWithState: ContainerWithState) => containerWithState.state !== NON_EXISTENT)
      .sort((a: ContainerWithState, b: ContainerWithState) => {
        return a.containerName.localeCompare(b.containerName, "de", { numeric: true, sensitivity: "base" });
      })
      .map((containerWithState: ContainerWithState) => ({
        id: containerWithState.containerId.toString(),
        columns: [
          <React.Fragment key={containerWithState.containerId + "_name"}>
            {containerWithState.containerName}
          </React.Fragment>,
          <React.Fragment key={containerWithState.containerId + "_woodType"}>
            {translatePossibleUndefined(containerWithState.content?.woodType as Translatable) || "-"}
          </React.Fragment>,
          <React.Fragment key={containerWithState.containerId + "_area"}>
            {getAreaName(containerWithState.area?.areaId) || "-"}
          </React.Fragment>,
          <React.Fragment key={containerWithState.containerId + "_containerType"}>
            {containerWithState.containerType.name}
          </React.Fragment>,
          <React.Fragment key={containerWithState.containerId + "_level"}>
            {containerWithState.content?.level || 0}/{containerWithState.containerType.volume}
          </React.Fragment>,
        ],
      })) || [];

  const [topValue, setTopValue] = useState(0);

  function topValueOnChangeHandler(topValue: number) {
    setTopValue(topValue);
  }

  return (
    <IconPaper
      icon={IconInfoCircle}
      onContentHeightChangeHandler={maxHeightOnChangeHandler}
      p={0}
      title="Behälterdetails"
      topValueOnChangeHandler={topValueOnChangeHandler}
    >
      <StandardTable
        h={maxHeight ? maxHeight - topValue + 2 : "100%"} // maxHeight - header + border
        headers={[
          {
            id: "name",
            title: "Name",
          },
          {
            id: "woodType",
            title: "Holzart",
          },
          {
            id: "area",
            title: "Lagerplatz",
          },
          {
            id: "containerType",
            title: "Behältertyp",
          },
          {
            id: "level",
            title: "Füllstand",
          },
        ]}
        isPending={isPending}
        rows={rows}
      />
    </IconPaper>
  );
}
