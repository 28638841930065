import { IconBox } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import { nameOf } from "../../../common/Utils";
import type { ContainerWithState } from "../../../service/containers/useGetContainerStates";
import { useGetContainerStates } from "../../../service/containers/useGetContainerStates";
import { IconPaper } from "../../paper/IconPaper";

import type { Mode } from "./ContainerIdSelection";
import { ContainerIdSelection } from "./ContainerIdSelection";
import type { SelectionData } from "./SelectionFormContext";
import { useSelectionContext } from "./SelectionFormContext";
import { useSelectionError } from "./useSelectionError";

export const SELECTION_DEBOUNCE_TIME_MS = 200;

interface SelectionProps {
  mode: Mode;
  selectedContainerNames: string[];
  setSelectedContainerNames: (selectedContainerNames: string[]) => void;
  heightOnChangeHandler?: (height: number) => void;
}

export function Selection({
  mode,
  selectedContainerNames,
  setSelectedContainerNames,
  heightOnChangeHandler,
}: SelectionProps) {
  const logPrefix = "Selection -";

  const selectionContext = useSelectionContext();

  const containerStatesQuery = useGetContainerStates(selectedContainerNames);
  const [containerStates, setContainerStates] = useState<ContainerWithState[]>([]);

  const error = useSelectionError(mode, containerStates);

  useEffect(() => {
    if (!containerStatesQuery.isPending) {
      if (containerStatesQuery.isError) {
        selectionContext.setFieldValue(nameOf<SelectionData>().error, containerStatesQuery.error?.message || "");
      } else {
        setContainerStates(containerStatesQuery.data);
      }
    }
  }, [
    containerStatesQuery.data,
    containerStatesQuery.error?.message,
    containerStatesQuery.isError,
    containerStatesQuery.isPending,
    selectionContext,
  ]);

  const selectionConstextSetFieldValue = selectionContext.setFieldValue;
  useEffect(() => {
    console.group(`${logPrefix} container handler`);
    console.log("Containers:", containerStates);
    selectionConstextSetFieldValue(nameOf<SelectionData>().containersWithState, containerStates);
    console.groupEnd();
  }, [containerStates, selectionConstextSetFieldValue]);

  useEffect(() => {
    console.group(`${logPrefix} isPending handler`);
    console.log("Pending:", containerStatesQuery.isPending);
    selectionConstextSetFieldValue(nameOf<SelectionData>().isPending, containerStatesQuery.isPending);
    console.groupEnd();
  }, [containerStatesQuery.isPending, selectionConstextSetFieldValue]);

  useEffect(() => {
    console.group(`${logPrefix} isError handler`);
    console.log("Error:", error || "none");
    selectionConstextSetFieldValue(nameOf<SelectionData>().error, error);
    console.groupEnd();
  }, [error, selectionConstextSetFieldValue]);

  return (
    <IconPaper
      description="um Aktionen durchzuführen oder sie zu befüllen"
      icon={IconBox}
      onContentHeightChangeHandler={heightOnChangeHandler}
      p={0}
      title="Behälter auswählen"
    >
      <ContainerIdSelection
        containerIdsOnChangeHandler={setSelectedContainerNames}
        containerStates={containerStates}
        error={error}
        isPending={containerStatesQuery.isPending}
        mode={mode}
        mt={10}
      />
    </IconPaper>
  );
}
