import { useMemo } from "react";

import { translateUntyped } from "../../common/Dictionary";

import { useGetCuttingLengths } from "./useGetCuttingLengths";

interface CuttingLength {
  cuttingLengthId: number;
  name: string;
}

export const useCuttingLengthIdTranslator = (): ((cuttingLengthId: number) => string | number) => {
  const cuttingLengths = useGetCuttingLengths();

  return useMemo(() => {
    return (cuttingLengthId: number): string | number => {
      if (cuttingLengths.isLoading) {
        return cuttingLengthId;
      }

      if (cuttingLengths.isError) {
        return `Error: ${cuttingLengths.error.message}`;
      }

      const cuttingLength: CuttingLength | undefined = cuttingLengths.data?.cuttingLengths.find(
        (length: CuttingLength) => length.cuttingLengthId === cuttingLengthId,
      );

      return translateUntyped(cuttingLength?.name) ?? cuttingLengthId;
    };
  }, [cuttingLengths]);
};
