import type { ContainerStateType } from "../../../service/containers/useGetContainerStates";
import { EMPTY, FILLED, NON_EXISTENT } from "../../../service/containers/useGetContainerStates";

import type { Mode } from "./ContainerIdSelection";

export const colorLookup: Record<ContainerStateType, string | Record<Mode, string>> = {
  [EMPTY]: {
    fill: "var(--mantine-color-green-light)",
    empty: "var(--mantine-color-red-light)",
  },
  [NON_EXISTENT]: "var(--mantine-color-blue-light)",
  [FILLED]: {
    fill: "var(--mantine-color-red-light)",
    empty: "var(--mantine-color-green-light)",
  },
};
