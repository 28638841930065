import { Button, Paper, SimpleGrid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCubePlus } from "@tabler/icons-react";
import { RenderJson } from "octalog-base";
import { useCallback, useEffect } from "react";

import { nameOf } from "../../../../common/Utils";
import { ContainerTypeSelection } from "../../../../components/input/container/container-type-selection/ContainerTypeSelection";
import { useSelectionContext } from "../../../../components/input/ContainerIdSelection/SelectionFormContext";
import { useCreateContainersMutation } from "../../../../service/containers/ContainerHooks";
import { useFilterContainerStates } from "../../../../service/containers/useFilterContainerStates";
import { NON_EXISTENT } from "../../../../service/containers/useGetContainerStates";
import classes from "../Actions.module.css";

interface CreateContainerBatch {
  containersNames: string[];
  containerTypeId: number | undefined;
}

export function CreateContainersAction() {
  const fillContainerForm = useSelectionContext();
  const createContainerMutation = useCreateContainersMutation();
  const createContainersForm = useForm<CreateContainerBatch>({
    initialValues: {
      containersNames: [],
      containerTypeId: undefined,
    },
  });
  const setFieldValueCreateContainerBatch = createContainersForm.setFieldValue;
  const determineContainerStates = useFilterContainerStates();
  useEffect(() => {
    setFieldValueCreateContainerBatch(
      nameOf<CreateContainerBatch>().containersNames,
      determineContainerStates(fillContainerForm.values.containersWithState, [NON_EXISTENT]).map(
        (containerState) => containerState.containerName,
      ),
    );
  }, [determineContainerStates, fillContainerForm.values.containersWithState, setFieldValueCreateContainerBatch]);
  const setFieldValueCreateContainerBatchCallback = useCallback(
    (containerTypeId: number | undefined) => {
      setFieldValueCreateContainerBatch(nameOf<CreateContainerBatch>().containerTypeId, containerTypeId);
    },
    [setFieldValueCreateContainerBatch],
  );

  return (
    <Paper mt={20} ta="left" withBorder>
      <form
        onSubmit={createContainersForm.onSubmit((values) => {
          createContainerMutation.mutate({
            body: {
              containers: values.containersNames.map((containerName) => ({
                containerName: containerName,
                containerTypeId: values.containerTypeId!,
              })),
            },
          });
        })}
      >
        <SimpleGrid cols={{ base: 1, sm: 2 }} p={10}>
          <ContainerTypeSelection
            classNames={{ root: classes.select__root }}
            key={createContainersForm.key(`${nameOf<CreateContainerBatch>().containerTypeId}`)}
            setInitialValueCallback={setFieldValueCreateContainerBatchCallback}
            {...createContainersForm.getInputProps(`${nameOf<CreateContainerBatch>().containerTypeId}`)}
          />
          <Button
            classNames={{ section: classes.button__section }}
            data-testid="create-containers-button"
            disabled={
              createContainersForm.values.containersNames.length === 0 ||
              !createContainersForm.values.containerTypeId ||
              fillContainerForm.values.isPending
            }
            fullWidth
            leftSection={
              createContainersForm.values.containersNames.length === 0
                ? "0"
                : createContainersForm.values.containersNames.length
            }
            loaderProps={{ type: "dots" }}
            loading={createContainerMutation.isPending}
            mt={24}
            rightSection={<IconCubePlus size={14} />}
            type="submit"
            variant="light"
          >
            Erstellen
          </Button>
        </SimpleGrid>
        <RenderJson data={createContainersForm.values} />
      </form>
    </Paper>
  );
}
