import { FormListItem } from "../../../../../components/output/FormList/FormListItem";
import { OrderItemType } from "../contexts/FormContexts";

import classes from "./OrderItem.module.css";
import { OrderItemArticle } from "./OrderItemArticle";
import { OrderItemWood } from "./OrderItemWood";

interface OrderItemProps {
  index: number;
  type: OrderItemType;
  onRemoveItem: (index: number, type: OrderItemType) => void;
}

export function OrderItem({ index, type, onRemoveItem }: OrderItemProps) {
  const renderItemContent = () => {
    switch (type) {
      case OrderItemType.WOOD: {
        return <OrderItemWood index={index} />;
      }
      case OrderItemType.ARTICLE: {
        return <OrderItemArticle index={index} />;
      }
      default: {
        throw new Error(`Unhandled OrderItemType: ${type}`);
      }
    }
  };

  return (
    <FormListItem
      className={classes.formListItem}
      key={index}
      removeListItem={() => onRemoveItem(index, type)}
      withDivider={false}
    >
      {renderItemContent()}
    </FormListItem>
  );
}
