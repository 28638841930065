import { useMemo } from "react";

import type { ContactResponseDto, GetContactsResponse } from "../generated/client/octalog-service";
import { ContactType } from "../generated/client/octalog-service";

/**
 * Normalizes a search term by converting it to lowercase and trimming whitespace.
 */
const normalizeSearchTerm = (term: string): string => term.toLowerCase().trim();

/**
 * Type guard to check if a contact is an individual contact.
 */
const isIndividualContact = (
  contact: ContactResponseDto,
): contact is ContactResponseDto & {
  contactType: ContactType.INDIVIDUAL;
} => contact.contactType === ContactType.INDIVIDUAL;

/**
 * Checks if a string field matches the search term.
 */
const matchesStringField = (field: string | undefined, searchTerm: string): boolean =>
  field?.toLowerCase().includes(searchTerm) || false;

/**
 * Checks if a contact matches the given search term.
 * This function performs a comprehensive search across various fields of the contact.
 */
const matchesContact = (contact: ContactResponseDto, searchTerm: string): boolean => {
  const normalizedTerm = normalizeSearchTerm(searchTerm);
  // Remove leading zero for phone number search to account for different formats
  const phoneTerm = normalizedTerm.startsWith("0") ? normalizedTerm.slice(1) : normalizedTerm;

  // Check name (either individual or company)
  const name = isIndividualContact(contact)
    ? `${contact.individualContact?.firstName} ${contact.individualContact?.lastName}`
    : contact.companyContact?.companyName;
  if (matchesStringField(name, normalizedTerm)) return true;

  // Check addresses (all fields)
  if (
    contact.addresses?.some((address) =>
      [address.addition, address.street, address.city, address.country, address.postalCode].some((field) =>
        matchesStringField(field, normalizedTerm),
      ),
    )
  )
    return true;

  // Check note
  if (matchesStringField(contact.note, normalizedTerm)) return true;

  // Check IDs (customer and supplier)
  const ids = [
    contact.contactIdentifierCustomer?.customerId,
    contact.contactIdentifierSupplier?.supplierId,
    contact.contactIdentifierSupplier?.customerIdAtSupplier,
  ];
  if (ids.some((id) => id?.toString().includes(normalizedTerm))) return true;

  // Check phone numbers
  return contact.phoneNumbers?.some((phone) => phone.phoneNumber.includes(phoneTerm)) || false;
};

/**
 * A custom hook that filters contacts based on a search term.
 * It uses memoization to optimize performance by only recalculating when inputs change.
 *
 * @param contacts - The list of contacts to filter
 * @param term - The search term to filter by
 * @returns A filtered list of contacts that match the search term
 */
export function useFilteredContacts(contacts: GetContactsResponse, term: string) {
  return useMemo(() => {
    if (!term) return contacts;

    // Split the search term into individual words and remove empty strings
    const searchTerms = new Set(term.toLowerCase().split(/\s+/).filter(Boolean));

    // Filter contacts: a contact matches if it matches all individual search terms
    return contacts.filter((contact) => [...searchTerms].every((searchTerm) => matchesContact(contact, searchTerm)));
  }, [contacts, term]);
}
