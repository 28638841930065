import { Text } from "@mantine/core";
import clsx from "clsx";
import { memo } from "react";

import { HOURS_PER_DAY } from "../../../common/constants";

import classes from "./TimeCell.module.css";

interface TimeCellProps {
  rowIndex: number;
  isMobile: boolean;
}

export const TimeCell = memo(function TimeCell({ rowIndex, isMobile }: TimeCellProps) {
  const firstRow = rowIndex === 0;
  const lastRow = rowIndex === HOURS_PER_DAY;

  const time = `${rowIndex.toString().padStart(2, "0")}:00`;

  return (
    <div className={classes.timeCell}>
      <Text
        className={clsx(classes.time, {
          [classes.firstTime as string]: firstRow,
          [classes.lastTime as string]: lastRow,
        })}
        fw={700}
        px={isMobile ? "xs" : "md"}
        size="xs"
        truncate
      >
        {firstRow || lastRow ? "" : time}
      </Text>
    </div>
  );
});
