import type { PaperProps } from "@mantine/core";
import { Button, Paper } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import React from "react";

import type { FormListItemProps } from "./FormListItem";

export interface FormListSimpleProps extends Omit<PaperProps, "children"> {
  label: string;
  insertListItem: () => void;
  children: React.ReactElement<FormListItemProps> | React.ReactElement<FormListItemProps>[];
  maxItems?: number;
  withBorder?: boolean;
}

export function FormListSimple({
  label,
  insertListItem,
  children,
  maxItems,
  withBorder = true,
  ...rest
}: FormListSimpleProps) {
  function shouldShowAddButton() {
    return Array.isArray(children) && maxItems ? children.length < maxItems : true;
  }

  return (
    <Paper p={10} withBorder={withBorder} {...rest}>
      {children}
      {shouldShowAddButton() ? (
        <Button onClick={() => insertListItem()} rightSection={<IconCirclePlus size={20} />} variant="transparent">
          {label} hinzufügen
        </Button>
      ) : null}
    </Paper>
  );
}
