import type { SelectProps } from "@mantine/core";

import { translateContactTypeToIcon } from "../../../common/Dictionary";

import { ContactTypeSelect } from "./ContactTypeSelect";

export type PhoneNumberTypeSelectProps = Omit<SelectProps, "data">;

export function PhoneNumberTypeSelect(props: PhoneNumberTypeSelectProps) {
  return <ContactTypeSelect detailType="phone" translateTypeToIcon={translateContactTypeToIcon} {...props} />;
}
