import type { MantineTheme } from "@mantine/core";
import { SimpleGrid, useMantineTheme } from "@mantine/core";
import { IconBox, IconBuildingWarehouse, IconPigMoney, IconUsers, IconShoppingCart } from "@tabler/icons-react";
import { RootContainer } from "octalog-base";

import { IconPaper } from "../../components/paper/IconPaper";

import { AreasSettings } from "./area/AreasSettings";
import { ArticlesSettings } from "./articles/ArticlesSettings";
import { ContactsSettings } from "./contacts/ContactsSettings"; // New import
import { ContainerTypeSettings } from "./container-type/ContainerTypeSettings";
import { PriceSettings } from "./price/PriceSettings";

function settingsComponents(theme: MantineTheme) {
  return [
    {
      id: "price",
      title: "Preise",
      description: "Verwalten von Preisen",
      icon: IconPigMoney,
      color: theme.colors.yellow,
      component: <PriceSettings />,
    },
    {
      id: "container-type",
      title: "Behälter Typen",
      description: "Größen und Maßeinheiten für Behälter",
      icon: IconBox,
      color: theme.colors.blue,
      component: <ContainerTypeSettings />,
    },
    {
      id: "area",
      title: "Lagerplätze",
      description: "Verwalten von Lagerplätzen",
      icon: IconBuildingWarehouse,
      color: theme.colors.red,
      component: <AreasSettings />,
    },
    {
      id: "contacts",
      title: "Kontakte",
      description: "Synchronisieren von Kontakten mit Lexware",
      icon: IconUsers,
      color: theme.colors.green,
      component: <ContactsSettings />,
    },
    {
      id: "articles",
      title: "Artikel",
      description: "Verwalten von Artikeln",
      icon: IconShoppingCart,
      color: theme.colors.orange,
      component: <ArticlesSettings />,
    },
  ];
}

export function Settings() {
  const theme = useMantineTheme();
  return (
    <RootContainer fluid>
      <SimpleGrid cols={{ base: 1, lg: 2 }} spacing="md">
        {settingsComponents(theme).map((setting) => (
          <IconPaper
            description={setting.description}
            icon={setting.icon}
            iconColor={setting.color}
            key={setting.id}
            title={setting.title}
          >
            {setting.component}
          </IconPaper>
        ))}
      </SimpleGrid>
    </RootContainer>
  );
}
