import { createFormContext } from "@mantine/form";

import type { ContainerWithState } from "../../../service/containers/useGetContainerStates";

export interface SelectionData {
  containersWithState: ContainerWithState[];
  isPending: boolean;
  error: string;
}

export const [SelectionFormProvider, useSelectionContext, useSelectionForm] = createFormContext<SelectionData>();
