import { Divider, SimpleGrid, Title } from "@mantine/core";
import React from "react";

import { EditTableDetails } from "./EditTableDetails";
import type { EditTableHeader, EditTableRow } from "./EditTableTypes";

interface EditTableProps {
  headers: EditTableHeader[];
  rows: EditTableRow[];
  createRow: React.ReactNode;
  testId?: string;
}

export function EditTable({ headers, rows, createRow, testId }: EditTableProps) {
  return (
    <>
      <SimpleGrid cols={headers.length} mt={10} mx={10} spacing={10}>
        {headers.map((header) => (
          <div key={header.id} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Title order={6}>{header.label}</Title>
          </div>
        ))}
      </SimpleGrid>
      <Divider my={7} />
      <SimpleGrid cols={headers.length} data-testid={testId} mb={10} mx={10} spacing={10}>
        {rows.map((row) => (
          <React.Fragment key={row.id}>
            <EditTableDetails data={row.columns} id={row.id} />
            <div>{row.editColumn}</div>
          </React.Fragment>
        ))}
      </SimpleGrid>
      <Divider my={7} />
      <SimpleGrid cols={headers.length} mb={10} mx={10} spacing={10}>
        {createRow}
      </SimpleGrid>
    </>
  );
}
