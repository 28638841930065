import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router";

import { ENVIRONMENT, SENTRY_ENABLE } from "./environment";

export function initSentry() {
  // noinspection JSUnusedGlobalSymbols
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    enabled: SENTRY_ENABLE === "true",
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1,
    tracePropagationTargets: [
      "localhost",
      "macbook.local",
      /^https:\/\/test\.octalog\.de\/octalog-service/,
      /^https:\/\/app\.octalog\.de\/octalog-service/,
    ],

    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,

    // Environment
    environment: ENVIRONMENT,

    release: import.meta.env.VITE_SENTRY_RELEASE,
  });
}
