import { nameOf } from "../../common/Utils";
import type {
  AreaResponseDto,
  ContainerTypeResponseDto,
  GetContainerResponseDto,
  GetContentResponseDto,
} from "../../generated/client/octalog-service";

export const CONTAINER_NAME_ACCESSOR = nameOf<GetContainerResponseDto>().containerName;
export const CONTAINER_TYPE_ID_ACCESSOR = `${nameOf<GetContainerResponseDto>().containerType}.${nameOf<ContainerTypeResponseDto>().containerTypeId}`;
export const WOOD_TYPE_ACCESSOR = `${nameOf<GetContainerResponseDto>().content}.${nameOf<GetContentResponseDto>().woodType}`;
export const CUTTING_LENGTH_ACCESSOR = `${nameOf<GetContainerResponseDto>().content}.${nameOf<GetContentResponseDto>().cuttingLength}`;
export const HUMIDITY_LEVEL_ACCESSOR = `${nameOf<GetContainerResponseDto>().content}.${nameOf<GetContentResponseDto>().humidityLevel}`;
export const LEVEL_ACCESSOR = `${nameOf<GetContainerResponseDto>().content}.${nameOf<GetContentResponseDto>().level}`;
export const FILLING_DATE_ACCESSOR = `${nameOf<GetContainerResponseDto>().content}.${nameOf<GetContentResponseDto>().fillingDate}`;
export const AREA_ID_ACCESSOR = `${nameOf<GetContainerResponseDto>().area}.${nameOf<AreaResponseDto>().areaId}`;
