import { CLIENT } from "../config/fetch";
import { UnitOfMeasurement } from "../generated/client/octalog-service";
import {
  getContainerTypesQueryKey,
  getContainerStatisticsQueryKey,
  getPricesQueryKey,
  getOrdersQueryKey,
  getContactsQueryKey,
  getAreasQueryKey,
  getContainersQueryKey,
  listArticlesQueryKey,
} from "../generated/client/octalog-service/@tanstack/react-query.gen";

import { translateUnitOfMeasurement } from "./Dictionary";

// Environments
export enum Environment {
  LOCAL = "local",
  CI = "ci",
  TEST = "test",
  PRODUCTION = "production",
}

// Containers
export const QUERY_KEY_CONTAINERS = getContainersQueryKey({ client: CLIENT });
export const QUERY_KEY_CONTAINER_TYPES = getContainerTypesQueryKey({ client: CLIENT });
export const QUERY_KEY_CONTAINER_STATISTICS = getContainerStatisticsQueryKey({ client: CLIENT });

// Contacts
export const QUERY_KEY_CONTACTS = getContactsQueryKey({ client: CLIENT });

// Areas
export const QUERY_KEY_AREAS = getAreasQueryKey({ client: CLIENT });

// Prices
export const QUERY_KEY_PRICES = getPricesQueryKey({ client: CLIENT });

// Orders
export const QUERY_KEY_ALL_ORDERS = getOrdersQueryKey({ client: CLIENT });

// Articles
export const QUERY_KEY_ALL_ARTICLES = listArticlesQueryKey({ client: CLIENT });

// Order Delivery Scheduling
export const QUERY_KEY_DELIVERY_SCHEDULE = "deliverySchedule";

// Settings
export const QUERY_KEY_WOOD_TYPES_SETTINGS = "woodTypesSettings";
export const QUERY_KEY_HUMIDITY_LEVELS_SETTINGS = "humidityLevelsSettings";
export const QUERY_KEY_CUTTING_LENGTHS_SETTINGS = "cuttingLengthsSettings";

// Regex
export const REGEX_EMAIL =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

export const UNIT_OF_MEASUREMENT_OPTIONS_SHORT = [
  {
    value: UnitOfMeasurement.SOLID_CUBIC_METER,
    label: translateUnitOfMeasurement(UnitOfMeasurement.SOLID_CUBIC_METER, true),
  },
  {
    value: UnitOfMeasurement.STACKED_CUBIC_METER,
    label: translateUnitOfMeasurement(UnitOfMeasurement.STACKED_CUBIC_METER, true),
  },
  {
    value: UnitOfMeasurement.LOOSE_CUBIC_METER,
    label: translateUnitOfMeasurement(UnitOfMeasurement.LOOSE_CUBIC_METER, true),
  },
];

export const UNIT_OF_MEASUREMENT_OPTIONS = [
  {
    value: UnitOfMeasurement.SOLID_CUBIC_METER,
    label: translateUnitOfMeasurement(UnitOfMeasurement.SOLID_CUBIC_METER),
  },
  {
    value: UnitOfMeasurement.STACKED_CUBIC_METER,
    label: translateUnitOfMeasurement(UnitOfMeasurement.STACKED_CUBIC_METER),
  },
  {
    value: UnitOfMeasurement.LOOSE_CUBIC_METER,
    label: translateUnitOfMeasurement(UnitOfMeasurement.LOOSE_CUBIC_METER),
  },
];

export const CURRENCY_OPTIONS: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: "currency",
  currency: "EUR",
};

export const CUBIC_METER_OPTIONS: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  notation: "standard",
};

// Constants
export const CUSTOMER_ID_MIN = 10_000;
export const CUSTOMER_ID_MAX = 69_999;
export const SUPPLIER_ID_MIN = 70_000;
export const SUPPLIER_ID_MAX = 99_999;
