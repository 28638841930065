import invariant from "tiny-invariant";

import type { LocalDateInternal } from "../../../../../common/calendar/LocalDateInternal";
import type { LocalTimeInternal } from "../../../../../common/calendar/LocalTimeInternal";
import { useScheduleDeliveryMutation } from "../../../mutation/useScheduleDeliveryMutation";
import { useUnscheduleDeliveryMutation } from "../../../mutation/useUnscheduleDeliveryMutation";

export enum DestinationId {
  calendar = "calendar",
  orderList = "order-list",
}

export interface DropSourceData {
  orderNumber: string;
  duration: string;
}

export interface DropDestinationData {
  destinationId: DestinationId;
  date?: LocalDateInternal;
  time?: LocalTimeInternal;
}

export function useDeliveryBoxDrop() {
  const scheduleDelivery = useScheduleDeliveryMutation();
  const unscheduleDelivery = useUnscheduleDeliveryMutation();

  return (dropSourceData: DropSourceData, dropDestinationData: DropDestinationData) => {
    switch (dropDestinationData.destinationId) {
      case DestinationId.calendar: {
        invariant(dropSourceData.orderNumber);
        invariant(dropSourceData.duration);
        invariant(dropDestinationData.date);
        invariant(dropDestinationData.time);
        scheduleDelivery.mutate({
          body: {
            orderNumber: dropSourceData.orderNumber,
            duration: dropSourceData.duration,
            deliveryDate: dropDestinationData.date.toString(),
            deliveryTime: dropDestinationData.time.toString(),
          },
        });
        break;
      }
      case DestinationId.orderList: {
        invariant(dropSourceData.orderNumber);
        unscheduleDelivery.mutate({ query: { orderNumber: dropSourceData.orderNumber } });
        break;
      }
      default: {
        throw new Error("Invalid destination ID");
      }
    }
  };
}
