import { useCallback } from "react";

import type { ContainerStateType, ContainerWithState } from "./useGetContainerStates";

export const useFilterContainerStates = () => {
  /**
   * Filters container states by specified state types using a pre-computed Set for efficient lookups.
   * @param containerStates An array of `ContainerState` to be filtered.
   * @param states An array of `ContainerStateType` indicating which states to filter by.
   * @returns A filtered array of `ContainerState`.
   */
  return useCallback((containerStates: ContainerWithState[], states: ContainerStateType[]): ContainerWithState[] => {
    const statesSet = new Set(states);
    return containerStates.filter(({ state }) => statesSet.has(state));
  }, []);
};
