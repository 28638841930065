import { Anchor, Button, Group, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCommonColors } from "octalog-base";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router";

import { tryToExtractDescription } from "../../common/error/ErrorMessageUtil";
import { nameOf } from "../../common/Utils";
import { FloatingLabelInput } from "../../components/input/FloatingLabelInput/FloatingLabelInput";
import { FloatingLabelPasswordInput } from "../../components/input/FloatingLabelInput/FloatingLabelPasswordInput";
import type { SignInRequestDto } from "../../generated/client/octalog-service";
import { useSignInMutation } from "../../service/iam/IamHooks";

import { AuthContainer } from "./components/AuthContainer";
import { AuthLoadingState } from "./components/AuthLoadingState";
import classes from "./signIn.module.css";

export function SignIn() {
  const commonColors = useCommonColors();
  const navigate = useNavigate();
  const signIn = useSignInMutation();

  const form = useForm<SignInRequestDto>({
    initialValues: {
      username: "",
      password: "",
    },
  });

  const setErrors = form.setErrors;
  useEffect(() => {
    if (signIn.isError && signIn.error.message === "Unauthorized") {
      setErrors({
        username: "Email oder Passwort ungültig",
        password: "Email oder Passwort ungültig",
      });
    }
  }, [setErrors, signIn.error, signIn.isError]);

  function renderErrorBox() {
    if (signIn.error?.message !== "Unauthorized") {
      return (
        <Text c={commonColors.redPrimary} className={signIn.isError ? classes.error : undefined} mt={20} size="sm">
          {tryToExtractDescription(signIn.error)}
        </Text>
      );
    }

    return null;
  }

  return (
    <AuthContainer
      subtitle={
        <>
          Haben Sie noch keinen Account?{" "}
          <Anchor component="button" onClick={() => navigate("/sign-up")} size="sm">
            Hier registrieren
          </Anchor>
        </>
      }
      title="Willkommen zurück!"
    >
      {signIn.isPending ? (
        <AuthLoadingState title="Anmeldung läuft" />
      ) : signIn.isSuccess ? (
        <Navigate to="/" />
      ) : (
        <form aria-label="Anmeldeformular" onSubmit={form.onSubmit((values) => signIn.mutate({ body: values }))}>
          <FloatingLabelInput
            aria-label="Email Adresse"
            autoComplete="username"
            data-testid="username"
            id="username-field"
            label="Email"
            required
            type="email"
            {...form.getInputProps(nameOf<SignInRequestDto>().username)}
          />
          <FloatingLabelPasswordInput
            aria-label="Passwort"
            autoComplete="current-password"
            data-testid="password"
            id="password-field"
            label="Passwort"
            required
            {...form.getInputProps(nameOf<SignInRequestDto>().password)}
          />
          <Group justify="space-between" mt="lg">
            <Anchor
              component="button"
              data-testid="forgotPasswordLink"
              id="forgot-password-link"
              onClick={() => navigate("/password-reset")}
              size="sm"
            >
              Passwort vergessen?
            </Anchor>
          </Group>
          <Button aria-label="Anmelden" data-testid="submit" fullWidth id="sign-in-button" mt="xl" type="submit">
            Einloggen
          </Button>
          {renderErrorBox()}
        </form>
      )}
    </AuthContainer>
  );
}
