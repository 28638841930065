import { Grid, NumberInput } from "@mantine/core";
import { useEffect, useRef } from "react";

import { nameOf } from "../../../../../common/Utils";
import { DisabledTextarea } from "../../../../../components/input/DisabledTextInput/DisabledTextarea";
import { PriceInput } from "../../../../../components/input/number/PriceInput";
import type { OrderItemWoodCreateDto, ArticleDto } from "../../../../../generated/client/octalog-service";
import type { OrderFormData, OrderItemArticleData } from "../contexts/FormContexts";
import type { OrderForm } from "../hooks/useOrderForm";

interface OrderItemArticleDetailsProps {
  form: OrderForm;
  index: number;
  article: ArticleDto | undefined;
}

export function OrderItemArticleDetails({ form, index, article }: OrderItemArticleDetailsProps) {
  const previousArticle = useRef(article);
  useEffect(() => {
    if (article !== previousArticle.current) {
      previousArticle.current = article;
      form.setFieldValue(
        `${nameOf<OrderFormData>().itemsArticle}.${index}.${nameOf<OrderItemWoodCreateDto>().priceOverride}`,
        undefined,
      );
    }
  }, [article, form, index]);

  return (
    <Grid align="end" ta="left">
      <Grid.Col span={12}>
        <DisabledTextarea
          label="Name"
          {...form.getInputProps(
            `${nameOf<OrderFormData>().itemsArticle}.${index}.${nameOf<OrderItemArticleData>().name}`,
          )}
          value={article?.name}
        />
      </Grid.Col>
      <Grid.Col span={12} ta="left">
        <DisabledTextarea
          label="Beschreibung"
          {...form.getInputProps(
            `${nameOf<OrderFormData>().itemsArticle}.${index}.${nameOf<OrderItemArticleData>().description}`,
          )}
          value={article?.description}
        />
      </Grid.Col>
      <Grid.Col span={12} ta="left">
        <DisabledTextarea
          label="SKU"
          {...form.getInputProps(
            `${nameOf<OrderFormData>().itemsArticle}.${index}.${nameOf<OrderItemArticleData>().sku}`,
          )}
          value={article?.sku}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <NumberInput
          label="Anzahl"
          min={1}
          placeholder="Menge"
          {...form.getInputProps(
            `${nameOf<OrderFormData>().itemsArticle}.${index}.${nameOf<OrderItemArticleData>().quantity}`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={7}>
        <PriceInput
          description={article?.price == undefined ? "Kein Preis in den Einstellungen definiert" : undefined}
          disabled={article == undefined || article?.price != undefined}
          externalValue={article?.price ?? undefined}
          label="Preis pro Stück"
          {...form.getInputProps(
            `${nameOf<OrderFormData>().itemsArticle}.${index}.${nameOf<OrderItemWoodCreateDto>().price}`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={5}>
        <PriceInput
          description="Preis pro Stück überschreiben"
          disabled={article == undefined || article?.price == undefined}
          label="Angebot"
          {...form.getInputProps(
            `${nameOf<OrderFormData>().itemsArticle}.${index}.${nameOf<OrderItemWoodCreateDto>().priceOverride}`,
          )}
        />
      </Grid.Col>
    </Grid>
  );
}
