import type { BoxProps } from "@mantine/core";
import { Box, Divider } from "@mantine/core";
import React from "react";

import { RemoveButton } from "../../button/remove/RemoveButton";

export interface FormListItemProps extends Omit<BoxProps, "children"> {
  removeListItem: () => void;
  children: React.ReactNode;
  withDivider?: boolean;
}

export function FormListItem({ removeListItem, children, withDivider = true, ...rest }: FormListItemProps) {
  return (
    <Box mb={10} mt={0} pos="relative" {...rest}>
      <Box pos="absolute" right={0} top={0}>
        <RemoveButton onClick={() => removeListItem()} />
      </Box>
      <Box pb={10} pt={40}>
        {children}
      </Box>
      <Divider hidden={!withDivider} mt={10} />
    </Box>
  );
}
