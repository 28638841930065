import type { TransformedValues } from "@mantine/form";
import type { CountryCode } from "libphonenumber-js";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { useEffect } from "react";

import { nameOf } from "../../../../common/Utils";
import type {
  AddressResponseDto,
  ContactCreateDto,
  ContactResponseDto,
  CreateAddressDto,
  CreateContactData,
  IndividualContactResponseDto,
  UpdateContactByIdData,
} from "../../../../generated/client/octalog-service";
import { ContactDetailAddressType, ContactType } from "../../../../generated/client/octalog-service";
import { useCreateContactMutation } from "../../../../service/contacts/useCreateContactMutation";
import { useUpdateContactMutation } from "../../../../service/contacts/useUpdateContactMutation";
import { SalutationType } from "../contact/individual/salutation/SalutationType";
import { ContactIdentifierType } from "../identifier/ContactIdentifierType";

import type { ContactData, IndividualContactData } from "./contactFormContext";
import { useCreateContactForm } from "./contactFormContext";
import { transformValues } from "./contactFormTransformer";
import { validate } from "./contactFormValidator";

function determineSelectedContactIdentifier(data: ContactResponseDto): ContactIdentifierType {
  if (data.contactIdentifierCustomer || data.contactIdentifierSupplier) {
    if (data.contactIdentifierCustomer && data.contactIdentifierSupplier) {
      return ContactIdentifierType.CUSTOMER_SUPPLIER;
    } else if (data.contactIdentifierCustomer) {
      return ContactIdentifierType.CUSTOMER;
    } else if (data.contactIdentifierSupplier) {
      return ContactIdentifierType.SUPPLIER;
    }
  }

  throw new Error("Contact identifier is not set");
}

export const transformInitialData = (data: ContactResponseDto): ContactData => ({
  ...initialValues,
  ...data,
  individualContact: data.individualContact
    ? transformIndividualContact(data.individualContact)
    : initialValues.individualContact,
  companyContact: data.companyContact ?? initialValues.companyContact,
  contactIdentifierCustomer: data.contactIdentifierCustomer ?? initialValues.contactIdentifierCustomer,
  contactIdentifierSupplier: data.contactIdentifierSupplier ?? initialValues.contactIdentifierSupplier,
  addresses: data.addresses ? data.addresses.map((address) => transformAddress(address)) : initialValues.addresses,
  phoneNumbers: data.phoneNumbers.map((phone) => {
    const phoneNumber = parsePhoneNumberWithError(phone.phoneNumber);
    return {
      ...phone,
      phoneNumber: phoneNumber.nationalNumber,
      countryCode: phoneNumber.country as CountryCode,
    };
  }),
  emails: data.emails ?? initialValues.emails,
  selectedContactIdentifier: determineSelectedContactIdentifier(data),
});

export const initialValues: ContactData = {
  selectedContactIdentifier: ContactIdentifierType.CUSTOMER,
  contactType: ContactType.INDIVIDUAL,
  individualContact: {
    salutationType: SalutationType.MR,
    salutation: undefined,
    firstName: undefined,
    lastName: "",
  },
  companyContact: {
    companyName: "",
  },
  contactIdentifierCustomer: {
    customerId: undefined,
  },
  contactIdentifierSupplier: {
    supplierId: undefined,
    customerIdAtSupplier: undefined,
  },
  note: undefined,
  addresses: [],
  phoneNumbers: [],
  emails: [],
  externalReference: undefined,
};

export const initialAddress: CreateAddressDto = {
  addressType: ContactDetailAddressType.INVOICE,
  addition: "",
  city: "",
  country: "DE",
  postalCode: "",
  street: "",
};

function transformIndividualContact(individualContact: IndividualContactResponseDto): IndividualContactData {
  return {
    ...individualContact,
    salutationType: individualContact.salutation
      ? determineSalutationType(individualContact.salutation)
      : SalutationType.NONE,
  };
}

function determineSalutationType(salutation: string): SalutationType {
  if (salutation === SalutationType.MR) {
    return SalutationType.MR;
  }
  if (salutation === SalutationType.MRS) {
    return SalutationType.MRS;
  }
  return SalutationType.OTHER;
}

function transformAddress(address: AddressResponseDto): CreateAddressDto {
  return {
    addressType: address.addressType ?? initialAddress.addressType,
    addition: address.addition ?? initialAddress.addition,
    street: address.street ?? initialAddress.street,
    postalCode: address.postalCode ?? initialAddress.postalCode,
    city: address.city ?? initialAddress.city,
    country: address.country ?? initialAddress.country,
  };
}

export const useContactForm = (initialContactData?: ContactResponseDto, resetOnSubmit = false) => {
  const createContactMutation = useCreateContactMutation();
  const updateContactMutation = useUpdateContactMutation();

  const form = useCreateContactForm({
    validateInputOnBlur: true,
    initialValues: initialContactData ? transformInitialData(initialContactData) : initialValues,
    validate: validate,
    transformValues: transformValues,
  });

  const { validateField } = form;
  useEffect(() => {
    for (let index = 0; index < form.values.addresses.length; index++) {
      validateField(`${nameOf<ContactData>().addresses}.${index}.${nameOf<CreateAddressDto>().addressType}`);
    }
  }, [form.values.addresses, validateField]);

  const handleSubmit = async (values: TransformedValues<typeof form>) => {
    try {
      let contactId;
      if (initialContactData) {
        // Update scenario
        const updateData: UpdateContactByIdData = {
          path: { contactId: initialContactData.contactId },
          body: values as ContactCreateDto,
          url: "/api/contacts/{contactId}",
        };
        await updateContactMutation.mutateAsync(updateData);
        contactId = initialContactData.contactId;
      } else {
        // Create scenario
        const createData: CreateContactData = {
          body: values as ContactCreateDto,
          url: "/api/contacts",
        };
        const response = await createContactMutation.mutateAsync(createData);
        contactId = response.contactId;
      }
      if (resetOnSubmit) {
        form.reset();
      }
      return contactId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    form,
    handleSubmit,
  };
};
