import { IconUserPlus } from "@tabler/icons-react";
import { RootContainer } from "octalog-base";

import { IconPaper } from "../../../components/paper/IconPaper";

import { useContactForm } from "./common/useContactForm";
import { ContactForm } from "./ContactForm";

export function NewContactView() {
  const { form, handleSubmit } = useContactForm();

  return (
    <RootContainer fluid>
      <IconPaper icon={IconUserPlus} title="Neuer Kontakt">
        <ContactForm form={form} onSubmit={handleSubmit} />
      </IconPaper>
    </RootContainer>
  );
}
