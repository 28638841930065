import type { TextProps } from "@mantine/core";
import { Button, CopyButton, Text, Tooltip } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";
import type { ReactNode } from "react";

interface CopyTextProps extends TextProps {
  valueToCopy?: string;
  children: ReactNode;
}

export function CopyText({ valueToCopy, children, ...rest }: CopyTextProps) {
  const commonColors = useCommonColors();
  if (!valueToCopy && typeof children !== "string" && typeof children !== "number") {
    throw new Error("valueToCopy is required if children is not a string or number");
  }
  return (
    <CopyButton
      value={valueToCopy ?? (typeof children === "string" || typeof children === "number" ? children?.toString() : "")}
    >
      {({ copied, copy }) => (
        <Tooltip
          color={commonColors.darkPrimary}
          events={{ hover: true, focus: true, touch: false }}
          label={copied ? "Kopiert" : "Kopieren"}
          position="right"
          withArrow
        >
          <Button
            color={commonColors.text}
            h="auto"
            m={0}
            onClick={copy}
            p={0}
            rightSection={
              <Text {...rest}>
                {copied ? <IconCheck size={14} /> : <IconCopy color={commonColors.primaryColor} size={14} />}
              </Text>
            }
            style={{ border: "none" }}
            variant="transparent"
          >
            <Text {...rest}>{children}</Text>
          </Button>
        </Tooltip>
      )}
    </CopyButton>
  );
}
