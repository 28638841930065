import { useMantineTheme } from "@mantine/core";
import { IconDropletHalf2Filled, IconSlice, IconTrees } from "@tabler/icons-react";

import type { Translatable } from "../../../common/Dictionary";
import { translate } from "../../../common/Dictionary";
import type { PieChartConfig } from "../PieChartConfig";

export function useByContainerPieChartConfig(): PieChartConfig[] {
  const theme = useMantineTheme();
  return [
    {
      title: "Holzsorten",
      subline: "Container",
      icon: IconTrees,
      color: theme.colors.green,
      dataKey: "woodTypeStatistics.containerCountByWoodType",
      dataNameKey: "woodType",
      dataValueKey: "count",
      dataNameTranslator: (name: string) => translate(name as Translatable),
    },
    {
      title: "Feuchtigkeit",
      subline: "Container",
      icon: IconDropletHalf2Filled,
      color: theme.colors.blue,
      dataKey: "humidityLevelStatistics.containerCountByHumidityLevel",
      dataNameKey: "humidityLevel",
      dataValueKey: "count",
      dataNameTranslator: (name: string) => translate(name as Translatable),
    },
    {
      title: "Schnittlänge",
      subline: "Container",
      icon: IconSlice,
      color: theme.colors.yellow,
      dataKey: "cuttingLengthStatistics.containerCountByCuttingLength",
      dataNameKey: "cuttingLength",
      dataValueKey: "count",
      dataNameTranslator: (name: string) => translate(name as Translatable),
    },
  ];
}
