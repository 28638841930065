import { Grid } from "@mantine/core";

import { ContactIdentifierType } from "./ContactIdentifierType";
import { InputIdentifierCustomer } from "./InputIdentifierCustomer";
import { InputIdentifierSupplier } from "./InputIdentifierSupplier";

interface InputIdentifierProps {
  selectedContactIdentifier: ContactIdentifierType;
}

export function InputIdentifier({ selectedContactIdentifier }: InputIdentifierProps) {
  return (
    <Grid mb={10}>
      {(() => {
        switch (selectedContactIdentifier) {
          case ContactIdentifierType.CUSTOMER: {
            return <InputIdentifierCustomer />;
          }
          case ContactIdentifierType.SUPPLIER: {
            return <InputIdentifierSupplier />;
          }
          case ContactIdentifierType.CUSTOMER_SUPPLIER: {
            return (
              <>
                <InputIdentifierCustomer />
                <InputIdentifierSupplier />
              </>
            );
          }
        }
      })()}
    </Grid>
  );
}
