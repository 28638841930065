import { SegmentedControl } from "@mantine/core";
import type { ReactNode } from "react";
import type React from "react";

import classes from "./ErrorSegmentControl.module.css";

interface ErrorSegmentControlProps extends React.ComponentProps<typeof SegmentedControl> {
  error?: ReactNode;
}

export function ErrorSegmentControl({ error, ...rest }: ErrorSegmentControlProps) {
  return (
    <>
      <SegmentedControl {...rest} classNames={classes} mod={{ error: error }} />
      {error ? <div className={classes.error}>{error}</div> : null}
    </>
  );
}
