import { Modal } from "@mantine/core";

import { EmptyDialog } from "../../empty/components/EmptyDialog";

interface EmptyModalProps {
  opened: boolean;
  onClose: () => void;
  containerIds: number[];
  pending: boolean;
}

export function EmptyModal({ opened, onClose, containerIds, pending }: EmptyModalProps) {
  return (
    <Modal onClose={onClose} opened={opened} title="Behälter entleeren">
      <EmptyDialog
        clearSelectedContainerIdsHandler={onClose}
        isPending={pending}
        selectedContainerIds={containerIds}
        showSelectedContainerCount
      />
    </Modal>
  );
}
