import { isNotEmpty } from "@mantine/form";

import type { LocalDateInternal } from "../../../../../common/calendar/LocalDateInternal";
import type { LocalTimeInternal } from "../../../../../common/calendar/LocalTimeInternal";
import { validationWrapper } from "../../../../contacts/new/common/contactFormValidator";
import type { OrderFormData } from "../contexts/FormContexts";

function validatePrice(value: number | undefined) {
  return value && Number(value) > 0 ? null : "Bitte geben Sie einen gültigen Preis ein";
}

function validatePriceOverride(value: number | undefined) {
  return !value || (value && Number(value)) >= 0 ? null : "Bitte geben Sie einen gültigen Endpreis ein";
}

export const useOrderValidation = () => {
  return {
    contactId: (value: number | undefined) =>
      value ? (value > 0 ? null : "Bitte wählen Sie einen Kontakt aus") : "Bitte wählen Sie einen Kontakt aus",
    deliveryAddress: (value: string | undefined, values: OrderFormData) =>
      validationWrapper(
        isNotEmpty("Bitte geben Sie eine gültige Lieferadresse ein"),
        false,
        !values.deliveryAddressDifferent,
      )(value),
    invoiceAddress: (value: string | undefined) => (value ? null : "Bitte geben Sie eine Rechnungsadresse ein"),
    deliveryTargetDateStart: (deliveryTargetDateStart: LocalDateInternal | undefined) => {
      if (!deliveryTargetDateStart) {
        return "Bitte geben Sie Lieferdatum ein";
      }
      return null;
    },
    deliveryTargetDateEnd: (deliveryTargetDateEnd: LocalDateInternal | undefined, values: OrderFormData) => {
      if (
        deliveryTargetDateEnd &&
        values.deliveryTargetDateStart &&
        deliveryTargetDateEnd < values.deliveryTargetDateStart
      ) {
        return 'Datum darf nicht vor dem "Von"-Datum liegen';
      }
      return null;
    },
    deliveryTargetTimeEnd: (timeEnd: LocalTimeInternal | undefined, values: OrderFormData) => {
      if (timeEnd && values.deliveryTargetTimeStart && timeEnd < values.deliveryTargetTimeStart) {
        return 'Uhrzeit darf nicht vor der "Von"-Zeit liegen';
      }
      return null;
    },
    itemsWood: {
      woodTypeId: (value: number | undefined) => (value ? null : "Bitte wählen Sie eine Holzart aus"),
      cuttingLengthId: (value: number | undefined) => (value ? null : "Bitte wählen Sie eine Schnittlänge aus"),
      humidityLevelId: (value: number | undefined) => (value ? null : "Bitte wählen Sie eine Feuchtigkeit aus"),
      unitOfMeasurement: (value: string | undefined) => (value ? null : "Bitte wählen Sie eine Maßeinheit aus"),
      volume: (value: number | undefined) =>
        value && Number(value) > 0 ? null : "Bitte geben Sie ein gültiges Volumen ein",
      price: (value: number | undefined) => validatePrice(value),
      priceOverride: (value: number | undefined) => validatePriceOverride(value),
    },
    itemsArticle: {
      price: (value: number | undefined) => validatePrice(value),
      priceOverride: (value: number | undefined) => validatePriceOverride(value),
    },
  };
};
