import { PasswordInput } from "@mantine/core";

import { BaseFloatingLabelInput } from "./BaseFloatingLabelInput";
import type { FloatingLabelPasswordInputProps } from "./types";

/**
 * Password input component with floating label behavior.
 * The label floats above the input when the input has value or is focused.
 */
export function FloatingLabelPasswordInput({ value, onBlur, onFocus, ...rest }: FloatingLabelPasswordInputProps) {
  return (
    <BaseFloatingLabelInput<HTMLInputElement> onBlur={onBlur} onFocus={onFocus} value={value}>
      {({ classNames, floating, handleBlur, handleFocus }) => (
        <PasswordInput
          data-floating={floating}
          labelProps={{ "data-floating": floating }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          {...rest}
          classNames={classNames}
        />
      )}
    </BaseFloatingLabelInput>
  );
}
