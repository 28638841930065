import { Grid, Group } from "@mantine/core";
import type { GetInputPropsReturnType } from "octalog-base";

import { EmailTypeSelect } from "../../../../components/input/contact/EmailTypeSelect";
import { FloatingLabelInput } from "../../../../components/input/FloatingLabelInput/FloatingLabelInput";

import classes from "./EmailDetailsInput.module.css";

interface EmailDetailsInputProps {
  emailInputProps: GetInputPropsReturnType;
  emailTypeInputProps: GetInputPropsReturnType;
}

export function EmailDetailsInput({ emailInputProps, emailTypeInputProps }: EmailDetailsInputProps) {
  return (
    <Grid>
      <Grid.Col span={12}>
        <FloatingLabelInput
          data-1p-ignore
          inputContainer={(children) => (
            <Group align="flex-start" gap={0} grow preventGrowOverflow={false} wrap="nowrap">
              {children}
              <EmailTypeSelect classNames={{ input: classes.selectEmailType }} {...emailTypeInputProps} />
            </Group>
          )}
          label="E-Mail"
          styles={{ input: { borderRadius: "var(--mantine-radius-default) 0 0 var(--mantine-radius-default)" } }}
          {...emailInputProps}
        />
      </Grid.Col>
    </Grid>
  );
}
