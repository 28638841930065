import { ActionIcon, Group, Text, Divider } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { memo } from "react";

import { LocalDateInternal } from "../../../../../../../common/calendar/LocalDateInternal";

interface CalendarNavigationProps {
  currentWeek: LocalDateInternal;
  onPreviousWeek: () => void;
  onNextWeek: () => void;
  isMobile: boolean;
}

export const CalendarNavigation = memo(function CalendarNavigation({
  currentWeek,
  onPreviousWeek,
  onNextWeek,
  isMobile,
}: CalendarNavigationProps) {
  const weekStart = LocalDateInternal.weekStart(currentWeek);
  const weekEnd = LocalDateInternal.weekEnd(currentWeek);
  return (
    <Group gap={isMobile ? "xs" : "md"} justify="center" p="md">
      <ActionIcon onClick={onPreviousWeek} size={isMobile ? "sm" : "md"} variant="subtle">
        <IconChevronLeft />
      </ActionIcon>
      <Text fw={500} size={isMobile ? "sm" : "md"}>
        {weekStart.humanize()} - {weekEnd.humanize()}
      </Text>
      <Divider my="5px" orientation="vertical" />
      <Text fw={500} size={isMobile ? "sm" : "md"}>
        KW {weekStart.isoWeek()}
      </Text>
      <ActionIcon onClick={onNextWeek} size={isMobile ? "sm" : "md"} variant="subtle">
        <IconChevronRight />
      </ActionIcon>
    </Group>
  );
});
