import { Paper } from "@mantine/core";
import { RootContainer } from "octalog-base";

import { OrdersTable } from "./components/OrdersTable/OrdersTable";

export function ListOrdersView() {
  return (
    <RootContainer fluid>
      <Paper withBorder>
        <OrdersTable />
      </Paper>
    </RootContainer>
  );
}
