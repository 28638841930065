import { sortBy } from "lodash-es";
import type { DataTableSortStatus } from "mantine-datatable";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";

import { nameOf } from "../../../../../common/Utils";
import type { PriceDto } from "../../../../../generated/client/octalog-service";
import { useCuttingLengthIdTranslator } from "../../../../../service/settings/useCuttingLengthIdTranslator";
import { useHumidityLevelIdTranslator } from "../../../../../service/settings/useHumidityLevelIdTranslator";
import { useWoodTypeIdTranslator } from "../../../../../service/settings/useWoodTypeIdTranslator";

interface PriceTableProps {
  prices: PriceDto[];
}

export function PriceTable({ prices }: PriceTableProps) {
  const woodTypeIdTranslator = useWoodTypeIdTranslator();
  const humidityLevelIdTranslator = useHumidityLevelIdTranslator();
  const cuttingLengthIdTranslator = useCuttingLengthIdTranslator();

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<PriceDto>>({
    columnAccessor: nameOf<PriceDto>().woodTypeId,
    direction: "asc",
  });
  const [records, setRecords] = useState(sortBy(prices, nameOf<PriceDto>().woodTypeId));

  useEffect(() => {
    const sortedData = sortBy(prices, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? sortedData.reverse() : sortedData);
  }, [prices, sortStatus]);

  const columns = [
    {
      title: "Holzart",
      accessor: nameOf<PriceDto>().woodTypeId,
      sortable: true,
      render: (woodTypeId: PriceDto) => woodTypeIdTranslator(woodTypeId.woodTypeId),
    },
    {
      title: "Feuchtigkeitsgrad",
      accessor: nameOf<PriceDto>().humidityLevelId,
      sortable: true,
      render: (humidityLevelId: PriceDto) => humidityLevelIdTranslator(humidityLevelId.humidityLevelId),
    },
    {
      title: "Schnittlänge",
      accessor: nameOf<PriceDto>().cuttingLengthId,
      sortable: true,
      render: (cuttingLengthId: PriceDto) => cuttingLengthIdTranslator(cuttingLengthId.cuttingLengthId),
    },
    {
      title: "Preis",
      accessor: nameOf<PriceDto>().price,
      sortable: true,
      render: (price: PriceDto) => `${price.price.toFixed(2)} €`,
    },
  ];

  // noinspection RequiredAttributes
  return (
    <DataTable
      columns={columns}
      idAccessor={({ woodTypeId, humidityLevelId, cuttingLengthId }: PriceDto) =>
        `${woodTypeId}:${humidityLevelId}:${cuttingLengthId}`
      }
      onSortStatusChange={setSortStatus}
      records={records}
      sortStatus={sortStatus}
    />
  );
}
