import type { SelectProps } from "@mantine/core";
import { Group, Select } from "@mantine/core";

import { translate } from "../../../common/Dictionary";
import { ContactDetailEmailType, ContactDetailPhoneNumberType } from "../../../generated/client/octalog-service";

export type ContactTypeSelectProps = Omit<SelectProps, "data"> & {
  detailType: "email" | "phone";
  translateTypeToIcon: (
    type: ContactDetailPhoneNumberType | ContactDetailEmailType,
    typeIdentifier: "email" | "phone",
  ) => string;
};

export function ContactTypeSelect({ detailType, translateTypeToIcon, ...props }: ContactTypeSelectProps) {
  const data = Object.values(detailType === "email" ? ContactDetailEmailType : ContactDetailPhoneNumberType).map(
    (t) => ({
      value: t,
      label: translateTypeToIcon(t, detailType),
    }),
  );

  return (
    <Select
      comboboxProps={{ width: 140, position: "bottom-end" }}
      data={data}
      maw={60}
      renderOption={({ option }) => (
        <Group align="flex-start" wrap="nowrap">
          {option.label} {translate(option.value as ContactDetailEmailType | ContactDetailPhoneNumberType)}
        </Group>
      )}
      styles={{ input: { paddingLeft: 10, paddingRight: 25 }, section: { width: 18, marginRight: 5 } }}
      {...props}
    />
  );
}
