import type { ContainerProps } from "@mantine/core";
import { Container, Divider, Grid } from "@mantine/core";

import type { PriceSettingsDto } from "../../../../generated/client/octalog-service";

import { PriceModifiers } from "./modifier/PriceModifiers";
import { Prices } from "./table/Prices";

interface EditPriceSettingsProps extends ContainerProps {
  priceSettings: PriceSettingsDto;
}

export function EditPriceSettings({ priceSettings, ...containerProps }: EditPriceSettingsProps) {
  return (
    <Container {...containerProps}>
      <Grid>
        <Grid.Col span={12}>
          <PriceModifiers priceSettings={priceSettings} />
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>
        <Grid.Col span={12}>
          <Prices />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
