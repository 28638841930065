import { Container } from "@mantine/core";
import React from "react";

import type { HolzPieChartProps } from "../charts/HolzPieChart";
import { HolzPieChart } from "../charts/HolzPieChart";

import type { IconPaperProps } from "./IconPaper";
import { IconPaper } from "./IconPaper";

interface PieChartPaperProps<T> extends IconPaperProps {
  holzPieChartProps: HolzPieChartProps<T>;
}

export function PieChartPaper<T>({ holzPieChartProps, ...iconPaperProps }: PieChartPaperProps<T>): React.JSX.Element {
  return (
    <IconPaper {...iconPaperProps}>
      <Container h={300} pb={10}>
        <HolzPieChart {...holzPieChartProps} />
      </Container>
    </IconPaper>
  );
}
