import { TextInput } from "@mantine/core";

import { BaseFloatingLabelInput } from "./BaseFloatingLabelInput";
import type { FloatingLabelInputProps } from "./types";

/**
 * Input component with floating label behavior.
 * The label floats above the input when the input has value or is focused.
 */
export function FloatingLabelInput({ value, onBlur, onFocus, ...rest }: FloatingLabelInputProps) {
  return (
    <BaseFloatingLabelInput onBlur={onBlur} onFocus={onFocus} value={value}>
      {({ classNames, floating, handleBlur, handleFocus }) => (
        <TextInput
          data-floating={floating}
          labelProps={{ "data-floating": floating }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          {...rest}
          classNames={classNames}
        />
      )}
    </BaseFloatingLabelInput>
  );
}
