import type { Options } from "@hey-api/client-fetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEY_ALL_ARTICLES } from "../../../../common/Constants";
import type { ApiResponseInteger, CreateArticleData } from "../../../../generated/client/octalog-service";
import { createArticleMutation } from "../../../../generated/client/octalog-service/@tanstack/react-query.gen";
import { useBaseMutation } from "../../../../hooks/useBaseMutation";

interface UseCreateArticleMutationOptions<TContext> {
  onSuccess?: (
    data: ApiResponseInteger,
    variables: Options<CreateArticleData>,
    context: TContext,
  ) => Promise<unknown> | unknown;
}

export const useCreateArticleMutation = ({ onSuccess }: UseCreateArticleMutationOptions<unknown>) => {
  const queryClient = useQueryClient();
  return useMutation(
    useBaseMutation({
      ...createArticleMutation(),
      successMessage: "Der Artikel wurde erfolgreich erstellt",
      errorMessage: "Der Artikel konnte nicht erstellt werden",
      onSuccess: async (data, variables, context) => {
        // noinspection JSIgnoredPromiseFromCall
        await queryClient.invalidateQueries({
          queryKey: QUERY_KEY_ALL_ARTICLES,
        });
        onSuccess?.(data, variables, context);
      },
    }),
  );
};
