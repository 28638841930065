import { Textarea } from "@mantine/core";

import { BaseFloatingLabelInput } from "./BaseFloatingLabelInput";
import type { FloatingLabelTextareaProps } from "./types";

/**
 * Textarea input component with floating label behavior.
 * The label floats above the input when the input has value or is focused.
 */
export function FloatingLabelTextarea({ value, onBlur, onFocus, ...rest }: FloatingLabelTextareaProps) {
  return (
    <BaseFloatingLabelInput<HTMLTextAreaElement> onBlur={onBlur} onFocus={onFocus} value={value}>
      {({ classNames, floating, handleBlur, handleFocus }) => (
        <Textarea
          data-floating={floating}
          labelProps={{ "data-floating": floating }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          {...rest}
          classNames={classNames}
        />
      )}
    </BaseFloatingLabelInput>
  );
}
