import { Badge } from "@mantine/core";
import type { CommonColors } from "octalog-base";

import { translate } from "../../common/Dictionary";
import { HumidityLevel } from "../../generated/client/octalog-service";

export function HumidityLevelBadge(
  { grayPrimary, greenPrimary, yellowPrimary, redPrimary }: CommonColors,
  humidityLevel?: HumidityLevel,
) {
  if (!humidityLevel) {
    return <Badge color={grayPrimary}>-</Badge>;
  }
  const translation = translate(humidityLevel);
  switch (humidityLevel) {
    case HumidityLevel.DRIED: {
      return <Badge color={greenPrimary}>{translation}</Badge>;
    }
    case HumidityLevel.PRE_DRIED: {
      return <Badge color={yellowPrimary}>{translation}</Badge>;
    }
    case HumidityLevel.WET: {
      return <Badge color={redPrimary}>{translation}</Badge>;
    }
  }
}
