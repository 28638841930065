import { notifications } from "@mantine/notifications";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCommonColors } from "octalog-base";

import { QUERY_KEY_CONTAINER_TYPES } from "../../common/Constants";
import { tryToExtractDescription } from "../../common/error/ErrorMessageUtil";
import {
  getContainerTypesOptions,
  createContainerTypeMutation,
} from "../../generated/client/octalog-service/@tanstack/react-query.gen";

export const useGetContainerTypesQuery = () => {
  return useQuery({
    ...getContainerTypesOptions(),
  });
};

export const useCreateContainerTypeMutation = () => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();
  return useMutation({
    ...createContainerTypeMutation(),
    onSuccess: (response) => {
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_CONTAINER_TYPES,
      });
      notifications.show({
        title: "Erfolg",
        message: `Behältertyp ${response.name} wurde erfolgreich erstellt!`,
        color: commonColors.greenPrimary,
      });
    },
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Behältertyp konnte nicht erstellt werden: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};

export const useUpdateContainerTypeMutation = () => {
  const commonColors = useCommonColors();
  const queryClient = useQueryClient();
  return useMutation({
    ...createContainerTypeMutation(),
    onSuccess: (response) => {
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_CONTAINER_TYPES],
      });
      notifications.show({
        title: "Erfolg",
        message: `Behältertyp ${response.name} wurde erfolgreich aktualisiert!`,
        color: commonColors.greenPrimary,
      });
    },
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Behältertyp konnte nicht aktualisiert werden: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};
