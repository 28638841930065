import type { ReactNode } from "react";
import React from "react";

import classes from "./BaseFloatingLabelInput.module.css";
import type { UseFloatingLabelProps } from "./useFloatingLabel";
import { useFloatingLabel } from "./useFloatingLabel";

export interface BaseFloatingLabelInputProps<T extends HTMLElement> extends UseFloatingLabelProps<T> {
  children: (props: {
    classNames: typeof classes;
    floating: boolean | undefined;
    handleBlur: UseFloatingLabelProps<T>["onBlur"];
    handleFocus: UseFloatingLabelProps<T>["onFocus"];
  }) => ReactNode;
}

export function BaseFloatingLabelInput<T extends HTMLElement>({
  value,
  onBlur,
  onFocus,
  children,
}: BaseFloatingLabelInputProps<T>): React.JSX.Element {
  const { floating, handleBlur, handleFocus } = useFloatingLabel({
    value,
    onBlur,
    onFocus,
  });

  return <>{children({ classNames: classes, floating, handleBlur, handleFocus })}</>;
}
