import dayjs from "dayjs";

import { CUBIC_METER_OPTIONS, CURRENCY_OPTIONS } from "./Constants";

export function formatCurrency(value: number) {
  return `${value.toLocaleString("de-DE", CURRENCY_OPTIONS)}`;
}

export function formatCubicMeter(value: number) {
  return `${value.toLocaleString("de-DE", CUBIC_METER_OPTIONS)} m³`;
}

export function formatDate(value: Date | string) {
  return dayjs(value).format("L");
}

export function formatTime(value: string) {
  const parts = value.split(":");
  return `${parts[0]}:${parts[1]}`;
}

export function formatDateOrUndefined(value?: Date) {
  return value ? dayjs(value).format("L") : undefined;
}

export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";

export const formatDateToYYYYMMDD = (date: Date | null): string => {
  return dayjs(date).format(DATE_FORMAT_YYYY_MM_DD);
};
