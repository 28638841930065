import { Grid } from "@mantine/core";
import { RootContainer } from "octalog-base";

import { AreaStatistics } from "./AreaStatistics";
import { PieChartStatisticsByContainer } from "./by-container/PieChartStatisticsByContainer";
import { PieChartStatisticsByVolume } from "./by-volume/PieChartStatisticsByVolume";
import { GraphStatisticsInputOutput } from "./GraphStatisticsInputOutput";

export function Statistics() {
  return (
    <RootContainer fluid>
      <Grid>
        <GraphStatisticsInputOutput />
        <AreaStatistics />
        <PieChartStatisticsByVolume />
        <PieChartStatisticsByContainer />
      </Grid>
    </RootContainer>
  );
}
