import { useContext } from "react";

import { DeliverySchedulingContext } from "./DeliverySchedulingContext";
import type { DeliverySchedulingContextType } from "./DeliverySchedulingContext.types";

export function useDeliverySchedulingContext(): DeliverySchedulingContextType {
  const context = useContext(DeliverySchedulingContext);
  if (context === undefined) {
    throw new Error("useDeliveryScheduling must be used within a DeliverySchedulingProvider");
  }
  return context;
}
