import { createFormContext } from "@mantine/form";
import { createContext, useContext } from "react";

import type { LocalDateInternal } from "../../../../../common/calendar/LocalDateInternal";
import type { LocalTimeInternal } from "../../../../../common/calendar/LocalTimeInternal";
import type { WoodTypeDto, CuttingLengthDto, HumidityLevelDto } from "../../../../../generated/client/octalog-service";
import type { transformOrderData, OrderForm } from "../hooks/useOrderForm";

// Order Item Types
export enum OrderItemType {
  WOOD = "WOOD",
  ARTICLE = "ARTICLE",
}

export enum OrderItemDtoType {
  WOOD = "OrderItemWoodDto",
  WOOD_CREATE = "OrderItemWoodCreateDto",
  WOOD_UPDATE = "OrderItemWoodUpdateDto",
  ARTICLE = "OrderItemArticleDto",
  ARTICLE_CREATE = "OrderItemArticleCreateDto",
  ARTICLE_UPDATE = "OrderItemArticleUpdateDto",
}

// Form Data Types
export interface BaseOrderItemData {
  type: OrderItemType;
  index: number;
  orderItemId?: number;
  price?: number;
  priceOverride?: number;
}

export interface OrderItemWoodData extends BaseOrderItemData {
  type: OrderItemType.WOOD;
  cuttingLengthId?: number;
  humidityLevelId?: number;
  unitOfMeasurement?: "SOLID_CUBIC_METER" | "STACKED_CUBIC_METER" | "LOOSE_CUBIC_METER";
  volume?: number;
  woodTypeId?: number;
}

export interface OrderItemArticleData extends BaseOrderItemData {
  type: OrderItemType.ARTICLE;
  description?: string;
  name?: string;
  quantity?: number;
  sku?: string;
}

export interface BaseOrderFormData {
  contactId?: number;
  deliveryAddress?: string;
  deliveryAddressDifferent?: boolean;
  deliveryDate?: string;
  deliveryTargetDateEnd?: LocalDateInternal;
  deliveryTargetDateStart?: LocalDateInternal;
  deliveryTargetTimeEnd?: LocalTimeInternal;
  deliveryTargetTimeStart?: LocalTimeInternal;
  invoiceAddress?: string;
  itemsWood: OrderItemWoodData[];
  itemsArticle: OrderItemArticleData[];
  orderDate: LocalDateInternal;
  totalPrice: number;
  totalPriceOverride?: number;
}

export interface OrderUpdateFormData extends BaseOrderFormData {
  orderNumber: string;
  status: string;
}

export interface OrderCreateFormData extends BaseOrderFormData {
  orderNumber?: never;
}

export type OrderFormData = OrderUpdateFormData | OrderCreateFormData;

// Context Types
interface CustomerFormState {
  isAddressValid: boolean;
  hasSelectedContact: boolean;
}

interface DeliveryFormState {
  isDateValid: boolean;
  isTimeValid: boolean;
  hasValidRange: boolean;
}

interface OrderItemsState {
  totalPrice: number;
  itemCount: number;
  isValid: boolean;
}

export interface CustomerFormContextProps {
  form: OrderForm;
  showContactSelect: boolean;
  state: CustomerFormState;
  setDeliveryAddress: (address?: string) => void;
  clearDeliveryAddress: () => void;
  validateAddresses: () => boolean;
}

export interface DeliveryFormContextProps {
  form: OrderForm;
  state: DeliveryFormState;
  setDateRange: (start: LocalDateInternal, end?: LocalDateInternal) => void;
  setTimeRange: (start: LocalTimeInternal, end?: LocalTimeInternal) => void;
  validateDeliveryRange: () => boolean;
}

export interface OrderItemsContextProps {
  form: OrderForm;
  state: OrderItemsState;
  onAddItem: (type: OrderItemType) => void;
  onRemoveItem: (index: number, type: OrderItemType) => void;
  calculateTotalPrice: () => number;
  validateItems: () => boolean;
  contextData: {
    wood: {
      woodTypes?: WoodTypeDto[];
      cuttingLengths?: CuttingLengthDto[];
      humidityLevels?: HumidityLevelDto[];
    };
  };
}

// Create Form Context
export const [OrderFormProvider, useOrderFormContext, useOrderForm] = createFormContext<
  OrderFormData,
  typeof transformOrderData
>();

// Create Feature Contexts
export const CustomerFormContext = createContext<CustomerFormContextProps | undefined>(undefined);
export const DeliveryFormContext = createContext<DeliveryFormContextProps | undefined>(undefined);
export const OrderItemsContext = createContext<OrderItemsContextProps | undefined>(undefined);

// Context Hooks
export const useCustomerForm = () => {
  const context = useContext(CustomerFormContext);
  if (!context) throw new Error("useCustomerForm must be used within CustomerFormProvider");
  return context;
};

export const useDeliveryForm = () => {
  const context = useContext(DeliveryFormContext);
  if (!context) throw new Error("useDeliveryForm must be used within DeliveryFormProvider");
  return context;
};

export const useOrderItems = () => {
  const context = useContext(OrderItemsContext);
  if (!context) throw new Error("useOrderItems must be used within OrderItemsProvider");
  return context;
};
