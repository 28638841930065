import { Grid, SegmentedControl, Text } from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";

import { translatableToComboboxData } from "../../../common/mantine/ComboboxUtils";
import { nameOf } from "../../../common/Utils";
import type {
  GetContainerResponseDto,
  UpdateContainerData,
  UpdateContainerRequestDto,
  UpdateContentRequestDto,
} from "../../../generated/client/octalog-service";
import { WoodType, CuttingLength, HumidityLevel } from "../../../generated/client/octalog-service";

import type { UpdateContainerFormData } from "./useEditContainerForm";

interface EditContainerModalContentProps {
  containerDto: GetContainerResponseDto;
  form: UseFormReturnType<UpdateContainerFormData, (values: UpdateContainerFormData) => UpdateContainerFormData>;
}

export function EditContainerModalContent({ containerDto, form }: EditContainerModalContentProps) {
  if (!containerDto.content) {
    return (
      <Grid.Col span={12}>
        <Text fw={500} size="sm">
          Kein Inhalt vorhanden
        </Text>
      </Grid.Col>
    );
  }
  return (
    <>
      <Grid.Col span={12}>
        <Text fw={500} size="sm">
          Holzart
        </Text>
        <SegmentedControl
          data={translatableToComboboxData(WoodType)}
          fullWidth
          key={`${form.key(nameOf<UpdateContainerData>().body)}.${nameOf<UpdateContainerRequestDto>().content}.${nameOf<UpdateContentRequestDto>().woodType}`}
          {...form.getInputProps(
            `${nameOf<UpdateContainerData>().body}.${nameOf<UpdateContainerRequestDto>().content}.${nameOf<UpdateContentRequestDto>().woodType}`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Text fw={500} size="sm">
          Schnittlänge
        </Text>
        <SegmentedControl
          data={translatableToComboboxData(CuttingLength)}
          fullWidth
          key={`${form.key(nameOf<UpdateContainerData>().body)}.${nameOf<UpdateContainerRequestDto>().content}.${nameOf<UpdateContentRequestDto>().cuttingLength}`}
          {...form.getInputProps(
            `${nameOf<UpdateContainerData>().body}.${nameOf<UpdateContainerRequestDto>().content}.${nameOf<UpdateContentRequestDto>().cuttingLength}`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Text fw={500} size="sm">
          Feuchtigkeit
        </Text>
        <SegmentedControl
          data={translatableToComboboxData(HumidityLevel)}
          fullWidth
          key={`${form.key(nameOf<UpdateContainerData>().body)}.${nameOf<UpdateContainerRequestDto>().content}.${nameOf<UpdateContentRequestDto>().humidityLevel}`}
          {...form.getInputProps(
            `${nameOf<UpdateContainerData>().body}.${nameOf<UpdateContainerRequestDto>().content}.${nameOf<UpdateContentRequestDto>().humidityLevel}`,
          )}
        />
      </Grid.Col>
    </>
  );
}
