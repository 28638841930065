import { useLoaderErrorMessage } from "./useLoaderErrorMessage";

export interface ErrorMessageProps {
  error?: (Error | null)[] | Error | null;
}

export function ErrorIndicator({ error }: ErrorMessageProps) {
  const errorMessage = useLoaderErrorMessage(error) ?? "Ein unbekannter Fehler ist aufgetreten.";
  return <span className="error-message">{errorMessage}</span>;
}
