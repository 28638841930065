import type { AccordionProps } from "@mantine/core";
import { Input, Accordion, Text } from "@mantine/core";

import type { ContactResponseDto } from "../../../generated/client/octalog-service";

import classes from "./ContactEntry.module.css";
import { ContactEntryBody } from "./ContactEntryBody";
import { ContactEntryHead } from "./ContactEntryHead";

interface ContactEntryProps extends Omit<AccordionProps, "children" | "chevronPosition" | "variant"> {
  label?: string;
  required?: boolean;
  contact?: ContactResponseDto;
  preview?: boolean;
  error?: string;
}

export function ContactEntry({ label, required, contact, preview = false, error, ...rest }: ContactEntryProps) {
  const renderContact = () => {
    return contact ? (
      <Accordion {...rest}>
        <Accordion.Item key={contact.contactId} value={contact.contactId.toString()}>
          <Accordion.Control>
            <ContactEntryHead contact={contact} preview={preview} />
          </Accordion.Control>
          <Accordion.Panel>
            <ContactEntryBody contact={contact} />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    ) : (
      <Text c="dimmed">Kein Kontakt ausgewählt</Text>
    );
  };

  return (
    <Input.Wrapper classNames={{ label: classes.inputWrapperLabel }} error={error} label={label} required={required}>
      {renderContact()}
    </Input.Wrapper>
  );
}
