import type { BoxProps } from "@mantine/core";
import { Burger, Container, Group, Space } from "@mantine/core";
import { OctalogLogo } from "octalog-base";

interface HeaderProps extends BoxProps {
  mobileOpened: boolean;
  desktopOpened: boolean;
  toggleMobile: () => void;
  toggleDesktop: () => void;
}

export function Header({ mobileOpened, desktopOpened, toggleMobile, toggleDesktop, ...rest }: HeaderProps) {
  return (
    <Container fluid h="100%" {...rest}>
      <Group align="center" grow h="100%" justify="space-between" px="md" py={5} wrap="nowrap">
        <Burger hiddenFrom="md" onClick={toggleMobile} opened={mobileOpened} size="sm" />
        <Burger onClick={toggleDesktop} opened={desktopOpened} size="sm" visibleFrom="md" />
        <OctalogLogo size="90%" />
        <Space h="ml" pr="25" />
      </Group>
    </Container>
  );
}
