import { useCallback } from "react";

import { useGetContainerTypesQuery } from "./ContainerTypeHooks";

export function useGetContainerTypeName() {
  const containerTypes = useGetContainerTypesQuery();

  return useCallback(
    (containerTypeId?: string | number): string => {
      if (!containerTypeId || containerTypes.isPending || containerTypes.isError) {
        return String(containerTypeId);
      } else {
        const foundType = containerTypes.data?.containerTypes.find(
          (containerTypeResponse) => containerTypeResponse.containerTypeId === Number(containerTypeId),
        );
        return foundType?.name || String(containerTypeId);
      }
    },
    [containerTypes.data?.containerTypes, containerTypes.isError, containerTypes.isPending],
  );
}
