import { Grid } from "@mantine/core";

import { nameOf } from "../../../../../common/Utils";
import { FloatingLabelInput } from "../../../../../components/input/FloatingLabelInput/FloatingLabelInput";
import type { IndividualContactCreateDto } from "../../../../../generated/client/octalog-service";
import type { ContactData } from "../../common/contactFormContext";
import { useCreateContactContext } from "../../common/contactFormContext";

import { InputSalutation } from "./salutation/InputSalutation";

export function InputIndividualContact() {
  const form = useCreateContactContext();

  return (
    <>
      <InputSalutation />
      <Grid.Col span={12}>
        <FloatingLabelInput
          data-1p-ignore
          label="Vorname"
          {...form.getInputProps(
            `${nameOf<ContactData>().individualContact}.${nameOf<IndividualContactCreateDto>().firstName}`,
          )}
          value={form.values.individualContact?.firstName ?? ""}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <FloatingLabelInput
          data-1p-ignore
          label="Nachname"
          {...form.getInputProps(
            `${nameOf<ContactData>().individualContact}.${nameOf<IndividualContactCreateDto>().lastName}`,
          )}
          value={form.values.individualContact?.lastName ?? ""}
        />
      </Grid.Col>
    </>
  );
}
