import { Grid } from "@mantine/core";
import { IconStack } from "@tabler/icons-react";
import { get } from "lodash-es";

import { PieChartPaper } from "../../../components/paper/PieChartPaper";
import type {
  ContainerStatisticsDto,
  VolumeByArea,
  VolumeByCuttingLength,
  VolumeByHumidityLevel,
  VolumeByWoodType,
} from "../../../generated/client/octalog-service";
import { AbstractIconPaperStatistics } from "../AbstractIconPaperStatistics";

import { useByVolumePieChartConfig } from "./useByVolumePieChartConfig";

const calcSumOfVolume = (data: (VolumeByArea | VolumeByWoodType | VolumeByHumidityLevel | VolumeByCuttingLength)[]) =>
  (data?.reduce((acc, cur) => acc + cur.volume, 0)?.toFixed(2) || "n/a") + " m³";

export function PieChartStatisticsByVolume() {
  const chartConfig = useByVolumePieChartConfig();

  return (
    <AbstractIconPaperStatistics icon={IconStack} title="Volumen Statistiken">
      {(data: ContainerStatisticsDto | undefined) => {
        return (
          <Grid align="stretch" justify="space-between" pb={8}>
            {chartConfig.map((config) => (
              <Grid.Col key={config.title} span={{ base: 12, md: 6 }}>
                <PieChartPaper
                  holzPieChartProps={{
                    headline: calcSumOfVolume(get(data, config.dataKey)),
                    subline: config.subline,
                    data: get(data, config.dataKey) || [],
                    dataNameKey: config.dataNameKey,
                    dataNameTranslator: config.dataNameTranslator,
                    dataValueKey: config.dataValueKey,
                    color: config.color,
                  }}
                  icon={config.icon}
                  iconColor={config.color}
                  px={8}
                  title={config.title}
                />
              </Grid.Col>
            ))}
          </Grid>
        );
      }}
    </AbstractIconPaperStatistics>
  );
}
