import type { SelectProps } from "@mantine/core";

import { translateContactTypeToIcon } from "../../../common/Dictionary";

import { ContactTypeSelect } from "./ContactTypeSelect";

export type EmailTypeSelectProps = Omit<SelectProps, "data">;

export function EmailTypeSelect(props: EmailTypeSelectProps) {
  return <ContactTypeSelect detailType="email" translateTypeToIcon={translateContactTypeToIcon} {...props} />;
}
