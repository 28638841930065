import type { PaperProps } from "@mantine/core";

import { nameOf } from "../../../../common/Utils";
import { FormListItem } from "../../../../components/output/FormList/FormListItem";
import { FormListSimple } from "../../../../components/output/FormList/FormListSimple";
import type { CreateEmailDto } from "../../../../generated/client/octalog-service";
import { ContactDetailEmailType } from "../../../../generated/client/octalog-service";
import type { ContactData } from "../common/contactFormContext";
import { useCreateContactContext } from "../common/contactFormContext";

import { EmailDetailsInput } from "./EmailDetailsInput";

interface EmailInputProps extends PaperProps {}

export function EmailInput({ ...rest }: EmailInputProps) {
  const form = useCreateContactContext();

  function formListItems() {
    return form.values.emails.map((_: CreateEmailDto, index) => (
      <FormListItem
        // eslint-disable-next-line react/no-array-index-key --- OCTA-45 Refactor NewContactView
        key={`${nameOf<ContactData>().emails}.${index}`}
        removeListItem={() => form.removeListItem(nameOf<ContactData>().emails, index)}
      >
        <EmailDetailsInput
          emailInputProps={form.getInputProps(
            `${nameOf<ContactData>().emails}.${index}.${nameOf<CreateEmailDto>().email}`,
          )}
          emailTypeInputProps={form.getInputProps(
            `${nameOf<ContactData>().emails}.${index}.${nameOf<CreateEmailDto>().emailType}`,
          )}
        />
      </FormListItem>
    ));
  }

  function insertListItem() {
    form.insertListItem(nameOf<ContactData>().emails, {
      emailType: ContactDetailEmailType.PRIVATE,
      email: "",
    } satisfies CreateEmailDto);
  }

  return (
    <FormListSimple insertListItem={insertListItem} label="E-Mail" {...rest}>
      {formListItems()}
    </FormListSimple>
  );
}
