import { notifications } from "@mantine/notifications";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCommonColors } from "octalog-base";
import { useNavigate } from "react-router";

import { tryToExtractDescription } from "../../common/error/ErrorMessageUtil";
import {
  getMeOptions,
  signInMutation,
  signUpMutation,
  signOutMutation,
  startPasswordResetMutation,
  confirmPasswordResetMutation,
} from "../../generated/client/octalog-service/@tanstack/react-query.gen";

export const useSignInMutation = () => {
  const commonColors = useCommonColors();
  return useMutation({
    ...signInMutation(),
    onError: (error: Error) => {
      const message =
        error.message === "Unauthorized" ? "Email oder Passwort ungültig" : `${tryToExtractDescription(error)}`;
      notifications.show({
        title: "Fehler",
        message: `Anmeldung fehlgeschlagen: ${message}`,
        color: commonColors.redPrimary,
      });
    },
  });
};

export const useSignOutMutation = () => {
  const commonColors = useCommonColors();
  const navigate = useNavigate();
  return useMutation({
    ...signOutMutation(),
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Abmeldung fehlgeschlagen: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
    onSuccess: () => {
      if (globalThis.location.pathname !== "/sign-in") {
        console.log("Redirecting to sign-in page…");
        navigate("/sign-in");
      }
    },
  });
};

export const useSignUpMutation = () => {
  const commonColors = useCommonColors();
  return useMutation({
    ...signUpMutation(),
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Registrierung fehlgeschlagen: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};

export const useMe = () => {
  return useQuery({
    ...getMeOptions(),
  });
};

export const useStartPasswordResetMutation = () => {
  const commonColors = useCommonColors();
  return useMutation({
    ...startPasswordResetMutation(),
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Passwort zurücksetzen fehlgeschlagen: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};

export const useConfirmPasswordResetMutation = () => {
  const commonColors = useCommonColors();
  return useMutation({
    ...confirmPasswordResetMutation(),
    onError: (error: Error) => {
      notifications.show({
        title: "Fehler",
        message: `Passwort bestätigen fehlgeschlagen: ${tryToExtractDescription(error)}`,
        color: commonColors.redPrimary,
      });
    },
  });
};
