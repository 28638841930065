import { RenderJson } from "octalog-base";

import { QueryLoaderBoundary } from "../../../components/loader/QueryLoaderBoundary";
import type { PriceSettingsDto } from "../../../generated/client/octalog-service";
import { useGetPriceSettings } from "../../../service/prices/useGetPriceSettings";

import { EnablePriceSettings } from "./create/EnablePriceSettings";
import { EditPriceSettings } from "./edit/EditPriceSettings";

export function PriceSettings() {
  const priceSettings = useGetPriceSettings();

  function renderContent(data: PriceSettingsDto) {
    return data.basePrice == null ? (
      <EnablePriceSettings my={20} />
    ) : (
      <EditPriceSettings my={20} priceSettings={data} />
    );
  }

  return (
    <>
      <QueryLoaderBoundary my={20} queryResult={priceSettings}>
        {(data) => renderContent(data)}
      </QueryLoaderBoundary>
      <RenderJson data={priceSettings.data} title="Price Settings" />
    </>
  );
}
