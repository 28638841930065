import type { __CloseButtonProps, ElementProps } from "@mantine/core";
import { Combobox, rem, useInputProps } from "@mantine/core";
import type { TimeInputProps } from "@mantine/dates";
import { TimeInput } from "@mantine/dates";
import { IconClock } from "@tabler/icons-react";
import React from "react";

export interface ClearableTimeInputProps extends TimeInputProps {
  /** Props passed down to the clear button */
  clearButtonProps?: __CloseButtonProps & ElementProps<"button">;
  icon?: React.ReactNode;
  onClear?: () => void;
}

export function ClearableTimeInput(props: ClearableTimeInputProps) {
  const {
    clearButtonProps,
    icon = <IconClock stroke={1.5} style={{ width: rem(16), height: rem(16) }} />,
    onChange,
    onClear,
    rightSection,
    rightSectionWidth,
    value,
    ...others
  } = useInputProps("ClearableTimeInput", {}, props);

  const shouldShowClearButton = value && onClear;

  const { size } = props;

  const clearButton = shouldShowClearButton ? (
    <Combobox.ClearButton
      size={size as string}
      {...clearButtonProps}
      onClear={() => {
        onClear();
      }}
    />
  ) : null;

  return (
    <TimeInput
      leftSection={icon}
      onChange={onChange}
      rightSection={shouldShowClearButton ? clearButton : rightSection}
      rightSectionWidth={shouldShowClearButton ? 32 : rightSectionWidth}
      value={value ?? ""} // TimeInput can't handle undefined values
      {...others}
    />
  );
}
