import { useQuery } from "@tanstack/react-query";

import {
  getContentLevelEventStatisticsOptions,
  getContainerStatisticsOptions,
} from "../../generated/client/octalog-service/@tanstack/react-query.gen";

export const useGetContainerStatistics = () => {
  return useQuery({
    ...getContainerStatisticsOptions(),
  });
};

export const useGetContentStatistics = (withNulls?: boolean, startDate?: string, endDate?: string) => {
  return useQuery({
    ...getContentLevelEventStatisticsOptions({
      query: {
        withNulls: withNulls,
        startDate: startDate,
        endDate: endDate,
      },
    }),
  });
};
