import type { BoxProps } from "@mantine/core";
import { ScrollArea, Accordion, Grid, JsonInput, Title } from "@mantine/core";
import { useContext } from "react";

import { DebugContext } from "./DebugContext";

interface RenderJsonProps extends BoxProps {
  title?: string;
  data: unknown;
  inGrid?: boolean;
}

function RenderAccordion({ title, data }: { title?: string; data: unknown }) {
  return (
    <Accordion chevronPosition="left" variant="default">
      <Accordion.Item key="json" value="json">
        <Accordion.Control>
          <Title order={5}>{title || "JSON"}</Title>
        </Accordion.Control>
        <Accordion.Panel>
          <JsonInput autosize formatOnBlur value={JSON.stringify(data, null, 2)} />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

export function RenderJson({ title, data, inGrid, ...rest }: RenderJsonProps) {
  const { showJson } = useContext(DebugContext);

  if (!showJson) {
    return null;
  }

  return inGrid ? (
    <Grid.Col span={12} {...rest}>
      <RenderAccordion data={data} title={title} />
    </Grid.Col>
  ) : (
    <ScrollArea {...rest}>
      <RenderAccordion data={data} title={title} />
    </ScrollArea>
  );
}
