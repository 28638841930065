import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { QUERY_KEY_AREAS, QUERY_KEY_CONTAINER_STATISTICS, QUERY_KEY_CONTAINERS } from "../../common/Constants";
import {
  getAreasOptions,
  updateAreaMutation,
  createAreaMutation,
  assignContainersToAreaMutation,
} from "../../generated/client/octalog-service/@tanstack/react-query.gen";
import { useBaseMutation } from "../useBaseMutation";

export const useGetAreasQuery = () => {
  return useQuery({
    ...getAreasOptions(),
  });
};

export const useCreateAreaMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    useBaseMutation({
      ...createAreaMutation(),
      successMessage: (response) => {
        return `Lagerplatz ${response.name} wurde erfolgreich erstellt!`;
      },
      errorMessage: `Fehler beim Erstellen des Lagerplatzes!`,
      onSuccess: () => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({ queryKey: QUERY_KEY_AREAS });
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({ queryKey: QUERY_KEY_CONTAINER_STATISTICS });
      },
    }),
  );
};

export interface AssignAreaProps {
  areaId: number;
  containerIds: number[];
}

export const useAssignAreaMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    useBaseMutation({
      ...assignContainersToAreaMutation(),
      successMessage: `Behälter wurden erfolgreich zugewiesen!`,
      errorMessage: `Behälter konnten nicht zugewiesen werden!`,
      onSuccess: () => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({
          // TODO: This needs an improvement - we should only invalidate the containers that are affected by the area
          queryKey: QUERY_KEY_CONTAINERS,
        });

        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({
          queryKey: QUERY_KEY_CONTAINER_STATISTICS,
        });
      },
    }),
  );
};

export const useUpdateAreaMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    useBaseMutation({
      ...updateAreaMutation(),
      successMessage: (response) => {
        return `Lagerplatz ${response.name} wurde erfolgreich aktualisiert!`;
      },
      errorMessage: `Lagerplatz konnte nicht aktualisiert werden!`,
      onSuccess: () => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({ queryKey: QUERY_KEY_AREAS });
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({ queryKey: QUERY_KEY_CONTAINER_STATISTICS });
      },
    }),
  );
};

export function useAreaNameTranslator() {
  const areas = useGetAreasQuery();

  return useCallback(
    (areaId?: string | number): string | number | undefined => {
      if (!areaId || areas.isPending || areas.isError) {
        return areaId;
      } else {
        const foundArea = areas.data?.areas.find((areaResponse) => areaResponse.areaId === Number(areaId));
        return foundArea?.name || areaId;
      }
    },
    [areas.data?.areas, areas.isError, areas.isPending],
  );
}
