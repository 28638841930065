import { Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { useNavigate } from "react-router";

import { useRequiredParams } from "../../common/react-router/RequiredParams";
import { FloatingLabelPasswordInput } from "../../components/input/FloatingLabelInput/FloatingLabelPasswordInput";
import { useConfirmPasswordResetMutation } from "../../service/iam/IamHooks";

import { AuthContainer } from "./components/AuthContainer";
import { AuthLoadingState } from "./components/AuthLoadingState";
import { AuthSuccessState } from "./components/AuthSuccessState";

export function ConfirmPasswordReset() {
  const { token } = useRequiredParams<{ token: string }>();
  const navigate = useNavigate();
  const confirmPasswordReset = useConfirmPasswordResetMutation();
  const [isValidPassword, setIsValidPassword] = useState(false);

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      token: token,
      password: "",
    },
    validate: {
      password: () => (isValidPassword ? null : "error"),
    },
  });

  return (
    <AuthContainer dataTestIdTitle="confirmPasswordReset" title="Neues Passwort setzen">
      {confirmPasswordReset.isPending ? (
        <AuthLoadingState title="Passwort ändern wird durchgeführt" />
      ) : confirmPasswordReset.isSuccess ? (
        <AuthSuccessState
          buttonText="Anmelden"
          dataTestIdButton="signIn"
          dataTestIdTitle="confirmPasswordResetSuccessful"
          onButtonClick={() => navigate("/sign-in")}
          title="Neues Passwort setzen erfolgreich"
        />
      ) : (
        <form
          aria-label="Passwort zurücksetzen Formular"
          onSubmit={form.onSubmit((values) => {
            confirmPasswordReset.mutate({
              body: {
                token: values.token,
                password: values.password,
              },
            });
          })}
        >
          <FloatingLabelPasswordInput
            data-testid="password"
            id="new-password"
            key={form.key("password")}
            label="Neues Passwort"
            required
            {...form.getInputProps("password")}
            aria-label="Neues Passwort eingeben"
            autoComplete="new-password"
            maxLength={100}
            minLength={8}
            onChange={(e) => {
              form.getInputProps("password").onChange(e);
              setIsValidPassword(e.currentTarget.value.length >= 8);
            }}
          />
          <Button
            aria-label="Passwort bestätigen"
            data-testid="submit"
            disabled={confirmPasswordReset.isPending || !form.isValid()}
            fullWidth
            mt="xl"
            type="submit"
          >
            Neues Passwort setzen
          </Button>
        </form>
      )}
    </AuthContainer>
  );
}
