import { QueryLoaderBoundary } from "../../../components/loader/QueryLoaderBoundary";
import { useGetAreasQuery } from "../../../hooks/areas/useAreaHooks";

import { AreaTable } from "./AreaTable";

export function AreasSettings() {
  const areas = useGetAreasQuery();

  return (
    <QueryLoaderBoundary my={20} queryResult={areas}>
      {(data) => AreaTable({ data })}
    </QueryLoaderBoundary>
  );
}
