import type { ApiErrorContainer } from "../../generated/client/octalog-service";
import { instanceOf, nameOf } from "../Utils";

export function tryToExtractDescription(error: Error | null): string | undefined {
  try {
    console.group("Error extractor");
    console.log(JSON.stringify(error));
    if (error) {
      if (instanceOf<ApiErrorContainer>(error, nameOf<ApiErrorContainer>().details)) {
        if (error.details.length > 0) {
          return error.details.map((detail) => detail.detailMessages).join(" \n");
        } else if (error.message) {
          console.groupEnd();
          return error.message;
        }
      }
      return "Interner Fehler. Bitte kontaktieren Sie den Support, wenn das Problem weiterhin besteht.";
    }
  } finally {
    console.groupEnd();
  }
}
