import { Button, Container, NumberInput, SimpleGrid, Checkbox } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconUpload } from "@tabler/icons-react";
import { useState } from "react";

import { LocalDateInternal } from "../../../common/calendar/LocalDateInternal";
import { LevelEventType } from "../../../generated/client/octalog-service";
import { useClearContainersMutation } from "../../../service/containers/ContainerHooks";
import { useEmptyContentMutation } from "../../../service/containers/ContentHooks";

interface EmptyDialogProps {
  selectedContainerIds: number[];
  clearSelectedContainerIdsHandler: () => void;
  isPending: boolean;
  hasError?: boolean;
  showSelectedContainerCount?: boolean;
}

export function EmptyDialog({
  selectedContainerIds,
  clearSelectedContainerIdsHandler,
  isPending,
  hasError = undefined,
  showSelectedContainerCount = false,
}: EmptyDialogProps) {
  const [emptyDate, setEmptyDate] = useState<LocalDateInternal | null>(LocalDateInternal.today());
  const [emptyVolume, setEmptyVolume] = useState(undefined as number | undefined);
  const [removeLocation, setRemoveLocation] = useState(true);

  const emptyContentMutation = useEmptyContentMutation();
  const clearContainersMutation = useClearContainersMutation();
  return (
    <>
      <SimpleGrid cols={{ base: 1, sm: 2 }} p={10}>
        <DatePickerInput
          label="Datum"
          locale="de"
          onChange={(value) => (value ? setEmptyDate(LocalDateInternal.fromDate(value)) : null)}
          value={emptyDate?.toDate()}
        />
        <NumberInput
          decimalScale={2}
          decimalSeparator=","
          fixedDecimalScale
          label="Volumen"
          min={0}
          onChange={(event) => {
            setEmptyVolume(Number(event));
          }}
          placeholder="Max"
          value={emptyVolume}
        />
        <Checkbox
          checked={removeLocation}
          defaultChecked
          label="Lagerplatz entfernen"
          onChange={(event) => setRemoveLocation(event.currentTarget.checked)}
          variant="outline"
        />
      </SimpleGrid>
      <Container my={10} p={10}>
        <Button
          disabled={isPending || selectedContainerIds.length <= 0 || hasError}
          fullWidth
          loaderProps={{ type: "dots" }}
          loading={clearContainersMutation.isPending}
          onClick={() => {
            if (emptyVolume === undefined) {
              clearContainersMutation.mutate(
                {
                  body: {
                    containerIds: selectedContainerIds,
                    emptyingDate: emptyDate?.toString(),
                    removeLocation: removeLocation,
                  },
                },
                {
                  onSuccess: () => {
                    clearSelectedContainerIdsHandler();
                  },
                },
              );
            } else {
              emptyContentMutation.mutate(
                {
                  body: {
                    containerIds: selectedContainerIds,
                    date: emptyDate?.toString() ?? LocalDateInternal.today().toString(),
                    volume: emptyVolume,
                    eventType: LevelEventType.EMPTY,
                  },
                },
                {
                  onSuccess: () => {
                    clearSelectedContainerIdsHandler();
                  },
                },
              );
            }
          }}
          px={0}
          rightSection={<IconUpload size={14} />}
          variant="light"
        >
          {showSelectedContainerCount ? `${selectedContainerIds.length} Behälter entleeren` : "Behälter entleeren"}
        </Button>
      </Container>
    </>
  );
}
