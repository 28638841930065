import { useCallback, useMemo, useState, useEffect, useRef } from "react";

import type { ContactResponseDto } from "../../generated/client/octalog-service";
import { useFilteredContacts } from "../../hooks/useFilteredContacts";
import { useGetContactsQuery } from "../../service/contacts/useGetContactsQuery";
import { getContactName } from "../../views/contacts/list/contactUtil";

export interface ContactSelectState {
  value: string;
  handleChange: (newValue: string) => void;
  clearValue: () => void;
  contacts: ContactResponseDto[];
  contactOptions: { value: string; label: string }[];
  filteredContactOptions: { value: string; label: string }[];
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
}

export function useContactSelect(
  onSelect: (contact: ContactResponseDto | null) => void,
  resetOnUndefined?: string,
): ContactSelectState {
  const [value, setValue] = useState("");
  const { data: contacts = [], isLoading, isError, error } = useGetContactsQuery();
  const filteredContacts = useFilteredContacts(contacts, value);

  const previousResetOnUndefined = useRef(resetOnUndefined);
  useEffect(() => {
    // Only update if the value of resetOnUndefined has actually changed
    if (resetOnUndefined !== previousResetOnUndefined.current) {
      previousResetOnUndefined.current = resetOnUndefined;
      // Reset value if resetOnUndefined is undefined and value is not empty
      if (resetOnUndefined == undefined && value !== "") {
        setValue("");
      }
    }
  }, [resetOnUndefined, value]);

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      const selected = contacts.find((contact) => getContactName(contact) === newValue);
      onSelect(selected || null);
    },
    [contacts, onSelect],
  );

  const clearValue = useCallback(() => {
    setValue("");
    onSelect(null);
  }, [onSelect]);

  const contactOptions = useMemo(
    () =>
      contacts.map((contact) => ({
        value: contact.contactId.toString(),
        label: getContactName(contact),
      })),
    [contacts],
  );

  const filteredContactOptions = useMemo(
    () =>
      filteredContacts.map((contact) => ({
        value: contact.contactId.toString(),
        label: getContactName(contact),
      })),
    [filteredContacts],
  );

  return {
    value,
    handleChange,
    clearValue,
    contacts,
    contactOptions,
    filteredContactOptions,
    isLoading,
    isError,
    error: error as Error | null,
  };
}
