import { Button, Paper, SimpleGrid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconBuildingWarehouse } from "@tabler/icons-react";
import { RenderJson } from "octalog-base";
import { useEffect } from "react";

import { nameOf } from "../../../../common/Utils";
import { AreaSelection } from "../../../../components/input/container/area-selection/AreaSelection";
import { useSelectionContext } from "../../../../components/input/ContainerIdSelection/SelectionFormContext";
import type {
  AssignContainersToAreaDto,
  AssignContainersToAreaData,
} from "../../../../generated/client/octalog-service";
import type { AssignAreaProps } from "../../../../hooks/areas/useAreaHooks";
import { useAssignAreaMutation } from "../../../../hooks/areas/useAreaHooks";
import classes from "../Actions.module.css";

interface AssignContainerFormData extends AssignContainersToAreaDto {
  areaId: number | undefined;
}

function transformValues(values: AssignContainerFormData): Omit<AssignContainersToAreaData, "url"> {
  return {
    path: {
      areaId: values.areaId ?? -1,
    },
    body: {
      containerIds: values.containerIds,
    },
  };
}

export function AssignContainersAction() {
  const fillContainerForm = useSelectionContext();
  const assignAreasMutation = useAssignAreaMutation();
  // noinspection JSUnusedGlobalSymbols
  const assignAreaForm = useForm<AssignContainerFormData, typeof transformValues>({
    initialValues: {
      areaId: undefined,
      containerIds: [],
    },
    validate: {
      areaId: (value) => value === undefined && "Bitte wählen Sie ein Lagerplatz aus",
    },
    transformValues: transformValues,
  });

  const assignAreaFormSetValues = assignAreaForm.setValues;
  useEffect(() => {
    assignAreaFormSetValues({
      containerIds: fillContainerForm.values.containersWithState.map((container) => container.containerId),
    });
  }, [fillContainerForm.values.containersWithState, assignAreaFormSetValues]);

  return (
    <Paper mt={20} ta="left" withBorder>
      <form
        onSubmit={assignAreaForm.onSubmit((values) => {
          assignAreasMutation.mutate(values);
        })}
      >
        <SimpleGrid cols={{ base: 1, sm: 2 }} p={10}>
          <AreaSelection
            classNames={{ root: classes.select__root }}
            key={assignAreaForm.key(`${nameOf<AssignAreaProps>().areaId}`)}
            {...assignAreaForm.getInputProps(`${nameOf<AssignAreaProps>().areaId}`)}
          />
          <Button
            classNames={{ section: classes.button__section }}
            data-testid="assign-containers-button"
            disabled={
              assignAreaForm.values.containerIds.length === 0 ||
              !assignAreaForm.values.areaId ||
              fillContainerForm.values.isPending
            }
            leftSection={
              assignAreaForm.values.containerIds.length === 0 ? "0" : assignAreaForm.values.containerIds.length
            }
            loaderProps={{ type: "dots" }}
            loading={assignAreasMutation.isPending}
            mt={24}
            rightSection={<IconBuildingWarehouse size={14} />}
            type="submit"
            variant="light"
          >
            Zuordnen
          </Button>
        </SimpleGrid>
        <RenderJson data={assignAreaForm.values} />
      </form>
    </Paper>
  );
}
