import type { MantineColorScheme } from "@mantine/core";
import { ActionIcon, AppShell, Group, Popover, ScrollArea, Stack, Switch, Text, Flex, Burger } from "@mantine/core";
import { IconLogout, IconMoonStars, IconSun } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { DebugContext } from "octalog-base";
import { useContext } from "react";

import { UserButton } from "../../../components/button/UserButton";
import { QueryLoaderBoundary } from "../../../components/loader/QueryLoaderBoundary";
import { OCTALOG_VERSION } from "../../../config/environment";
import { getVersionOptions } from "../../../generated/client/octalog-service/@tanstack/react-query.gen";

import { Navigation } from "./Navigation";

interface NavbarProps {
  mobileOpened: boolean;
  toggleMobile: () => void;
  closeMobile: () => void;
  colorScheme: MantineColorScheme;
  toggleColorScheme: () => void;
  signOut: () => void;
  showDeveloperOptions: boolean;
}

export function Navbar({
  mobileOpened,
  toggleMobile,
  closeMobile,
  colorScheme,
  toggleColorScheme,
  signOut,
  showDeveloperOptions,
}: NavbarProps) {
  const version = useQuery({
    ...getVersionOptions(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  const { showJson, setShowJson } = useContext(DebugContext);

  return (
    <>
      <AppShell.Section>
        <Group>
          {mobileOpened ? (
            <Burger hiddenFrom="md" ml={15} mt={-5} onClick={toggleMobile} opened={mobileOpened} size="sm" />
          ) : null}
          <UserButton pl={10} />
        </Group>
      </AppShell.Section>
      <AppShell.Section component={ScrollArea} grow my="md" pl={3}>
        <Navigation closeNavigationInMobileModeCallback={closeMobile} />
      </AppShell.Section>
      <AppShell.Section>
        <Group justify="center">
          <ActionIcon onClick={signOut} size={30} variant="default">
            <IconLogout size={16} />
          </ActionIcon>
          <ActionIcon onClick={toggleColorScheme} size={30} variant="default">
            {colorScheme === "dark" ? <IconSun size={16} /> : <IconMoonStars size={16} />}
          </ActionIcon>
        </Group>
      </AppShell.Section>
      <AppShell.Section>
        <QueryLoaderBoundary mt={15} queryResult={version} size="sm">
          {(data) => (
            <Flex justify="center">
              <Popover offset={5} position="right" withArrow>
                <Popover.Target>
                  <Text c="dimmed" fw={700} mt={10} size="xs">
                    {data.octalogVersion}
                  </Text>
                </Popover.Target>
                <Popover.Dropdown p={2} pl={5}>
                  <Text c="dimmed" fw={500} fz={10}>
                    {data.buildVersion} (UI: {OCTALOG_VERSION})
                  </Text>
                </Popover.Dropdown>
              </Popover>
            </Flex>
          )}
        </QueryLoaderBoundary>
      </AppShell.Section>
      <AppShell.Section hidden={!showDeveloperOptions} mt={10}>
        <Stack align="center">
          <Switch checked={showJson} label="Show JSON" onChange={(event) => setShowJson(event.currentTarget.checked)} />
        </Stack>
      </AppShell.Section>
    </>
  );
}
