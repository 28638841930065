import {
  IconAdjustments,
  IconDownload,
  IconGraph,
  IconListDetails,
  IconListSearch,
  IconUpload,
  IconUserPlus,
  IconUsers,
  IconBuildingWarehouse,
  IconRoute,
} from "@tabler/icons-react";
import type { CommonColors } from "octalog-base";
import type React from "react";

export interface Link {
  icon: React.ReactNode;
  color: string;
  label: string;
  to: string;
  id: string;
  subLinks?: Link[];
}

export function getLinks(commonColors: CommonColors): Link[] {
  return [
    {
      icon: <IconBuildingWarehouse size={16} />,
      color: commonColors.greenPrimary,
      label: "Bestand",
      to: "/containers",
      id: "containers",
      subLinks: [
        {
          icon: <IconListSearch size={16} />,
          color: commonColors.greenPrimary,
          label: "Übersicht",
          to: "/containers/list",
          id: "containers-list",
        },
        {
          icon: <IconGraph size={16} />,
          color: commonColors.greenPrimary,
          label: "Statistiken",
          to: "/containers/statistics",
          id: "containers-statistics",
        },
        {
          icon: <IconDownload size={16} />,
          color: commonColors.greenPrimary,
          label: "Behälter befüllen",
          to: "/containers/fill",
          id: "containers-fill",
        },
        {
          icon: <IconUpload size={16} />,
          color: commonColors.greenPrimary,
          label: "Behälter entleeren",
          to: "/containers/empty",
          id: "containers-empty",
        },
      ],
    },
    {
      icon: <IconUsers size={16} />,
      color: commonColors.greenPrimary,
      label: "Aufträge",
      to: "/orders/create",
      id: "orders",
      subLinks: [
        {
          icon: <IconUserPlus size={16} />,
          color: commonColors.greenPrimary,
          label: "Auftrag erstellen",
          to: "/orders/create",
          id: "orders-create",
        },
        {
          icon: <IconListDetails size={16} />,
          color: commonColors.greenPrimary,
          label: "Übersicht",
          to: "/orders/list",
          id: "orders-list",
        },
        {
          icon: <IconRoute size={16} />,
          color: commonColors.greenPrimary,
          label: "Auslieferungen Planen",
          to: "/orders/delivery/planner",
          id: "orders-delivery-planner",
        },
      ],
    },
    {
      icon: <IconUsers size={16} />,
      color: commonColors.greenPrimary,
      label: "Kontakte",
      to: "/contacts",
      id: "contacts",
      subLinks: [
        {
          icon: <IconListDetails size={16} />,
          color: commonColors.greenPrimary,
          label: "Übersicht",
          to: "/contacts/all",
          id: "contacts-all",
        },
        {
          icon: <IconUserPlus size={16} />,
          color: commonColors.greenPrimary,
          label: "Kontakt erstellen",
          to: "/contacts/new",
          id: "contacts-new",
        },
      ],
    },
    {
      icon: <IconAdjustments size={16} />,
      color: commonColors.greenPrimary,
      label: "Einstellungen",
      to: "/settings",
      id: "settings",
    },
  ];
}
