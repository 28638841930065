import { Text, Grid } from "@mantine/core";

import { LocalDateInternal } from "../../../../../../../../common/calendar/LocalDateInternal";
import { LocalTimeInternal } from "../../../../../../../../common/calendar/LocalTimeInternal";
import { LabelValue } from "../../../../../../../../components/output/LabelValue/LabelValue";
import type { OrderDto } from "../../../../../../../../generated/client/octalog-service";

export interface DesiredDeliveryPeriodDetailsProps {
  order: OrderDto;
}

export function DesiredDeliveryPeriodDetails({ order }: DesiredDeliveryPeriodDetailsProps) {
  function renderDeliveryDate() {
    return order.deliveryTargetDateEnd ? (
      <LabelValue
        label="Datum zwischen"
        value={
          LocalDateInternal.fromString(order.deliveryTargetDateStart).humanize() +
          " und " +
          LocalDateInternal.fromString(order.deliveryTargetDateEnd).humanize()
        }
      />
    ) : (
      <LabelValue label="Datum" value={LocalDateInternal.fromString(order.deliveryTargetDateStart).humanize()} />
    );
  }

  function renderDeliveryTime() {
    if (!order.deliveryTargetTimeStart) {
      return <LabelValue label="Uhrzeit" value="Keine Angabe" />;
    }

    return order.deliveryTargetTimeEnd ? (
      <LabelValue
        label="Uhrzeit zwischen"
        value={
          LocalTimeInternal.fromString(order.deliveryTargetTimeStart).humanize() +
          " und " +
          LocalTimeInternal.fromString(order.deliveryTargetTimeEnd).humanize()
        }
      />
    ) : (
      <LabelValue label="Uhrzeit" value={LocalTimeInternal.fromString(order.deliveryTargetTimeStart).humanize()} />
    );
  }

  return (
    <Grid>
      <Grid.Col span={12}>
        <Text fw={700}>Gewünschter Lieferzeitraum</Text>
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 6 }} ta="left">
        {renderDeliveryDate()}
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 6 }} ta="left">
        {renderDeliveryTime()}
      </Grid.Col>
    </Grid>
  );
}
