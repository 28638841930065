import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import type React from "react";
import invariant from "tiny-invariant";

export function registerDropTargetAndAutoScroll({ ref }: { ref: React.RefObject<HTMLDivElement | null> }) {
  const element = ref.current;
  invariant(element, "Element ref not set");
  return combine(
    dropTargetForElements({
      element,
    }),
    autoScrollForElements({
      element,
      getAllowedAxis: () => "vertical",
    }),
  );
}
