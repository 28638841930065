import dayjs from "dayjs";
import { useState } from "react";

import type { OrderDto } from "../../../../../../../generated/client/octalog-service";

export const STATUS_FILTER_OPTIONS = [
  { value: "NEW", label: "Neue Bestellungen" },
  { value: "SCHEDULED", label: "Geplante Bestellungen" },
  { value: "IN_DELIVERY", label: "In Lieferung" },
  { value: "DELIVERED", label: "Geliefert" },
  { value: "CANCELED", label: "Abgebrochen" },
  { value: "UNSCHEDULED", label: "Nicht geplant" },
  { value: "RESCHEDULED", label: "Neu geplant" },
] as const;

export const TIME_FILTER_OPTIONS = [
  { value: "current", label: "Diese Woche" },
  { value: "next", label: "Nächste Woche" },
] as const;

export type StatusFilterValue = (typeof STATUS_FILTER_OPTIONS)[number]["value"];
export type TimeFilterValue = (typeof TIME_FILTER_OPTIONS)[number]["value"];

export function useOrderFilters() {
  const [statusFilter, setStatusFilter] = useState<StatusFilterValue[]>(["NEW", "UNSCHEDULED"]);
  const [timeFilter, setTimeFilter] = useState<TimeFilterValue | null>(null);

  const filterOrders = (orders: OrderDto[]) => {
    return orders.filter((order) => {
      const matchesStatus = statusFilter.isEmpty() || statusFilter.includes(order.status);

      const orderDate = dayjs(order.deliveryTargetDateStart);
      const currentWeekStart = dayjs().startOf("week");
      const nextWeekStart = currentWeekStart.add(1, "week");

      const matchesTime =
        !timeFilter ||
        (timeFilter === "current" && orderDate.isBetween(currentWeekStart, nextWeekStart)) ||
        (timeFilter === "next" && orderDate.isBetween(nextWeekStart, nextWeekStart.add(1, "week")));

      return matchesStatus && matchesTime;
    });
  };

  return {
    statusFilter,
    setStatusFilter,
    timeFilter,
    setTimeFilter,
    filterOrders,
  };
}
