import { Group, Select } from "@mantine/core";
import { IconPhone } from "@tabler/icons-react";
import type { CountryCode } from "libphonenumber-js";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import type { GetInputPropsReturnType } from "octalog-base";

import { PhoneNumberTypeSelect } from "../../../../components/input/contact/PhoneNumberTypeSelect";
import { FloatingLabelNumericFormat } from "../../../../components/input/FloatingLabelInput/FloatingLabelNumericFormat";

import classes from "./PhoneNumberDetailsInput.module.css";

interface PhoneNumberDetailsInputProps {
  phoneNumberTypeInputProps: GetInputPropsReturnType;
  phoneNumberInputProps: GetInputPropsReturnType;
  countryCodeInputProps: GetInputPropsReturnType;
}

export function PhoneNumberDetailsInput({
  phoneNumberTypeInputProps,
  phoneNumberInputProps,
  countryCodeInputProps,
}: PhoneNumberDetailsInputProps) {
  return (
    <FloatingLabelNumericFormat
      allowLeadingZeros={true}
      allowNegative={false}
      classNames={{ input: classes.inputBase, label: classes.label }}
      decimalScale={0}
      inputContainer={(children) => (
        <Group align="flex-start" gap={0} grow preventGrowOverflow={false} wrap="nowrap">
          <Select
            allowDeselect={false}
            classNames={{
              input: classes.selectCountryCodeInput,
              section: classes.selectCountryCodeSection,
              option: classes.selectCountryCodeOption,
            }}
            comboboxProps={{ width: 110, position: "bottom-start" }}
            data={getCountries().map((countryCode) => ({
              value: countryCode,
              label: `(+${getCountryCallingCode(countryCode)})`,
            }))}
            data-1p-ignore
            defaultValue="DE"
            maw={75}
            renderOption={({ option }) => {
              return `${option.value} (+${getCountryCallingCode(option.value as CountryCode)})`;
            }}
            searchable
            {...countryCodeInputProps}
          />
          {children}
          <PhoneNumberTypeSelect classNames={{ input: classes.selectPhoneNumberType }} {...phoneNumberTypeInputProps} />
        </Group>
      )}
      label="Telefonnummer"
      leftSection={<IconPhone size={16} />}
      {...phoneNumberInputProps}
    />
  );
}
