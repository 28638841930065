import { Textarea, Checkbox, Text, Title, Stack, Center, Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useEffect, useState } from "react";

import { nameOf } from "../../../../../common/Utils";
import { AddButton } from "../../../../../components/button/add/AddButton";
import { LoaderBoundary } from "../../../../../components/loader/LoaderBoundary";
import { ContactEntry } from "../../../../../components/output/contact/ContactEntry";
import { CreateContactModal } from "../../../../../components/output/contact/CreateContactModal";
import { ContactSelect } from "../../../../../components/select/ContactSelect";
import type { OrderCreateDto, ContactResponseDto } from "../../../../../generated/client/octalog-service";
import { useGetContactsQuery } from "../../../../../service/contacts/useGetContactsQuery";
import type { OrderFormData } from "../contexts/FormContexts";
import { useCustomerForm } from "../contexts/FormContexts";

import classes from "./CustomerDataSection.module.css";

export function CustomerDataSection() {
  const { form, showContactSelect, setDeliveryAddress, clearDeliveryAddress } = useCustomerForm();
  const contactsQuery = useGetContactsQuery();

  const inputPropsInvoiceAddress = form.getInputProps(nameOf<OrderFormData>().invoiceAddress);
  const inputPropsDeliveryAddress = form.getInputProps(nameOf<OrderFormData>().deliveryAddress);
  const inputPropsDeliveryAddressDifferent = form.getInputProps(nameOf<OrderFormData>().deliveryAddressDifferent);

  const [selectedContact, setSelectedContact] = useState<ContactResponseDto | undefined>();

  function onDeliveryAddressDifferentChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.checked) {
      setDeliveryAddress(form.values.invoiceAddress);
    } else {
      clearDeliveryAddress();
    }
  }

  function setSelectedContactId(contactId: number) {
    form.setFieldValue(nameOf<OrderCreateDto>().contactId, contactId);
  }

  useEffect(() => {
    setSelectedContact(contactsQuery.data?.find((contact) => contact.contactId === Number(form.values.contactId)));
  }, [contactsQuery.data, form.values.contactId]);

  function printNotesIfAvailable(notes: string | undefined) {
    if (notes) {
      return (
        <>
          <Text fw={700} size="sm">
            Notizen:
          </Text>
          <Text className={classes.notes} size="sm">
            {notes}
          </Text>
        </>
      );
    }
    return null;
  }

  const [newContactModalOpened, { open: openNewContactModal, close: closeNewContactModal }] = useDisclosure(false);

  return (
    <Stack ta="left">
      <Center>
        <Title c="dimmed" mt="xs" order={4}>
          Kundendaten
        </Title>
      </Center>
      <LoaderBoundary hidden={!showContactSelect} isPending={contactsQuery.isPending}>
        <Group align="flex-end" wrap="nowrap">
          <ContactSelect
            onSelect={(contact) => {
              form.setFieldValue(nameOf<OrderCreateDto>().contactId, contact?.contactId);
            }}
            resetOnUndefined={selectedContact?.contactId.toString()}
            w="100%"
          />
          <AddButton onClick={() => openNewContactModal()} variant="outline" />
        </Group>
      </LoaderBoundary>
      <ContactEntry contact={selectedContact} label="Kontakt" required />
      {printNotesIfAvailable(selectedContact?.note)}
      <Textarea
        {...inputPropsInvoiceAddress}
        autosize
        data-1p-ignore
        label="Rechnungsadresse"
        minRows={4}
        placeholder="Geben Sie die Rechnungsadresse ein"
        required
      />
      <Checkbox
        label="Abweichende Lieferadresse"
        {...inputPropsDeliveryAddressDifferent}
        onChange={(event) => {
          onDeliveryAddressDifferentChange(event);
          inputPropsDeliveryAddressDifferent.onChange(event);
        }}
      />
      {form.values.deliveryAddressDifferent ? (
        <Textarea
          {...inputPropsDeliveryAddress}
          autosize
          data-1p-ignore
          label="Lieferadresse"
          minRows={4}
          placeholder="Geben Sie die Lieferadresse ein"
          required={form.values.deliveryAddressDifferent}
          value={form.values.deliveryAddress ?? undefined}
        />
      ) : null}

      <CreateContactModal
        contactIdCallback={setSelectedContactId}
        onClose={closeNewContactModal}
        opened={newContactModalOpened}
      />
    </Stack>
  );
}
