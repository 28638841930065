export function useErrorMessageGenerator() {
  let formatter: Intl.ListFormat | null = null;

  return (ids: string[], singularMessage: string, pluralMessage: string, placeholder?: string): string => {
    if (!formatter) {
      formatter = new Intl.ListFormat("de", { style: "short", type: "conjunction" });
    }

    if (ids.length === 0) {
      return "";
    } // no error

    const formattedIds = formatter.format(ids);
    const message = ids.length === 1 ? singularMessage : pluralMessage;

    return placeholder ? message.replace(placeholder, formattedIds) : `${message} ${formattedIds}`;
  };
}
