import type { BoxProps, MantineSize } from "@mantine/core";
import type { UseQueryResult } from "@tanstack/react-query";
import type React from "react";

import { ErrorIndicator } from "./ErrorIndicator";
import { LoadingIndicator } from "./LoadingIndicator";

export interface QueryLoaderBoundaryProps<T> extends BoxProps {
  queryResult: UseQueryResult<T>;
  size?: MantineSize;
  children: ((data: T, error: Error | null) => React.JSX.Element | React.JSX.Element[]) | React.JSX.Element;
}

export function QueryLoaderBoundary<T>({
  queryResult,
  size = "md",
  children,
  ...boxProps
}: QueryLoaderBoundaryProps<T>): React.JSX.Element | React.JSX.Element[] {
  const { isPending, isError, data, error } = queryResult;

  if (isPending) {
    return <LoadingIndicator loaderSize={size} {...boxProps} />;
  }

  if (isError) {
    return <ErrorIndicator error={error} />;
  }

  // Check if children is a function before calling it
  if (typeof children === "function") {
    return children(data, error);
  }

  // If it's not a function, return it directly
  return children;
}
