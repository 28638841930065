import { NumberFormatter } from "@mantine/core";

import { translateUnitOfMeasurement } from "../../../common/Dictionary";
import { EditTable } from "../../../components/table/edit-table/EditTable";
import type { EditTableHeader, EditTableRow } from "../../../components/table/edit-table/EditTableTypes";
import type {
  UnitOfMeasurement,
  ContainerTypeListResponseDto,
  ContainerTypeResponseDto,
} from "../../../generated/client/octalog-service";

import { CreateNewContainerTypeRow } from "./CreateNewContainerTypeRow";
import { EditContainerTypeModal } from "./EditContainerTypeModal";

interface ContainerTypeTableProps {
  data: ContainerTypeListResponseDto;
}

export function ContainerTypeTable({ data }: ContainerTypeTableProps) {
  const headers: EditTableHeader[] = [
    { id: "header-name-container-type", label: "Name" },
    { id: "header-volume-container-type", label: "Volumen" },
    { id: "header-unitOfMeasurement-container-type", label: "Maßeinheit" },
    { id: "header-actions-container-type", label: "" },
  ];

  const rows: EditTableRow[] = data.containerTypes.map((containerType: ContainerTypeResponseDto) => ({
    id: containerType.containerTypeId.toString(),
    columns: [
      {
        id: "name-container-type",
        label: containerType.name,
      },
      {
        id: "volume-container-type",
        label: <NumberFormatter decimalScale={2} fixedDecimalScale value={containerType.volume} />,
      },
      {
        id: "unitOfMeasurement-container-type",
        label: translateUnitOfMeasurement(containerType.unitOfMeasurement as UnitOfMeasurement, true),
      },
    ],
    editColumn: <EditContainerTypeModal containerTypeDto={containerType} />,
  }));

  return (
    <EditTable
      createRow={<CreateNewContainerTypeRow />}
      headers={headers}
      rows={rows}
      testId="container-type-settings-table"
    />
  );
}
