export const WEEKDAYS = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

export const HOURS_PER_DAY = 24;

export const ROWS_PER_HOUR = 4;
export const ROWS_PER_DAY = HOURS_PER_DAY * ROWS_PER_HOUR; // 24 hours * 4 slots per hour

export const MINUTES_PER_ROW = 15;

export const TOTAL_COLUMN_COUNT = WEEKDAYS.length + 1; // +1 for the time column
export const TOTAL_ROW_COUNT = ROWS_PER_DAY;

export const ROW_HEIGHT_PX = 12;

export const PX_PER_MINUTE = ROW_HEIGHT_PX / MINUTES_PER_ROW;
