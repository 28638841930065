/* eslint-disable @typescript-eslint/no-empty-function --- it's intended to disable logging */

import { Environment } from "../common/Constants";

import { ENVIRONMENT } from "./environment";

export function disableLoggingInProduction() {
  if (ENVIRONMENT === Environment.PRODUCTION) {
    console.log = function () {};
    console.group = function () {};
    console.groupEnd = function () {};
  }
}
