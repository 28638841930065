import { Text } from "@mantine/core";
import { map } from "lodash-es";
import type React from "react";

interface EditTableDetailsProps {
  data: React.ReactNode[];
}

export function StandardTableDetails({ data }: EditTableDetailsProps) {
  return (
    <>
      {map(data, (value, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Text size="sm" truncate="end">
            {value}
          </Text>
        </div>
      ))}
    </>
  );
}
