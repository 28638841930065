import type { MantineSize } from "@mantine/core";
import { Badge } from "@mantine/core";

import { translateOrderStatus } from "../../../../common/Dictionary";
import type { OrderStatus } from "../../../../generated/client/octalog-service";

const STATUS_COLORS: Record<OrderStatus, string> = {
  NEW: "blue",
  SCHEDULED: "yellow",
  IN_DELIVERY: "orange",
  DELIVERED: "green",
  CANCELED: "red",
  UNSCHEDULED: "gray",
  RESCHEDULED: "violet",
};

interface OrderStatusBadgeProps {
  status: OrderStatus;
  size?: MantineSize;
}

export function OrderStatusBadge({ status, size = "xs" }: OrderStatusBadgeProps) {
  return (
    <Badge color={STATUS_COLORS[status]} size={size} variant="filled">
      {translateOrderStatus(status)}
    </Badge>
  );
}
