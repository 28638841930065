import type { TextProps } from "@mantine/core";
import { Text } from "@mantine/core";

import type { ContactResponseDto } from "../../../generated/client/octalog-service";
import { getContactName } from "../../../views/contacts/list/contactUtil";

interface ContactNameProps extends TextProps {
  contact: ContactResponseDto;
  withSalutation?: boolean;
}

export function ContactName({ contact, withSalutation = true }: ContactNameProps) {
  return <Text truncate="end">{getContactName(contact, withSalutation)}</Text>;
}
