import type { SegmentedControlProps } from "@mantine/core";
import { SegmentedControl } from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";
import { get } from "lodash-es";
import type { _TransformValues } from "octalog-base";
import React from "react";

import type { NestedKeyOf } from "../../../common/Utils";

interface SegmentedControlInputProps<Values extends object, TransformValues extends _TransformValues<Values>>
  extends Omit<SegmentedControlProps, "value"> {
  form: UseFormReturnType<Values, TransformValues>;
  formKey: NestedKeyOf<Values>;
}

export function SegmentedControlInput<Values extends object, TransformValues extends _TransformValues<Values>>({
  form,
  formKey,
  ...props
}: SegmentedControlInputProps<Values, TransformValues>): React.JSX.Element {
  return (
    <SegmentedControl
      {...form.getInputProps(formKey)}
      {...props}
      value={`${get(form.values, formKey as keyof Values)}`}
    />
  );
}
