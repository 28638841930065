import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/min";

import { trimRecursively } from "../../../../common/Utils";
import type {
  CompanyContactCreateDto,
  ContactCreateDto,
  CreateAddressDto,
  CreateEmailDto,
  IndividualContactCreateDto,
} from "../../../../generated/client/octalog-service";
import { ContactType } from "../../../../generated/client/octalog-service";
import { SalutationType } from "../contact/individual/salutation/SalutationType";
import { ContactIdentifierType } from "../identifier/ContactIdentifierType";

import type { ContactData, FormPhoneNumber, IndividualContactData } from "./contactFormContext";

export function transformValues(values: ContactData): ContactCreateDto {
  const trimmedValues = trimRecursively<ContactData>(values);
  if (!trimmedValues) {
    throw new Error("Contact data may not be null");
  }
  return {
    ...trimmedValues,
    contactType: values.contactType,
    individualContact:
      values.contactType === ContactType.INDIVIDUAL
        ? transformIndividualContact(trimmedValues.individualContact)
        : undefined,
    companyContact:
      values.contactType === ContactType.COMPANY ? transformCompanyContact(trimmedValues.companyContact) : undefined,
    contactIdentifierCustomer:
      values.selectedContactIdentifier === ContactIdentifierType.CUSTOMER ||
      values.selectedContactIdentifier === ContactIdentifierType.CUSTOMER_SUPPLIER
        ? trimmedValues.contactIdentifierCustomer
        : undefined,
    contactIdentifierSupplier:
      values.selectedContactIdentifier === ContactIdentifierType.SUPPLIER ||
      values.selectedContactIdentifier === ContactIdentifierType.CUSTOMER_SUPPLIER
        ? trimmedValues.contactIdentifierSupplier
        : undefined,
    note: trimmedValues.note,
    addresses:
      trimmedValues.addresses.map((address: CreateAddressDto) => {
        return {
          ...address,
          addition: address.addition,
        };
      }) ?? [],
    emails:
      trimmedValues.emails.map((email: CreateEmailDto) => {
        return {
          ...email,
          email: email.email,
        };
      }) ?? [],
    phoneNumbers:
      trimmedValues.phoneNumbers.map((phoneNumber: FormPhoneNumber) => {
        return isPossiblePhoneNumber(phoneNumber.phoneNumber ?? "", phoneNumber.countryCode) &&
          isValidPhoneNumber(phoneNumber.phoneNumber ?? "", phoneNumber.countryCode)
          ? {
              ...phoneNumber,
              phoneNumber: parsePhoneNumber(
                phoneNumber.phoneNumber ?? "",
                phoneNumber.countryCode,
              ).formatInternational(),
            }
          : {
              ...phoneNumber,
              phoneNumber: phoneNumber.phoneNumber,
            };
      }) ?? [],
  };
}

function transformIndividualContact(individualContact?: IndividualContactData): IndividualContactCreateDto {
  if (!individualContact) {
    throw new Error("Individual contact data may not be null");
  }
  const { salutationType, ...rest } = individualContact;
  return {
    ...rest,
    firstName: individualContact.firstName ?? undefined,
    lastName: individualContact.lastName ?? "",
    salutation: transformSalutation(salutationType, individualContact.salutation),
  };
}

function transformSalutation(
  salutationType: SalutationType | undefined,
  salutation: string | undefined,
): string | undefined {
  if (salutationType === SalutationType.NONE) {
    return undefined;
  } else if (salutationType === SalutationType.OTHER) {
    return salutation ?? undefined;
  } else {
    return salutationType;
  }
}

function transformCompanyContact(companyContact?: CompanyContactCreateDto): CompanyContactCreateDto {
  if (!companyContact) {
    throw new Error("Company contact data may not be null");
  }
  return {
    ...companyContact,
  };
}
