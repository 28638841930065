import { useEffect, useState } from "react";

import { LocalDateInternal } from "../../../../../../../common/calendar/LocalDateInternal";
import { LocalTimeInternal } from "../../../../../../../common/calendar/LocalTimeInternal";

export function useCalendarTime() {
  const [currentTime, setCurrentTime] = useState(LocalTimeInternal.now());
  const [currentWeek, setCurrentWeek] = useState(LocalDateInternal.today());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(LocalTimeInternal.now()), 60_000);
    return () => clearInterval(timer);
  }, []);

  const weekStart = LocalDateInternal.weekStart(currentWeek);
  const weekEnd = LocalDateInternal.weekEnd(currentWeek);

  const handlePreviousWeek = () => {
    setCurrentWeek(currentWeek.subtractWeeks(1));
  };

  const handleNextWeek = () => {
    setCurrentWeek(currentWeek.addWeeks(1));
  };

  return {
    currentTime,
    currentWeek,
    weekStart,
    weekEnd,
    handlePreviousWeek,
    handleNextWeek,
  };
}
