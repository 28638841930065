// Converted from https://github.com/tolu/ISO8601-duration/blob/master/src/index.ts

// PnYnMnWnDTnHnMnS
const numbers = String.raw`\d+`;
const fractionalNumbers = `${numbers}(?:[\\.,]${numbers})?`;
const datePattern = `(${numbers}Y)?(${numbers}M)?(${numbers}W)?(${numbers}D)?`;
const timePattern = `T(${fractionalNumbers}H)?(${fractionalNumbers}M)?(${fractionalNumbers}S)?`;

const iso8601 = `P(?:${datePattern}(?:${timePattern})?)`;

/**
 * The ISO8601 regex for matching / testing durations
 */
export const pattern = new RegExp(iso8601);

export class Duration {
  readonly years: number;
  readonly months: number;
  readonly weeks: number;
  readonly days: number;
  readonly hours: number;
  readonly minutes: number;
  readonly seconds: number;

  constructor(years = 0, months = 0, weeks = 0, days = 0, hours = 0, minutes = 0, seconds = 0) {
    this.years = years;
    this.months = months;
    this.weeks = weeks;
    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  /** Parse PnYnMnDTnHnMnS format to object */
  public static parse(durationString: string): Duration {
    const matches = durationString.replaceAll(",", ".").match(pattern);
    if (!matches) {
      throw new RangeError(`invalid duration: ${durationString}`);
    }
    // Slice away first entry in the match-array (the input string)
    const slicedMatches: (string | undefined)[] = matches.slice(1);
    if (slicedMatches.filter((v) => v != null).length === 0) {
      throw new RangeError(`invalid duration: ${durationString}`);
    }
    // Check only one fraction is used
    if (slicedMatches.filter((v) => /\./.test(v || "")).length > 1) {
      throw new RangeError("only the smallest unit can be fractional");
    }

    const years = Number.parseFloat(slicedMatches[0] || "0") || 0;
    const months = Number.parseFloat(slicedMatches[1] || "0") || 0;
    const weeks = Number.parseFloat(slicedMatches[2] || "0") || 0;
    const days = Number.parseFloat(slicedMatches[3] || "0") || 0;
    const hours = Number.parseFloat(slicedMatches[4] || "0") || 0;
    const minutes = Number.parseFloat(slicedMatches[5] || "0") || 0;
    const seconds = Number.parseFloat(slicedMatches[6] || "0") || 0;

    return new Duration(years, months, weeks, days, hours, minutes, seconds);
  }

  /**
   * Converts the duration to human-readable format
   * @returns Human readable duration (e.g., "1h 30m", "45m", "3h")
   */
  public humanize(): string {
    if (this.hours === 0) {
      return `${this.minutes}m`;
    }

    if (this.minutes === 0) {
      return `${this.hours}h`;
    }

    return `${this.hours}h ${this.minutes}m`;
  }

  public toMinutes(): number {
    return (
      this.years * 525_600 +
      this.months * 43_800 +
      this.weeks * 10_080 +
      this.days * 1440 +
      this.hours * 60 +
      this.minutes +
      this.seconds / 60
    );
  }
}
