import { QueryLoaderBoundary } from "../../../components/loader/QueryLoaderBoundary";
import { useGetContainerTypesQuery } from "../../../service/containers/ContainerTypeHooks";

import { ContainerTypeTable } from "./ContainerTypeTable";

export function ContainerTypeSettings() {
  const containerTypes = useGetContainerTypesQuery();

  return (
    <QueryLoaderBoundary my={20} queryResult={containerTypes}>
      {(data) => ContainerTypeTable({ data })}
    </QueryLoaderBoundary>
  );
}
