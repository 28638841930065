import { Grid, Text } from "@mantine/core";

import { nameOf } from "../../../../common/Utils";
import { FloatingLabelNumericFormat } from "../../../../components/input/FloatingLabelInput/FloatingLabelNumericFormat";
import type { CreateContactIdentifierCustomerDto } from "../../../../generated/client/octalog-service";
import type { ContactData } from "../common/contactFormContext";
import { useCreateContactContext } from "../common/contactFormContext";

export function InputIdentifierCustomer() {
  const form = useCreateContactContext();
  return (
    <>
      <Grid.Col span={12}>
        <Text>Kundeninformationen</Text>
      </Grid.Col>
      <Grid.Col span={12}>
        <FloatingLabelNumericFormat
          allowNegative={false}
          decimalScale={0}
          label="Kunden-Nr. (wird automatisch erzeugt)"
          {...form.getInputProps(
            `${nameOf<ContactData>().contactIdentifierCustomer}.${nameOf<CreateContactIdentifierCustomerDto>().customerId}`,
          )}
          value={form.values.contactIdentifierCustomer?.customerId ?? ""}
        />
      </Grid.Col>
    </>
  );
}
