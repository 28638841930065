import type { PaperProps } from "@mantine/core";
import { Center, Menu, Button, Paper, Text } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import type { IconType } from "octalog-base";
import React from "react";

import type { FormListItemProps } from "./FormListItem";

export interface FormListMenuItem {
  key: string;
  Icon?: IconType;
  label: string;
  onClick: () => void;
}

export interface FormListProps extends Omit<PaperProps, "children"> {
  label: string;
  menuItems?: FormListMenuItem[];
  children: React.ReactElement<FormListItemProps> | React.ReactElement<FormListItemProps>[];
}

export function FormList({ label, menuItems, children, ...rest }: FormListProps) {
  return (
    <Paper p={10} withBorder {...rest}>
      {children}
      <Menu width={200}>
        <Menu.Target>
          <Center>
            <Button pr={12} rightSection={<IconCirclePlus size={20} />} variant="transparent">
              {label} hinzufügen
            </Button>
          </Center>
        </Menu.Target>
        <Menu.Dropdown>
          {menuItems?.map((item) => (
            <Menu.Item
              key={item.key}
              leftSection={item.Icon ? <item.Icon size={18} stroke={1.5} /> : null}
              onClick={() => item.onClick()}
            >
              <Text fw={700} size="sm">
                {item.label}
              </Text>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Paper>
  );
}
