import { Navigate } from "react-router";

import { useSignOutMutation } from "../../service/iam/IamHooks";

import { AuthContainer } from "./components/AuthContainer";
import { AuthLoadingState } from "./components/AuthLoadingState";

export function SignOut() {
  const signOut = useSignOutMutation();

  if (!signOut.isPending && !signOut.isSuccess && !signOut.isError) {
    signOut.mutate({});
  }

  return (
    <AuthContainer>
      {signOut.isPending ? <AuthLoadingState title="Abmeldung läuft" /> : <Navigate to="/sign-in" />}
    </AuthContainer>
  );
}
