import type { ComboboxParsedItem } from "@mantine/core";
import { isOptionsGroup } from "@mantine/core";

interface FilterPickedTagsInput {
  data: ComboboxParsedItem[];
  value: string[];
}

export function filterPickedTags({ data, value }: FilterPickedTagsInput) {
  const normalizedValue = new Set(value.map((item) => item.trim().toLowerCase()));

  // eslint-disable-next-line unicorn/no-array-reduce -- TODO: Refactor
  return data.reduce<ComboboxParsedItem[]>((acc, item) => {
    if (isOptionsGroup(item)) {
      acc.push({
        group: item.group,
        items: item.items.filter((option) => !normalizedValue.has(option.label.toLowerCase().trim())),
      });
    } else if (!normalizedValue.has(item.label.toLowerCase().trim())) {
      acc.push(item);
    }

    return acc;
  }, []);
}
