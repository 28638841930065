import { IconUsers } from "@tabler/icons-react";
import { RootContainer } from "octalog-base";

import { IconPaper } from "../../../components/paper/IconPaper";

import { ContactList } from "./ContactList";

export function ContactListView() {
  return (
    <RootContainer fluid>
      <IconPaper icon={IconUsers} title="Kontakte">
        <ContactList />
      </IconPaper>
    </RootContainer>
  );
}
