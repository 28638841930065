import { Grid } from "@mantine/core";

import { nameOf } from "../../../../common/Utils";
import { FloatingLabelTextarea } from "../../../../components/input/FloatingLabelInput/FloatingLabelTextarea";
import type { ContactData } from "../common/contactFormContext";
import { useCreateContactContext } from "../common/contactFormContext";

export function InputNote() {
  const form = useCreateContactContext();

  return (
    <Grid.Col span={12}>
      <FloatingLabelTextarea
        autosize
        label="Notiz"
        {...form.getInputProps(nameOf<ContactData>().note)}
        value={form.values.note ?? ""}
      />
    </Grid.Col>
  );
}
