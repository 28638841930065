import type { CSSProperties, NumberInputStylesNames } from "@mantine/core";
import { Alert } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";

import type { NestedKeyOf } from "../../../../../common/Utils";
import type { DecimalScale2InputProps } from "../../../../../components/input/number/DecimalScale2Input";
import { PriceInput } from "../../../../../components/input/number/PriceInput";
import { QueryLoaderBoundary } from "../../../../../components/loader/QueryLoaderBoundary";
import type { PriceContainerDto } from "../../../../../generated/client/octalog-service";
import { useGetPrices } from "../../../../../service/prices/useGetPrices";
import type { OrderFormData } from "../contexts/FormContexts";
import { useOrderItems } from "../contexts/FormContexts";
import type { OrderForm } from "../hooks/useOrderForm";

import { usePriceCalculation } from "./usePriceCalculation";

interface PriceProps extends Omit<DecimalScale2InputProps, "form"> {
  priceData: PriceContainerDto;
  woodTypeId?: number;
  humidityLevelId?: number;
  cuttingLengthId?: number;
  volume?: number;
  form: OrderForm;
  formKey: NestedKeyOf<OrderFormData>;
}

function PriceCalculated({
  priceData,
  woodTypeId,
  humidityLevelId,
  cuttingLengthId,
  volume,
  form,
  formKey,
  ...rest
}: PriceProps) {
  const commonColors = useCommonColors();
  const { price, requireManualPrice, description } = usePriceCalculation({
    priceData,
    woodTypeId,
    humidityLevelId,
    cuttingLengthId,
    volume,
  });

  const style: Partial<Record<NumberInputStylesNames, CSSProperties>> = requireManualPrice
    ? { input: { textAlign: "center" } }
    : { input: { textAlign: "center", background: "transparent", color: commonColors.text, opacity: 1 } };

  return (
    <PriceInput
      {...form.getInputProps(formKey)}
      {...rest}
      description={description}
      disabled={!requireManualPrice}
      externalValue={price}
      styles={style}
    />
  );
}

function PricePreset({
  priceData,
  woodTypeId,
  humidityLevelId,
  cuttingLengthId,
  volume,
  form,
  formKey,
  ...rest
}: PriceProps) {
  const { price, requireManualPrice, description } = usePriceCalculation({
    priceData,
    woodTypeId,
    humidityLevelId,
    cuttingLengthId,
    volume,
  });

  const style: Partial<Record<NumberInputStylesNames, CSSProperties>> = { input: { textAlign: "center" } };

  const alert =
    (form.getInputProps(formKey).value !== price && !requireManualPrice && (
      <Alert color="yellow" icon={<IconInfoCircle />} title="Preisabweichung" variant="light">
        Der gespeicherte Preis weicht vom automatisch berechneten Preis ab.
        <br />
        <br />
        Automatisch berechneter Preis: <strong>{price?.toFixed(2)}€</strong>
        <br />
        Berechnungsgrundlage: <strong>{description}</strong>
      </Alert>
    )) ||
    null;

  return <PriceInput {...form.getInputProps(formKey)} {...rest} description={alert ?? description} styles={style} />;
}

interface CalculatedPriceProps {
  woodTypeId?: number;
  humidityLevelId?: number;
  cuttingLengthId?: number;
  volume?: number;
  formKey: NestedKeyOf<OrderFormData>;
  usePreset?: boolean;
}

export function CalculatedWoodPrice({
  woodTypeId,
  humidityLevelId,
  cuttingLengthId,
  volume,
  formKey,
  usePreset = false,
}: CalculatedPriceProps) {
  const { form } = useOrderItems();
  const priceData = useGetPrices();

  function renderPrice(priceData: PriceContainerDto) {
    return usePreset ? (
      <PricePreset
        cuttingLengthId={cuttingLengthId}
        form={form}
        formKey={formKey}
        humidityLevelId={humidityLevelId}
        priceData={priceData}
        volume={volume}
        woodTypeId={woodTypeId}
      />
    ) : (
      <PriceCalculated
        cuttingLengthId={cuttingLengthId}
        form={form}
        formKey={formKey}
        humidityLevelId={humidityLevelId}
        priceData={priceData}
        volume={volume}
        woodTypeId={woodTypeId}
      />
    );
  }

  return <QueryLoaderBoundary queryResult={priceData}>{(data) => renderPrice(data)}</QueryLoaderBoundary>;
}
