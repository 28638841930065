import { Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import type { ContactData } from "../../../views/contacts/new/common/contactFormContext";
import { useContactForm } from "../../../views/contacts/new/common/useContactForm";
import { ContactForm } from "../../../views/contacts/new/ContactForm";

interface CreateContactModalProps {
  opened: boolean;
  onClose: () => void;
  contactIdCallback: (contactId: number) => void;
}

export function CreateContactModal({ opened, onClose, contactIdCallback }: CreateContactModalProps) {
  const { form, handleSubmit } = useContactForm();
  const isMobile = useMediaQuery("(max-width: 600px)");

  function onSubmit(values: ContactData) {
    handleSubmit(values).then((contactId) => {
      if (contactId) {
        contactIdCallback(contactId);
      }
      onClose();
      form.reset();
    });
  }

  return (
    <Modal fullScreen={isMobile} onClose={onClose} opened={opened} size="auto">
      <ContactForm form={form} onSubmit={onSubmit} />
    </Modal>
  );
}
