import type { UseFormReturnType } from "@mantine/form";
import { useForm } from "@mantine/form";

import type { GetContainerResponseDto, UpdateContainerRequestDto } from "../../../generated/client/octalog-service";

export interface UpdateContainerFormData {
  containerId: number;
  container: UpdateContainerRequestDto;
}

export const useEditContainerForm = (
  containerDto: GetContainerResponseDto,
): UseFormReturnType<UpdateContainerFormData, (values: UpdateContainerFormData) => UpdateContainerFormData> => {
  return useForm<UpdateContainerFormData>({
    initialValues: {
      containerId: containerDto.containerId,
      container: {
        containerName: containerDto.containerName,
        containerType: {
          containerTypeId: containerDto.containerType.containerTypeId,
          adjustLevel: true,
        },
        content: containerDto.content
          ? {
              woodType: containerDto.content.woodType,
              cuttingLength: containerDto.content.cuttingLength,
              humidityLevel: containerDto.content.humidityLevel,
            }
          : undefined,
        areaId: containerDto.area?.areaId,
      },
    },
    validate: {
      container: {
        containerName: (value) => {
          if (!value) {
            return "Bitte geben Sie einen Namen ein";
          }
        },
      },
    },
    validateInputOnBlur: true,
  });
};
