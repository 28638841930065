import { useSessionStorage } from "@mantine/hooks";
import type React from "react";

import { DebugContext } from "./DebugContext";

interface DebugProviderProps {
  children: React.ReactNode;
}

export function DebugProvider({ children }: DebugProviderProps) {
  const [showJson, setShowJson] = useSessionStorage<boolean>({
    key: "show-json",
    defaultValue: false,
  });

  return <DebugContext.Provider value={{ showJson, setShowJson }}>{children}</DebugContext.Provider>;
}
