import type { SelectProps } from "@mantine/core";
import { Select } from "@mantine/core";
import { useEffect } from "react";

import { areasToComboBoxData } from "../../../../common/mantine/ComboboxUtils";
import { useGetAreasQuery } from "../../../../hooks/areas/useAreaHooks";
import { QueryLoaderBoundary } from "../../../loader/QueryLoaderBoundary";

interface ContainerTypeSelectionProps extends SelectProps {
  setInitialValueCallback?: (containerTypeId: number | undefined) => void;
}

export function AreaSelection({ setInitialValueCallback, value, ...rest }: ContainerTypeSelectionProps) {
  const areas = useGetAreasQuery();

  useEffect(() => {
    if (areas.data) {
      setInitialValueCallback?.(areas.data.areas[0]?.areaId ?? undefined);
    }
  }, [areas.data, setInitialValueCallback]);

  return (
    <QueryLoaderBoundary mt={15} queryResult={areas} size="sm">
      {(data, error) => (
        <Select
          {...rest}
          allowDeselect={false}
          data={areasToComboBoxData(data?.areas)}
          data-testid="area-select"
          error={
            error || data?.areas.length === 0
              ? "Es existiert noch kein Lagerplatz. Bitte in den Einstellungen anlegen."
              : undefined
          }
          label="Lagerplatz"
          placeholder="Lagerplatz auswählen…"
          value={value?.toString() ?? value}
        />
      )}
    </QueryLoaderBoundary>
  );
}
