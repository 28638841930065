import type { SelectProps } from "@mantine/core";
import { Select } from "@mantine/core";
import { useEffect } from "react";

import { containerTypesToComboBoxData } from "../../../../common/mantine/ComboboxUtils";
import { useGetContainerTypesQuery } from "../../../../service/containers/ContainerTypeHooks";
import { QueryLoaderBoundary } from "../../../loader/QueryLoaderBoundary";

interface ContainerTypeSelectionProps extends SelectProps {
  setInitialValueCallback?: (containerTypeId: number | undefined) => void;
}

export function ContainerTypeSelection({ setInitialValueCallback, value, ...rest }: ContainerTypeSelectionProps) {
  const containerTypes = useGetContainerTypesQuery();

  useEffect(() => {
    if (containerTypes.data) {
      setInitialValueCallback?.(containerTypes.data.containerTypes[0]?.containerTypeId ?? undefined);
    }
  }, [containerTypes.data, setInitialValueCallback]);

  return (
    <QueryLoaderBoundary mt={15} queryResult={containerTypes} size="sm">
      {(data) => (
        <Select
          {...rest}
          allowDeselect={false}
          data={containerTypesToComboBoxData(data?.containerTypes)}
          data-testid="container-type-select"
          error={
            data?.containerTypes.length === 0
              ? "Es existiert noch kein Behältertyp. Bitte in den Einstellungen anlegen."
              : undefined
          }
          label="Behältertyp"
          placeholder="Behältertyp auswählen…"
          value={value?.toString() ?? value}
        />
      )}
    </QueryLoaderBoundary>
  );
}
