import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { Paper, Select, Stack, MultiSelect, ScrollArea } from "@mantine/core";
import clsx from "clsx";
import { useEffect, useRef, useState, memo } from "react";

import { QueryLoaderBoundary } from "../../../../../../components/loader/QueryLoaderBoundary";
import { useGetOrders } from "../../../../query/useGetOrders";
import { DestinationId } from "../../hooks/useDeliveryBoxDrop";
import { DeliveryBox } from "../DeliveryBox/DeliveryBox";

import type { StatusFilterValue, TimeFilterValue } from "./hooks/useOrderFilters";
import { useOrderFilters, STATUS_FILTER_OPTIONS, TIME_FILTER_OPTIONS } from "./hooks/useOrderFilters";
import classes from "./OrderList.module.css";

export const OrderList = memo(function OrderList() {
  const ordersQuery = useGetOrders();
  const { statusFilter, setStatusFilter, timeFilter, setTimeFilter, filterOrders } = useOrderFilters();

  const handleStatusFilterChange = (value: string[]) => {
    setStatusFilter(value as StatusFilterValue[]);
  };

  const handleTimeFilterChange = (value: string | null) => {
    setTimeFilter(value as TimeFilterValue | null);
  };

  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) return; // Early return if ref isn't attached yet

    return dropTargetForElements({
      element: el,
      getData: () => ({ destinationId: DestinationId.orderList }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    });
  }, [ordersQuery.data?.data]);

  return (
    <Paper className={classes.container} h="100%" p="md" withBorder>
      <Stack className={classes.filters}>
        <MultiSelect
          clearable
          data={STATUS_FILTER_OPTIONS}
          label="Status"
          multiple
          onChange={handleStatusFilterChange}
          placeholder={statusFilter.isEmpty() ? "Alle Status" : undefined}
          value={statusFilter}
        />
        <Select
          data={TIME_FILTER_OPTIONS}
          label="Zeitraum"
          onChange={handleTimeFilterChange}
          placeholder="Alle Zeiträume"
          value={timeFilter}
        />
      </Stack>

      <ScrollArea p="md">
        <QueryLoaderBoundary mt="50vh" queryResult={ordersQuery}>
          {(response) => {
            const allOrders = response.data;
            // Filter out orders that are already scheduled
            const unscheduledOrders = allOrders.filter((order) => !order.deliverySchedule);
            const filteredOrders = filterOrders(unscheduledOrders);

            return (
              <div
                className={clsx(classes.dropContainer, {
                  [classes.isDraggingOver as string]: isDraggedOver,
                })}
                ref={ref}
                style={{ minHeight: 400 }}
              >
                {filteredOrders.map((order) => (
                  <div className={classes.orderRow} key={order.orderNumber}>
                    <DeliveryBox order={order} />
                  </div>
                ))}
              </div>
            );
          }}
        </QueryLoaderBoundary>
      </ScrollArea>
    </Paper>
  );
});
