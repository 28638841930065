import { useMemo } from "react";

import { translateUntyped } from "../../common/Dictionary";

import { useGetWoodTypes } from "./useGetWoodTypes";

interface WoodType {
  woodTypeId: number;
  name: string;
}

export const useWoodTypeIdTranslator = (): ((woodTypeId: number) => string | number) => {
  const woodTypes = useGetWoodTypes();

  return useMemo(() => {
    return (woodTypeId: number): string | number => {
      if (woodTypes.isLoading) {
        return woodTypeId;
      }

      if (woodTypes.isError) {
        return `Error: ${woodTypes.error.message}`;
      }

      const woodType: WoodType | undefined = woodTypes.data?.woodTypes.find(
        (type: WoodType) => type.woodTypeId === woodTypeId,
      );

      return translateUntyped(woodType?.name) ?? woodTypeId;
    };
  }, [woodTypes]);
};
