// Each hour has 4 slots (15 min intervals)
import type { LocalDateInternal } from "../../../../../../../../common/calendar/LocalDateInternal";
import { LocalTimeInternal } from "../../../../../../../../common/calendar/LocalTimeInternal";
import { ROWS_PER_HOUR, MINUTES_PER_ROW } from "../../common/constants";

/**
 * Converts a row index to a time representation in hours and minutes.
 *
 * The calculation is based on dividing the row index by the number of rows
 * per hour to determine the hours, and using the remainder to calculate the
 * additional minutes.
 *
 * @param rowIndex - The index of the time slot to convert.
 * @returns A LocalTimeInternal object representing the time in hours and minutes.
 */
export function getTimeOfRowIndex(rowIndex: number): LocalTimeInternal {
  const hours = Math.floor(rowIndex / ROWS_PER_HOUR);
  const minutes = (rowIndex % ROWS_PER_HOUR) * MINUTES_PER_ROW;
  return new LocalTimeInternal(hours, minutes);
}

export function getIndicesOfDateAndTime(
  deliveryDate: LocalDateInternal,
  deliveryTime: LocalTimeInternal,
): {
  columnIndex: number;
  rowIndex: number;
} {
  return {
    columnIndex: deliveryDate.dayOfWeek(),
    rowIndex: deliveryTime.hour! * ROWS_PER_HOUR + Math.floor(deliveryTime.minute / (60 / ROWS_PER_HOUR)),
  };
}
