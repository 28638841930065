import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import type { PaperProps } from "@mantine/core";
import { Center, Paper, Text } from "@mantine/core";
import clsx from "clsx";
import { useRef, useEffect, useState, useMemo } from "react";
import invariant from "tiny-invariant";

import { Duration } from "../../../../../../common/calendar/Duration";
import { instanceOf, nameOf } from "../../../../../../common/Utils";
import type { OrderDto, OrderStatus } from "../../../../../../generated/client/octalog-service";
import { OrderStatusBadge } from "../../../../components/OrderStatusBadge/OrderStatusBadge";
import { useDeliverySchedulingContext } from "../../context";
import type { DropSourceData } from "../../hooks/useDeliveryBoxDrop";
import type { ScheduledOrder } from "../../types/ScheduledOrder";
import { MINUTES_PER_ROW } from "../Calendar/common/constants";

import classes from "./DeliveryBox.module.css";

interface DeliveryBoxProps extends PaperProps {
  order: OrderDto | ScheduledOrder;
}

export function DeliveryBox({ order, ...rest }: DeliveryBoxProps) {
  const { selectedOrderNumber, setSelectedOrderNumber, detailPanelHandlers } = useDeliverySchedulingContext();

  if (!order) {
    throw new Error("DeliveryBox should not be rendered for an invalid order");
  }

  const handleClick = () => {
    setSelectedOrderNumber(order.orderNumber);
    detailPanelHandlers.open();
  };

  const isSelected = order.orderNumber === selectedOrderNumber;

  const deliveryAddress = (order.deliveryAddress ?? order.invoiceAddress)?.replace(/^Herr |^Frau /, "");

  const scheduledOrder: ScheduledOrder | undefined = instanceOf<ScheduledOrder>(
    order,
    nameOf<ScheduledOrder>().__isScheduledOrder,
  )
    ? (order as ScheduledOrder)
    : undefined;

  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);

  useEffect(() => {
    const el = ref.current;
    invariant(el);

    // noinspection JSUnusedGlobalSymbols
    return draggable({
      element: el,
      getInitialData: () =>
        ({
          orderNumber: order.orderNumber,
          duration: order.deliverySchedule?.duration ?? "PT1H",
        }) satisfies DropSourceData,
      onDragStart: () => setDragging(true),
      onDrop: () => setDragging(false),
    });
  }, [order]);

  const durationInRows = useMemo(() => {
    if (!scheduledOrder) {
      return undefined;
    }
    return Math.ceil(Duration.parse(scheduledOrder.deliverySchedule.duration).toMinutes() / MINUTES_PER_ROW);
  }, [scheduledOrder]);

  function getLineClamp() {
    if (durationInRows) {
      return durationInRows < 2 ? 1 : durationInRows / 2;
    }
    return 1;
  }

  return (
    <Paper
      className={clsx(classes.deliveryBox, {
        [classes.selected as string]: isSelected,
        [classes.dragging as string]: dragging,
      })}
      onClick={handleClick}
      style={{
        "--delivery-duration-slots": scheduledOrder ? durationInRows : 2,
      }}
      withBorder
      {...rest}
      ref={ref}
    >
      <Center h="100%" maw="100%">
        <Text fw={700} lineClamp={scheduledOrder ? getLineClamp() : 1} px="xs" size="xs">
          {deliveryAddress} {order.status ? <OrderStatusBadge status={order.status as OrderStatus} /> : null}
        </Text>
      </Center>
    </Paper>
  );
}
