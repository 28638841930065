// noinspection StructuralWrap
/* eslint-disable react/jsx-max-depth,@typescript-eslint/no-unused-expressions, react/no-array-index-key --- this file is copied from mantine and modified */

import type { Factory, PillFactory, Styles, TagsInputProps, TagsInputStylesNames } from "@mantine/core";
import {
  Combobox,
  extractStyleProps,
  factory,
  getOptionsLockup,
  getParsedComboboxData,
  Input,
  InputBase,
  Loader,
  OptionsDropdown,
  Pill,
  PillsInput,
  Space,
  useCombobox,
  useProps,
  useResolvedStylesApi,
  useStyles,
} from "@mantine/core";
import { useId, useMergedRef, useUncontrolled } from "@mantine/hooks";
import type React from "react";
import { useRef } from "react";

import { filterPickedTags } from "./filter-picked-tags";
import { getSplittedTags } from "./get-splitted-tags";

export interface StyleableTagsInputProps extends TagsInputProps {
  legend?: React.ReactNode;
  legendOnInput?: boolean;
  isPending?: boolean;
  styledValues?: Map<string, Styles<PillFactory>>;
}

export type StyleableTagsInputFactory = Factory<{
  props: StyleableTagsInputProps;
  ref: HTMLInputElement;
  stylesNames: TagsInputStylesNames;
}>;

const defaultProps: Partial<StyleableTagsInputProps> = {
  maxTags: Infinity,
  allowDuplicates: false,
  acceptValueOnBlur: true,
  splitChars: [","],
  hiddenInputValuesDivider: ",",
};

export const StyleableTagsInput = factory<StyleableTagsInputFactory>((_props, ref) => {
  const props = useProps("TagsInput", defaultProps, _props);
  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars --- this file is copied from mantine and modified
    vars,
    size,
    value,
    defaultValue,
    styledValues,
    onChange,
    onKeyDown,
    maxTags,
    allowDuplicates,
    onDuplicate,
    variant,
    data,
    dropdownOpened,
    defaultDropdownOpened,
    onDropdownOpen,
    onDropdownClose,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars --- this file is copied from mantine and modified
    selectFirstOptionOnChange,
    onOptionSubmit,
    comboboxProps,
    filter,
    limit,
    withScrollArea,
    maxDropdownHeight,
    searchValue,
    defaultSearchValue,
    onSearchChange,
    readOnly,
    disabled,
    splitChars,
    onFocus,
    onBlur,
    onPaste,
    radius,
    rightSection,
    rightSectionWidth,
    rightSectionPointerEvents,
    rightSectionProps,
    leftSection,
    leftSectionWidth,
    leftSectionPointerEvents,
    leftSectionProps,
    inputContainer,
    inputWrapperOrder,
    isPending,
    withAsterisk,
    required,
    labelProps,
    descriptionProps,
    errorProps,
    wrapperProps,
    description,
    label,
    error,
    withErrorStyles,
    name,
    form,
    id,
    clearable,
    clearButtonProps,
    hiddenInputProps,
    hiddenInputValuesDivider,
    mod,
    legend,
    legendOnInput,
    renderOption,
    onRemove,
    onClear,
    scrollAreaProps,
    acceptValueOnBlur,
    ...others
  } = props;

  const _id = useId(id);
  const parsedData = getParsedComboboxData(data);
  const optionsLockup = getOptionsLockup(parsedData);
  const inputRef = useRef<HTMLInputElement>(null);
  const _ref = useMergedRef(inputRef, ref);

  // noinspection JSUnusedGlobalSymbols
  const combobox = useCombobox({
    opened: dropdownOpened,
    defaultOpened: defaultDropdownOpened,
    onDropdownOpen,
    onDropdownClose: () => {
      onDropdownClose?.();
      combobox.resetSelectedOption();
    },
  });

  const {
    styleProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars --- this file is copied from mantine and modified
    rest: { type, autoComplete, ...rest },
  } = extractStyleProps(others);

  const [_value, setValue] = useUncontrolled({
    value,
    defaultValue,
    finalValue: [],
    onChange,
  });

  const [_searchValue, setSearchValue] = useUncontrolled({
    value: searchValue,
    defaultValue: defaultSearchValue,
    finalValue: "",
    onChange: onSearchChange,
  });

  const getStyles = useStyles<StyleableTagsInputFactory>({
    name: "TagsInput",
    classes: {} as never,
    props,
    classNames,
    styles,
    unstyled,
  });

  const { resolvedClassNames, resolvedStyles } = useResolvedStylesApi<StyleableTagsInputFactory>({
    props,
    styles,
    classNames,
  });

  const handleValueSelect = (val: string) => {
    const isDuplicate = _value.some((tag) => tag.toLowerCase() === val.toLowerCase());

    if (isDuplicate) {
      onDuplicate?.(val);
    }

    if ((!isDuplicate || (isDuplicate && allowDuplicates)) && _value.length < maxTags!) {
      onOptionSubmit?.(val);
      setSearchValue("");

      if (val.length > 0) {
        setValue([..._value, val]);
      }
    }
  };

  const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(event);

    const inputValue = _searchValue.trim();
    const { length } = inputValue;

    if (splitChars!.includes(event.key) && length > 0) {
      setValue(
        getSplittedTags({
          splitChars,
          allowDuplicates,
          maxTags,
          value: _searchValue,
          currentTags: _value,
        }),
      );
      setSearchValue("");
      event.preventDefault();
    }

    if (event.key === "Enter" && length > 0 && !event.nativeEvent.isComposing) {
      event.preventDefault();
      handleValueSelect(inputValue);
    }

    if (event.key === "Backspace" && length === 0 && _value.length > 0 && !event.nativeEvent.isComposing) {
      onRemove?.(_value.at(-1)!);
      setValue(_value.slice(0, -1));
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    onPaste?.(event);
    event.preventDefault();

    if (event.clipboardData) {
      const pastedText = event.clipboardData.getData("text/plain");
      setValue(
        getSplittedTags({
          splitChars,
          allowDuplicates,
          maxTags,
          value: `${_searchValue}${pastedText}`,
          currentTags: _value,
        }),
      );
      setSearchValue("");
    }
  };

  const values = _value.map((item, index) => {
    const styles = styledValues?.get(item);
    return (
      <Pill
        disabled={disabled}
        key={`${item}-${index}`}
        onRemove={() => {
          setValue(_value.filter((i) => item !== i));
          onRemove?.(item);
        }}
        styles={styles}
        unstyled={unstyled}
        withRemoveButton={!readOnly}
        {...getStyles("pill")}
      >
        {item}
      </Pill>
    );
  });

  const clearButton = clearable && _value.length > 0 && !disabled && !readOnly && (
    <Combobox.ClearButton
      size={size as string}
      {...clearButtonProps}
      onClear={() => {
        setValue([]);
        setSearchValue("");
        inputRef.current?.focus();
        combobox.openDropdown();
        onClear?.();
      }}
    />
  );

  return (
    <>
      <Combobox
        __staticSelector="TagsInput"
        classNames={resolvedClassNames}
        onOptionSubmit={(val) => {
          onOptionSubmit?.(val);
          setSearchValue("");

          _value.length < maxTags! && setValue([..._value, optionsLockup[val]!.label]);
        }}
        readOnly={readOnly}
        size={size}
        store={combobox}
        styles={resolvedStyles}
        unstyled={unstyled}
        {...comboboxProps}
      >
        <Combobox.DropdownTarget>
          <PillsInput
            {...styleProps}
            __staticSelector="TagsInput"
            __stylesApiProps={{ ...props, multiline: true }}
            className={className}
            classNames={resolvedClassNames}
            description={description}
            descriptionProps={descriptionProps}
            disabled={disabled}
            error={error}
            errorProps={errorProps}
            id={_id}
            inputContainer={inputContainer}
            inputWrapperOrder={inputWrapperOrder}
            label={label}
            labelProps={labelProps}
            leftSection={leftSection}
            leftSectionPointerEvents={leftSectionPointerEvents}
            leftSectionProps={leftSectionProps}
            leftSectionWidth={leftSectionWidth}
            mod={mod}
            multiline
            radius={radius}
            required={required}
            rightSection={rightSection || clearButton}
            rightSectionPointerEvents={rightSectionPointerEvents}
            rightSectionProps={rightSectionProps}
            rightSectionWidth={rightSectionWidth}
            size={size}
            style={style}
            styles={resolvedStyles}
            unstyled={unstyled}
            variant={variant}
            withAsterisk={withAsterisk}
            withErrorStyles={withErrorStyles}
            wrapperProps={wrapperProps}
          >
            <Pill.Group disabled={disabled} unstyled={unstyled} {...getStyles("pillsList")}>
              {values}
              <Combobox.EventsTarget autoComplete={autoComplete}>
                <PillsInput.Field
                  {...rest}
                  ref={_ref}
                  {...getStyles("inputField")}
                  disabled={disabled}
                  id={_id}
                  onBlur={(event) => {
                    onBlur?.(event);

                    acceptValueOnBlur && handleValueSelect(_searchValue);
                    combobox.closeDropdown();
                  }}
                  onChange={(event) => setSearchValue(event.currentTarget.value)}
                  onFocus={(event) => {
                    onFocus?.(event);
                    combobox.openDropdown();
                  }}
                  onKeyDown={handleInputKeydown}
                  onPaste={handlePaste}
                  readOnly={readOnly}
                  required={required ? _value.length === 0 : undefined}
                  unstyled={unstyled}
                  value={_searchValue}
                />
              </Combobox.EventsTarget>
              {isPending ? <Loader size="sm" /> : null}
            </Pill.Group>
            {legend ? (
              <>
                <Space h={5} />
                <Input.Description component="span" {...descriptionProps}>
                  {legendOnInput && _value.length === 0 ? null : legend}
                </Input.Description>
              </>
            ) : null}
          </PillsInput>
        </Combobox.DropdownTarget>

        <OptionsDropdown
          aria-label={label ? undefined : others["aria-label"]}
          data={filterPickedTags({ data: parsedData, value: _value })}
          filter={filter}
          hidden={readOnly || disabled}
          hiddenWhenEmpty
          labelId={label ? `${_id}-label` : undefined}
          limit={limit}
          maxDropdownHeight={maxDropdownHeight}
          renderOption={renderOption}
          scrollAreaProps={scrollAreaProps}
          search={_searchValue}
          unstyled={unstyled}
          withScrollArea={withScrollArea}
        />
      </Combobox>
      <Combobox.HiddenInput
        disabled={disabled}
        form={form}
        name={name}
        value={_value}
        valuesDivider={hiddenInputValuesDivider}
        {...hiddenInputProps}
      />
    </>
  );
});

StyleableTagsInput.classes = { ...InputBase.classes, ...Combobox.classes };
StyleableTagsInput.displayName = "@mantine/core/StyleableTagsInput";
