import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { Box } from "@mantine/core";
import clsx from "clsx";
import { memo, useRef, useState, useEffect } from "react";
import invariant from "tiny-invariant";

import { Duration } from "../../../../../../../../../../common/calendar/Duration";
import type { LocalDateInternal } from "../../../../../../../../../../common/calendar/LocalDateInternal";
import type { LocalTimeInternal } from "../../../../../../../../../../common/calendar/LocalTimeInternal";
import type { DropDestinationData, DropSourceData } from "../../../../../../hooks/useDeliveryBoxDrop";
import { DestinationId } from "../../../../../../hooks/useDeliveryBoxDrop";
import type { ScheduledOrder } from "../../../../../../types/ScheduledOrder";
import { DeliveryBox } from "../../../../../DeliveryBox/DeliveryBox";
import { ROW_HEIGHT_PX, PX_PER_MINUTE } from "../../../../common/constants";

import classes from "./TimeSlot.module.css";

interface TimeSlotProps {
  date: LocalDateInternal;
  time: LocalTimeInternal;
  order?: ScheduledOrder;
}

export const TimeSlot = memo(function TimeSlot({ date, time, order }: TimeSlotProps) {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const [high, setHigh] = useState(ROW_HEIGHT_PX);

  useEffect(() => {
    const el = ref.current;
    invariant(el);

    return dropTargetForElements({
      element: el,
      getData: () =>
        ({
          destinationId: DestinationId.calendar,
          date: date,
          time: time,
        }) satisfies DropDestinationData,
      onDragEnter: (base) => {
        setIsDraggedOver(true);
        const data: DropSourceData = base.source.data as unknown as DropSourceData;
        setHigh(Duration.parse(data.duration).toMinutes() * PX_PER_MINUTE);
      },
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    });
  }, [date, time]);

  return (
    <div className={classes.timeSlot}>
      <Box
        className={clsx(classes.dropContainer, {
          [classes.isDraggingOver as string]: isDraggedOver,
        })}
        h={high}
        ref={ref}
      >
        {order ? <DeliveryBox order={order} /> : null}
      </Box>
    </div>
  );
});
