import type { SegmentedControlProps } from "@mantine/core";
import { SegmentedControl } from "@mantine/core";

import { ContactIdentifierType } from "./ContactIdentifierType";

interface ContactIdentifierSegmentControlProps extends Omit<SegmentedControlProps, "data"> {}

export function ContactIdentifierSegmentControl({ ...rest }: ContactIdentifierSegmentControlProps) {
  return (
    <SegmentedControl
      data={[
        {
          value: ContactIdentifierType.CUSTOMER,
          label: "Kunde",
        },
        {
          value: ContactIdentifierType.CUSTOMER_SUPPLIER,
          label: "Kunde & Lieferant",
        },
        {
          value: ContactIdentifierType.SUPPLIER,
          label: "Lieferant",
        },
      ]}
      {...rest}
    />
  );
}
