import { useForm, isNotEmpty, hasLength } from "@mantine/form";

import { trimRecursively } from "../../../../common/Utils";
import type { ArticleDto } from "../../../../generated/client/octalog-service";

const VALIDATION_MESSAGES = {
  NAME_REQUIRED: "Bitte geben Sie einen Artikelnamen ein",
  NAME_LENGTH: "Bitte geben Sie einen Namen zwischen 1 und 50 Zeichen ein",
  PRICE_MIN: "Preis muss größer als 0 sein",
  PRICE_MAX: "Preis muss kleiner als 1.000.000 sein",
  SKU_MIN_LENGTH: "Artikelnummer muss mindestens 2 Zeichen haben",
  SKU_FORMAT: "Artikelnummer darf nur Buchstaben, Zahlen und Bindestriche enthalten",
} as const;

const VALIDATION_RULES = {
  NAME_MAX_LENGTH: 50,
  SKU_MIN_LENGTH: 2,
  PRICE_MAX: 1_000_000,
} as const;

function transformValues(values: ArticleDto): ArticleDto {
  const trimmedValues = trimRecursively<ArticleDto>(values);
  if (!trimmedValues) {
    throw new Error("Contact data may not be null");
  }
  return trimmedValues;
}

export const useArticleForm = () => {
  return useForm<ArticleDto>({
    validateInputOnBlur: true,
    initialValues: {
      name: "",
      description: "",
      price: undefined,
      sku: "",
    },
    validate: {
      name: (value) => {
        return (
          isNotEmpty(VALIDATION_MESSAGES.NAME_REQUIRED)(value) ||
          hasLength({ min: 1, max: VALIDATION_RULES.NAME_MAX_LENGTH }, VALIDATION_MESSAGES.NAME_LENGTH)(value) ||
          null
        );
      },
      price: (value) => {
        if (!value) return null;
        if (value <= 0) return VALIDATION_MESSAGES.PRICE_MIN;
        if (value > VALIDATION_RULES.PRICE_MAX) return VALIDATION_MESSAGES.PRICE_MAX;
        return null;
      },
      sku: (value) => {
        if (value.length < VALIDATION_RULES.SKU_MIN_LENGTH) return VALIDATION_MESSAGES.SKU_MIN_LENGTH;
        if (!/^[\dA-Za-z-]+$/.test(value)) return VALIDATION_MESSAGES.SKU_FORMAT;
        return null;
      },
    },
    transformValues: transformValues,
  });
};
