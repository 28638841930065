import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  unscheduleDeliveryMutation,
  getOrderQueryKey,
  getDeliveryScheduleQueryKey,
  getOrdersQueryKey,
} from "../../../generated/client/octalog-service/@tanstack/react-query.gen";
import { useBaseMutation } from "../../../hooks/useBaseMutation";
import { useCalendarTime } from "../views/DeliverySchedulingView/components/Calendar/hooks/useCalendarTime";

export function useUnscheduleDeliveryMutation() {
  const queryClient = useQueryClient();
  const { weekStart, weekEnd } = useCalendarTime();

  return useMutation({
    ...useBaseMutation({
      ...unscheduleDeliveryMutation(),
      successMessage: "Der Liefertermin wurde erfolgreich zurückgesetzt.",
      errorMessage: "Der Liefertermin konnte nicht zurückgesetzt werden",
      onSuccess: (_, request) => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({
          queryKey: getOrderQueryKey({
            path: {
              orderNumber: request.query.orderNumber,
            },
          }),
        });

        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({
          queryKey: getOrdersQueryKey(),
        });

        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({
          queryKey: getDeliveryScheduleQueryKey({
            query: {
              startDate: weekStart.toString(),
              endDate: weekEnd.toString(),
            },
          }),
        });
      },
    }),
  });
}
