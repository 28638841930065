import { Text } from "@mantine/core";

interface ContainerCountProps {
  selectedCount: number;
  totalCount: number;
}

export function ContainerCount({ selectedCount, totalCount }: ContainerCountProps) {
  return (
    <Text c="gray" fw={700} size="sm">
      {selectedCount} von {totalCount} ausgewählt
    </Text>
  );
}
