import { Stack, Title, Text, Loader } from "@mantine/core";
import { IconHourglass } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";

interface AuthLoadingStateProps {
  title: string;
}

export function AuthLoadingState({ title }: AuthLoadingStateProps) {
  const commonColors = useCommonColors();

  return (
    <Stack align="center" gap="md">
      <Title order={2}>{title}</Title>
      <IconHourglass aria-hidden="true" color={commonColors.white} size={40} />
      <Text>Bitte warten Sie einen Moment.</Text>
      <Loader aria-label="Lädt..." />
    </Stack>
  );
}
