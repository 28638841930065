import dayjs from "dayjs";

export class LocalDateInternal {
  readonly __localDateInternal = true;
  // noinspection JSUnusedGlobalSymbols
  readonly __preserveDuringTrim = true;

  readonly day: number;
  readonly month: number;
  readonly year: number;

  constructor(day: number, month: number, year: number) {
    if (!Number.isInteger(day) || !Number.isInteger(month) || !Number.isInteger(year)) {
      throw new TypeError("Day, month, and year must be integers");
    }
    if (month < 1 || month > 12) {
      throw new Error("Month must be between 1 and 12");
    }
    if (day < 1 || day > 31) {
      throw new Error("Day must be between 1 and 31");
    }
    this.day = day;
    this.month = month;
    this.year = year;
  }

  static fromDate(date: Date): LocalDateInternal {
    return new LocalDateInternal(date.getDate(), date.getMonth() + 1, date.getFullYear());
  }

  static fromDayjs(date: dayjs.Dayjs): LocalDateInternal {
    return new LocalDateInternal(date.date(), date.month() + 1, date.year());
  }

  static fromString(date: string, format?: string): LocalDateInternal {
    const parsed = format ? dayjs(date, format) : dayjs(date);
    if (!parsed.isValid()) {
      throw new Error("Invalid date string");
    }
    return LocalDateInternal.fromDayjs(parsed);
  }

  static fromStringOrNull(date: string | null | undefined, format?: string): LocalDateInternal | null {
    if (!date) {
      return null;
    }
    return LocalDateInternal.fromString(date, format);
  }

  static fromStringOrUndefined(date: string | null | undefined, format?: string): LocalDateInternal | undefined {
    if (!date) {
      return undefined;
    }
    return LocalDateInternal.fromString(date, format);
  }

  static today(): LocalDateInternal {
    const now = dayjs();
    return LocalDateInternal.fromDayjs(now);
  }

  static weekStart(week?: Date | LocalDateInternal | string): LocalDateInternal {
    if (!week) {
      return LocalDateInternal.fromDayjs(dayjs().startOf("week"));
    }

    return week instanceof LocalDateInternal
      ? LocalDateInternal.fromDayjs(dayjs(week.toDate()).startOf("week"))
      : LocalDateInternal.fromDayjs(dayjs(week).startOf("week"));
  }

  static weekEnd(week?: Date | LocalDateInternal | string): LocalDateInternal {
    if (!week) {
      return LocalDateInternal.fromDayjs(dayjs().endOf("week"));
    }

    return week instanceof LocalDateInternal
      ? LocalDateInternal.fromDayjs(dayjs(week.toDate()).endOf("week"))
      : LocalDateInternal.fromDayjs(dayjs(week).endOf("week"));
  }

  addWeeks(weeks: number): LocalDateInternal {
    return LocalDateInternal.fromDayjs(this.toDayjs().add(weeks, "week"));
  }

  subtractWeeks(weeks: number): LocalDateInternal {
    return LocalDateInternal.fromDayjs(this.toDayjs().subtract(weeks, "week"));
  }

  addDays(days: number): LocalDateInternal {
    return LocalDateInternal.fromDayjs(this.toDayjs().add(days, "day"));
  }

  subtractDays(days: number): LocalDateInternal {
    return LocalDateInternal.fromDayjs(this.toDayjs().subtract(days, "day"));
  }

  toDayjs(): dayjs.Dayjs {
    return dayjs(this.toString());
  }

  toDate(): Date {
    return new Date(this.toString());
  }

  valueOf(): number {
    return this.toDate().valueOf();
  }

  toString(): string {
    const paddedMonth = this.month.toString().padStart(2, "0");
    const paddedDay = this.day.toString().padStart(2, "0");
    return `${this.year}-${paddedMonth}-${paddedDay}`;
  }

  humanize(): string {
    return this.toDayjs().format("L");
  }

  isoWeek(): number {
    return this.toDayjs().isoWeek();
  }

  isToday(): boolean {
    return this.toDayjs().isSame(dayjs(), "day");
  }

  dayOfWeek(): number {
    return this.toDayjs().weekday();
  }

  equals(other: LocalDateInternal): boolean {
    return this.day === other.day && this.month === other.month && this.year === other.year;
  }

  isSameWeek(other: LocalDateInternal): boolean {
    return dayjs(this.toDate()).isSame(other.toDayjs(), "week");
  }
}
