import { Text } from "@mantine/core";
import { map } from "lodash-es";

import type { EditTableColumn } from "./EditTableTypes";

interface EditTableDetailsProps {
  id: string;
  data: EditTableColumn[];
}

export function EditTableDetails({ id, data }: EditTableDetailsProps) {
  return (
    <>
      {map(data, (value) => (
        <div
          data-test-id={`edit-table-details-${id}-${value.id}`}
          key={`${id}-${value.id}`}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <Text size="sm" truncate="end">
            {value.label}
          </Text>
        </div>
      ))}
    </>
  );
}
