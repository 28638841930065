import { roundToTwoDecimalPlaces } from "../../../../../common/math/math";
import { formatCurrency } from "../../../../../common/UnitFormatter";
import type { PriceContainerDto, PriceDto } from "../../../../../generated/client/octalog-service";

const PRICE_CONSTANTS = {
  DEFAULT_RESULT: {
    description: "-- €",
    requireManualPrice: false,
  } as PriceResult,

  NO_AUTO_PRICE: {
    description: "Die automatische Preisberechnung ist nicht aktiviert.",
    requireManualPrice: true,
  } as PriceResult,

  NO_PRICE_AVAILABLE: {
    description: "Für diese Variante ist kein Preis verfügbar",
    requireManualPrice: true,
  } as PriceResult,
} as const;

export interface PriceConfig {
  woodTypeId: number;
  humidityLevelId: number;
  cuttingLengthId: number;
}

export interface PriceCalculationProps {
  priceData: PriceContainerDto;
  woodTypeId?: number;
  humidityLevelId?: number;
  cuttingLengthId?: number;
  volume?: number;
}

export interface PriceResult {
  price?: number;
  description: string;
  requireManualPrice: boolean;
}

function findMatchingPrice(prices: PriceDto[], config: PriceConfig): PriceDto | undefined {
  return prices.find(
    (price) =>
      price.woodTypeId === config.woodTypeId &&
      price.humidityLevelId === config.humidityLevelId &&
      price.cuttingLengthId === config.cuttingLengthId,
  );
}

function calculateFinalPrice(basePrice: number, volume: number): PriceResult {
  const totalPrice = roundToTwoDecimalPlaces(basePrice * volume);
  return {
    price: totalPrice,
    description: `${formatCurrency(basePrice)} pro m³`,
    requireManualPrice: false,
  };
}

function validateInputs(
  woodTypeId?: number,
  humidityLevelId?: number,
  cuttingLengthId?: number,
  volume?: number,
): boolean {
  return Boolean(woodTypeId && humidityLevelId && cuttingLengthId && volume);
}

export function usePriceCalculation({
  priceData,
  woodTypeId,
  humidityLevelId,
  cuttingLengthId,
  volume,
}: PriceCalculationProps): PriceResult {
  if (priceData.prices.length === 0) {
    return PRICE_CONSTANTS.NO_AUTO_PRICE;
  }

  if (!validateInputs(woodTypeId, humidityLevelId, cuttingLengthId, volume)) {
    return PRICE_CONSTANTS.DEFAULT_RESULT;
  }

  const priceConfig: PriceConfig = {
    woodTypeId: Number(woodTypeId),
    humidityLevelId: Number(humidityLevelId),
    cuttingLengthId: Number(cuttingLengthId),
  };

  const price = findMatchingPrice(priceData.prices, priceConfig);
  if (!price) {
    return PRICE_CONSTANTS.NO_PRICE_AVAILABLE;
  }

  const basePrice = price.priceOverride ?? price.price;
  return calculateFinalPrice(basePrice, volume!);
}
