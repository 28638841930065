import type { BoxProps } from "@mantine/core";
import { Flex, ActionIcon, Box, Divider, Grid, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";

import { formatCurrency } from "../../../../../../common/UnitFormatter";
import { LabelValue } from "../../../../../../components/output/LabelValue/LabelValue";
import type { OrderDto } from "../../../../../../generated/client/octalog-service";

import { AddressDetails } from "./DeliveryDetails/AddressDetails/AddressDetails";
import { DeliveryScheduleDetails } from "./DeliveryDetails/DeliveryScheduleDetails/DeliveryScheduleDetails";
import { DesiredDeliveryPeriodDetails } from "./DeliveryDetails/DesiredDeliveryPeriodDetails/DesiredDeliveryPeriodDetails";
import classes from "./OrdersDetails.module.css";
import { OrderUpdateModal } from "./OrderUpdateModal/OrderUpdateModal";
import { ProductDetails } from "./ProductDetails/ProductDetails";

export interface OrderDetailsProps extends BoxProps {
  order: OrderDto;
}

export function OrderDetails({ order, ...rest }: OrderDetailsProps) {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const commonColors = useCommonColors();
  return (
    <Box className={classes.details} px={{ base: 0, md: 20, lg: 50 }} {...rest}>
      <Stack px="xl" py="lg">
        <Text fw={700} size="md">
          Auftragsnummer #{order.orderNumber}
        </Text>

        <Divider my="sm" />
        <DesiredDeliveryPeriodDetails order={order} />

        <Divider my="sm" />
        <DeliveryScheduleDetails order={order} />

        <Divider my="sm" />

        <AddressDetails order={order} />

        <Divider my="sm" />

        <Text fw={700} size="md">
          Produkte
        </Text>
        {order.items.map((item, index) => (
          <div key={item.orderItemId}>
            <ProductDetails item={item} />
            {index < order.items.length - 1 ? <Divider mt="md" mx={50} /> : null} {/* Divider between products */}
          </div>
        ))}

        <Divider my="sm" />

        <Grid align="flex-end" ta="left">
          <Grid.Col span={6}>
            <LabelValue label="Gesamtpreis" value={formatCurrency(order.totalPrice)} />
            {order.totalPriceOverride ? (
              <LabelValue label="Angebot" value={formatCurrency(order.totalPriceOverride)} />
            ) : null}
          </Grid.Col>
          <Grid.Col span={6}>
            <LabelValue label="Endpreis" value={formatCurrency(order.totalPriceOverride ?? order.totalPrice)} />
          </Grid.Col>
        </Grid>
        <Flex justify="flex-end">
          <ActionIcon color={commonColors.primaryColor} onClick={openModal} size="sm" variant="subtle">
            <IconEdit />
          </ActionIcon>
        </Flex>
        <Flex justify="flex-end">
          <OrderUpdateModal onClose={closeModal} opened={modalOpened} order={order} />
        </Flex>
      </Stack>
    </Box>
  );
}
