import { PriceInput } from "../number/PriceInput";

import { BaseFloatingLabelInput } from "./BaseFloatingLabelInput";
import type { FloatingLabelPriceInputProps } from "./types";

/**
 * InputBase component with floating label behavior.
 * The label floats above the input when the input has value or is focused.
 */
export function FloatingLabelPriceInput({
  value,
  onBlur,
  onFocus,
  classNames: externalClassNames,
  ...rest
}: FloatingLabelPriceInputProps) {
  return (
    <BaseFloatingLabelInput<HTMLInputElement> onBlur={onBlur} onFocus={onFocus} value={value}>
      {({ classNames: baseClassNames, floating, handleBlur, handleFocus }) => (
        <PriceInput
          data-floating={floating}
          labelProps={{ "data-floating": floating }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          {...rest}
          classNames={{ ...baseClassNames, ...externalClassNames }}
        />
      )}
    </BaseFloatingLabelInput>
  );
}
