export function useLoaderErrorMessage(error?: (Error | null)[] | Error | null): string | undefined {
  if (error === null || error === undefined) {
    return undefined;
  }
  if (Array.isArray(error)) {
    return error
      .filter((e) => e !== null)
      .map((e) => e.message)
      .join(" ");
  }
  return error.message;
}
