import dayjs from "dayjs";
import locale from "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";

export function configureDayJs() {
  dayjs.extend(utc);
  dayjs.extend(LocalizedFormat);
  dayjs.extend(customParseFormat);
  dayjs.extend(isBetween);
  dayjs.locale(locale);
  dayjs.extend(weekday);
  dayjs.extend(isoWeek);
}
