import type { StyleProp } from "@mantine/core";
import { Paper, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { RenderJson } from "octalog-base";
import React, { useRef, useEffect } from "react";

import { QueryLoaderBoundary } from "../../../../../../components/loader/QueryLoaderBoundary";
import { useGetOrders } from "../../../../query/useGetOrders";
import { toScheduledOrders } from "../../types/ScheduledOrder";

import { CalendarGrid } from "./CalendarGrid/CalendarGrid";
import { CalendarHeader } from "./CalendarHeader/CalendarHeader";
import { CalendarNavigation } from "./CalendarNavigation/CalendarNavigation";
import { useCalendarTime } from "./hooks/useCalendarTime";

interface CalendarProps {
  maxHeight: StyleProp<React.CSSProperties["maxHeight"]>;
}

export function Calendar({ maxHeight }: CalendarProps) {
  const { currentWeek, weekStart, weekEnd, handlePreviousWeek, handleNextWeek } = useCalendarTime();
  const scheduledOrdersQuery = useGetOrders({
    scheduledDeliveryDateFrom: weekStart,
    scheduledDeliveryDateTo: weekEnd,
  });

  const viewport = useRef<HTMLDivElement>(null);

  // Scroll to 8:00 on mount
  useEffect(() => {
    if (scheduledOrdersQuery.isSuccess) {
      viewport.current?.scrollTo({ top: 0 });
    }
  }, [scheduledOrdersQuery.isSuccess]);

  const isMobile = useMediaQuery("(max-width: 600px)") ?? false;

  function renderContent() {
    return isMobile ? (
      renderGrid()
    ) : (
      <Paper h="100%" withBorder>
        {renderGrid()}
      </Paper>
    );
  }

  function renderGrid() {
    return (
      <QueryLoaderBoundary mt="50vh" queryResult={scheduledOrdersQuery}>
        {({ data }) => (
          <Stack gap={0} h="100%" mah={maxHeight} p={0}>
            <CalendarNavigation
              currentWeek={currentWeek}
              isMobile={isMobile}
              onNextWeek={handleNextWeek}
              onPreviousWeek={handlePreviousWeek}
            />
            <CalendarHeader isMobile={isMobile} weekStart={weekStart} />
            <CalendarGrid isMobile={isMobile} orders={toScheduledOrders(data)} weekStart={weekStart} />
            <RenderJson data={data} title="Orders" />
          </Stack>
        )}
      </QueryLoaderBoundary>
    );
  }

  return renderContent();
}
