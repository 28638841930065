import { useLocation } from "react-router";

import type { Link } from "./links";

function isIndexView(link: Link) {
  return link.to === "/containers" || link.to === "/containers/list";
}

export const useActiveLink = (link: Link) => {
  const location = useLocation();
  if (location.pathname === "/" && isIndexView(link)) {
    return true;
  }
  return link.subLinks ? location.pathname.startsWith(link.to) : location.pathname === link.to;
};
