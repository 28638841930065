import { useCommonColors } from "octalog-base";
import { useMemo } from "react";

import { getLinks } from "./links";
import { MemorizedNavigationLink } from "./NavigationLink";

interface NavigationProps {
  closeNavigationInMobileModeCallback: () => void;
}

export function Navigation({ closeNavigationInMobileModeCallback }: NavigationProps) {
  const commonColors = useCommonColors();
  const links = useMemo(() => getLinks(commonColors), [commonColors]);

  const navigationLinks = useMemo(
    () =>
      links.map((link) => (
        <MemorizedNavigationLink
          closeNavigationInMobileModeCallback={closeNavigationInMobileModeCallback}
          key={link.id}
          link={link}
        />
      )),
    [links, closeNavigationInMobileModeCallback],
  );

  return <div>{navigationLinks}</div>;
}
