import { Text, Grid } from "@mantine/core";

import { Duration } from "../../../../../../../../common/calendar/Duration";
import { LocalDateInternal } from "../../../../../../../../common/calendar/LocalDateInternal";
import { LocalTimeInternal } from "../../../../../../../../common/calendar/LocalTimeInternal";
import { LabelValue } from "../../../../../../../../components/output/LabelValue/LabelValue";
import type { OrderDto } from "../../../../../../../../generated/client/octalog-service";

export interface DeliveryScheduleDetailsProps {
  order: OrderDto;
}

export function DeliveryScheduleDetails({ order }: DeliveryScheduleDetailsProps) {
  return (
    <Grid>
      <Grid.Col span={12}>
        <Text fw={700}>Geplante Lieferung</Text>
      </Grid.Col>
      {order.deliverySchedule ? (
        <>
          <Grid.Col span={{ base: 12, xs: 6 }} ta="left">
            <LabelValue
              label="Datum"
              value={LocalDateInternal.fromString(order.deliverySchedule.deliveryDate).humanize()}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6 }} ta="left">
            <LabelValue
              label="Uhrzeit"
              value={LocalTimeInternal.fromString(order.deliverySchedule.deliveryTime).humanize()}
            />
            <LabelValue label="Dauer" value={Duration.parse(order.deliverySchedule.duration).humanize()} />
          </Grid.Col>
        </>
      ) : (
        <Grid.Col span={12}>
          <Text c="dimmed" fw={500} size="sm">
            Noch nicht geplant
          </Text>
        </Grid.Col>
      )}
    </Grid>
  );
}
