import { isEmail, isInRange, isNotEmpty } from "@mantine/form";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js/min";
import type { ReactNode } from "react";

import { CUSTOMER_ID_MAX, CUSTOMER_ID_MIN, SUPPLIER_ID_MAX, SUPPLIER_ID_MIN } from "../../../../common/Constants";
import type { ContactDetailAddressType, ContactCreateDto } from "../../../../generated/client/octalog-service";
import { ContactType } from "../../../../generated/client/octalog-service";
import { SalutationType } from "../contact/individual/salutation/SalutationType";
import { ContactIdentifierType } from "../identifier/ContactIdentifierType";

import type { ContactData } from "./contactFormContext";

export function validationWrapper(
  func: (value: unknown) => string | number | true | ReactNode | null,
  nullable = false,
  skip = false,
): (value: unknown) => string | number | true | ReactNode | null {
  return (value) => {
    return skip || (nullable && !value) ? null : func(value);
  };
}

function validateAddressType(value: ContactDetailAddressType, values: ContactCreateDto) {
  if (values.addresses.filter((address) => address.addressType === value).length > 1) {
    return "Jeder Adresstyp darf nur einmal verwendet werden!";
  }
}

function getIndexFromKey(key: string) {
  const index = key.match(/\d+/);
  return index ? Number.parseInt(index[0]) : -1;
}

function validatePhoneNumber(value: string | undefined, formContact: ContactData, key: string) {
  if (!value || value.trim().length === 0) {
    return "Bitte geben Sie eine Telefonnummer ein.";
  } else {
    const countryCode = formContact.phoneNumbers[getIndexFromKey(key)]?.countryCode;
    if (!countryCode) {
      return "Bitte geben Sie ein Land ein.";
    }
    if (!isPossiblePhoneNumber(value, countryCode) || !isValidPhoneNumber(value, countryCode)) {
      return "Bitte geben Sie eine gültige Telefonnummer ein.";
    }
  }
}

export const validate = {
  individualContact: {
    salutation: (value: string | undefined, values: ContactData) =>
      validationWrapper(
        isNotEmpty("Bitte geben Sie eine Anrede ein."),
        values.contactType !== ContactType.INDIVIDUAL ||
          values.individualContact?.salutationType !== SalutationType.OTHER,
      )(value),
    lastName: (value: string, values: ContactData) =>
      validationWrapper(
        isNotEmpty("Bitte geben Sie einen Nachnamen ein."),
        false,
        values.contactType !== ContactType.INDIVIDUAL,
      )(value),
  },
  companyContact: {
    companyName: (value: string, values: ContactData) =>
      validationWrapper(
        isNotEmpty("Bitte geben Sie einen Firmennamen ein."),
        values.contactType !== ContactType.COMPANY,
      )(value),
  },
  contactIdentifierCustomer: {
    customerId: (value: number | undefined, values: ContactData) =>
      validationWrapper(
        isInRange(
          {
            min: CUSTOMER_ID_MIN,
            max: CUSTOMER_ID_MAX,
          },
          `Kundennummer muss zwischen ${CUSTOMER_ID_MIN} und ${CUSTOMER_ID_MAX} liegen.`,
        ),
        true,
        values.selectedContactIdentifier !== ContactIdentifierType.CUSTOMER &&
          values.selectedContactIdentifier !== ContactIdentifierType.CUSTOMER_SUPPLIER,
      )(value),
  },
  contactIdentifierSupplier: {
    supplierId: (value: number | undefined, values: ContactData) =>
      validationWrapper(
        isInRange(
          {
            min: SUPPLIER_ID_MIN,
            max: SUPPLIER_ID_MAX,
          },
          `Lieferantennummer muss zwischen ${SUPPLIER_ID_MIN} und ${SUPPLIER_ID_MAX} liegen.`,
        ),
        true,
        values.selectedContactIdentifier !== ContactIdentifierType.SUPPLIER &&
          values.selectedContactIdentifier !== ContactIdentifierType.CUSTOMER_SUPPLIER,
      )(value),
    customerIdAtSupplier: (value: string | undefined, values: ContactData) =>
      validationWrapper(
        isNotEmpty("Bitte geben Sie eine valide Kundennummer bei diesem Lieferanten ein."),
        true,
        values.selectedContactIdentifier !== ContactIdentifierType.SUPPLIER &&
          values.selectedContactIdentifier !== ContactIdentifierType.CUSTOMER_SUPPLIER,
      )(value),
  },
  note: (value: string | undefined) => {
    if (value) {
      return value.length > 255 ? "Maximal 255 Zeichen erlaubt." : undefined;
    }
  },
  addresses: {
    addressType: (value: string, values: ContactData) => validateAddressType(value as ContactDetailAddressType, values),
    city: isNotEmpty("Bitte geben Sie eine Stadt ein."),
    country: isNotEmpty("Bitte geben Sie ein Land ein."),
    postalCode: isNotEmpty("Bitte geben Sie eine Postleitzahl ein."),
    street: isNotEmpty("Bitte geben Sie eine Straße ein."),
  },
  emails: {
    email: isEmail("Bitte geben Sie eine gültige E-Mail-Adresse ein."),
  },
  phoneNumbers: {
    phoneNumber: (value: string, formContact: ContactData, key: string) => validatePhoneNumber(value, formContact, key),
  },
};
