import { Text } from "@mantine/core";
import { captureException } from "@sentry/react";
import React, { useEffect, useRef } from "react";

import type { DecimalScale2InputProps } from "./DecimalScale2Input";
import { DecimalScale2Input } from "./DecimalScale2Input";

export const CURRENCY_SYMBOL = "€";

export interface PriceInputProps extends DecimalScale2InputProps {
  externalValue?: number | string | undefined;
}

/**
 * Input component for price values with currency symbol
 * Handles both manual input and external value updates
 */
export function PriceInput({ externalValue, value, onChange, disabled, ...rest }: PriceInputProps): React.JSX.Element {
  const hasManualEdit = useRef(false);
  const previousExternalValue = useRef(externalValue);

  useEffect(() => {
    // Only update if externalValue has actually changed
    if (
      onChange != undefined &&
      externalValue !== previousExternalValue.current &&
      (disabled || !hasManualEdit.current)
    ) {
      previousExternalValue.current = externalValue;
      try {
        onChange?.(externalValue ?? "");
      } catch (error) {
        captureException(error, {
          extra: {
            externalValue,
            previousExternalValue: previousExternalValue.current,
          },
        });
      }
    }
  }, [externalValue, onChange, disabled]);

  const handleChange = (newValue: string | number) => {
    if (!disabled) {
      hasManualEdit.current = true;
      onChange?.(newValue);
    }
  };

  return (
    <DecimalScale2Input
      disabled={disabled}
      label="Preis"
      onChange={handleChange}
      rightSection={
        <Text mr={5} size="sm" variant="dimmed">
          {CURRENCY_SYMBOL}
        </Text>
      }
      styles={{ input: { textAlign: "center" } }}
      value={value}
      {...rest}
    />
  );
}
