import type { BoxProps, TextInputProps } from "@mantine/core";
import { Box, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

interface SearchFieldProps extends BoxProps {
  setSearchTerm: (term: string) => void;
  searchTerm: string;
  textInputProps?: TextInputProps;
}

export function SearchField({ setSearchTerm, searchTerm, textInputProps, ...rest }: SearchFieldProps) {
  return (
    <Box style={{ display: "flex", justifyContent: "center" }} {...rest}>
      <TextInput
        leftSection={<IconSearch size="1rem" />}
        onChange={(event) => setSearchTerm(event.currentTarget.value)}
        placeholder="Suche"
        value={searchTerm}
        {...textInputProps}
      />
    </Box>
  );
}
