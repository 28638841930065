import { Box, Flex, Grid, Group, LoadingOverlay, SimpleGrid, Text, useMantineTheme, Switch } from "@mantine/core";
import { DatePickerInput, DatesProvider } from "@mantine/dates";
import { IconArrowsExchange, IconCalendar, IconDownload, IconUpload } from "@tabler/icons-react";
import { useCommonColors } from "octalog-base";
import { useState } from "react";
import { Bar, ComposedChart, Legend, Line, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { formatDate, formatDateToYYYYMMDD } from "../../common/UnitFormatter";
import { IconPaper } from "../../components/paper/IconPaper";
import { useGetContentStatistics } from "../../service/statistics/StatisticsService";

export function GraphStatisticsInputOutput() {
  const commonColors = useCommonColors();
  const theme = useMantineTheme();
  const [showEmptyTimestamps, setShowEmptyTimestamps] = useState(false);

  const [contentStatisticsDateRange, setContentStatisticsDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const contentStatistics = useGetContentStatistics(
    showEmptyTimestamps,
    contentStatisticsDateRange[0] && contentStatisticsDateRange[1]
      ? formatDateToYYYYMMDD(contentStatisticsDateRange[0])
      : undefined,
    contentStatisticsDateRange[0] && contentStatisticsDateRange[1]
      ? formatDateToYYYYMMDD(contentStatisticsDateRange[1])
      : undefined,
  );

  function renderContent() {
    return (
      <Grid align="stretch" justify="space-between">
        <Grid.Col span={{ base: 12 }}>
          <SimpleGrid cols={{ base: 1, md: 2, lg: 2 }} ml={70} mr={30}>
            <Flex align="center" justify="flex-start">
              <DatesProvider settings={{ locale: "de", timezone: "UTC" }}>
                <DatePickerInput
                  clearable
                  leftSection={<IconCalendar />}
                  onChange={setContentStatisticsDateRange}
                  placeholder="Zeitraum auswählen"
                  type="range"
                  value={contentStatisticsDateRange}
                  valueFormat="DD.MM.YYYY"
                  w={270}
                />
              </DatesProvider>
            </Flex>
            <Flex align="center" justify="flex-end">
              <Switch
                checked={showEmptyTimestamps}
                label="Zeige Zeitpunkte ohne Daten"
                onChange={(event) => setShowEmptyTimestamps(event.currentTarget.checked)}
                styles={{
                  body: { justifyContent: "flex-end" },
                }}
                w={250}
              />
            </Flex>
          </SimpleGrid>
        </Grid.Col>
        <Grid.Col mb={50} span={{ base: 12 }}>
          <Box pos="relative">
            <LoadingOverlay
              overlayProps={{ radius: "sm", blur: 3, color: commonColors.body, backgroundOpacity: 0.1 }}
              visible={contentStatistics.isPending}
              zIndex={1000}
            />
            <ResponsiveContainer height={500} width="100%">
              <ComposedChart
                data={contentStatistics?.data?.contentLevelEventStatistics}
                margin={{
                  top: 5,
                  right: 10,
                  left: 0,
                  bottom: 5,
                }}
              >
                <XAxis
                  angle={-35}
                  dataKey="date"
                  textAnchor="end"
                  tickFormatter={(value) => {
                    return formatDate(value);
                  }}
                />
                <YAxis unit="m³" />
                <Tooltip
                  contentStyle={{ backgroundColor: commonColors.body }}
                  formatter={(value) => {
                    return value + " m³";
                  }}
                  labelFormatter={(value) => {
                    return formatDate(value);
                  }}
                />
                <Legend wrapperStyle={{ position: "relative", marginTop: 5 }} />
                <Bar
                  activeBar={<Rectangle stroke={commonColors.text} />}
                  barSize={10}
                  dataKey="fillVolume"
                  fill={commonColors.primaryColor}
                  name="Eingelagertes Holz"
                />
                <Bar
                  activeBar={<Rectangle stroke={commonColors.text} />}
                  barSize={10}
                  dataKey="emptyVolume"
                  fill={commonColors.redPrimary}
                  name="Ausgelagertes Holz"
                />
                <Line dataKey="stockVolume" name="Lagerbestand" stroke={commonColors.bluePrimary} type="monotone" />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Grid.Col>
        <Grid.Col span={{ base: 6 }}>
          <Group justify="center">
            <IconDownload size={20} stroke={2.5} />
            <Text size="1.4rem">{contentStatistics?.data?.sumFillVolume} m³</Text>
          </Group>
          <Text c="dimmed" mt={10}>
            Eingelagertes Holz im gesamten Zeitraum
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 6 }}>
          <Group justify="center">
            <IconUpload size={20} stroke={2.5} />
            <Text size="1.4rem">{contentStatistics?.data?.sumEmptyVolume} m³</Text>
          </Group>
          <Text c="dimmed" mt={10}>
            Ausgelagertes Holz im gesamten Zeitraum
          </Text>
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
      <IconPaper
        icon={IconArrowsExchange}
        iconColor={theme.colors.green}
        p={0}
        paperProps={{ p: 8 }}
        title="Holz Ein- und Ausgang"
        variant="light"
      >
        {renderContent()}
      </IconPaper>
    </Grid.Col>
  );
}
