import { Flex, Text } from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";

import type {
  PriceModifierCuttingLengthDto,
  PriceModifierHumidityLevelDto,
  PriceModifierWoodTypeDto,
  PriceSettingsDto,
} from "../../../../../generated/client/octalog-service";
import { useCuttingLengthIdTranslator } from "../../../../../service/settings/useCuttingLengthIdTranslator";
import { useHumidityLevelIdTranslator } from "../../../../../service/settings/useHumidityLevelIdTranslator";
import { useWoodTypeIdTranslator } from "../../../../../service/settings/useWoodTypeIdTranslator";

import { PriceModifierInput } from "./PriceModifierInput";
import { PriceModifierType } from "./PriceModifierType";

type ModifierType = PriceModifierWoodTypeDto | PriceModifierHumidityLevelDto | PriceModifierCuttingLengthDto;

interface PriceModifierSectionProps {
  title: string;
  type: PriceModifierType;
  modifiers: ModifierType[];
  form: UseFormReturnType<PriceSettingsDto, (values: PriceSettingsDto) => PriceSettingsDto>;
}

export function PriceModifierSection({ title, type, modifiers, form }: PriceModifierSectionProps) {
  const woodTypeIdTranslator = useWoodTypeIdTranslator();
  const humidityLevelIdTranslator = useHumidityLevelIdTranslator();
  const cuttingLengthIdTranslator = useCuttingLengthIdTranslator();

  function renderPriceModifiers(type: PriceModifierType, modifiers: ModifierType[]) {
    return modifiers.map((modifier, index) => (
      <PriceModifierInput
        key={form.key(`${type}.${index}.value`)}
        label={getLabel(modifier, type)}
        style={{ width: "6rem" }}
        {...form.getInputProps(`${type}.${index}.value`)}
      />
    ));
  }

  function getLabel(modifier: ModifierType, type: PriceModifierType): string | number {
    switch (type) {
      case PriceModifierType.CuttingLength: {
        return cuttingLengthIdTranslator((modifier as PriceModifierCuttingLengthDto).cuttingLengthId);
      }
      case PriceModifierType.HumidityLevel: {
        return humidityLevelIdTranslator((modifier as PriceModifierHumidityLevelDto).humidityLevelId);
      }
      case PriceModifierType.WoodType: {
        return woodTypeIdTranslator((modifier as PriceModifierWoodTypeDto).woodTypeId);
      }
      default: {
        throw new Error("Invalid price modifier type");
      }
    }
  }

  return (
    <>
      <Text fw={700} mt={10}>
        {title}
      </Text>
      <Flex direction="row" gap="sm" justify="center" wrap="wrap">
        {renderPriceModifiers(type, modifiers)}
      </Flex>
    </>
  );
}
