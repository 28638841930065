import { Grid } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconUpload } from "@tabler/icons-react";
import { RenderJson, RootContainer } from "octalog-base";
import { useState } from "react";

import { Selection, SELECTION_DEBOUNCE_TIME_MS } from "../../components/input/ContainerIdSelection/Selection";
import {
  SelectionFormProvider,
  useSelectionForm,
} from "../../components/input/ContainerIdSelection/SelectionFormContext";
import { ContainerDetails } from "../../components/output/container-details/ContainerDetails";
import { IconPaper } from "../../components/paper/IconPaper";

import { EmptyDialog } from "./components/EmptyDialog";

export function EmptyView() {
  const emptyContainerForm = useSelectionForm({
    initialValues: {
      containersWithState: [],
      isPending: false,
      error: "",
    },
  });

  const [selectedContainerNames, setSelectedContainerNames] = useDebouncedState<string[]>(
    [],
    SELECTION_DEBOUNCE_TIME_MS,
  );

  const [maxHeight, setMaxHeight] = useState(0);

  function heightOnChangeHandler(maxHeight: number) {
    setMaxHeight(maxHeight);
  }

  return (
    <RootContainer fluid>
      <SelectionFormProvider form={emptyContainerForm}>
        <Grid>
          <Grid.Col order={{ base: 1, sm: 1 }} span={{ base: 12, sm: 6 }}>
            <Selection
              heightOnChangeHandler={heightOnChangeHandler}
              mode="empty"
              selectedContainerNames={selectedContainerNames}
              setSelectedContainerNames={setSelectedContainerNames}
            />
          </Grid.Col>
          <Grid.Col order={{ base: 2, sm: 2 }} span={{ base: 12, sm: 6 }}>
            <ContainerDetails
              containers={emptyContainerForm.values.containersWithState}
              isPending={emptyContainerForm.values.isPending}
              maxHeight={maxHeight}
            />
          </Grid.Col>
          <Grid.Col order={{ base: 4, sm: 3 }} span={{ base: 12, sm: 6 }}>
            <IconPaper icon={IconUpload} p={0} paperProps={{ px: 8 }} title="Behälter entleeren">
              <EmptyDialog
                clearSelectedContainerIdsHandler={() => setSelectedContainerNames([])}
                hasError={emptyContainerForm.values.error !== ""}
                isPending={emptyContainerForm.values.isPending}
                selectedContainerIds={emptyContainerForm.values.containersWithState.map(
                  (container) => container.containerId,
                )}
              />
            </IconPaper>
          </Grid.Col>
        </Grid>
        <RenderJson data={emptyContainerForm.values} />
      </SelectionFormProvider>
    </RootContainer>
  );
}
