import { Grid, TextInput } from "@mantine/core";

import { nameOf } from "../../../../../../common/Utils";
import { ErrorSegmentControl } from "../../../../../../components/input/ErrorSegmentControl/ErrorSegmentControl";
import type { ContactData, IndividualContactData } from "../../../common/contactFormContext";
import { useCreateContactContext } from "../../../common/contactFormContext";

import { SALUTATION_TYPES, SalutationType } from "./SalutationType";

export function InputSalutation() {
  const form = useCreateContactContext();

  return (
    <>
      <Grid.Col span={12}>
        <ErrorSegmentControl
          data={SALUTATION_TYPES}
          fullWidth
          {...form.getInputProps(
            `${nameOf<ContactData>().individualContact}.${nameOf<IndividualContactData>().salutationType}`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        {form.values.individualContact?.salutationType === SalutationType.OTHER ? (
          <TextInput
            placeholder="Anrede"
            {...form.getInputProps(
              `${nameOf<ContactData>().individualContact}.${nameOf<IndividualContactData>().salutation}`,
            )}
          />
        ) : null}
      </Grid.Col>
    </>
  );
}
