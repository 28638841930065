import { createBrowserRouter, RouterProvider } from "react-router";

import { ErrorBoundary } from "./components/error/ErrorBoundary";
import { LayoutExternal } from "./layout/LayoutExternal";
import { LayoutInternal } from "./layout/LayoutInternal";
import { ContactListView } from "./views/contacts/list/ContactListView";
import { NewContactView } from "./views/contacts/new/NewContactView";
import { EmptyView } from "./views/empty/EmptyView";
import { FillView } from "./views/fill/FillView";
import { ConfirmPasswordReset } from "./views/iam/ConfirmPasswordReset";
import { SignIn } from "./views/iam/SignIn";
import { SignOut } from "./views/iam/SignOut";
import { SignUp } from "./views/iam/SignUp";
import { SignUpConfirmation } from "./views/iam/SignUpConfirmation";
import { StartPasswordReset } from "./views/iam/StartPasswordReset";
import { CreateOrderView } from "./views/orders/views/CreateOrderView/CreateOrderView";
import { DeliverySchedulingView } from "./views/orders/views/DeliverySchedulingView/DeliverySchedulingView";
import { ListOrdersView } from "./views/orders/views/ListOrdersView/ListOrdersView";
import { Settings } from "./views/settings/Settings";
import { Statistics } from "./views/statistics/Statistics";
import { Stock } from "./views/stock/Stock";

const routes = [
  {
    element: <LayoutExternal />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "sign-out",
        element: <SignOut />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "sign-up/confirm/:result",
        element: <SignUpConfirmation />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "password-reset",
        element: <StartPasswordReset />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "password-reset/confirm/:token",
        element: <ConfirmPasswordReset />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: "/",
    element: <LayoutInternal />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Stock />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "containers",
        children: [
          {
            path: "list",
            element: <Stock />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "statistics",
            element: <Statistics />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "fill",
            element: <FillView />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "empty",
            element: <EmptyView />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      {
        path: "orders",
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: "create",
            element: <CreateOrderView />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "list",
            element: <ListOrdersView />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "delivery",
            children: [
              {
                path: "planner",
                element: <DeliverySchedulingView />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
        ],
      },
      {
        path: "contacts",
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: "all",
            element: <ContactListView />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "new",
            element: <NewContactView />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      {
        path: "settings",
        element: <Settings />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "*",
        element: <Stock />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
];

export function Router() {
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}
