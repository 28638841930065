import {
  WoodType,
  CuttingLength,
  HumidityLevel,
  ContactDetailEmailType,
  ContactDetailPhoneNumberType,
  UnitOfMeasurement,
  ContactDetailAddressType,
  OrderStatus,
} from "../generated/client/octalog-service";

import { exhaustiveCheck } from "./Utils";

// Content
export type Translatable =
  | WoodType
  | CuttingLength
  | HumidityLevel
  | ContactDetailEmailType
  | ContactDetailPhoneNumberType;
type CombinedEnums = WoodType | CuttingLength | HumidityLevel | ContactDetailEmailType | ContactDetailPhoneNumberType;

export function translate(translatable: Translatable) {
  try {
    return translateCombinedEnums(translatable as CombinedEnums);
  } catch {
    console.error("Unknown type: " + translatable);
    return translatable.toString();
  }
}

export function translateUntyped(translatable: string | undefined) {
  if (translatable === undefined) {
    return undefined;
  }
  return translateCombinedEnums(translatable as CombinedEnums);
}

export function translatePossibleUndefined(translatable?: Translatable): string | undefined {
  if (translatable === undefined) {
    return undefined;
  }
  return translate(translatable);
}

function translateCombinedEnums(translatable: CombinedEnums) {
  switch (translatable) {
    // WoodTypes
    case WoodType.BEECH: {
      return "Buche";
    }
    case WoodType.OAK: {
      return "Eiche";
    }
    case WoodType.SPRUCE: {
      return "Fichte";
    }
    case WoodType.ASH: {
      return "Esche";
    }
    case WoodType.LARCH: {
      return "Lärche";
    }
    // CuttingLengths
    case CuttingLength.CM25: {
      return "25cm";
    }
    case CuttingLength.CM33: {
      return "33cm";
    }
    // HumidityLevels
    case HumidityLevel.DRIED: {
      return "Trocken";
    }
    case HumidityLevel.PRE_DRIED: {
      return "Vorgetrocknet";
    }
    case HumidityLevel.WET: {
      return "Frisch";
    }
    // EmailTypes and PhoneNumberTypes
    case ContactDetailEmailType.BUSINESS:
    case ContactDetailPhoneNumberType.BUSINESS: {
      return "Geschäftlich";
    }
    case ContactDetailEmailType.OFFICE:
    case ContactDetailPhoneNumberType.OFFICE: {
      return "Büro";
    }
    case ContactDetailEmailType.PRIVATE:
    case ContactDetailPhoneNumberType.PRIVATE: {
      return "Privat";
    }
    case ContactDetailEmailType.OTHER:
    case ContactDetailPhoneNumberType.OTHER: {
      return "Alternativ";
    }
    case ContactDetailPhoneNumberType.FAX: {
      return "Fax";
    }
    case ContactDetailPhoneNumberType.MOBILE: {
      return "Mobil";
    }
    default: {
      return exhaustiveCheck(translatable);
    }
  }
}

// ContainerType
type CombinedUnitOfMeasurementEnums = UnitOfMeasurement;

export function translateUnitOfMeasurement(translatable: UnitOfMeasurement, short = false) {
  try {
    return translateCombinedUnitOfMeasurementEnums(translatable as CombinedUnitOfMeasurementEnums, short);
  } catch {
    console.error("Unknown type: " + translatable);
    return translatable.toString();
  }
}

export function translateUnitOfMeasurementPossibleUndefined(translatable?: UnitOfMeasurement): string {
  if (translatable === undefined) {
    return "undefined";
  }
  return translateUnitOfMeasurement(translatable);
}

function translateCombinedUnitOfMeasurementEnums(translatable: CombinedUnitOfMeasurementEnums, short = false) {
  switch (translatable) {
    case UnitOfMeasurement.SOLID_CUBIC_METER: {
      return short ? "FM" : "Festmeter";
    }
    case UnitOfMeasurement.STACKED_CUBIC_METER: {
      return short ? "RM" : "Raummeter";
    }
    case UnitOfMeasurement.LOOSE_CUBIC_METER: {
      return short ? "SRM" : "Schüttraummeter";
    }
    default: {
      return exhaustiveCheck(translatable);
    }
  }
}

export function translateAddressType(translatable: ContactDetailAddressType) {
  switch (translatable) {
    case ContactDetailAddressType.INVOICE: {
      return "Rechnungsadresse";
    }
    case ContactDetailAddressType.DELIVERY: {
      return "Lieferadresse";
    }
    default: {
      return exhaustiveCheck(translatable);
    }
  }
}

export function translateOrderStatus(translatable: OrderStatus) {
  switch (translatable) {
    case OrderStatus.NEW: {
      return "Neu";
    }
    case OrderStatus.SCHEDULED: {
      return "Geplant";
    }
    case OrderStatus.UNSCHEDULED: {
      return "Planung entfernt";
    }
    case OrderStatus.RESCHEDULED: {
      return "Neu geplant";
    }
    case OrderStatus.IN_DELIVERY: {
      return "In Lieferung";
    }
    case OrderStatus.DELIVERED: {
      return "Geliefert";
    }
    case OrderStatus.CANCELED: {
      return "Abgebrochen";
    }
    default: {
      return exhaustiveCheck(translatable);
    }
  }
}

export function translateContactTypeToIcon(
  translatable: ContactDetailEmailType | ContactDetailPhoneNumberType,
  type: "email" | "phone",
) {
  switch (type) {
    case "email": {
      return translateEmailTypeToIcon(translatable as ContactDetailEmailType);
    }
    case "phone": {
      return translatePhoneNumberTypeToIcon(translatable as ContactDetailPhoneNumberType);
    }
  }
}

export function translateEmailTypeToIcon(translatable: ContactDetailEmailType) {
  switch (translatable) {
    case ContactDetailEmailType.PRIVATE: {
      return "🏠";
    }
    case ContactDetailEmailType.BUSINESS: {
      return "💼";
    }
    case ContactDetailEmailType.OFFICE: {
      return "🏢";
    }
    case ContactDetailEmailType.OTHER: {
      return "📬";
    }
    default: {
      return exhaustiveCheck(translatable);
    }
  }
}

export function translatePhoneNumberTypeToIcon(translatable: ContactDetailPhoneNumberType) {
  switch (translatable) {
    case ContactDetailPhoneNumberType.MOBILE: {
      return "📱";
    }
    case ContactDetailPhoneNumberType.FAX: {
      return "📠";
    }
    case ContactDetailPhoneNumberType.OTHER: {
      return "📞";
    }
    case ContactDetailPhoneNumberType.PRIVATE: {
      return "🏠";
    }
    case ContactDetailPhoneNumberType.OFFICE: {
      return "🏢";
    }
    case ContactDetailPhoneNumberType.BUSINESS: {
      return "💼";
    }
    default: {
      return exhaustiveCheck(translatable);
    }
  }
}
