export class LocalTimeInternal {
  readonly __localTimeInternal = true;
  // noinspection JSUnusedGlobalSymbols
  readonly __preserveDuringTrim = true;

  readonly hour: number;
  readonly minute: number;

  constructor(hour: number, minute: number) {
    if (hour < 0 || hour > 23) throw new Error(`Invalid hour: ${hour}`);
    if (minute < 0 || minute > 59) throw new Error(`Invalid minute: ${minute}`);
    this.hour = hour;
    this.minute = minute;
  }

  static now(): LocalTimeInternal {
    const now = new Date();
    return new LocalTimeInternal(now.getHours(), now.getMinutes());
  }

  static fromString(time: string): LocalTimeInternal {
    // Handle both HH:mm and HH:mm:ss formats (24-hour)
    const timeRegex = /^([01]?\d|2[0-3]):([0-5]\d)(?::([0-5]\d))?$/;
    const matches = time.match(timeRegex);

    if (matches && matches[1] && matches[2]) {
      const hour = Number.parseInt(matches[1], 10);
      const minute = Number.parseInt(matches[2], 10);
      return new LocalTimeInternal(hour, minute);
    }

    throw new Error(`Invalid time format. Expected HH:mm or HH:mm:ss (24-hour format). Got: ${time}`);
  }

  static fromStringOrUndefined(time: string | undefined): LocalTimeInternal | undefined {
    if (!time) {
      return undefined;
    }
    return LocalTimeInternal.fromString(time);
  }

  addMinutes(minutes: number): LocalTimeInternal {
    const totalMinutes = this.hour * 60 + this.minute + minutes;
    const normalizedMinutes = ((totalMinutes % 1440) + 1440) % 1440; // 1440 = 24 * 60
    const newHour = Math.floor(normalizedMinutes / 60);
    const newMinute = normalizedMinutes % 60;
    return new LocalTimeInternal(newHour, newMinute);
  }

  toString(): string {
    return `${this.hour.toString().padStart(2, "0")}:${this.minute.toString().padStart(2, "0")}`;
  }

  equals(other: LocalTimeInternal): boolean {
    return this.hour === other.hour && this.minute === other.minute;
  }

  valueOf(): number {
    return this.hour * 60 + this.minute;
  }

  humanize(): string {
    return this.toString();
  }
}
