import type { PillFactory, StackProps, Styles } from "@mantine/core";
import { Group, NumberInput, Stack, Text } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";

import type { ContainerWithState } from "../../../service/containers/useGetContainerStates";
import { StyleableTagsInput } from "../StyleableTagsInput/StyleableTagsInput";

import { colorLookup } from "./colorLookup";
import classes from "./ContainerIdSelection.module.css";
import { ContainerStateLegend } from "./ContainerStateLegend";
import { useNumberInputRange } from "./useNumberInputRange";

export type Mode = "fill" | "empty";

interface ContainerIdSelectionProps extends StackProps {
  mode: Mode;
  containerStates: ContainerWithState[];
  isPending?: boolean;
  containerIdsOnChangeHandler: (containerIds: string[]) => void;
  error: string;
}

export function ContainerIdSelection({
  mode,
  containerStates,
  containerIdsOnChangeHandler,
  error,
  isPending,
  ...rest
}: ContainerIdSelectionProps) {
  const logPrefix = "ContainerIdSelection -";

  const [isStyleableTagsInputSet, setIsStyleableTagsInputSet] = useState(false);

  const [internalContainerNames, setInternalContainerNames] = useState<string[]>([]);

  const { numberInputFrom, numberInputTo, setNumberInputFrom, setNumberInputTo, errorInputFrom, errorInputTo } =
    useNumberInputRange(setInternalContainerNames);

  useEffect(() => {
    const internalContainerNamesEmpty = internalContainerNames.length === 0;
    console.group(`${logPrefix} reset input`);
    console.log("Set:", internalContainerNamesEmpty);
    if (internalContainerNamesEmpty) {
      setIsStyleableTagsInputSet(false);
    }
    console.groupEnd();
  }, [internalContainerNames, internalContainerNames.length]);

  useEffect(() => {
    console.group(`${logPrefix} container selection handler`);
    const selectedContainerNames = internalContainerNames.map((it) => it.toString());
    console.log("Selected:", selectedContainerNames);
    containerIdsOnChangeHandler(selectedContainerNames);
    console.groupEnd();
  }, [internalContainerNames, containerIdsOnChangeHandler]);

  useEffect(() => {
    console.group(`${logPrefix} container selection reset handler`);
    console.log("Set:", containerStates.length === 0);
    if (containerStates.length === 0) {
      setInternalContainerNames([]);
    }
    console.groupEnd();
  }, [containerStates.length]);

  function mapToStyledValues(containerStates: ContainerWithState[]): Map<string, Styles<PillFactory>> {
    return new Map(
      containerStates.map((containerState) => [
        containerState.containerName,
        {
          root: {
            backgroundColor:
              typeof colorLookup[containerState.state] === "string"
                ? (colorLookup[containerState.state] as string)
                : (colorLookup[containerState.state] as Record<Mode, string>)[mode],
          },
        },
      ]),
    );
  }

  const handleStyleableTagsInputChange = useCallback((value: string[]) => {
    console.group(`${logPrefix} - StyleableTagsInput - OnChange`);
    const containerNames = value.map((it) => it.toString());
    console.log("ContainerNames:", containerNames);
    setInternalContainerNames(containerNames);
    const isStyleableTagsInputSet = value.length > 0;
    console.log("IsInputSet:", isStyleableTagsInputSet);
    setIsStyleableTagsInputSet(isStyleableTagsInputSet);
    console.groupEnd();
  }, []);

  const [numberInputFromTouched, setNumberInputFromTouched] = useState(false);
  const [numberInputToTouched, setNumberInputToTouched] = useState(false);

  return (
    <Stack {...rest}>
      <StyleableTagsInput
        classNames={{ inputField: classes.inputField }}
        clearable
        data={[]}
        data-testid="container-ids-input"
        disabled={numberInputFrom !== undefined || numberInputTo !== undefined}
        error={error}
        isPending={isPending}
        label=""
        legend={<ContainerStateLegend mode={mode} />}
        legendOnInput={true}
        mb={20}
        mx={20}
        onChange={handleStyleableTagsInputChange}
        placeholder="Behälter-Namen eingeben…"
        splitChars={[",", " "]}
        styledValues={mapToStyledValues(containerStates)}
        value={internalContainerNames}
        wrapperProps={{ "data-testid": "container-ids-input-wrapper" }}
      />
      <Stack gap="xs">
        <div style={{ flex: 1 }}>
          <Text fw={500} size="sm">
            Oder eine Spanne auswählen
          </Text>
          <Text c="dimmed" mt={7} size="xs">
            (Nur Zahlen möglich)
          </Text>
        </div>
        <Group align="flex-start" grow mb={20} mx={20}>
          <NumberInput
            disabled={isStyleableTagsInputSet}
            error={numberInputFromTouched ? errorInputFrom : null}
            label="Von"
            min={1}
            onBlur={() => setNumberInputFromTouched(true)}
            onChange={(value) => {
              if (value === "") {
                setNumberInputFrom(undefined);
              } else {
                setNumberInputFrom(Number(value));
                setNumberInputFromTouched(true);
              }
            }}
            placeholder="Behälter-Name"
          />
          <NumberInput
            disabled={isStyleableTagsInputSet}
            error={numberInputToTouched ? errorInputTo : null}
            label="Bis"
            min={1}
            onBlur={() => setNumberInputToTouched(true)}
            onChange={(value) => {
              if (value === "") {
                setNumberInputTo(undefined);
              } else {
                setNumberInputTo(Number(value));
                setNumberInputToTouched(true);
              }
            }}
            placeholder="Behälter-Name"
          />
        </Group>
      </Stack>
    </Stack>
  );
}
