import { InputBase } from "@mantine/core";
import { NumericFormat } from "react-number-format";

import { BaseFloatingLabelInput } from "./BaseFloatingLabelInput";
import type { FloatingLabelNumericFormatProps } from "./types";

/**
 * InputBase component with floating label behavior.
 * The label floats above the input when the input has value or is focused.
 */
export function FloatingLabelNumericFormat({
  value,
  onBlur,
  onFocus,
  classNames: externalClassNames,
  ...rest
}: FloatingLabelNumericFormatProps) {
  const numericValue = typeof value === "string" ? value : value?.toString();
  return (
    <BaseFloatingLabelInput<HTMLInputElement> onBlur={onBlur} onFocus={onFocus} value={value}>
      {({ classNames: baseClassNames, floating, handleBlur, handleFocus }) => (
        <InputBase
          component={NumericFormat}
          data-floating={floating}
          labelProps={{ "data-floating": floating }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={numericValue}
          {...rest}
          classNames={{ ...baseClassNames, ...externalClassNames }}
        />
      )}
    </BaseFloatingLabelInput>
  );
}
